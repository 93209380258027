import React, { useEffect, useState } from 'react';
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CCard, CCardBody } from '@coreui/react-pro';
import { I18n } from 'react-i18next';
import PropTypes from 'prop-types';

import { tabType } from './../../types/index';

const propTypes = {
  tabs: PropTypes.arrayOf(tabType),
  onTab: PropTypes.func,
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

function UiNavsTabs(props) {
  
  const [currentTab, setCurrentTab] = useState();
  
  useEffect(() => {
    let { activeTab } = props;
    if (props.tabs.length > 0 && (!activeTab || !props.tabs.find(obj => obj.id === activeTab))) {
      activeTab = props.tabs[0].id;
    }
    setCurrentTab(activeTab);
  }, [props])
  
  function getComponent(key) {
    if (props.children && props.children.props.children) {
      return props.children.props.children.filter(comp => comp.key === key);
    }
    return <div />;
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiNavsTabs">
          {props.tabs && props.tabs.length > 0 && (
            <CNav variant="tabs" role="tablist">
              {props.tabs.map((i, key) => (
                <CNavItem key={`nav-tabs-nav-${key}`}>
                  <CNavLink data-tab={i.id} onClick={() => props.onTab(i.id, i.route)} active={currentTab === i.id}>
                    {t(`Table.${i.id}`)}
                  </CNavLink>
                </CNavItem>
              ))}
            </CNav>
          )}
          {props.tabs && props.tabs.length > 0 && (

            <CCard style={{ marginTop: '15px' }}>
              <CCardBody>
                <CTabContent>
                  {props.tabs.map((i, key) => (
                    <CTabPane key={`nav-tabs-content-${key}`} data-tab={i.id} visible={i.id === currentTab}>
                      {getComponent(i.id)}
                    </CTabPane>
                  ))}
                </CTabContent>
              </CCardBody>
            </CCard>
          )}
          {(!props.tabs || props.tabs.length < 1) && props.children}
        </div>
      )}
    </I18n>
  );

}

UiNavsTabs.propTypes = propTypes;

export default UiNavsTabs;
