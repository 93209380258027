import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

// REDUCERS
import { init, userLogin, userResume, currentLanguageChanged, queueChanged, currentSessionChanged, currentSessionTabChanged, invokeExport, invokeImport, setCurrentBreakpoint } from '../redux/globalServiceDuck';

import { receiveToken } from '../redux/tokenReducer';


class GlobalService {
  static instance = null;

  static getInstance(store, storage, defaultLanguage, siteMap) {
    if (!GlobalService.instance) {
      GlobalService.instance = new GlobalService(store, storage, defaultLanguage, siteMap);
    }
    return GlobalService.instance;
  }

  constructor(store, storage, defaultLanguage, siteMap) {
    this.store = store;
    const lang = i18n.language || defaultLanguage;
    this.store.dispatch(init(lang));
    this.currentLanguage = lang;

    this.currentSection = null;
    this.currentSectionTabObj = null;
    this.currentSectionTab = null;

    this.currentBreakpoint = null;

    this.localStorage = (storage) ? storage : undefined;

    this.siteMap = (siteMap) ? (siteMap) : new Array();
  }

  set currentUser(user) {
    let auth = JSON.parse(this.localStorage.getItem('auth'));
    let localuser = {};

    if (user) {
      localuser = {
        userId: user.id_user,
        username: (user.first_name || user.last_name) ? user.first_name + ' ' + user.last_name : user.email,
        email: user.email,
        // userGroup: (user.admin) ? 'admin' : user.permission_group_id,
        userGroup: auth.groups,
        defaultLanguage: (user.defaultLanguage) ? user.defaultLanguage : 'it',
        scopes: user.scopes.concat(auth.groups)
      };
      this.localStorage.setItem('user', JSON.stringify(localuser));
    }
    if (auth && user) {
      let completeUser = {
        userId: (localuser) ? localuser.userId : 0,
        username: (localuser) ? localuser.username : 'user',
        userEmail: localuser.email,
        userGroup: (localuser) ? localuser.userGroup : 1,
        defaultLanguage: (localuser) ? localuser.defaultLanguage : 'it',
        tokenType: (auth) ? auth.tokenType : null,
        accessToken: (auth) ? auth.accessToken : null,
        idToken: (auth) ? auth.idToken : null,
        expiresIn: (auth) ? auth.expiresIn : null,
        refreshToken: (auth) ? auth.refreshToken : null,
        scopes: (localuser && localuser.scopes) ? localuser.scopes : (auth && auth.scopes) ? auth.scopes : [],
        timestamp: (auth) ? auth.timestamp : new Date().getTime()
      };
      this.store.dispatch(userLogin(completeUser));
    }
  }

  get currentUser() {
    let auth = JSON.parse(this.localStorage.getItem('auth'));
    let user = JSON.parse(this.localStorage.getItem('user'));
    return {
      userId: (user) ? user.userId : 0,
      username: (user) ? user.username : '',
      userGroup: (user) ? user.userGroup : 0,
      defaultLanguage: (user) ? user.defaultLanguage : 'it',
      tokenType: (auth) ? auth.tokenType : null,
      accessToken: (auth) ? auth.accessToken : null,
      idToken: (auth) ? auth.idToken : null,
      expiresIn: (auth) ? auth.expiresIn : null,
      refreshToken: (auth) ? auth.refreshToken : null,
      scopes: (user && user.scopes) ? user.scopes : (auth && auth.scopes) ? auth.scopes : [],
      timestamp: (auth) ? auth.timestamp : new Date().getTime()
    };
  }

  resumeUser() {
    let user = this.currentUser;
    this.store.dispatch(userResume(user));
    if (user.accessToken) {
      this.store.dispatch(receiveToken({}));
    }
  }

  changeLanguage(language) {
    if (language && this.currentLanguage !== language) {
      this.currentLanguage = language;
      this.store.dispatch(currentLanguageChanged(language));
    }
  }

  set queue(queue) {
    let oldQueue = this.localStorage.getItem('queue');
    if (oldQueue !== JSON.stringify(queue)) {
      this.localStorage.setItem('queue', JSON.stringify(queue));
      this.store.dispatch(queueChanged(queue));
    }
  }

  get queue() {
    return JSON.parse(this.localStorage.getItem('queue'));
  }

  populateSiteMap(siteMap) {
    this.siteMap = siteMap;
  }

  changeSection(sectionId, tabName, currentId) {
    if (this.siteMap && this.siteMap[sectionId] !== undefined) {
      let currentSection = cloneDeep(this.siteMap[sectionId]);

      if (currentSection.sectionParent !== undefined) {
        let parentSection = cloneDeep(this.siteMap[currentSection.sectionParent]);
        currentSection.sectionService = (!currentSection.sectionService) ? parentSection.sectionService : currentSection.sectionService;
        currentSection.sectionReducersList = (!currentSection.sectionReducersList) ? parentSection.sectionReducersList : currentSection.sectionReducersList;
        currentSection.sectionViewScope = (!currentSection.sectionViewScope) ? parentSection.sectionViewScope : currentSection.sectionViewScope;
        currentSection.sectionCreateScope = (!currentSection.sectionCreateScope) ? parentSection.sectionCreateScope : currentSection.sectionCreateScope;
        currentSection.sectionEditScope = (!currentSection.sectionEditScope) ? parentSection.sectionEditScope : currentSection.sectionEditScope;
        currentSection.sectionDeleteScope = (!currentSection.sectionDeleteScope) ? parentSection.sectionDeleteScope : currentSection.sectionDeleteScope;
      }

      if (currentId && currentId.length > 0) {
        let tab = cloneDeep(currentSection.sectionTabs);
        let tabReplaced = JSON.parse(JSON.stringify(currentSection.sectionTabs).replace(new RegExp('currentSectionId', 'g'), currentId));
        if (Array.isArray(tab)) {
          currentSection.sectionTabs = [];
          for (let i = 0; i < tab.length; i++) {
            let item = Object.assign({}, tab[i], tabReplaced[i]);
            if (item.scope === '*' || this.currentUser.scopes.includes(item.scope)) {
              currentSection.sectionTabs.push(item);
            }
          }
        } else {
          currentSection.sectionTabs = Object.assign({}, tab, tabReplaced);
        }
      }

      let initTab = 0;
      if (tabName && tabName.length > 0) {
        for (let i = 0; i < currentSection.sectionTabs.length; i++) {
          if (currentSection.sectionTabs[i].id === tabName) {
            initTab = i;
            break;
          }
        }
      } else {
        for (let i = 0; i < currentSection.sectionTabs.length; i++) {
          if (currentSection.sectionTabs[i].default === true) {
            initTab = i;
            break;
          }
        }
      }

      if (!isEqual(currentSection, this.currentSection)) {
        this.store.dispatch(currentSessionChanged(currentSection));
        this.currentSection = currentSection;
        this.currentSectionTabObj = null;
        this.currentSectionTab = null;
      }

      if (initTab !== this.currentSectionTab && Array.isArray(currentSection.sectionTabs) && currentSection.sectionTabs.length > 0) {
        this.store.dispatch(currentSessionTabChanged(currentSection.sectionTabs[initTab]));
        this.currentSectionTabObj = currentSection.sectionTabs[initTab];
        this.currentSectionTab = initTab;
      }
    }
  }

  getNavItems(currentUser, alphabeticalSort = true) {
    if (!this.siteMap) {
      return null;
    }

    let newList = new Array();
    let list = Object.values(this.siteMap).filter(obj => obj.sectionIndex >= 0 && (obj.sectionViewScope === '*' || (currentUser.scopes && currentUser.scopes.indexOf(obj.sectionViewScope) >= 0 && (!obj.sectionGroups || obj.sectionGroups.indexOf(currentUser.userGroup) >= 0))));
    list = list.sort(function (a, b) {
      return (a.sectionIndex > b.sectionIndex) ? 1 : ((a.sectionIndex < b.sectionIndex) ? -1 :
        ((a.sectionId > b.sectionId) ? 1 : (a.sectionId < b.sectionId) ? -1 : 0)
      );
    });


    let sublists = {};
    let sublistParents = list.filter(obj => obj.menuChildren !== undefined);

    for (let sublist of sublistParents) {
      sublists[sublist.sectionMenuId] = [];
      for (let child of sublist.menuChildren) {
        let item = list.find(obj => obj.sectionId === child);
        if (item) sublists[sublist.sectionMenuId].push(item);
        list = list.filter(obj => obj.sectionId !== child);
      }
      if (alphabeticalSort) {
        sublists[sublist.sectionMenuId] = sublists[sublist.sectionMenuId].sort(function (a, b) {
          return (i18n.t(a.sectionName) > i18n.t(b.sectionName)) ? 1 : ((i18n.t(a.sectionName) < i18n.t(b.sectionName)) ? -1 : 0);
        });
      }
    }

    list = list.map((t) => {
      if (!!t.menuChildren) {
        let children = [];
        for (let child of sublists[t.sectionMenuId]) {
          let menuChild = {
            name: i18n.t(child.sectionName),
            url: child.sectionRoute,
            icon: child.sectionIcon,
            badge: child.sectionBadge,
          };
          children.push(menuChild);
        }
        newList.push({
          title: !!t.sectionTitle,
          name: i18n.t(t.sectionName),
          url: t.sectionRoute,
          icon: t.sectionIcon,
          badge: t.sectionBadge,
          children: children
        });
      } else {
        if (!t.alreadyEntered) {
          newList.push({
            title: !!t.sectionTitle,
            name: i18n.t(t.sectionName),
            url: t.sectionRoute,
            icon: t.sectionIcon,
            badge: t.sectionBadge
          });
        }
      }
    });

    let linksList = [];

    if (currentUser.scopes && currentUser.scopes.indexOf('export') >= 0) {
      let exportLink = {
        name: i18n.t('Common.export'),
        action: 'export',
        icon: 'icon-cloud-download',
        class: 'mt-auto',
        variant: 'success',
      };
      linksList.push(exportLink);
    }

    if (currentUser.scopes && currentUser.scopes.indexOf('import') >= 0) {
      let importLink = {
        name: i18n.t('Common.import'),
        action: 'import',
        icon: 'icon-cloud-upload',
        class: (currentUser.scopes.indexOf('export') >= 0) ? undefined : 'mt-auto',
        variant: 'danger',
      };
      linksList.push(importLink);
    }

    let navigation = {
      items: newList.concat(linksList)
    };
    return navigation;
  }

  getMainRoutes(currentUser) {
    let list = Object.values(this.siteMap).filter(obj => obj.sectionComponent !== undefined && (obj.sectionViewScope === '*' || (currentUser.scopes && currentUser.scopes.indexOf(obj.sectionViewScope) >= 0 && (!obj.sectionGroups || obj.sectionGroups.indexOf(currentUser.userGroup) >= 0)) || obj.sectionParent !== undefined));
    let routesList = [];
    for (let t of list) {
      //if (t.sectionParent === undefined || (t.sectionParent !== undefined && routesList.find(obj => obj.name === t.sectionParent))){
      routesList.push({
        title: !!t.sectionTitle,
        name: t.sectionId,
        path: t.sectionRoute,
        exact: t.exactRoute,
        component: t.sectionComponent,
        child: t.sectionChild,
        breadcrumbName: t.sectionName
      });
      //}
    }
    return routesList;
  }

  invokeExport() {
    this.store.dispatch(invokeExport(new Date()));
  }

  invokeImport() {
    this.store.dispatch(invokeImport(new Date()));
  }

  get currentBreakpoint() {
    return this._currentBreakpoint;
  }

  /**
   * returns the class of the current windows size
   * according to bootstrap
   */
  set currentBreakpoint(width) {
    let newBreakpoint = this._currentBreakpoint;

    switch (width > 0) {
      case (width < 360): newBreakpoint = 'xxs'; break;
      case (width < 576): newBreakpoint = 'xs'; break;
      case (width >= 576 && width < 768): newBreakpoint = 'sm'; break;
      case (width >= 768 && width < 992): newBreakpoint = 'md'; break;
      case (width >= 992 && width < 1440): newBreakpoint = 'lg'; break;
      default: newBreakpoint = 'xl';
    }

    if (newBreakpoint !== this._currentBreakpoint) {
      this._currentBreakpoint = newBreakpoint;
      this.store.dispatch(setCurrentBreakpoint(newBreakpoint));
    }
  }
}

export default (GlobalService);
