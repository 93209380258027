import BaseService from './baseService';

export default class CommunityService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!CommunityService.instance) {
      CommunityService.instance = new CommunityService(store);
    }
    return CommunityService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'users', 'user');
  }
  
  uploadMedia(contentId, newMedia, lang, token, okCallBack, koCallBack, progressCallback) {
    if (newMedia.child_id){
      return super.postMultipart(this.itemName, this.urlPath+'/'+contentId+'/image/'+newMedia.child_id, lang, token, newMedia, 'logo', okCallBack, koCallBack, progressCallback);
    }
    return super.postMultipart(this.itemName, this.urlPath+'/'+contentId+'/image', lang, token, newMedia, 'logo', okCallBack, koCallBack, progressCallback);
  }

  deleteMedia(contentId, mediaId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName+'_cover', this.urlPath+'/'+contentId+'/image', lang, token, okCallBack, koCallBack);
  }
}
