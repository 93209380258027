import BaseService from './baseService';

export default class AcademyTopicsService extends BaseService {

    static instance = null;

    static getInstance(store) {
        if (!AcademyTopicsService.instance) {
            AcademyTopicsService.instance = new AcademyTopicsService(store);
        }
        return AcademyTopicsService.instance;
    }

    constructor(store) {
        let baseUrl = process.env.API_PATH;
        super(store, baseUrl, 'topics', 'topics');
    }

    getList(obj, lang, token, okCallBack, koCallBack) {
        let id;
        (obj.id !== undefined) ? id = obj.id : id = obj.id;
        return super.get(this.listName, this.urlPath +'/' + id + '/academy/topics', lang, token, null, okCallBack, koCallBack);
    }

    addItem(contentId, obj, lang, token, okCallBack, koCallBack) {
        return super.post(this.itemName, this.urlPath +'/' + contentId + '/academy/topics', lang, token, obj, okCallBack, koCallBack);
    }

    updateItemCustomId(contentId, obj, lang, token, okCallBack, koCallBack) {
        return super.put(this.itemName, this.urlPath +'/' + contentId + '/academy/topics/' + obj.id, lang, token, obj, okCallBack, koCallBack);
    }

    deleteItem(contentId, id, lang, token, okCallBack, koCallBack) {
        function deleteKoCallBack(error) {
            this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
        }
        return super.delete(this.itemName, this.urlPath +'/' + contentId + '/academy/topics/' + id, lang, token, okCallBack, deleteKoCallBack.bind(this));
    }
}