import BaseService from './baseService';

export default class CaseStudiesService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!CaseStudiesService.instance) {
      CaseStudiesService.instance = new CaseStudiesService(store);
    }
    return CaseStudiesService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'case_studies', 'case_study');
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    let newObj = {};
    newObj.type = obj.type;
    // newObj.background_color = '';
    // newObj.logo = '';
    newObj.translations = [
      {
        language: obj.language,
        title: obj.title,
        subtitle: obj.subtitle,
        permalink: obj.permalink,
        description: ''
      },
      {
        language: (obj.language === 'it') ? 'en' : 'it',
        title: obj.title,
        subtitle: obj.subtitle,
        permalink: (obj.language === 'it') ? obj.permalink + '-en' : obj.permalink.slice(0, -3),
        description: ''
      }
    ];
    return super.post(this.itemName, this.urlPath, lang, token, newObj, okCallBack, koCallBack);
  }

  uploadMedia(contentId, newMedia, lang, token, okCallBack, koCallBack, progressCallback) {
    if (newMedia.child_id) {
      return super.postMultipart(this.itemName, this.urlPath + '/' + contentId + '/logo', lang, token, newMedia, 'logo', okCallBack, koCallBack, progressCallback);
    }
    return super.postMultipart(this.itemName, this.urlPath + '/' + contentId + '/logo', lang, token, newMedia, 'logo', okCallBack, koCallBack, progressCallback);
  }

  deleteMedia(contentId, mediaId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName + '_cover', this.urlPath + '/' + contentId + '/logo', lang, token, okCallBack, koCallBack);
  }
}
