// GLOBAL
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CRow, CCol, CCard, CFormInput, CButton, CCardBody, CCardGroup, CContainer, CInputGroup, CInputGroupText, } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { cilLockLocked, cilUser } from '@coreui/icons'
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

// STORE
import ApplicationStore from '../../redux/store';

// UTILS
import withRouter from '../../utils/withNavigation';

// SERVICES
import JWTService from '../../services/jwtService';

// COMPONENTS
import { mapStateToProps } from './AbstractComponent';

import UiLoading from '../ui/UiLoading';

import logo from '../../../images/logo.svg';
import logoWhite from '../../../images/logoWhite.svg';

class Login extends Component {
  constructor(props) {
    super(props);

    this.oauthService = JWTService.getInstance(ApplicationStore, process.env.LOGIN_PATH, process.env.CLIENT_ID, process.env.CLIENT_SECRET, localStorage, process.env.BASENAME);

    let loading = true;
    const queryParams = this.parseLocationSearch(this.props.location.search);
    if (queryParams.code) {
      this.code = queryParams.code;
      this.oauthService.authorizeByCode(this.code);
    } else if (queryParams.logout === 'true') {
      this.oauthService.logout();
    } else {
      loading = false;
    }

    this.state = {
      loading,
      error: null,
      username: '',
      password: '',
    };
  }

  parseLocationSearch(locationSearch) {
    const location = (locationSearch && locationSearch.length > 0) ? this.props.location.search.substr(1) : '';
    const queryStrings = location.split('&');
    const queryParams = {};
    for (const query of queryStrings) {
      const keyValue = query.split('=');
      if (keyValue.length > 0) {
        queryParams[keyValue[0]] = keyValue.length > 1 ? keyValue[1] : undefined;
      }
    }
    return queryParams;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const queryParams = this.parseLocationSearch(nextProps.location.search);
    if (queryParams && queryParams.code && queryParams.code !== this.code) {
      this.code = queryParams.code;
      this.oauthService.authorizeByCode(queryParams.code);
      return false;
    } else if (queryParams.logout === 'true') {
      this.oauthService.logout();
    } else {
      if (nextProps.tokenReducer && nextProps.tokenReducer.status === 'AUTHENTICATED' && this.props.tokenReducer.status !== 'AUTHENTICATED') {
        this.props.navigate('/', { replace: true });
        return false;
      } else if (nextProps.tokenReducer && nextProps.tokenReducer.status === 'NOT_AUTHENTICATED' && window.localStorage.getItem('auth')) {
        this.oauthService.logout();
        return false;
      }
    }
    return true;
  }

  changeHandler(target, event) {
    const state = cloneDeep(this.state);
    state[target] = event.target.value;
    this.setState(state);
  }

  handleKeyPress(event = null) {
    if (event && event.key) {
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          if (this.state.username && this.state.password) {
            this.performLogin();
          }
          break;
        // no default
      }
    }
  }

  performLogin() {
    this.setState({ loading: true, error: null, success: null });
    function okCallback(success) {
      this.setState({ loading: false, error: null });
    }

    function koCallback(error) {
      let msg = error.message;
      if (error && error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message;
      }
      this.setState({ loading: false, error: msg, success: null });
    }

    this.oauthService.getToken(this.state.username.toLowerCase(), this.state.password, okCallback.bind(this), koCallback.bind(this));
  }

  render() {
    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
              <CRow className="justify-content-center">
                <CCol md="8">
                  {this.state.loading && <UiLoading height={80} width={80} />}
                  {!this.state.loading && (
                    <CCardGroup>
                      <CCard className="p-4">
                        <CCardBody>
                          <CRow>
                            <CCol className="d-lg-none">
                              <img src={logo} alt="Beije - People First" className="login-brand-logo" />
                            </CCol>
                          </CRow>
                          <h1>{t('Login.login')}</h1>
                          {this.state.error && <p className="error">{this.state.error}</p>}
                          <CInputGroup className="mb-3">
                            <CInputGroupText>
                              <CIcon icon={cilUser} />
                            </CInputGroupText>
                            <CFormInput type="text" placeholder={t('Login.username')} value={this.state.username}
                              onChange={this.changeHandler.bind(this, 'username')} onKeyPress={event => this.handleKeyPress(event)} />
                          </CInputGroup>
                          <CInputGroup className="mb-4">
                            <CInputGroupText>
                              <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <CFormInput type="password" placeholder={t('Login.password')} value={this.state.password}
                              onChange={this.changeHandler.bind(this, 'password')} onKeyPress={event => this.handleKeyPress(event)} />
                          </CInputGroup>
                          <CRow>
                            <CCol sm={6} md={6} lg={6} xl={6} className="d-flex align-items-center justify-content-center justify-content-sm-start">
                              <CButton className="px-4" color="primary" disabled={!this.state.username || !this.state.password}
                                onClick={this.performLogin.bind(this)}>
                                {t('Login.login_btn')}
                              </CButton>
                            </CCol>
                            <CCol sm={6} md={6} lg={6} xl={6} className="mt-4 mt-sm-0 text-right d-flex align-items-center justify-content-center justify-content-sm-end">
                              <Link to="/forgot_password">{t('Login.forgot_password')}</Link>
                            </CCol>
                          </CRow>
                        </CCardBody>
                      </CCard>
                      <CCard className="text-white bg-primary py-5 d-none d-lg-block">
                        <CCardBody className="text-center">
                          <CRow>
                            <CCol>
                              <img src={logoWhite} alt="Beije - People First" className="login-brand-logo" />
                            </CCol>
                          </CRow>
                          {/*<div>
                          <h2>{t('Login.register')}</h2>
                          <p>{t('Login.register_msg')}</p>
                          <Button tag={Link} to="/register" color="primary" className="mt-3" active>{t('Login.register_btn')}</Button>
                        </div>*/}
                        </CCardBody>
                      </CCard>
                    </CCardGroup>
                  )}
                </CCol>
              </CRow>
            </CContainer>
          </div>
        )}
      </I18n>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Login));
