import BaseService from './baseService';
import cloneDeep from 'lodash/cloneDeep';

export default class UsersService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!UsersService.instance) {
      UsersService.instance = new UsersService(store);
    }
    return UsersService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.LOGIN_PATH;
    super(store, baseUrl, 'users', 'user');
  }

  getUserMe(lang, token, okCallBack, koCallBack) {
    function meOkCallBack(res) {
      let responseData = { ...res.data.userProfileDto };
      responseData.scopes = res.data.privileges;
      if (okCallBack) okCallBack({ data: responseData });
    }
    return super.get(`${this.itemName}_me`, '/myprofile', lang, token, null, meOkCallBack.bind(this), koCallBack);
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    const newObj = cloneDeep(obj);
    newObj.birth_place_id = Number(newObj.birth_place_id);
    return super.post(this.itemName, this.urlPath, lang, token, newObj, okCallBack, koCallBack);
  }

  getQueue(lang, token, okCallBack, koCallBack) {
    return super.get('queue', 'queue', lang, token, null, okCallBack, koCallBack);
  }


  changePsw(contentId, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName,'/admin/changepsw/' + contentId, lang, token, obj, okCallBack, koCallBack);
  }

  getAddresses(userId,lang, token, okCallBack, koCallBack){
    return super.get(this.itemName, '/admin/' + this.listName + '/' + userId + '/addresses', lang, token, null, okCallBack, koCallBack);
  }
}
