export default class ApiCallsQueueService {
  static instance = null;

  static getInstance() {
    if (!ApiCallsQueueService.instance) {
      ApiCallsQueueService.instance = new ApiCallsQueueService();
    }
    return ApiCallsQueueService.instance;
  }

  constructor() {
    this.queue = [];
  }
  
  queueHasPendingCall(){
    return this.queue.length > 0;
  }
  
  queueApiCall(originalRequest) {
    this.queue.push(originalRequest);
  }
  
  removeFirstFromQueueApiCall() {
    if (this.queue.length > 0){
      return this.queue.shift();
    }
    return null;
  }
}
