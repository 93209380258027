import { CCard, CCardBody, CCol, CRow, CToast, CBadge, CTooltip } from '@coreui/react-pro'
import React, { useState, useEffect, useRef, memo, useCallback, useMemo } from 'react'
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import store from '../../redux/store';

//google maps
import { APIProvider, Map, useApiLoadingStatus, APILoadingStatus, Marker, useMap, InfoWindow } from '@vis.gl/react-google-maps';

//clone deep
import cloneDeep from 'lodash/cloneDeep';


//services
import ConstantsService from '../../services/constantsService';
import CompanyAddressesService from '../../services/companyAddressesService';
import MapService from '../../services/mapService';
import UserAddressesService from '../../services/userAddressesService';
import { cisFilter } from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';

//components
import UiButton from './UiButton';


const customMarker = {
  path: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
  fillColor: "red",
  fillOpacity: 2,
  strokeWeight: 1,
  rotation: 0,
  scale: 1,
};

const UiMap = memo(function UiMap(props) {
  const [markers, setMarkers] = useState([{
    coordinates: {
      lat: 45.610160,
      lng: 9.233810,
    },
    showWindow: false
  }]);
  const [markersWindows, setMarkersWindows] = useState([0]);
  const [currentMarkerSelected, setCurrentMarkerSelected] = useState();

  //window width
  const [windowWidth, setWindowWidth] = useState();

  //errors
  const [showCompanyError, setCompanyError] = useState(false);
  const [showAddressError, setAddressError] = useState(false);
  const [showUserError, setUserError] = useState(false);
  const [showCityError, setCityError] = useState(false);

  //google maps
  const status = useApiLoadingStatus();
  let mapRef;
  const resizeMap = useRef(false);

  const userAddressesService = UserAddressesService.getInstance(store);
  const companyAddressesService = CompanyAddressesService.getInstance(store);
  const mapService = MapService.getInstance(store);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(() => {
    if (props.markers?.length > 0) {
      resizeMap.current = false;
      setMarkers(props.markers);
    }else{
      setMarkers([{
        coordinates: {
          lat: 45.610160,
          lng: 9.233810,
        },
        showWindow: false
      }])
    }
  }, [props])


  const Wrapper = () => {
    mapRef = useMap('map');

    useEffect(() => {
      if (mapRef && markers.length > 0) {
        adjustMapZoom();
      }
    }, [mapRef, markers])

    return null
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  function handleOnChange(evt) {
    eval(`set${evt.target.name}(evt.target.value)`);
    if (evt.target.name === 'Address') setAddressError(false);
  }

  function onMapLoad() {
  }

  function adjustMapZoom() {
    if (markers.length === 0 || resizeMap.current) return; // return if there are no markers

    // Calculate geographical limits that contain all markers
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach((marker) => bounds.extend(marker.coordinates));

    // set map limits
    mapRef.fitBounds(bounds);

  };

  function toggleInfoWindow(i) {
    const cloneMarkers = cloneDeep(markers);
    cloneMarkers[i].showWindow = !cloneMarkers[i].showWindow;
    resizeMap.current = true;
    setMarkers(cloneMarkers);
  }

  function handleCurrentMarker(marker, index) {
    const cloneMarkers = cloneDeep(markers);
    cloneMarkers.forEach((marker, i) => {
      if (i !== index) {
        marker.showWindow = false;
        marker.active = false;
      } else if (i === index && marker.active) {
        marker.active = false;
      } else {
        marker.active = true;
      }
    })
    setMarkers(cloneMarkers);
    if (props.handleCurrentPosition) props.handleCurrentPosition(cloneMarkers[index]);

  }


  const renderMarkers = useMemo(() => { //cache value returned by function, in order to avoid unnecessary rendering
    if (markers?.length > 0) {
      return markers.map((marker, i) => {
        return (
          <React.Fragment key={i + Date.now()}>
            <Marker
              icon={{ url: marker?.category === 'user' ? (!marker.active ? '../../../assets/icons/user-icon.png' : '../../../assets/icons/user-icon-red.png') : marker?.category === 'company' && (!marker.active ? '../../../assets/icons/company-icon.png' : '../../../assets/icons/company-icon-red.png') }}
              key={i + Date.now()}
              position={marker.coordinates}
              onClick={toggleInfoWindow.bind(this, i)}
            />
            {marker.showWindow &&
              <InfoWindow
                key={i + 1 + Date.now()}
                onCloseClick={toggleInfoWindow.bind(this, i)}
                position={{
                  lat: marker.coordinates.lat,
                  lng: marker.coordinates.lng
                }}
              >
                <div>
                  {marker?.category === 'user' ?
                    <>
                      <div style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: '18px' }}>{marker.user}</div>
                      <div style={{ fontSize: '15px' }}>{marker.address}</div>
                      <CBadge color={marker.type === 'work' ? 'danger' : marker.type === 'home' ? 'info' : 'success'} className='mt-2' style={{ fontSize: '15px' }}>{i18n.t(`SelectValues.${marker.type}`)}</CBadge>
                      <div className='marker-filter mt-2'>
                        <UiButton icon="cis-search" classNameContainer={"btn-map-range"} label={marker.active ? "Disattiva ricerca nelle vicinanze" : "Cerca nelle vicinanze"} onClick={handleCurrentMarker.bind(this, marker, i)} />
                      </div>

                    </> :
                    marker?.category === 'company' ?
                      <>
                        <div style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: '18px' }}>{marker.company}</div>
                        <div style={{ fontSize: '15px' }}>{marker.address}</div>
                        <CBadge color={marker.type === 'headquarter' ? 'primary' : marker.type === 'registered_office' ? 'warning' : 'dark'} className='mt-2' style={{ fontSize: '15px' }}>{i18n.t(`SelectValues.${marker.type}`)}</CBadge>
                        <div className='marker-filter mt-2'>
                          <UiButton icon={!marker.active ? "cis-search" : ""} classNameContainer={"btn-map-range"} label={marker.active ? "Disattiva ricerca nelle vicinanze" : "Cerca nelle vicinanze"} onClick={handleCurrentMarker.bind(this, marker, i)} />
                        </div>
                      </> : marker.category === 'address' ?
                        <>
                          <div style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: '18px' }}>{marker.address}</div>
                          <div className='marker-filter mt-2'>
                            <UiButton icon="cis-search" label={marker.active ? i18n.t('Common.deactivate_range_search') :  i18n.t('Common.activate_range_search')} classNameContainer={"btn-map-range"} onClick={handleCurrentMarker.bind(this, marker, i)} />
                          </div>
                        </> : <>
                          <div style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: '18px' }}>People First</div>
                          <div style={{ fontSize: '15px' }}>{i18n.t('Common.people_first_address')}</div>
                          <CBadge color={'primary'} className='mt-2' style={{ fontSize: '15px' }}>{i18n.t(`SelectValues.headquarter`)}</CBadge>
                          <div className='marker-filter mt-2'>
                            <UiButton icon="cis-search" label={marker.active ? "Disattiva ricerca nelle vicinanze" : "Cerca nelle vicinanze"} classNameContainer={"btn-map-range"} onClick={handleCurrentMarker.bind(this, marker, i)} />
                          </div>
                        </>
                  }
                </div>
              </InfoWindow>
            }

          </React.Fragment>
        )
      })
    } else {
      return <></>
    }
  }, [markers])

  return (
    <I18n ns="translations">
      {t => (
        <>
          <div style={{ height: '70%' }}>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY} onLoad={onMapLoad.bind(this)}>
              <Map
                style={props.style}
                className='addresses-map'
                id={'map'}
                defaultZoom={7}
                gestureHandling={'greedy'}
                disableDefaultUI={false}
              >
                {renderMarkers}
              </Map>

              <Wrapper />

            </APIProvider>
          </div>
        </>
      )}
    </I18n>
  )
}, (prevProps, nextProps) => {
  function compareArrays(arrfirst, arrsecond) {
    if (arrfirst?.length !== arrsecond?.length) {
      return false;
    }
    return arrfirst?.every((value, index) => JSON.stringify(value) === JSON.stringify(arrsecond[index]));
  }
  if (!compareArrays(prevProps.markers, nextProps.markers)) {
    return false;
  } else {
    return true;
  }
})

export default UiMap;

