import React, { Component } from 'react';
import { CFormLabel, CTooltip } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

//SERVICES
import SiteMapService from '../../services/siteMapService';

//UTILS
import ObjectsUtils from '../../utils/objectsUtils';

//TYPES
import { userType } from '../../types';

const propTypes = {
  route: PropTypes.string,
  fieldReturned: PropTypes.string,
  fieldDisplayed: PropTypes.string,
  currentUser: userType,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  inline: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFocus: PropTypes.func,
  values: PropTypes.array,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  nullable: PropTypes.bool,
  tooltipMsg: PropTypes.string,
  placeholder: PropTypes.string,
  onValueChanged: PropTypes.func,
  alreadyTranslated: PropTypes.bool,
  forceTranslation: PropTypes.bool
};

class UiSelectClassic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isTranslated: false
    };

    this.CurrentOptionSuggestion = React.createRef();

    this.siteMap = SiteMapService.getSiteMap();
    this.crudScopes = Object.values(this.siteMap).filter(obj => obj.sectionChild !== undefined && obj.sectionId !== undefined).map(c => c.sectionId);
    this.routeScopes = Object.values(this.siteMap).filter(obj => obj.sectionChild === undefined && obj.sectionId !== undefined).map(r => r.sectionId);
    this.settingsScopes = (this.siteMap.settingsSection) ? this.siteMap.settingsSection.menuRouteScopes : [];

    window.addEventListener('scroll', this.setSelectOptionsPosition.bind(this));
    window.addEventListener('keydown', this.evalNavigationSelectOptions.bind(this));
    window.addEventListener('mousedown', this.evalClickComponent.bind(this));
  }

  componentDidMount() {
    document.addEventListener('changeLang', () => {
      this.setState({
        isTranslated: true
      })
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setSelectOptionsPosition.bind(this));
    window.removeEventListener('keydown', this.evalNavigationSelectOptions.bind(this));
    window.removeEventListener('mousedown', this.evalClickComponent.bind(this));
    document.removeEventListener('changeLang', () => {
      this.setState({
        isTranslated: true
      })
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = cloneDeep(prevState);
    const newProps = cloneDeep(nextProps);
    const oldVal = (prevState.currentOptionValue && (prevState.currentOptionValue.length > 0 || parseInt(prevState.currentOptionValue) > 0)) ? prevState.currentOptionValue : null;
    const newVal = (nextProps.value ? nextProps.value : null);
    if (prevState.isTranslated && !nextProps.alreadyTranslated && nextProps.fieldDisplayed) {
      let clonedArray = cloneDeep(nextProps?.values);
      newState.values = clonedArray.map((item) => {
        newState.isTranslated = false;
        item[nextProps.fieldDisplayed] = i18n.t(`SelectValues.${item[nextProps.fieldDisplayed]}`);
        return item;
      })
    }
    let valuesChanged = false;
    newState.forced = false;
    if ((!newState.values && nextProps.values) || !isEqual(nextProps.values, newState.values)) {
      valuesChanged = true;
      if (nextProps?.forceTranslation) {
        if (!nextProps.alreadyTranslated && nextProps.fieldDisplayed) {
          let clonedArray = cloneDeep(nextProps?.values);
          // if (clonedArray[0][nextProps?.fieldDisplayed]?.includes('[vuoto]') || clonedArray[0][nextProps?.fieldDisplayed]?.includes('[empty]')) {
          //   clonedArray.splice(0, 1);
          // }
          clonedArray = clonedArray.map((item) => {
            if (!(item[nextProps.fieldDisplayed].includes('[vuoto]') || item[nextProps?.fieldDisplayed]?.includes('[empty]'))) {
              item[nextProps?.fieldDisplayed] = i18n.t(`SelectValues.${item[nextProps?.fieldDisplayed]}`);
            }
            newState.isTranslated = false;
            return item
          })
          newState.values = (nextProps?.nosort) ? clonedArray : clonedArray.sort((a, b) => {
            return (a[nextProps?.fieldDisplayed] > b[nextProps?.fieldDisplayed]) ? 1 : (a[nextProps?.fieldDisplayed] < b[nextProps?.fieldDisplayed]) ? -1 : 0;
          });

          if (isEqual(nextProps.values, newState.values)) {
            valuesChanged = false;
          }
        }
      } else {
        newState.values = (nextProps?.nosort && nextProps?.values) ? nextProps?.values : (nextProps?.values && !nextProps.simpleSort) ? nextProps?.values.sort((a, b) => {
          const val = (nextProps?.alreadyTranslated)
            ? ((nextProps?.fieldDisplayed) ? ((a[nextProps?.fieldDisplayed] > b[nextProps?.fieldDisplayed]) ? 1 : ((a[nextProps?.fieldDisplayed] < b[nextProps?.fieldDisplayed]) ? -1 : 0)) : ((a > b) ? 1 : ((a < b) ? -1 : 0)))
            : ((nextProps?.fieldDisplayed) ? (
              (i18n.t(a[nextProps?.fieldDisplayed]) > i18n.t(b[nextProps?.fieldDisplayed])) ? 1
                : ((i18n.t(a[nextProps?.fieldDisplayed]) < i18n.t(b[nextProps?.fieldDisplayed])) ? -1 : 0))
              : ((String(a).indexOf('Table.') !== 0 && String(a).indexOf('Common.') !== 0) ? ((i18n.t(`SelectValues.${a}`) > i18n.t(`SelectValues.${b}`)) ? 1 : ((i18n.t(`SelectValues.${a}`) < i18n.t(`SelectValues.${b}`)) ? -1 : 0))
                : ((i18n.t(a) > i18n.t(b)) ? 1 : ((i18n.t(a) < i18n.t(b)) ? -1 : 0))));
          return val;
        }) : newProps.values.sort((a, b) => {
          if (a[nextProps.fieldDisplayed] < b[nextProps.fieldDisplayed]) {
            return -1;
          }
          if (a[nextProps.fieldDisplayed] > b[nextProps.fieldDisplayed]) {
            return 1;
          }
          return 0;
        });
      }
    }
    if (newVal !== oldVal || valuesChanged) {
      let currentOptionValue = null;
      let currentOptionText = '';
      let optionSelectedIndex = ((nextProps.nullable) || !newState.values || newState.values.length === 0) ? -1 : 0;
      if (newState.values && newState.values.length > 0) {
        let selectedIndex = nextProps?.returnObj ? newState.optionSelectedIndex : newState.values.findIndex((obj) => { return (nextProps.fieldReturned) ? '' + obj[nextProps.fieldReturned] === '' + nextProps.value : '' + obj === '' + nextProps.value });
        if (selectedIndex < optionSelectedIndex) {
          selectedIndex = optionSelectedIndex;
          newState.forced = true;
        }
        if (newVal && selectedIndex === -1) {
          return newState;
        }
        if (selectedIndex >= 0) {
          const content = newState.values[selectedIndex];
          currentOptionValue = (nextProps.fieldReturned) ? content[nextProps.fieldReturned] : content;
          currentOptionText = (nextProps.fieldDisplayed) ? content[nextProps.fieldDisplayed] : content;
          optionSelectedIndex = selectedIndex;
        }
      }
      // newState.open = false;
      newState.currentOptionText = currentOptionText;
      newState.currentOptionValue = currentOptionValue;
      newState.optionSelectedIndex = optionSelectedIndex;
      newState.optionSuggestion = optionSelectedIndex;
    }
    newState.invalid = ((nextProps.required && !nextProps.nullable) ? (nextProps.invalid ? nextProps.invalid : ((newState.optionSelectedIndex < 0))) : null);
    return newState;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.forced && this.props.onValueChanged) {
      const event = {
        target: {
          name: this.props.name,
          value: nextState.currentOptionValue,
        },
      };
      if (this.props.onValueChanged) this.props.onValueChanged(event);
      return false;
    }
    return true;
  }

  handleOpenSelectOptionsDiv() {
    if (!this.props.disabled) {
      this.setSelectOptionsPosition();
      if (this.CurrentOptionSelected) {
        this.UiSelectOptions.scrollTop = this.CurrentOptionSelected.offsetTop - (this.CurrentOptionSelected.getBoundingClientRect().height * 2);
      } else {
        this.UiSelectOptions.scrollTop = 0;
      }
      this.setState({ open: true, invalid: false });
    }
  }

  handleCloseSelectOptionsDiv(resetSuggestion = true) {
    if (!this.props.disabled) {
      this.setState((prevState) => ({
        open: false,
        optionSuggestion: resetSuggestion ? this.state.optionSelectedIndex : prevState.optionSuggestion,
        invalid: (this.props.required ? (!prevState.currentOptionValue) : null)
      }));
    }
  }

  handleToggleSelectOptionsDiv() {
    if (!this.props.disabled) {
      if (!this.state.open) {
        this.handleOpenSelectOptionsDiv();
      } else {
        this.handleCloseSelectOptionsDiv();
      }
    }
  }

  handleOnChange(selectionIndex, selection) {
    if (!this.props.disabled) {
      let value = null;
      if (selectionIndex >= 0) {
        value = this.props.fieldReturned ? selection[this.props.fieldReturned] : selection;
        this.setState({
          currentOptionValue: value,
          invalid: this.props.required ? !value : null,
          currentOptionText: this.props.fieldDisplayed ? selection[this.props.fieldDisplayed] : selection,
          optionSelectedIndex: selectionIndex,
          optionSuggestion: selectionIndex,
        });
      }

      const event = {
        target: {
          name: this.props.name,
          value,
        },
      };

      this.handleCloseSelectOptionsDiv(false);
      if (this.props.onChange) this.props.onChange(event);
      if (this.props.onBlur) {
        setTimeout(() => {
          this.props.onBlur(event);
        }, 500);
      }
    }
  }

  handleOnBlur(/* event */) {
    setTimeout(() => {
      this.handleCloseSelectOptionsDiv();
      // if (this.props.onBlur) this.props.onBlur(event);
    }, 500);
  }

  /**
   * Set the position of the options div for the selector
   */
  setSelectOptionsPosition() {
    if (!this.UiSelectBox) return;
    let positionString = '';
    const SelectBoxDimensions = this.UiSelectBox.getBoundingClientRect();
    const SelectOptionsDimensions = this.UiSelectOptions.getBoundingClientRect();
    const valueHeight = SelectBoxDimensions.top + SelectBoxDimensions.height + SelectOptionsDimensions.height;

    if (valueHeight < window.innerHeight) {
      positionString = ' open-bottom';
    } else {
      positionString = ' open-top';
    }
    this.setState({ positionString });
  }

  evalNavigationSelectOptions(event) {
    if (this.state.open) {
      event.preventDefault();
      switch (event.key) {
        case 'ArrowDown':
          if (this.state.optionSuggestion < this.state.values.length - 1) {
            this.setState({ optionSuggestion: this.state.optionSuggestion + 1 }, () => {
              this.UiSelectOptions.scrollTop = this.CurrentOptionSuggestion.offsetTop - (this.CurrentOptionSuggestion.getBoundingClientRect().height * 2);
            });
          }
          break;
        case 'ArrowUp':
          if ((!this.props.required && this.props.nullable && this.state.optionSuggestion > -1) || (this.state.optionSuggestion > 0)) {
            this.setState({ optionSuggestion: this.state.optionSuggestion - 1 }, () => {
              this.UiSelectOptions.scrollTop = this.CurrentOptionSuggestion.offsetTop - (this.CurrentOptionSuggestion.getBoundingClientRect().height * 2);
            });
          }
          break;
        case 'Escape':
          this.handleCloseSelectOptionsDiv();
          this.UiSelectOptions.scrollTop = 0;
          break;
        case 'Enter':
          this.handleOnChange(this.state.optionSuggestion, this.state.values[this.state.optionSuggestion]);
          this.handleCloseSelectOptionsDiv();
          break;
        // no default
      }
    }
  }

  evalClickComponent(event) {
    if (this.state.open && (!this.UiSelectBox || !this.UiSelectBox.contains(event.target))) {
      this.handleCloseSelectOptionsDiv();
    }
  }

  assignOptionRef(referenceValue, value, loopIndex) {
    const compareValue = (this.props.fieldDisplayed) ? value[this.props.fieldDisplayed] : value;
    if ('' + this.state.currentOptionText === '' + compareValue) {
      this.CurrentOptionSelected = referenceValue;
    }
    if (loopIndex === this.state.optionSuggestion) {
      this.CurrentOptionSuggestion = referenceValue;
    }
  }

  render() {
    let selectClassName = '';
    if (this.props.className && typeof this.props.className === 'string' && this.props.className.length > 0) selectClassName = ` ${this.props.className}`;

    let clearRoute = (this.props.route && this.props.route !== 'filter') ? this.props.route : null;
    if (clearRoute && clearRoute.indexOf('_') > 0) {
      clearRoute = (clearRoute.indexOf('_related') > 0) ? clearRoute.substring(0, clearRoute.indexOf('_related')) : clearRoute;

      clearRoute = clearRoute.replace('_tmp', '');
    }

    return (
      <I18n ns="translations">
        {t => (
          <div className={`UiSelect${this.props.inline ? ' UiSelect_inline' : ''}${this.props.disabled ? ' disabled' : ''}${this.state.invalid ? ' is-invalid' : ''}${selectClassName}`} style={this.props?.style}>
            {this.props.label ? (
              <CFormLabel htmlFor={this.props.name} className={`UiMainLabel${this.props.inline ? ` UiMainLabel_inline${this.props.autowidth ? ' inline_autowidth' : ''}` : ''}`}>
                {t(this.props.label)} {this.props.required ? '*' : ''}
                {this.props.tooltipMsg && (
                  <span>
                    <CTooltip placement="top" content={t(this.props.tooltipMsg)}>
                      <CIcon icon="cis-info-circle" size="sm" />
                    </CTooltip>
                  </span>
                )}
              </CFormLabel>
            ) : (
              ''
            )}
            {!!this.props.value && !!clearRoute && (
              <small className="text-muted ms-3">
                {this.props.currentUser && this.routeScopes.indexOf(clearRoute) >= 0 && this.props.currentUser.scopes.indexOf(`${this.crudScopes[this.routeScopes.indexOf(clearRoute)]}_view`) >= 0 && (
                  <Link to={`/${clearRoute}/${this.props.value}`}>
                    <CIcon icon="cis-link" />
                  </Link>
                )}
                {this.props.currentUser && this.settingsScopes.indexOf(clearRoute) >= 0 && this.props.currentUser.scopes.indexOf('settings_view') >= 0 && (
                  <Link to={`/${clearRoute}/${this.props.value}`}>
                    <CIcon icon="cis-link" />
                  </Link>
                )}
                {this.props.currentUser && this.routeScopes.indexOf(clearRoute) < 0 && this.settingsScopes.indexOf(clearRoute) < 0 && this.props.currentUser.scopes.indexOf('artwork_settings_view') >= 0 && (
                  <Link to={`/${clearRoute}/${this.props.value}`}>
                    <CIcon icon="cis-link" />
                  </Link>
                )}
              </small>
            )}
            <div ref={UiSelectBox => (this.UiSelectBox = UiSelectBox)} tabIndex={0}
              id={this.props.name} className={`form-control UiSelectBody${this.props.inline ? ` UiSelectBody_inline${this.props.autowidth ? ' inline_autowidth' : ''}` : ''}`} name={this.props.name}
              onFocus={this.props.onFocus?.bind(this)} onBlur={this.handleOnBlur.bind(this)} onClick={(!this.props.disabled && !this.state.open) ? () => this.handleToggleSelectOptionsDiv() : null}>
              <div className={`UiSelectBox ${this.state.open && this.state.positionString ? this.state.positionString : ''}`}>
                <p ref={(UiSelectLabel) => this.focusTarget = UiSelectLabel} className={`UiSelectBoxText${!this.state.currentOptionText ? ' no-selected' : ''} ${this.props.danger ? 'text-danger' : ''}`}>
                  {ObjectsUtils.isStringValid(this.state?.currentOptionText)
                    ? (this.props.fieldDisplayed || this.props.alreadyTranslated)
                      ? (this.state.currentOptionText.slice(0, 40)) : t(`SelectValues.${this.state.currentOptionText}`)
                    : (this.props.placeholder) ? t(this.props.placeholder) : `${t('Common.select')}...`}
                </p>
                {!this.props.disabled && (
                  <span role="presentation" onClick={this.handleToggleSelectOptionsDiv.bind(this)} className="UiSelectBoxArrowDown">
                    <CIcon icon="cis-caret-bottom" />
                  </span>
                )}
              </div>
              <div ref={UiSelectOptions => (this.UiSelectOptions = UiSelectOptions)}
                id="select-options" className={`UiSelectBoxOptions ${this.state.open ? 'show' : ''} ${this.state.positionString ? this.state.positionString : ''}`} name="select-options">
                <ul>
                  {!this.props.required && this.props.nullable && (
                    <li className={`UiSelectBoxOption${this.state.optionSuggestion === -1 ? ' suggestion' : ''}`} onClick={this.handleOnChange.bind(this, -1, null)}>
                      &nbsp;
                    </li>
                  )}
                  {this.state.values && this.state.values.map((value, index) => (
                    <li key={`select-classic-${this.props.name}-${index}`} ref={(referenceValue) => this.assignOptionRef(referenceValue, value, index)}
                      className={`UiSelectBoxOption${(this.props.fieldDisplayed && '' + value[this.props.fieldDisplayed] === '' + this.state.currentOptionText || '' + value === '' + this.state.currentOptionText) ? ' selected' : ''}${index === this.state.optionSuggestion ? ' suggestion' : ''}`}
                      onClick={this.handleOnChange.bind(this, index, value)}>
                      {(this.props.fieldDisplayed) ? value[this.props.fieldDisplayed] : t((String(value).indexOf('Table.') !== 0 && String(value).indexOf('Common.') !== 0 && !this.props.alreadyTranslated) ? `SelectValues.${value}` : value)}
                    </li>

                  ))}
                </ul>
              </div>
            </div>
            {this.state.invalid && (
              <div className="invalid-feedback">{t('Common.field_invalid')}</div>
            )}
          </div>
        )}
      </I18n>
    );
  }
}

UiSelectClassic.propTypes = propTypes;

export default UiSelectClassic;