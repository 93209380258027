import Moment from 'moment';
import { bool, shape, number, string, arrayOf, oneOfType, instanceOf } from 'prop-types';

/**
 * propType for tabs
 */
export const tabType = shape({
  id: string.isRequired,
  route: string.isRequired,
  default: bool,
  scope: oneOfType([string, arrayOf(string)]),
});

/**
 * propType for user
 */
export const userType = shape({
  userId: oneOfType([string, number]),
  username: string,
  timestamp: number,
  tokenType: string,
  userGroup: oneOfType([string, number]),
  accessToken: string,
  refreshToken: string,
  defaultLanguage: string,
  scopes: arrayOf(string),
  expiresIn: number,
});

export const carType = shape({
  brand: string,
  model: string,
  color: string,
  id: number.isRequired,
  air_conditioning: bool,
  plate: string.isRequired,
  contact_id: number.isRequired,
});

export const chartColumnType = shape({
  id: string,
  role: string,
  type: oneOfType([string, number, instanceOf(Date)]),
});

export const iso6933LangShape = shape({
  id: number,
  iso2: string,
  iso3: string,
  name: string,
  icon: string
});