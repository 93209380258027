import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

const withRouter = (Component) => {

    const ComponentWithRouterProp = (props) => {
        const location = useLocation();
        const navigate = useNavigate();

        return (
            <Component location={location} navigate={navigate} {...props} />
        );
    }
    return ComponentWithRouterProp;
}

export default withRouter;