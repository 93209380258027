// GLOBAL
import { connect } from 'react-redux';

// UTILS
import withRouter from '../../utils/withNavigation';

// COMPONENTS
import AbstractGrid from './AbstractGrid';
import { mapStateToProps } from './AbstractComponent';

class Users extends AbstractGrid {
  render() {
    return super.render();
  }
}

export default connect(mapStateToProps)(withRouter(Users));
