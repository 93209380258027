import React, { useState, useEffect } from 'react';
import { I18n } from 'react-i18next';
import PropTypes from 'prop-types';

//UI
import { CFormLabel, CFormCheck } from '@coreui/react-pro';

const propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  values: PropTypes.array,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  nullable: PropTypes.bool,
  firstChecked: PropTypes.bool,
  alreadyTranslated: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

function UiRadioButton(props) {
  const [fieldDisplayed, setFieldDisplayed] = useState();
  const [fieldReturned, setFieldReturned] = useState();
  const [invalid, setInvalid] = useState();

  useEffect(() => {
    setFieldDisplayed(props.fieldDisplayed);
    setFieldReturned(props.fieldReturned);
    setInvalid(props.required ? (!!(!props.value && !props.fieldDisplayed && !props.fieldReturned)) : undefined);
  }, [props])

  useEffect(() => {
    if (!props.disabled && props.firstChecked) handleOnChange((fieldReturned) ? props.values[0][fieldReturned] : props.values[0]);
  }, [])

  function handleOnChange(value) {
    if (!props.disabled) {
      const event = {
        target: {
          name: props.name,
          value: value,
        },
      };
      if (props.onChange) props.onChange(event);

      if (props.onBlur) {
        setTimeout(() => {
          props.onBlur(event);
        }, 500);
      }
    }
  }

  function handleOnFocus(evt) {
    if (!props.disabled && props.onFocus) {
      props.onFocus(evt);
    }
  }

  function handleOnBlur() {
    if (props.onBlur) {
      const event = {
        target: {
          name: props.name,
          value: props.value,
        },
      };
      props.onBlur(event);
    }
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className={`UiRadioButton${invalid ? ' is-invalid' : ''} ${props.inline ? ' radio-inline' : 'radio-column'}`} style={props.gap && { gap: `${props.gap}` }}>
          {!!props.label ? (
            <CFormLabel className="UiMainLabel">
              {t(props.label)} {props.required ? '*' : ''}
            </CFormLabel>
          ) : (
            ''
          )}
          {props.nullable && (
            <div>
              <CFormLabel htmlFor={`${props.name}-null`}>
                <CFormCheck type="radio" id={`${props.name}-null`} name={props.name} disabled={props.disabled} checked={!props.value}
                  onFocus={handleOnFocus.bind(this)} onChange={handleOnChange.bind(this)} onBlur={handleOnBlur.bind(this)} />

                <span className="checkmark">n.c.</span>
              </CFormLabel>
            </div>
          )}
          <div className={props.inline ? 'radio-wrap' : ''}>
            {props.values.map((current, k) => (
              <div key={`radio-button-${props.name}-${k}`}>
                <CFormLabel htmlFor={`${props.name + k}`}>
                  <CFormCheck type="radio" id={`${props.name + k}`} name={props.name} disabled={props.disabled}
                    checked={(fieldReturned) ? current[fieldReturned] === props.value : (typeof current !== 'object') ? current === props.value : false}
                    onFocus={handleOnFocus.bind(this)}
                    onChange={handleOnChange.bind(this, (fieldReturned) ? current[fieldReturned] : current)}
                    /* onBlur={props.onBlur}*/ />

                  <span className="checkmark">{(fieldDisplayed) ? (props.alreadyTranslated ? current[fieldDisplayed] : t(`SelectValues.${current[fieldDisplayed]}`)) : (typeof current !== 'object') ? t((current.indexOf('Table.') !== 0 && current.indexOf('Common.') !== 0 && !props.alreadyTranslated) ? `SelectValues.${current}` : current) : null}</span>
                </CFormLabel>
              </div>
            ))}
          </div>
          {invalid && (
            <div className="invalid-feedback">{t('Common.field_invalid')}</div>
          )}
        </div>
      )}
    </I18n>
  );
}

UiRadioButton.propTypes = propTypes;

export default UiRadioButton;
