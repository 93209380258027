//GLOBAL
import React from 'react'
import { connect } from 'react-redux';

//UI
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro';

//UTILS
import withRouter from '../../utils/withNavigation';
import ConstantsService from '../../services/constantsService';
import { SketchPicker } from 'react-color';
import ObjectsUtils from '../../utils/objectsUtils';

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiSelectClassic from '../ui/UiSelectClassic';
import UiMdEditor from '../ui/UiMdEditor';
import UiPictureUpload from '../ui/UiPictureUpload';
import UiTranslationsTabs from '../ui/UiTranslationsTabs';

class CaseStudy extends AbstractDetail {

  constructor(props) {
    super(props);

    this.state.language = 'it';
    this.state.translations = [
      { locale: 'it' },
      { locale: 'en' },
    ];
    this.state.typology = '';
    this.state.displayColorPicker = false;
    this.state.currentBgColor = '';
  }

  updateModel() {
    if (!this.computedItemId && !this.itemId) {
      return;
    }
    super.updateModel();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentBgColor === '' && this.state?.model?.hasOwnProperty('backgroundColor')) {
      this.setState({
        currentBgColor: this.state?.model?.background_color
      })
    }
  }

  buildHeaderSubtitle() {
    let subtitle = null;
    if (this.state.model && ObjectsUtils.isArrayNotEmpty(this.state.model.translations)) {
      subtitle = this.state.model.translations.find(obj => obj.language === this.globalService.currentLanguage)?.title;
      if (!subtitle) {
        subtitle = this.state.model.translations.find(obj => obj.language === 'en')?.title;
      }
      if (!subtitle) {
        subtitle = this.state.model.translations[0].title;
      }
    }
    return subtitle;
  }

  handleTipologyChange(evt) {
    if (evt?.traget?.value) {
      this.setState({
        typology: evt?.target?.value
      })
    }

    if (evt) {
      this.handleOnChange.bind(this);
    }
  }

  handleColorChange(color) {
    const obj = {
      target: {
        name: 'backgroundColor',
        value: color?.hex
      }

    }
    this.handleOnChange(obj);
    this.setState({
      currentBgColor: color?.hex
    })

  }

  handleClickColor() {
    if (this.state?.editable) {
      this.setState({
        displayColorPicker: !this.state?.displayColorPicker
      })
    }
  }

  renderChildren(t) {
    return (
      <>
        <UiCard className="mt-4 colored-header" icon="cis-browser" title={t('Table.main_data')} collapsible={false}>
          <UiTranslationsTabs
            tabs={this.state.translations}
            activeTab={this.state.language}
            disabled={true}
            onTab={(locale) => this.setState({ language: locale })}
          >
            <div>
              {this.state.model.translations && this.state.model.translations.map((translation, index) => (
                <div id={translation.language} key={translation.language}>
                  <CRow>
                    <CCol sm="12" md="6" lg="6" xl="6">
                      <UiInput type="text" name={`translations[${index}].title`} label="Table.title"
                        disabled={!this.state.editable}
                        required={this.state.mandatoryFields.indexOf('title') >= 0}
                        value={translation.title}
                        onFocus={this.handleOnFocus.bind(this)}
                        onChange={this.handleOnChange.bind(this)}
                        onBlur={this.handleOnBlur.bind(this)}
                      />
                    </CCol>
                    <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                      <UiInput type="text" name={`translations[${index}].subtitle`} label="Table.subtitle"
                        disabled={!this.state.editable}
                        required={this.state.mandatoryFields.indexOf('subtitle') >= 0}
                        value={translation.subtitle}
                        onFocus={this.handleOnFocus.bind(this)}
                        onChange={this.handleOnChange.bind(this)}
                        onBlur={this.handleOnBlur.bind(this)}
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12" md="6" lg="6" xl="6">
                      <label style={{ marginBottom: '8px', fontWeight: 'bold' }}>{t('Table.background_color')}</label>
                      <div style={{ width: '100%', height: 40, padding: 7, border: '1px solid #768192', borderRadius: '0.375rem' }} className={!this.state?.editable ? 'disabled-color' : ''}>
                        <div style={{ width: '100%', height: '100%', backgroundColor: this.state?.model?.backgroundColor }} onClick={this.handleClickColor.bind(this)}>
                          {(this.state?.currentBgColor === '#fff' || this.state?.currentBgColor === '#ffffff' || !this.state.editable) ? this.state?.currentBgColor : ''}
                        </div>
                      </div>
                      {
                        this.state.displayColorPicker && (
                          <SketchPicker
                            color={this.state?.model?.backgroundColor}
                            onChange={this.handleColorChange.bind(this)}
                          />
                        )
                      }
                    </CCol>
                    <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                      <UiInput type="text" name={`translations[${index}].permalink`} label="Table.permalink"
                        disabled={!this.state.editable}
                        required={this.state.mandatoryFields.indexOf('permalink') >= 0}
                        value={translation.permalink}
                        onFocus={this.handleOnFocus.bind(this)}
                        onChange={this.handleOnChange.bind(this)}
                        onBlur={this.handleOnBlur.bind(this)}
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12" md="6" lg="6" xl="6">
                      <UiSelectClassic name={`translations[${index}].language`} label={'Table.language'}
                        value={translation.language}
                        fieldDisplayed={"name"} fieldReturned={"iso2"}
                        values={ConstantsService.iso6933LangList}
                        nosort={true}
                        onValueChanged={(evt) => {
                          this.handleOnChange(evt)
                        }}
                        onChange={(evt) => {
                          this.handleOnChange(evt)
                        }}
                        forceTranslation alreadyTranslated={false}
                        disabled
                      />
                    </CCol>
                    <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                      <UiSelectClassic name='type' label={'Table.type'}
                        value={this.state.model.type}
                        fieldDisplayed={"label"} fieldReturned={"value"}
                        values={ConstantsService.case_study_types}
                        nosort={true}
                        onValueChanged={this.handleTipologyChange.bind(this)}
                        onChange={this.handleTipologyChange.bind(this)}
                        forceTranslation alreadyTranslated={false}
                        disabled
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <UiMdEditor name={`translations[${index}].description`}
                      value={translation.description}
                      disabled={!this.state.editable}
                      onFocus={this.handleOnFocus.bind(this)}
                      onChange={this.handleOnChange.bind(this)}
                      onBlur={this.handleOnBlur.bind(this)}
                    />
                  </CRow>
                </div>
              ))}
            </div>
          </UiTranslationsTabs>
        </UiCard>
        <UiCard className="mt-4 colored-header" icon="cis-browser" title={t('Table.logo')} collapsible={false}>
          <CRow>
            <UiPictureUpload name='logo'
              disabled={!this.state.editable}
              value={this.state.model.logo}
              onChange={this.onNewImageChange.bind(this, this.state.model, 'logo')}
              onDelete={this.onImageDelete.bind(this, this.itemId)}
              fullScreenEnabled editable
            />
          </CRow>
        </UiCard>
      </>
    )
  }
}

export default connect(mapStateToProps)(withRouter(CaseStudy));