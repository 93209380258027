import React, { useRef, useEffect } from "react";
import { Converter } from "showdown";
import PropTypes from 'prop-types';

import boldIcon from "../../../images/icons/bold.png";
import italicIcon from "../../../images/icons/italic.png";
import underlineIcon from "../../../images/icons/underline.png";
import listIcon from "../../../images/icons/list.png";
import orderedListIcon from "../../../images/icons/ordered-list.png";
import linkIcon from "../../../images/icons/link.png";

const propTypes = {
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    tooltipMsg: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
};

const converter = new Converter({
    simpleLineBreaks: true,
    simplifiedAutoLink: true,
    noHeaderId: true,
});

function getValueSnippets(textarea) {
    const { selectionStart, selectionEnd, value } = textarea;
    return [
        value.substring(0, selectionStart),
        value.substring(selectionStart, selectionEnd).split("\n"),
        value.substring(selectionEnd),
    ];
}

const UiMdEditor = (props) => {

    const textAreaRef = useRef(null);
    const previewRef = useRef(null);

    useEffect(() => {
        textAreaRef.current.addEventListener("scroll", function () {
            // Get vertical scroll position of textarea
            var scrollTop = textAreaRef.current.scrollTop;

            // set scroll position of preview div
            previewRef.current.scrollTop = scrollTop;
        });
         previewRef.current.addEventListener("scroll", function () {
          // Get vertical scroll position of textarea
          var scrollTop =  previewRef.current.scrollTop;

          // set scroll position of preview div
          textAreaRef.current.scrollTop = scrollTop;
      });
    }, []);

    function handleOnChange(evt) {
        if (!props.disabled) {
            const event = {
                target: {
                    name: props.name,
                    value: evt.target.value,
                },
            };
            if (props.onChange) props.onChange(event);

            // if (props.onBlur) {
            //     setTimeout(() => {
            //         props.onBlur(event);
            //     }, 500);
            // }
        }
    }

    return (
        <div className="container-md">
            <div className="toolbar">
                <button
                    type="button"
                    onClick={() => {
                        const [start, snippets, end] = getValueSnippets(ref.current);
                        const transformed = snippets.map((s) => `**${s}**`).join("\n");
                        handleOnChange({
                            target: {
                                value: `${start}${transformed}${end}`,
                            },
                        });
                    }}
                >
                    <img src={boldIcon} alt="icon bold" />
                </button>
                <button
                    type="button"
                    onClick={() => {
                        const [start, snippets, end] = getValueSnippets(ref.current);
                        const transformed = snippets.map((s) => `_${s}_`).join("\n");
                        handleOnChange({
                            target: {
                                value: `${start}${transformed}${end}`,
                            },
                        });
                    }}
                >
                    <img src={italicIcon} alt="italic icon" />
                </button>
                <button
                    type="button"
                    onClick={() => {
                        const [start, snippets, end] = getValueSnippets(ref.current);
                        const transformed = snippets.map((s) => `<u>${s}</u>`).join("\n");
                        handleOnChange({
                            target: {
                                value: `${start}${transformed}${end}`,
                            },
                        });
                    }}
                >
                    <img src={underlineIcon} alt="underline icon" />
                </button>
                <button
                    type="button"
                    onClick={() => {
                        const [start, snippets, end] = getValueSnippets(ref.current);
                        const transformed = snippets.map((s) => `- ${s}`).join("\n");
                        handleOnChange({
                            target: {
                                value: `${start}${transformed}${end}`,
                            },
                        });
                    }}
                >
                    <img src={listIcon} alt="list icon" />
                </button>
                <button
                    type="button"
                    onClick={() => {
                        const [start, snippets, end] = getValueSnippets(ref.current);
                        const transformed = snippets
                            .map((s, i) => `${i + 1}. ${s}`)
                            .join("\n");
                        handleOnChange({
                            target: {
                                value: `${start}${transformed}${end}`,
                            },
                        });
                    }}
                >
                    <img src={orderedListIcon} alt="orderedList icon" />
                </button>
                <button
                    type="button"
                    onClick={() => {
                        const [start, snippets, end] = getValueSnippets(ref.current);
                        const link = prompt("Inserisci il link");
                        if (link) {
                            const transformed = snippets
                                .map((s) => `[${s}](${link})`)
                                .join("\n");
                            handleOnChange({
                                target: {
                                    value: `${start}${transformed}${end}`,
                                },
                            });
                        }
                    }}
                >
                    <img src={linkIcon} alt="link icon" />
                </button>
            </div>
            <textarea
                ref={textAreaRef}
                value={props.value}
                className="editor"
                onChange={handleOnChange.bind(this)}>
            </textarea>
            <div
                ref={previewRef}
                className="preview"
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(props.value) }}>
            </div>
        </div>
    );
};

export default UiMdEditor;