import BaseService from './baseService';
import cloneDeep from 'lodash/cloneDeep';

export default class EventsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!EventsService.instance) {
      EventsService.instance = new EventsService(store);
    }
    return EventsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'events', 'event');
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    let newObj = {};
    newObj.event_date = obj.event_date;
    newObj.images = [];
    newObj.translations = [
      {
        language: obj.language,
        title: obj.title,
        subtitle: obj.subtitle,
        permalink: obj.permalink,
        description: ''
      }
    ];
    return super.addItem(newObj, lang, token, okCallBack, koCallBack);
  }

  updateItemCustomId(contentId, obj, lang, token, okCallBack, koCallBack) {
    let newObj = cloneDeep(obj);
    if (newObj.translations[1].id_event === undefined || newObj.translations[1].id_event === null) {
      newObj.translations[1] = {
        id_event: obj.translations[0].id_event,
        language: newObj.translations[1].language,
        title: newObj.translations[1].title,
        subtitle: newObj.translations[1].subtitle,
        permalink: newObj.translations[1].permalink,
        description: newObj.translations[1].description
      }
    }

    function updateKoCallBack(error) {
      this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
    }
    return super.put(this.itemName, this.urlPath + '/' + contentId, lang, token, newObj, okCallBack, updateKoCallBack.bind(this));
  }

  getCover(contentId, obj, lang, token, okCallBack, koCallBack) {
    return super.get(this.itemName + '_cover', this.urlPath + '/' + contentId + '/cover', lang, token, obj, okCallBack, koCallBack);
  }

  setAsCover(contentId, imageId, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName + "_cover", this.urlPath + '/' + contentId + '/set_cover', lang, token, { cover_img_id: imageId }, okCallBack, koCallBack);
  }

  removeCover(contentId, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName + "_cover", this.urlPath + '/' + contentId + '/remove_cover', lang, token, {}, okCallBack, koCallBack);
  }

  addImage(contentId, obj, image, lang, token, okCallBack, koCallBack, progressCallback) {
    const newImage = {
      name: image.image_name,
      id: contentId,
      file: image.image,
      description: image.image_name,
      type: obj.type,
      v_order: image.v_order
    };
    return super.postMultipart(this.itemName + '_images', `${this.urlPath}/${contentId}/site-images-s3`, lang, token, newImage, 'image_content', okCallBack, koCallBack, progressCallback);
  }

  orderImages(contentId, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, this.urlPath + '/' + contentId + '/images/ordered', lang, token, obj, okCallBack, koCallBack);
  }

  disableItem(contentId, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, this.urlPath + '/deactivate/' + contentId, lang, token, obj, okCallBack, koCallBack);
  }

  reactivateItem(contentId, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, this.urlPath + '/activate/' + contentId, lang, token, obj, okCallBack, koCallBack);
  }
}