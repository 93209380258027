// GLOBAL
import React from 'react';
import { Link } from 'react-router-dom';
import { CCol, CForm, CRow } from '@coreui/react-pro';
import { I18n } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

// SERVICES
import TableMapService from '../../services/tableMapService';
import ConstantsService from '../../services/constantsService';

// COMPONENTS
import AbstractComponent from './AbstractComponent';
import UiSectionHeader from '../ui/UiSectionHeader';
import UiNavsTabs from '../ui/UiNavsTabs';

import UiCard from '../ui/UiCard';
import UiCheckbox from '../ui/UiCheckbox';
import UiCoverUpload from '../ui/UiCoverUpload';
import UiInput from '../ui/UiInput';
import UiModal from '../ui/UiModal';
import UiPictureUpload from '../ui/UiPictureUpload';
import UiVideoUpload from '../ui/UiVideoUpload';
import ObjectsUtils from '../../utils/objectsUtils';

export default class AbstractDetail extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentSection: null,
      viewable: false,
      editable: false,
      model: null,
      modelLoaded: false,
      mandatoryFields: [],
      exportData: {},
      importData: {},
      languageModel: {}
    };

    this.computedItemId = null;
    this.state.activeTab = 'master_data';
  }

  componentDidMount() {
    if (this.sectionService && !this.state.modelLoaded) {
      this.updateModel();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = cloneDeep(prevState);
    if (nextProps.globalServiceReducer && nextProps.globalServiceReducer.status === 'SESSION_READY' && nextProps.globalServiceReducer.currentUser
      && nextProps.globalServiceReducer.currentSection && !isEqual(prevState.currentSection, nextProps.globalServiceReducer.currentSection)) {
      newState.currentSection = nextProps.globalServiceReducer.currentSection;
      newState.viewable = nextProps.globalServiceReducer.currentUser.scopes.indexOf(nextProps.globalServiceReducer.currentSection.sectionViewScope) >= 0 || nextProps.globalServiceReducer.currentSection.sectionViewScope === "*";
      newState.editable = nextProps.globalServiceReducer.currentUser.scopes.indexOf(nextProps.globalServiceReducer.currentSection.sectionEditScope) >= 0 || nextProps.globalServiceReducer.currentSection.sectionEditScope === "*";
      if (newState.currentSection.sectionTabs && newState.currentSection.sectionTabs.length > 0) {
        if (!newState.currentSection.sectionTabs.find((obj) => obj.id === newState.activeTab)) {
          newState.activeTab = newState.currentSection.sectionTabs[0].id;
        }
      }
      newState.mandatoryFields = (TableMapService.getTableMapByGroup(nextProps.globalServiceReducer.currentUser.userGroup)[newState.currentSection.sectionId]) ? TableMapService.getTableMapByGroup(nextProps.globalServiceReducer.currentUser.userGroup)[newState.currentSection.sectionId].filter((obj) => obj.mandatory === true).map((obj) => obj.name) : [];
    }

    if (isEqual(prevState.currentSection, nextProps.globalServiceReducer.currentSection)) {
      if (newState.viewable && !newState.modelLoaded && nextProps.apiReducer && nextProps.apiReducer[newState.currentSection.sectionId] && nextProps.apiReducer[newState.currentSection.sectionId].status === 'VALID') {
        newState.modelLoaded = true;
        newState.model = cloneDeep(Array.isArray(nextProps.apiReducer[newState.currentSection.sectionId].data) ? { list: nextProps.apiReducer[newState.currentSection.sectionId].data } : nextProps.apiReducer[newState.currentSection.sectionId].data);
      }
    }
    if (!newState.currentTranslation) {
      newState.currentTranslation = 'it';
    }
    return newState;
  }

  populateServices() {
    if (!this.globalService.currentUser || !(this.globalService.currentUser.userId !== 0)) {
      return;
    }

    if (!this.sectionService) {
      super.populateServices();

      if (this.state && !this.state.modelLoaded) {
        this.updateModel();
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const should = super.shouldComponentUpdate(nextProps, nextState);
    if (nextProps.location?.pathname && this.globalService.currentSection?.sectionRoute) {
      let params = ObjectsUtils.getUrlParamsFromFormat(this.globalService.currentSection.sectionRoute, nextProps.location.pathname);
      this.hasNumericId = /^\d+$/.test(params.id);
      if (this.hasNumericId && parseInt(params.id) !== parseInt(this.itemId) || !this.hasNumericId && params.id !== this.itemId) {
        this.itemId = this.hasNumericId ? parseInt(params.id) : params.id;
        this.updateModel();
        return false;
      }
      if (params.activeTab && this.state.activeTab !== params.activeTab) {
        let activeTab = params.activeTab
        if (this.globalService.currentSection.sectionTabs && this.globalService.currentSection.sectionTabs.length > 0) {
          if (!this.globalService.currentSection.sectionTabs.find((obj) => obj.id === activeTab)) {
            activeTab = this.globalService.currentSection.sectionTabs[0].id;
          }
        }
        this.setState({ activeTab: activeTab });
        return false;
      }
    }
    return should;
  }

  updateModel() {
    if (!this.computedItemId && !this.itemId) {
      return;
    }
    this.setState({ modelLoaded: false, model: null, changing: false }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
    this.sectionService.init();
    this.sectionService.getItem((this.computedItemId) ? this.computedItemId : this.itemId, this.globalService.currentLanguage, this.globalService.currentUser, this.storeModel.bind(this), this.manageMissingItem.bind(this));
  }

  storeModel() {
    const lastSelected = (localStorage.getItem('lastSelected')) ? JSON.parse(localStorage.getItem('lastSelected')) : {};

    lastSelected[this.props.globalServiceReducer.currentSection.sectionParent] = [];
    lastSelected[this.props.globalServiceReducer.currentSection.sectionId] = [].push((this.computedItemId) ? this.computedItemId : this.itemId);
    localStorage.setItem('lastSelected', JSON.stringify(lastSelected));
  }

  manageMissingItem(event) {
    if (event && event.status === 404) {
      this.setState({ modelLoaded: true, model: null });
    }
  }

  initRelation(link, response) {
    this.setState({ [link]: response.data });
  }

  refreshRelation(link, filter) {
    this.sectionService.getRelation((this.computedItemId) ? this.computedItemId : this.itemId, filter, link, this.globalService.currentLanguage, this.globalService.currentUser, this.initRelation.bind(this, link));
  }

  openDetail(id, target) {
    if (target && typeof target === 'string') {
      this.props.navigate(`/${target}/${id}`);
    } else {
      this.props.navigate(`/${this.props.globalServiceReducer.currentSection.sectionChild}/${id}`);
    }
  }

  handleOnFocus(event) { }

  handleOnChange(event) {
    var model = cloneDeep(this.state.model);
    eval(`model.${event.target.name} = event.target.value`);
    this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
  }

  handleOnRangeChange(event) {
    var model = cloneDeep(this.state.model);
    model[`start_${event.target.name}`] = event.target.startDate;
    model[`end_${event.target.name}`] = event.target.endDate;
    this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
  }

  handleOnForcedChange(event) {
    var model = cloneDeep(this.state.model);
    if (model && eval(`model.${event.target.name} !== event.target.value`)) {
      eval(`model.${event.target.name} = event.target.value`);
    }

    //this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing); this.handleOnBlur(event); });
  }

  getChangedModel(event) {
    var { data } = cloneDeep(this.props.apiReducer[this.props.globalServiceReducer.currentSection.sectionId]);
    var model = cloneDeep(this.state.model);
    if (event.target.startDate !== undefined && event.target.endDate !== undefined) {
      model[`${event.target.name}_start`] = event.target.startDate;
      model[`${event.target.name}_end`] = event.target.endDate;
    } else {
      eval(`model.${event.target.name} = event.target.value`);
    }
    return (!isEqual(data, model)) ? model : null;
  }

  handleOnBlur(event) {
    if (this.props.apiReducer[this.props.globalServiceReducer.currentSection.sectionId].status === 'LOADING') {
      return;
    }
    if (event && event.target && event.target.name) {
      let model = this.getChangedModel(event);
      if (model) {
        if (ConstantsService.autosaveEnabled) {
          this.sectionService.updateItemCustomId((this.computedItemId) ? this.computedItemId : this.itemId, model, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallback.bind(this), this.koCallback.bind(this));
        } else {
          this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
        }
      }
    }
  }

  handleOnBlurWithRefresh(event) {
    if (this.props.apiReducer[this.props.globalServiceReducer.currentSection.sectionId].status === 'LOADING') {
      return;
    }
    if (event && event.target && event.target.name) {
      let model = this.getChangedModel(event);
      if (model) {
        if (ConstantsService.autosaveEnabled) {
          this.sectionService.updateItemCustomId((this.computedItemId) ? this.computedItemId : this.itemId, model, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
        } else {
          this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
        }
      }
    }
  }

  handleOnSave() {
    if (ConstantsService.autosaveEnabled || this.props.apiReducer[this.props.globalServiceReducer.currentSection.sectionId].status === 'LOADING') {
      return;
    }
    this.sectionService.updateItemCustomId((this.computedItemId) ? this.computedItemId : this.itemId, this.state.model, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  okCallback() {
    this.setState({ changing: false }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
    return toast.success(i18n.t('Common.success'));
  }

  okCallbackWithRefresh(response) {
    const model = response.data;
    this.setState({ model, changing: false }, () => { this.props.onSaveButtonEnabled(this.state.changing); });

    /* DA VERIFICARE
      const oldModel = cloneDeep(this.state.model);
      const model = {};
      if (!ConstantsService.autosaveEnabled && this.state.changing){
        model = {...response.data, ...oldModel }
        this.setState({ model }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
      } else {
        model = response.data;
        this.setState({ model, changing: false }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
      }
    */

    return toast.success(i18n.t('Common.success'));
  }

  koCallback(response, firstError) {
    if (firstError) {
      return toast.error(`${i18n.t('Common.error')}: ${ObjectsUtils.buildError(firstError)}`, { autoClose: 5000 });
    }
    if (response.status > 399 || response.data.status > 399) {
      return toast.error(`${i18n.t('Common.error')}: ${ObjectsUtils.buildError(response)}`, { autoClose: 5000 });
    }
    return toast.success(i18n.t('Common.success'));
  }

  handleAddLanguage(newLang) {
    if (!this.state.model.translations) return;
    const newModel = cloneDeep(this.state.model);
    /* SE translations è sotto forma di array: [locale: "it", title: "pippo" ...] */
    if (Array.isArray(this.state.model.translations)) {
      const lang = this.state.model.translations.find((obj) => obj.locale === newLang.locale);
      if (lang) {
        this.setState({ alreadyTranslatedError: true });
        return;
      }
      this.toggleModal('modalLanguageAdd');
      newModel.translations.push(newLang);
    } else {
      /* SE translations è sotto forma di mappa: {it: {title: "pippo"}}*/
      const lang = Object.keys(this.state.model.translations).find((locale) => locale === newLang.locale);
      if (lang) {
        this.setState({ alreadyTranslatedError: true });
        return;
      }
      this.toggleModal('modalLanguageAdd');
      newModel.translations[newLang.locale] = newLang;
    }

    if (ConstantsService.autosaveEnabled) {
      this.setState({ model: newModel, alreadyTranslatedError: false, currentTranslation: newLang.locale });
      this.sectionService.updateItemCustomId((this.computedItemId) ? this.computedItemId : this.itemId, newModel, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallback.bind(this), this.koCallback.bind(this));
    } else {
      this.setState({ model: newModel, alreadyTranslatedError: false, currentTranslation: newLang.locale, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
    }
  }

  changeTab(id, url) {
    this.setState({ activeTab: id });
    this.props.navigate(url);
  }

  handleRenderContact(contact) {
    if (this.globalService.currentUser && this.globalService.currentUser.scopes.indexOf('contacts_view') >= 0) {
      return (<Link to={`/contact/${contact.contact_id}`}>{contact.business_name}{(contact.contact_relation_type) ? ` - ${contact.contact_relation_type}` : ''}</Link>);
    }
    return (<div>{contact.business_name}{(contact.contact_relation_type) ? ` - ${contact.contact_relation_type}` : ''}</div>);
  }

  handleAddContact(contact) {
    this.toggleModal('modalContactAdd');
    this.sectionService.addContact((this.computedItemId) ? this.computedItemId : this.itemId, contact, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshRelation.bind(this, 'contacts', null), this.koCallback.bind(this));
  }

  handleEditContact(contact) {
    this.toggleModal('modalContactAdd');
    this.sectionService.updateContact((this.computedItemId) ? this.computedItemId : this.itemId, contact, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshRelation.bind(this, 'contacts', null), this.koCallback.bind(this));
  }

  handleDeleteContact(contact) {
    this.toggleModal('modalContactDelete');
    this.sectionService.deleteContact((this.computedItemId) ? this.computedItemId : this.itemId, contact, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshRelation.bind(this, 'contacts', null), this.koCallback.bind(this));
  }

  handleOnSingleImageChange(event) {
    let model = cloneDeep(this.state.model);
    const index = (model.media_contents) ? model.media_contents.findIndex(obj => obj.type === event.target.name) : -1;
    const target = (index >= 0) ? model.media_contents[index] : {};

    if (event.target.file) {
      const toastId = toast(`${i18n.t('Common.uploading')}...`, { autoClose: false });

      function addOkCallBack(success) {
        toast.update(toastId, {
          render: i18n.t('Common.success'),
          type: 'success',
          className: 'rotateY animated',
          autoClose: 1000,
        });

        if (success && success.data) {
          if (index >= 0) {
            model.media_contents[index] = success.data;
          } else {
            if (!model.media_contents) {
              model.media_contents = [];
            }
            model.media_contents.push(success.data);
          }
        }

        this.setState({ model });
      }

      function progressCallBack(event) {
        toast.update(toastId, {
          render: `${i18n.t('Common.uploading')}: ${event.loaded}/${event.total}`,
          type: 'info',
          className: 'rotateY animated',
          autoClose: (event.loaded < event.total) ? false : 1000,
        });
      }

      function addKoCallBack(response) {
        toast.update(toastId, {
          render: `${i18n.t('Common.error')}: ${response.data.message}`,
          type: 'error',
          className: 'rotateY animated',
          autoClose: 5000,
        });
      }

      target.child_id = event.target.child_id;
      target.file = event.target.file;
      target.filename = event.target.image_name;
      target.is_video = false;
      target.published = true;
      target.type = event.target.name;
      this.sectionService.uploadMedia(this.itemId, target, this.globalService.currentLanguage, this.globalService.currentUser, addOkCallBack.bind(this), addKoCallBack.bind(this), progressCallBack.bind(this));
    } /*else if (event.target.fileurl) {
      model[(event.target.name !== 'null') ? event.target.name + "_image" : "image"] = event.target.fileurl;
      model[(event.target.name !== 'null') ? event.target.name + "_filename" : "filename"] = event.target.image_name;
      model[(event.target.name !== 'null') ? event.target.name + "_image_path" : "image_path"] = undefined;
      this.sectionService.updateItemCustomId(this.itemId, model, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
    } */else {
      this.onImageDelete(target.child_id)
    }
  }

  renderSingleImage(targetName) {
    const target = (this.state.model.media_contents) ? this.state.model.media_contents.find(obj => obj.type === targetName) || {} : {};

    return (
      <UiCoverUpload name={targetName} label={'Table.' + targetName} disabled={!this.state.editable}
        filename={target.filename} value={(target.path) ? target.path : undefined}
        onChange={this.handleOnSingleImageChange.bind(this)} />
    );
  }

  onNewImageChange(target, targetName, event) {
    let model = cloneDeep(this.state.model);

    if (event.target.image) {
      const toastId = toast(`${i18n.t('Common.uploading')}...`, { autoClose: false });

      function addOkCallBack(success) {
        toast.update(toastId, {
          render: i18n.t('Common.success'),
          type: 'success',
          className: 'rotateY animated',
          autoClose: 1000,
        });

        if (success && success.data) {
          model = success.data;
        }
        this.setState({ model });
      }

      function progressCallBack(event) {
        toast.update(toastId, {
          render: `${i18n.t('Common.uploading')}: ${event.loaded}/${event.total}`,
          type: 'info',
          className: 'rotateY animated',
          autoClose: (event.loaded < event.total) ? false : 1000,
        });
      }

      function addKoCallBack(response) {
        let msg = "";
        if (response && response.data && response.data.message) {
          msg = response.data.message;
        }
        toast.update(toastId, {
          render: `${i18n.t('Common.error')}: ${msg}`,
          type: 'error',
          className: 'rotateY animated',
          autoClose: 5000,
        });
      }

      let image = cloneDeep(event.target);
      image.file = event.target.image;
      image.filename = event.target.image_name;
      this.sectionService.uploadMedia(this.itemId, image, this.globalService.currentLanguage, this.globalService.currentUser, addOkCallBack.bind(this), addKoCallBack.bind(this), progressCallBack.bind(this));
    }
  }

  onImageDataChange(target, event) {
    const index = (this.state.model.media_contents) ? this.state.model.media_contents.findIndex(obj => obj.child_id === target.child_id) : -1;
    if (index >= 0) {
      let model = cloneDeep(this.state.model);
      model.media_contents[index][event.target.name] = event.target.value;
      this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
    }
  }

  onImageDataUpdate(target, event) {
    if (ConstantsService.autosaveEnabled) {
      this.sectionService.updateMediaData(this.itemId, target, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallback.bind(this), this.koCallback.bind(this));
    }
  }

  onImageDelete(targetId) {
    // if (ConstantsService.autosaveEnabled) {
    this.sectionService.deleteMedia(this.itemId, targetId, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
    // } else {
    //   const index = (this.state.model.media_contents) ? this.state.model.media_contents.findIndex(obj => obj.child_id === targetId) : -1;
    //   if (index >= 0) {
    //     let model = cloneDeep(this.state.model);
    //     model.media_contents.splice(index, 1);
    //     this.setState({ model, changing: true }, () => { this.props.onSaveButtonEnabled(this.state.changing); });
    //   }
    // }
  }

  renderMultipleImages(targetName) {
    let model = cloneDeep(this.state.model);
    let media_contents = (model.media_contents) ? model.media_contents.filter(obj => obj.type === targetName) : [];
    return (
      <UiCard title={i18n.t(`Table.${targetName}`)} icon="cis-image" collapsible={false}>
        <CRow>
          {media_contents && media_contents.map((image, index) => (
            <CCol sm="12" md="5" lg="5" xl="5" key={index} className="blockBorder">
              <CRow>
                {(image.is_video) ?
                  <UiVideoUpload name="file" label="Table.new_video" disabled={!(this.state.editable)}
                    videoId={image[ConstantsService.defaultDBIdentifier]} weight={200} width={200} height={200}
                    filename={image.filename} value={image.path} deletable
                    onChange={this.onNewImageChange.bind(this, image, targetName)} onDelete={this.onImageDelete.bind(this, image.child_id)} />
                  :
                  <UiPictureUpload name="file" label={`${i18n.t('Table.image')} ${index + 1}/${media_contents.length}`}
                    filename={image.filename} value={(image.path) ? image.path : undefined} disabled={!this.state.editable}
                    downloadable deletable onChange={this.onNewImageChange.bind(this, image, targetName)} onDelete={this.onImageDelete.bind(this, image.child_id)} />
                }
              </CRow>
              {image.errorMessage && image.errorMessage.length > 0 && (
                <CRow>
                  <p className="error">{image.errorMessage}</p>
                </CRow>
              )}
              <CRow>
                <CCol sm="12" md="2" lg="2" xl="2">
                  <UiInput type='number' name="order" label={'Table.order'} disabled={!this.state.editable}
                    value={image.order} onChange={this.onImageDataChange.bind(this, image)} onBlur={this.onImageDataUpdate.bind(this, image)} />
                </CCol>
                <CCol sm="12" md="5" lg="5" xl="5" className="rightCol">
                  <UiCheckbox name="published" label={'Table.published'} disabled={!this.state.editable}
                    value={image.published} onChange={this.onImageDataChange.bind(this, image)} onBlur={this.onImageDataUpdate.bind(this, image)} />
                </CCol>
              </CRow>
            </CCol>
          ))}
          {this.state[`new_${targetName}`]
            && (
              <CCol sm="12" md="6" lg="6" xl="6" className="blockBorder">
                <CRow>
                  {(this.state[`new_${targetName}`].is_video) ?
                    <UiVideoUpload name="file" label="Table.new_video" disabled={!(this.state.editable)}
                      videoId={'0'} weight={200} width={200} height={200}
                      filename={this.state[`new_${targetName}`].path} value={this.state[`new_${targetName}`].path}
                      onChange={this.onNewImageChange.bind(this, this.state[`new_${targetName}`], targetName)} />
                    :
                    <UiPictureUpload name="file" label="Table.new_image" disabled={!this.state.editable}
                      filename={this.state[`new_${targetName}`].path} value={this.state[`new_${targetName}`].path}
                      onChange={this.onNewImageChange.bind(this, this.state[`new_${targetName}`], targetName)} />
                  }
                </CRow>
                {this.state[`new_${targetName}`].errorMessage && this.state[`new_${targetName}`].errorMessage.length > 0 && (
                  <CRow>
                    <p className="error">{this.state[`new_${targetName}`].errorMessage}</p>
                  </CRow>
                )}
                <CRow>
                  <CCol sm="12" md="2" lg="2" xl="2">
                    <UiInput type='number' name="order" label={'Table.order'} disabled={!this.state.editable}
                      value={this.state[`new_${targetName}`].order} onChange={this.onNewImageChange.bind(this, this.state[`new_${targetName}`], targetName)} />
                  </CCol>
                  <CCol sm="12" md="5" lg="5" xl="5" className="rightCol">
                    <UiCheckbox name="is_video" label={'Table.is_video'} disabled={!this.state.editable}
                      value={this.state[`new_${targetName}`].is_video} onChange={this.onNewImageChange.bind(this, this.state[`new_${targetName}`], targetName)} />
                  </CCol>
                  <CCol sm="12" md="5" lg="5" xl="5" className="rightCol">
                    <UiCheckbox name="published" label={'Table.published'} disabled={!this.state.editable}
                      value={this.state[`new_${targetName}`].published} onChange={this.onNewImageChange.bind(this, this.state[`new_${targetName}`], targetName)} />
                  </CCol>
                </CRow>
              </CCol>
            )}
        </CRow>
      </UiCard>
    );
  }

  buildHeaderSubtitle() {
    let subtitle = null;
    if (this.state.model) {
      if (this.state.currentSection.sectionMainField) {
        if (!!this.state.currentSection.sectionMainFieldTwo) {
          subtitle = eval(`this.state.model.${this.state.currentSection.sectionMainField}`) + ' ' + eval(`this.state.model.${this.state.currentSection.sectionMainFieldTwo}`);
        } else {
          subtitle = eval(`this.state.model.${this.state.currentSection.sectionMainField}`);
        }

      }
      if (this.state.currentSection.sectionMainTraslatedField) subtitle = (this.state.model.translations) ? this.state.model.translations[this.globalService.currentLanguage][this.state.currentSection.sectionMainTraslatedField] : null;
    }
    return subtitle;
  }

  buildHeaderButtons() {
    return null;
  }

  renderChildren() {
    return (
      <CRow>
        <CCol sm="12" md="12" lg="12" xl="12" />
      </CRow>
    );
  }

  onDeleteItem(event) {
    this.toggleModal('modalDelete');
  }

  renderDeleteModal(t) {
    return (
      <UiModal title={t('Common.delete_items')} okLabel={t('Common.yes')} koLabel={t('Common.no')}
        isOpen={this.state.modalDelete} errorsModal={this.state.errorsModal}
        onSubmit={this.onDeleteItem.bind(this)} onCancel={this.toggleModal.bind(this, 'modalDelete')}>
        <p>{t('Common.are_you_sure')}</p>
      </UiModal>
    );
  }

  renderDeleteModalInTab(t, modalName, deleteFunc, errorsModal) {
    return (
      <UiModal title={t('Common.delete_items')} okLabel={t('Common.yes')} koLabel={t('Common.no')}
        isOpen={this.state[modalName]} errorsModal={errorsModal}
        onSubmit={deleteFunc.bind(this)} onCancel={this.toggleModal.bind(this, modalName)}>
        <p>{t('Common.are_you_sure')}</p>
      </UiModal>
    );
  }

  render() {
    return (
      <I18n ns="translations">
        {(t) => (
          <div className="animated">
            {this.renderToast()}
            <CForm>
              <CRow>
                {this.state.currentSection
                  && (
                    <CCol sm="12" md="12" lg="12" xl="12">
                      <UiSectionHeader changing={this.state.changing} title={this.state.currentSection.sectionName} icon={this.state.currentSection.sectionIcon}
                        subtitle={(this.buildHeaderSubtitle(t)) ? this.buildHeaderSubtitle(t) : (this.state.modelLoaded) ? t('Common.not_found') : ''}
                        onSave={this.handleOnSave.bind(this)} sectionParent={this.state.currentSection.sectionParent}>
                        {this.state.model && this.buildHeaderButtons(t)}
                      </UiSectionHeader>
                    </CCol>
                  )}
              </CRow>
              {this.state.currentSection && this.state.model && (
                <CRow>
                  <CCol sm="12" md="12" lg="12" xl="12">
                    <UiNavsTabs tabs={this.state.currentSection.sectionTabs} activeTab={this.state.activeTab} onTab={this.changeTab.bind(this)}>
                      {this.renderChildren(t)}
                    </UiNavsTabs>
                  </CCol>
                </CRow>
              )}
              {this.renderScrollUpButton()}
            </CForm>
            {(!this.state.currentSection || !this.state.modelLoaded) && this.renderSpinner()}
            {this.renderDeleteModal(t)}
            {this.renderExportModal(t)}
            {this.renderImportModal(t)}
          </div>
        )}
      </I18n>
    );
  }
}
