//GLOBAL
import React from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

//UI
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro';

//UTILS
import withRouter from '../../utils/withNavigation';
import ObjectsUtils from '../../utils/objectsUtils';

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiMdEditor from '../ui/UiMdEditor';
import UiPictureUpload from '../ui/UiPictureUpload';
import UiDatePicker from '../ui/UiDatePicker';
import UiTranslationsTabs from '../ui/UiTranslationsTabs';
import UiTextArea from '../ui/UiTextArea';
import UiButton from '../ui/UiButton';
import UiSortableGrid from '../ui/UiSortableGrid';

class Event extends AbstractDetail {

  constructor(props) {
    super(props);

    this.state.language = 'it';
    this.state.translations = [
      { locale: 'it' },
      { locale: 'en' },
    ];
  }

  updateModel() {
    if (!this.computedItemId && !this.itemId) {
      return;
    }
    super.updateModel();

    //this.sectionService.getCover(this.itemId, {}, this.globalService.currentLanguage, this.globalService.currentUser, this.initRelation.bind(this, 'cover'));
    //this.sectionService.getImages(this.itemId, {}, this.globalService.currentLanguage, this.globalService.currentUser, this.initImages.bind(this));
  }

  // initImages(response) {
  //   this.setState({ site_images: response.data, orderedSiteImages: (response.data) ? response.data.map((obj) => '' + obj.id) : [] });
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = super.getDerivedStateFromProps(nextProps, prevState);

    if (!newState.newImage) {
      newState.newImage = {};
    }

    if (newState.model) {
      let translations = newState.model.translations ?? [];

      let localeIt = translations.find(obj => obj.language === 'it');
      if (!localeIt) {
        translations.push({ language: 'it' });
      }

      let localeEn = translations.find(obj => obj.language === 'en');
      if (!localeEn) {
        translations.push({ language: 'en' });
      }

      newState.model.translations = translations;

      newState.model.images.sort((a,b) => {
        return a.v_order - b.v_order;
      })
      newState.site_images = newState.model.images;

      newState.orderedSiteImages = newState.model.images.map((obj) => '' + obj.id);
      if (newState.model?.cover_img_id) {
        let cover = newState.model.images.find(img => img.id === newState.model.cover_img_id);
        newState.cover = cover;
      } else {
        newState.cover = null;
      }
    }

    return newState;
  }

  getChangedModel(event) {
    var model = super.getChangedModel(event);
    if (model) {
      if (event.target.name && event.target.name.indexOf('.title') > 0 && event.target.value) {
        let permalinkField = event.target.name.replace('.title', '.permalink');
        if (!ObjectsUtils.isStringValid(eval(`model.${permalinkField}`))) {
          let link = ObjectsUtils.buildPermalink(event.target.value, this.state.language);
          eval(`model.${permalinkField} = link`);
        }
      }
    }
    return model;
  }

  buildHeaderSubtitle() {
    let subtitle = null;
    if (this.state.model && ObjectsUtils.isArrayNotEmpty(this.state.model.translations)) {
      subtitle = this.state.model.translations.find(obj => obj.language === this.globalService.currentLanguage)?.title;
      if (!subtitle) {
        subtitle = this.state.model.translations.find(obj => obj.language === 'en')?.title;
      }
      if (!subtitle) {
        subtitle = this.state.model.translations[0].title;
      }
    }
    return subtitle;
  }

  addOkCallBack() {
    this.updateModel();
    return toast.success(i18n.t('Common.success'));
  }

  progressCallBack(toastId, event) {
    toast.update(toastId, {
      render: `${i18n.t('Common.uploading')}: ${event.loaded}/${event.total}`,
      type: 'info',
      className: 'rotateY animated',
      autoClose: (event.loaded < event.total) ? false : 1000,
    });
  }

  handleSetImageAsCover(event) {
    this.sectionService.setAsCover(this.itemId, event.target.image_id, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  handleDeleteCover() {
    this.sectionService.removeCover(this.itemId, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  handleOnImageDelete(id, evt) {
    this.sectionService.deleteImage(this.itemId, id, this.globalService.currentLanguage, this.globalService.currentUser, this.deleteOkCallback.bind(this,id), this.koCallback.bind(this));
  }

  deleteOkCallback(id,evt){
    this.okCallback();
    let clone = cloneDeep(this.state.model);
    clone.images = clone.images.filter(item => item.id !== id);
    const orderedSiteImages = clone.images.map((obj) => '' + obj.id);
    this.setState({
      site_images: clone.images,
      orderedSiteImages
    })
    this.sectionService.orderImages(this.itemId, orderedSiteImages, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  handleOnNewImageChange(target, event) {
    let toastId = toast(`${i18n.t('Common.uploading')}...`, { autoClose: true })

    let obj = {
      ...event.target,
      v_order: this.state?.images?.length > 0 ? this.state.model.images[this.state.model.images.length - 1].v_order + 1 : 1
    }
    this.sectionService.addImage(this.itemId, target, obj, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this), this.progressCallBack.bind(this, toastId));
  }

  handleDisable() {
    if (!this.state.model.disable_date || this.state.model.disable_date === null) {
      this.sectionService.disableItem((this.computedItemId) ? this.computedItemId : this.itemId, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
    } else {
      this.sectionService.reactivateItem((this.computedItemId) ? this.computedItemId : this.itemId, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
    }
  }

  renderSortableImage(t, image) {
    return (
      <UiPictureUpload name={`site_image`} label={`${t('Table.image')} ${image.v_order}/${this.state.model.images.length}`}
        disabled={!this.state.editable}
        currentUser={this.globalService.currentUser} filename={image.image_name}
        value={(image.original) ? image.original : undefined} imageId={image.id}
        description={image.description}
        weight={image.weight} width={image.width} height={image.height}
        onSetAsCover={this.handleSetImageAsCover.bind(this)}
        onDelete={this.handleOnImageDelete.bind(this, image.id)}
        deletable fullScreenEnabled downloadable setCoverEnabled
      />
    )
  }

  handleOnOrderChange(evt) {
    if (evt?.target?.value && !isEqual(this.state.orderedSiteImages, evt.target.value)) {
      const new_images = [];
      for (let index of evt.target.value) {
        new_images.push(this.state.model.images.find(obj => '' + obj.id === '' + index));
      }
      this.setState({ site_images: new_images, orderedSiteImages: evt.target.value });
      this.sectionService.orderImages(this.itemId, evt.target.value, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
    }
  }

  renderChildren(t) {
    return (
      <>
        <UiCard className="mt-4 colored-header" icon="cis-drink" title={t('Table.main_data')} collapsible={false}>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiDatePicker type="text" name="event_date" label="Table.event_date"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('event_date') >= 0}
                value={this.state.model.event_date}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
            <CCol sm="12" md="6" lg="6" xl="6" className="rightCol" style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <UiButton
                label={!this.state.model.disable_date || this.state.model.disable_date === null ? t('Common.disable') : t('Common.reactivate')}
                onClick={this.handleDisable.bind(this)}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="12" lg="12" xl="12">
              <UiPictureUpload name='cover_img' label={'Table.cover_image'}
                disabled={!this.state.editable}
                value={this.state.cover?.original}
                onDelete={this.handleDeleteCover.bind(this)}
                notInsertable downloadable deletable fullScreenEnabled
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="12" lg="12" xl="12">
              <UiTranslationsTabs tabs={this.state.translations} activeTab={this.state.language} disabled={true}
                onTab={(locale) => this.setState({ language: locale })} >
                <div>
                  {
                    this.state.model.translations && this.state.model.translations.map((translation, index) => (
                      <div key={translation.language}>
                        <CRow>
                          <CCol sm="12" md="6" lg="6" xl="6">
                            <UiInput type="text" name={`translations[${index}].title`} label="Table.title"
                              disabled={!this.state.editable}
                              required={this.state.mandatoryFields.indexOf('title') >= 0}
                              value={translation.title}
                              onFocus={this.handleOnFocus.bind(this)}
                              onChange={this.handleOnChange.bind(this)}
                              onBlur={this.handleOnBlur.bind(this)}
                            />
                          </CCol>
                          <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                            <UiInput type="text" name={`translations[${index}].permalink`} label="Table.permalink"
                              disabled={!this.state.editable}
                              required={this.state.mandatoryFields.indexOf('permalink') >= 0}
                              value={translation.permalink}
                              onFocus={this.handleOnFocus.bind(this)}
                              onChange={this.handleOnChange.bind(this)}
                              onBlur={this.handleOnBlur.bind(this)}
                            />
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol sm="12" md="12" lg="12" xl="12">
                            <UiTextArea type="text" name={`translations[${index}].subtitle`} label="Table.subtitle"
                              disabled={!this.state.editable}
                              required={this.state.mandatoryFields.indexOf('subtitle') >= 0}
                              value={translation.subtitle}
                              onFocus={this.handleOnFocus.bind(this)}
                              onChange={this.handleOnChange.bind(this)}
                              onBlur={this.handleOnBlur.bind(this)}
                            />
                          </CCol>
                        </CRow>
                        <CRow>
                          <UiMdEditor name={`translations[${index}].description`}
                            value={translation.description}
                            disabled={!this.state.editable}
                            onFocus={this.handleOnFocus.bind(this)}
                            onChange={this.handleOnChange.bind(this)}
                            onBlur={this.handleOnBlur.bind(this)}
                          />
                        </CRow>
                      </div>
                    ))
                  }
                </div>
              </UiTranslationsTabs>
            </CCol>
          </CRow>
        </UiCard>

        <UiCard className="mt-4 colored-header" icon="cis-drink" title={t('Table.images')} collapsible={false}>
          <CRow>
            {this.state.newImage
              && (
                <CCol sm="12" md="12" lg="12" xl="12">
                  <UiPictureUpload name={`newImage.image`} label="Table.new_image" disabled={!this.state.editable}
                    currentUser={this.globalService.currentUser} filename={this.state.newImage.image_name} value={this.state.newImage.image_path}
                    //onChange={this.onNewImageChange.bind(this, this.state.model, `newImage${imageCount}.image`)}
                    onChange={this.handleOnNewImageChange.bind(this, this.state.model)}
                  />
                </CCol>
              )}
            <CCol sm="12" md="12" lg="12" xl="12">
              <UiSortableGrid
                type="text" name="prova" label="Table.order" columns={3} sortValue={'v_order'}
                value={this.state.orderedSiteImages} required alreadyTranslated fieldDisplayed={'name'} fieldReturned={'id'}
                values={this.state.site_images} customRender={this.renderSortableImage.bind(this, t)}
                onChange={this.handleOnOrderChange.bind(this)}
              />
            </CCol>
          </CRow>
        </UiCard>
      </>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Event));