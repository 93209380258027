// GLOBAL
import React, { useState, useEffect, useRef } from 'react';
import { CRow, CFormLabel, CButton, CTooltip, CProgress, CFormInput, COffcanvasHeader } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

import Tiff from 'tiff.js';

// UTILS
import ObjectsUtils from '../../utils/objectsUtils';

// COMPONENTS
import UiButton from './UiButton';

function UiPictureUpload(props) {

  const [oldProp, setOldProp] = useState(null);
  const [tiffRendered, setTiffRendered] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [isDragging, setIsDragging] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [waitingDownload, setWaitingDownload] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(null);

  const fullcanvas = useRef()
  const imagePlaceholder = useRef()
  const canvas = useRef()
  let UiInputFile = useRef(null)

  useEffect(() => {
    setOldProp(prevOldProp => {
      let newOldProp = cloneDeep(prevOldProp);
      if (!newOldProp || !ObjectsUtils.areStringsEqual(newOldProp.name, props.name) || !ObjectsUtils.areStringsEqual(newOldProp.filename, props.filename) || !ObjectsUtils.areStringsEqual(newOldProp.value, props.value)) {
        newOldProp = {
          name: props.name,
          filename: props.filename ? props.filename : undefined,
          value: props.value,
        };
        return newOldProp;
      }
    });
    setFile(null);
    if (!ObjectsUtils.areStringsEqual(imagePreviewUrl, props.value) || props.file) { setUploadStatus((props.value && props.value.length > 0) ? 'UPLOADED' : (props.file) ? 'UPLOADING' : ''); }
    if (!ObjectsUtils.areStringsEqual(fileName, props.filename)) { setFileName(props.filename ? props.filename : undefined); }
    if (!ObjectsUtils.areStringsEqual(imagePreviewUrl, props.value)) {
      setImagePreviewUrl(props.value ? props.value : '');
      setHasImage(!!props.value);
      if (tiffRendered) { setTiffRendered(false); }
      if (isFullScreen) { setIsFullScreen(false); }
      if (isDragging) { setIsDragging(false); }
      if (waitingDownload) { setWaitingDownload(false); }
    }
  }, [props])

  useEffect(() => {
    if (imagePreviewUrl && (imagePreviewUrl.indexOf('.tiff') === (imagePreviewUrl.length - 5))) {
      if (!tiffRendered) {
        renderTiff(imagePreviewUrl);
        setTiffRendered(true);
      }
    }
  }, [imagePreviewUrl, tiffRendered])

  function dispatchChange(newFile) {
    if (newFile.file || !oldProp) {
      setOldProp(null);

      const event = {
        target: {
          name: props.name,
          image_name: newFile.file.name,
          image: newFile.file,
          url: newFile.imagePreviewUrl
        },
      };
      if (props.onChange) props.onChange(event);
    }
  }

  function handleImageSubmit(file, imageURL) {
    if (!props.disabled && file) {

      const newFile = {};
      newFile.file = file;
      newFile.imagePreviewUrl = imageURL;
      newFile.uploadStatus = 'UPLOADING';
      dispatchChange(newFile);

      setFile(file);
      setImagePreviewUrl(imageURL);
      setUploadStatus('UPLOADING');
    }
  }

  function handleImageDelete() {
    if (!props.disabled && hasImage) {

      setFile(null);
      setFileName(null);
      setImagePreviewUrl('');
      setHasImage(false);

      const event = {
        target: {
          name: props.name,
        },
      };
      if (props.onDelete) props.onDelete(event);
    }
  }

  function handleImageChange(ev) {
    ev.preventDefault();

    const file = ev.target.files[0];
    const imageURL = URL.createObjectURL(file);

    if (file) {
      handleImageSubmit(file, imageURL);
    }
  }

  function handleSetImageAsCover() {
    if (!props.disabled && hasImage) {
      const event = {
        target: {
          image_id: props.imageId,
        },
      };

      props.onSetAsCover(event);
    }
  }

  function handleDownload() {
    if (imagePreviewUrl) {
      setWaitingDownload(true);
      window.open(imagePreviewUrl, '_blank');
    }
  }

  function handleDragOver(ev) {
    ev.preventDefault();

    if (!props.disabled && !isDragging) {
      setIsDragging(true);
    }
  }

  function handleDrop(ev) {
    ev.preventDefault();

    const file = ev.dataTransfer.files[0];

    if (!props.disabled && file) {
      handleImageSubmit(file);
    }
  }

  function handleDragLeave() {
    if (!props.disabled && isDragging) {
      setIsDragging(false);
    }
  }

  function handleToogleFullScreen() {
    setIsFullScreen(!isFullScreen);
  }

  function renderFilenameToShow() {
    const filename = file ? file.name : null;
    const nameLenght = filename ? filename.length : 0;

    if (nameLenght <= 35) return filename;

    return `${filename.slice(0, 20)} ... ${filename.slice(nameLenght - 6, nameLenght)}`;
  }

  function renderUploadingBar() {
    return (
      <div className="previewPlaceholder noImage">
        <div className="placeholderContent noImage">
          {/* <p className={`placeholderMessage`}>{state.uploadStatus}:</p> */}
          <p className="placeholderMessage">{renderFilenameToShow()}</p>
          <CProgress color="success" value={100} />
        </div>
      </div>
    );
  }

  function renderPlaceholder(t) {
    return (
      <div className={`previewPlaceholder${isDragging ? ' dragInFile' : ''} ${!hasImage ? ' noImage' : ''}`}>
        {!props.notInsertable ? (
          <div className={`placeholderContent${!hasImage ? ' noImage' : ''}`}>
            <p className="placeholderMessage">{t('Common.dnd_image_here')}</p>
            {!hasImage && (
              <UiButton className="UiAddImageButton" label={t('Common.select_image')}
                disabled={props.disabled} onClick={() => UiInputFile.click()} />
            )}
          </div>
        ) : <p className='text-center'>
          Nessuna immagine selezionata
        </p>}
      </div>
    );
  }

  /* renderImageUrl(target) {
    let resizer = '';
    let rect = (target !== undefined && target !== null) ? target.getBoundingClientRect() : null;
    if(rect && rect.height > rect.width) {
      resizer = '?height='+rect.height;
    } else if (rect) {
      resizer = '?width='+rect.width;
    }
    return state.imagePreviewUrl + resizer;
  } */

  function renderImageUrl() {
    return `${imagePreviewUrl}?height=300&width=500`;
  }

  function renderTiff(filename) {
    const canvasNode = canvas;
    const fullCanvasNode = fullcanvas;

    if (canvasNode) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'arraybuffer';
      xhr.open('GET', filename);
      xhr.onload = () => {
        const tiff = new Tiff({ buffer: xhr.response });
        const width = tiff.width();
        const height = tiff.height();
        const ratio = width / height;
        const originalCanvas = tiff.toCanvas();

        let parentWidth = 300;
        let parentHeight = 300;

        const contWidth = imagePlaceholder.getBoundingClientRect().width - 10;
        const contHeight = imagePlaceholder.getBoundingClientRect().height - 10;
        if (ratio < 1) {
          parentHeight = contHeight;
          parentWidth = Math.floor(ratio * contHeight);
        } else {
          parentWidth = contWidth;
          parentHeight = Math.floor(contWidth / ratio);
        }

        const resizedCanvas = document.createElement('canvas');
        const resizedContext = resizedCanvas.getContext('2d');
        resizedCanvas.width = parentWidth;
        resizedCanvas.height = parentHeight;

        resizedContext.drawImage(originalCanvas, 0, 0, parentWidth, parentHeight);

        while (canvasNode.firstChild) {
          canvasNode.removeChild(canvasNode.firstChild);
        }
        canvasNode.appendChild(resizedCanvas);

        if (fullCanvasNode) {
          let fullParentWidth = 300;
          let fullParentHeight = 300;

          const fullContWidth = window.innerWidth - 10;
          const fullContHeight = window.innerHeight - 10;
          if (ratio < 1) {
            fullParentHeight = fullContHeight;
            fullParentWidth = Math.floor(ratio * fullContHeight);
          } else {
            fullParentWidth = fullContWidth;
            fullParentHeight = Math.floor(fullContWidth / ratio);
          }

          const fullResizedCanvas = document.createElement('canvas');
          const fullResizedContext = fullResizedCanvas.getContext('2d');
          fullResizedCanvas.width = fullParentWidth;
          fullResizedCanvas.height = fullParentHeight;

          fullResizedContext.drawImage(originalCanvas, 0, 0, fullParentWidth, fullParentHeight);

          while (fullCanvasNode.firstChild) {
            fullCanvasNode.removeChild(fullCanvasNode.firstChild);
          }
          fullCanvasNode.appendChild(fullResizedCanvas);
        }
        setTiffRendered(true);
      };
      xhr.send();
    }
  }

  function renderFullScreen() {
    return (
      <div className={`UiFullScreen ${isFullScreen ? '' : 'hidden'}`}>
        <div className="UiFullScreenToggle" onClick={handleToogleFullScreen.bind(this)}>
          <CIcon icon="cis-x" />
        </div>
        <div className="UiFullScreenImage">
          {imagePreviewUrl && imagePreviewUrl.length > 0 && isFullScreen && (
            <div>
              {imagePreviewUrl.indexOf('.tiff') !== imagePreviewUrl.length - 5 && (
                <img alt={fileName} src={`${imagePreviewUrl}?height=${window.innerHeight}`} />
              )}
              {imagePreviewUrl.indexOf('.tiff') === imagePreviewUrl.length - 5 && (
                <div ref={fullcanvas} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderActions(t) {
    return (
      <div className="UiImageActions">
        {props.showInfo && (
          <div className="UiImageName">
            <p>
              MB: {props.weight ? props.weight : '-'} -{' '}
              {props.width ? props.width : '-'} x{' '}
              {props.height ? props.height : '-'} px
            </p>
          </div>
        )}
        {props.downloadable && (
          <div className="UiImageDownload">
            <CTooltip placement="top" content={t('Tooltips.image_download')}>
              <CButton id={`downloadImageAction-${props.imageId}`} variant="ghost" color="primary" onClick={handleDownload.bind(this)}>
                <CIcon icon="cis-cloud-download" className="UiImageAction" />
              </CButton>
            </CTooltip>
          </div>
        )}
        {props.fullScreenEnabled && (
          <div className="UiImageDetail">
            <CTooltip placement="top" content={t('Tooltips.image_fullscreen')}>
              <CButton id={`fullscreenImageAction-${props.imageId}`} variant="ghost" color="primary" onClick={handleToogleFullScreen.bind(this)}>
                <CIcon icon="cis-search" className="UiImageAction" />
              </CButton>
            </CTooltip>
          </div>
        )}
        {props.setCoverEnabled &&
          imagePreviewUrl.indexOf('.tiff') !== imagePreviewUrl.length - 5 && (
            <div className="UiImageCover">
              <CTooltip placement="top" content={t('Tooltips.set_image_as_cover')}>
                <CButton id={`setAsCoverImageAction-${props.imageId}`} variant="ghost" color="primary"
                  disabled={props.disabled} onClick={handleSetImageAsCover.bind(this)}>
                  <CIcon icon="cis-image" className="UiImageAction" />
                </CButton>
              </CTooltip>
            </div>
          )}
        {props.deletable && (
          <div className="UiImageRemove">
            <CTooltip placement="top" content={t('Tooltips.delete_image')}>
              <CButton id={`deleteImageAction-${props.imageId}`} variant="ghost" color="primary"
                disabled={props.disabled} onClick={handleImageDelete.bind(this)}>
                <CIcon icon="cis-trash" className="UiImageAction" />
              </CButton>
            </CTooltip>
          </div>
        )}
        {props.editable && (
          <div className="UiImageRemove">
            <CTooltip placement="top" content={t('Tooltips.change_image')}>
              <CButton id={`changeImageAction-${props.imageId}`} variant="ghost" color="primary"
                disabled={props.disabled} onClick={() => UiInputFile.click()}>
                <CIcon icon="cis-pen" className="UiImageAction" />
              </CButton>
            </CTooltip>
          </div>
        )}
      </div>
    );
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiPictureUpload">
          {renderFullScreen()}
          {props.label ? (
            <CFormLabel className="UiMainLabel">
              {t(props.label)} {props.required ? '*' : ''}
            </CFormLabel>
          ) : (
            ''
          )}
          <div className="Detail_imageCover UiImagePreview previewComponent">
            {/* <UiInput type="text" className="UiInputPicture" name="imageName" disabled={props.disabled}
              placeholder={props.disabled ? '' : t('Common.placeholder_filename')} value={state.filename}
              onChange={handleOnNameChange.bind(this)} onBlur={handleOnNameBlur.bind(this)} /> */}
            <div className={`UiPictureUploadPreview ${isDragging ? ' dragInFile' : ''} ${hasImage ? ' imagePreview' : ''}`}
              draggable onDragEnter={handleDragOver.bind(this)} onDragOver={handleDragOver.bind(this)}
              onDragLeave={handleDragLeave.bind(this)} onDrop={handleDrop.bind(this)}>
              {(uploadStatus === 'UPLOADING') ? renderUploadingBar() : (!imagePreviewUrl || imagePreviewUrl.length <= 0) ? renderPlaceholder(t) : null}
              {imagePreviewUrl && imagePreviewUrl.length > 0 && (
                <div className="imagePlaceholder" ref={imagePlaceholder}>
                  {imagePreviewUrl && (
                    <img className="previewImage" alt={fileName} src={renderImageUrl()} />
                  )}
                </div>
              )}

            </div>
            <CRow>
              <CFormInput type="file" ref={node => {
                if (node) {
                  UiInputFile = node;
                }
              }}
                accept={props.accept}
                id="UiInputFile" className="UiInputFile" name="UiInputFile"
                disabled={props.disabled} onChange={handleImageChange.bind(this)} />

              {uploadStatus ? (
                renderActions(t)
              ) : (
                <div className="UiImageActions" />
              )}
            </CRow>
          </div>
        </div>
      )}
    </I18n>
  );
}

export default UiPictureUpload
