// GLOBAL
import React from 'react';
import { connect } from 'react-redux';
import { CCard, CCardBody, CRow, CCol, CFormLabel } from '@coreui/react-pro';

// UTILS
import withRouter from '../../utils/withNavigation';

// SERVICES
import ConstantsService from '../../services/constantsService';

// COMPONENTS
import AbstractDetail from './AbstractDetail';
import { mapStateToProps } from './AbstractComponent';

import UiAutocomplete from '../ui/UiAutocomplete';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiSelectClassic from '../ui/UiSelectClassic';
import UiMap from '../ui/UiMap';

import cloneDeep from 'lodash/cloneDeep';

class City extends AbstractDetail {
  constructor(props) {
    super(props)
    this.state = {
      date: new Date()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = cloneDeep(AbstractDetail.getDerivedStateFromProps(nextProps, prevState));
    // const newCityState = cloneDeep(newState);
    // console.log("CITY STATE",newCityState)
    if (newState.model) {
      if (!newState.model.geo_coordinates) {
        newState.model.geo_coordinates = { coordinates: [] };
      } else if (!newState.model.geo_coordinates.coordinates) {
        newState.model.geo_coordinates.coordinates = [];
      }
      while (newState.model.geo_coordinates.length < 2) {
        newState.model.geo_coordinates.push(0);
      }
    }

    return newState;
  }


  renderChildren(t) {
    return (
      <>
        <CCard>
          <CCardBody>
            <CRow className='row-gap'>
              <CCol sm="12" md="6" lg="6" xl="6">
                <UiInput type="text" name="city" label="Table.name" disabled={!this.state.editable}
                  value={this.state.model.city} required={this.state.mandatoryFields.indexOf('city') >= 0}
                  onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)} />
              </CCol>
              <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                <UiSelectClassic name='country' label={'Table.country'} disabled={!this.state.editable} forceTranslation
                  value={this.state.model.country} required={this.state.mandatoryFields.indexOf('country') >= 0}
                  values={ConstantsService.countries} nullable={false} nosort={true} fieldDisplayed='name' fieldReturned='alpha_2_code'
                  onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)}
                  onValueChanged={this.handleOnForcedChange.bind(this)} />
              </CCol>
            </CRow>
            <CRow className='row-gap'>
              <CCol sm="12" md="6" lg="6" xl="6">
                <UiInput type="text" name="zip_code" label="Table.zip_code" disabled={!this.state.editable} maxLength={5}
                  value={this.state.model.zip_code} required={this.state.mandatoryFields.indexOf('zip_code') >= 0}
                  onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)} />
              </CCol>
              <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                <UiInput type="text" name="province" label="Table.state" disabled={!this.state.editable} maxLength={2}
                  value={this.state.model.province} required={this.state.mandatoryFields.indexOf('province') >= 0}
                  onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)} />
              </CCol>
            </CRow>
            <CRow className='row-gap'>
              <CCol sm="12" md="6" lg="6" xl="6">
                <UiInput type="text" name="district" label="Table.region" disabled={!this.state.editable}
                  value={this.state.model.district} required={this.state.mandatoryFields.indexOf('region') >= 0}
                  onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)} />
              </CCol>
              <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                {/* <UiSelectClassic name='zone' label={'Table.zone'} disabled={!this.state.editable}
                  value={this.state.model.zone} required={this.state.mandatoryFields.indexOf('zone') >= 0}
                  values={ConstantsService.italyZones} nullable={false} nosort={true}
                  onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)}
                  onValueChanged={this.handleOnForcedChange.bind(this)} /> */}
              </CCol>
            </CRow>
            {/* <CRow>
              <CCol sm="12" md="12" lg="12" xl="12">
                <UiCard title={t('Table.geo_coordinates')} icon="cis-location-pin" collapsible={false} className="mt-4">
                  <CRow>
                    <CCol sm="12" md="6" lg="6" xl="6">
                      <UiInput type="number" name="geo_coordinates.coordinates[0]" label="Table.latitude" disabled={!this.state.editable}
                        value={this.state.model.geo_coordinates.coordinates[0]} required={this.state.mandatoryFields.indexOf('geo_coordinates') >= 0}
                        onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)} />
                    </CCol>
                    <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                      <UiInput type="number" name="geo_coordinates.coordinates[1]" label="Table.longitude" disabled={!this.state.editable}
                        value={this.state.model.geo_coordinates.coordinates[1]} required={this.state.mandatoryFields.indexOf('geo_coordinates') >= 0}
                        onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)} />
                    </CCol>
                  </CRow>
                </UiCard>
              </CCol>
            </CRow> */}
          </CCardBody>
        </CCard>
      </>
    );
  }

  render() {
    return super.render();
  }
}

export default connect(mapStateToProps)(withRouter(City));
