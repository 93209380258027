import React, { Component } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react-pro';

class Page500 extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol sm="12" md="6" lg="6" xl="6">
              <span className="clearfix">
                <h1 className="float-left display-3 me-4">500</h1>
                <h4 className="pt-3">Houston, we have a problem!</h4>
                <p className="text-muted float-left">
                  The page you are looking for is temporarily unavailable.
                </p>
              </span>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

export default Page500;
