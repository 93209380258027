//GLOBAL
import React from 'react'
import { connect } from 'react-redux';

//UI
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro';

//UTILS
import withRouter from '../../utils/withNavigation';
import { I18n } from 'react-i18next';
import store from "../../redux/store";
import cloneDeep from 'lodash/cloneDeep';
import ObjectsUtils from '../../utils/objectsUtils';
import i18n from 'i18next';
import { toast } from 'react-toastify';

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiSelectClassic from '../ui/UiSelectClassic';
import UiCheckbox from '../ui/UiCheckbox';
import UiMdEditor from '../ui/UiMdEditor';
import UiTranslationsTabs from '../ui/UiTranslationsTabs';
import UiRelationBox from '../ui/UiRelationBox';
import UiInputChip from '../ui/UiInputChip';

//SERVICE
import ConstantsService from '../../services/constantsService';
import AcademyTopicsService from '../../services/academyTopicsService';
import AcademySubtopicsService from '../../services/academySubtopicsService';

class Academy extends AbstractDetail {

  constructor(props) {
    super(props);

    this.state.value = '';
    this.state.language = 'it';
    this.state.translations = [
      { locale: 'it' },
      { locale: 'en' },
    ];
    this.state.topicCreationFields = [];
    this.state.academyTopics = [];
    this.academyTopicsService = AcademyTopicsService.getInstance(store);
    this.state.academySubtopics = [];
    this.academySubtopicsService = AcademySubtopicsService.getInstance(store);
    this.state.currentRelationItem = {};

    this.currentSubtopics = [];
    this.currentName = '';
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = super.getDerivedStateFromProps(nextProps, prevState);

    if (newState.model) {
      let languages = newState.model.languages ?? [];

      let localeIt = languages.find(obj => obj.language === 'it');
      if (!localeIt) {
        languages.push({ language: 'it' });
      }

      let localeEn = languages.find(obj => obj.language === 'en');
      if (!localeEn) {
        languages.push({ language: 'en' });
      }

      newState.model.languages = languages;
    }

    return newState;
  }

  componentDidMount() {
    document.addEventListener('chipsChanged', this.eventListener.bind(this,'chips'));
    document.addEventListener('inputChanged', this.eventListener.bind(this,'input'));
    this.academySubtopicsService.getList({}, this.globalService.currentLanguage, this.globalService.currentUser, this.subTopicsOkCallback.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.language !== this.state.language) || (prevState.academySubtopics.length !== this.state.academySubtopics.length)) {
      this.manageSubtopics();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('chipsChanged', this.eventListener);
    document.removeEventListener('inputChanged', this.eventListener);
  }

  updateModelData(data){
    if(data?.data){
      this.setState({
        model: data.data
      })
    }
  }

  eventListener = (type,evt) => {
    if (evt?.detail?.target) {
      if(type === 'chips'){
        this.currentSubtopics = evt.detail.target.value;
      }else{
        this.currentName = evt.detail.target.value;
      }
    }
  }

  subTopicsOkCallback(response) {
    if (response && response.data.length > 0) {
      this.setState({
        academySubtopics: response.data
      })
    }
  }

  manageSubtopics() {
    const currentTranslation = this.state.language === 'it' ? "translations[0].name" : "translations[1].name";
    this.setState({
      topicUpdateFields: [
        { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: "input", name: currentTranslation, label: "Table.name",emitEvent: true },
        { grid: 0, sortable: true, position: 0, creation: 2, singlerow: false, updatable: true, mandatory: false, readonly: false, type: "chip", name: "subtopics", label: "Table.subtopics", values: this.state.academySubtopics, alreadyTranslated: true, chipSection: 'academy', isUpdate: true, lng: this.state.language, fieldDisplayed: "value", singlerow: true, noRight: true },
      ],
      topicCreationFields: [
        { grid: 0, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: "input", name: "name", label: "Table.name" },
        { grid: 0, sortable: true, position: 0, creation: 3, singlerow: false, updatable: true, mandatory: false, readonly: false, type: "chip", name: "subtopics", label: "Table.subtopics", values: this.state.academySubtopics, alreadyTranslated: true, chipSection: 'academy', lng: this.state.language, fieldDisplayed: "value", singlerow: true, noRight: true },
      ]
    });
  }

  initRelationWithLocale(link, response) {
    let dataIT = (response && response.data) ? response.data.map((obj) => {
      return { global_id: obj.id, ...obj.languages.find(obj => obj.language === 'it') }
    }) : undefined;

    let dataEN = (response && response.data) ? response.data.map((obj) => {
      return { global_id: obj.id, ...obj.languages.find(obj => obj.language === 'en') }
    }) : undefined;

    this.setState({ [link]: response?.data, [link + '-it']: dataIT, [link + '-en']: dataEN });
  }

  buildHeaderSubtitle() {
    let subtitle = null;
    if (this.state.model && ObjectsUtils.isArrayNotEmpty(this.state.model.languages)) {
      subtitle = this.state.model.languages.find(obj => obj.language === this.globalService.currentLanguage)?.academy_name;
      if (!subtitle) {
        subtitle = this.state.model.languages.find(obj => obj.language === 'en')?.academy_name;
      }
      if (!subtitle) {
        subtitle = this.state.model.languages[0].academy_name;
      }
    }
    return subtitle;
  }

  isCreationValid(type, evt) {
    return true;
  }

  handleOnEditTopics(evt) {
    const obj = evt;
    if(this.state.language === 'it'){
      obj.translations[0].name = this.currentName;
    }else{
      obj.translations[1].name = this.currentName;
    }
    obj.subtopics = this.currentSubtopics;
    this.academyTopicsService.updateItem(obj, this.globalService?.currentLanguage, this.globalService?.currentUser, this.addNewTopicOkCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal(`modalAddTopics${this.state.language}`);
  }

  handleOnDeleteTopics(evt) {
    this.sectionService.deleteTopic(this.itemId, evt.id, this.globalService?.currentLanguage, this.globalService?.currentUser, this.addNewTopicOkCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal(`modalDeleteTopics${this.state.language}`);
  }

  handleOnAddTopics(evt) {
    const newTopic = [{
      translations: [
        {
          name: evt.name,
          language: this.state.language
        },
        {
          name: evt.name,
          language: this.state.language === 'it' ? 'en' : 'it'
        }
      ],
      subtopics: this.currentSubtopics

    }]
    this.sectionService.addTopic(this.itemId, newTopic, this.globalService.currentLanguage, this.globalService.currentUser, this.addNewTopicOkCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal(`modalAddTopics${this.state.language}`);

  }

  addNewTopicOkCallback() {
    this.sectionService.getItem(this.itemId, this.globalService.currentLanguage, this.globalService.currentUser, this.updateModelData.bind(this), this.koCallback.bind(this));
    return toast.success(i18n.t('Common.success'));
  }

  handleOnChangeTopics(evt) {
    let cloneModelTopics = cloneDeep(this.state.model.topics);
    const obj = {
      translations: [
        {
          name: evt.target.name === 'name' && evt.target.value,
          language: 'it'
        }, {
          name: evt.target.name === 'name' && evt.target.value,
          language: 'en'
        },
      ],
      subtopics: [
        {
          translations: [
            {

            }
          ]
        }
      ]
    }
    cloneModelTopics = obj;
    // this.setState({
    //   model: cloneModelTopics
    // })

  }

  handleCurrentItem(item){
    this.setState({
      currentRelationItem: item
    })
  }

  renderTopicsRow(t, topic) {
    const item = topic?.translations.find(topic => topic.language === this.state.language);
    return (
      <CRow>
        <CCol>
          <div>{(item?.name) ? item.name : t('Common.add_language')}</div>
        </CCol>
      </CRow>
    );
  }

  renderChildren(t) {
    return (
      <>
        <I18n ns="translations">
          {
            (t) => (
              <>
                <UiCard className="mt-4 colored-header" icon="cis-education" title={t('Table.description')} collapsible={false}>
                  <CRow className='row-academy-detail'>
                    <CCol sm="12" md="6" lg="6" xl="6">
                      <UiSelectClassic name='work_mode' label={'Table.work_mode'}
                        value={this.state.model.work_mode}
                        values={ConstantsService.mode_types}
                        nosort={true}
                        onValueChanged={this.handleOnChange.bind(this)}
                        onChange={this.handleOnChange.bind(this)}
                        fieldDisplayed={"label"} fieldReturned={"value"} forceTranslation={true}
                        alreadyTranslated={false}
                      />
                    </CCol>
                    <CCol sm="12" md="6" lg="6" xl="6" className='rightCol'>
                      <UiCheckbox name="published" label="Table.published"
                        disabled={!this.state.editable}
                        required={this.state.mandatoryFields.indexOf('published') >= 0}
                        value={this.state.model.published}
                        onFocus={this.handleOnFocus.bind(this)}
                        onChange={this.handleOnChange.bind(this)}
                        onBlur={this.handleOnBlur.bind(this)}
                      />
                    </CCol>
                  </CRow>
                  <UiTranslationsTabs
                    tabs={this.state.translations}
                    activeTab={this.state.language}
                    // disabled={true}
                    onTab={(locale) => this.setState({ language: locale })}
                  >
                    <div>
                      {this.state.model.languages && this.state.model.languages.map((translation, index) => (
                        <div id={translation.language} key={translation.language}>
                          <CRow>
                            <CCol sm="12" md="6" lg="6" xl="6">
                              <UiInput type="text" name={`languages[${index}].academy_name`} label="Table.name"
                                disabled={!this.state.editable}
                                required={this.state.mandatoryFields.indexOf('academy_name') >= 0}
                                value={translation.academy_name}
                                onFocus={this.handleOnFocus.bind(this)}
                                onChange={this.handleOnChange.bind(this)}
                                onBlur={this.handleOnBlur.bind(this)}
                              />
                            </CCol>
                            <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                              <UiInput type="text" name={`languages[${index}].title`} label="Table.title"
                                disabled={!this.state.editable}
                                required={this.state.mandatoryFields.indexOf('title') >= 0}
                                value={translation.title}
                                onFocus={this.handleOnFocus.bind(this)}
                                onChange={this.handleOnChange.bind(this)}
                                onBlur={this.handleOnBlur.bind(this)}
                              />
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol sm="12" md="6" lg="6" xl="6">
                              <UiInput type="text" name={`languages[${index}].subtitle`} label="Table.subtitle"
                                disabled={!this.state.editable}
                                required={this.state.mandatoryFields.indexOf('subtitle') >= 0}
                                value={translation.subtitle}
                                onFocus={this.handleOnFocus.bind(this)}
                                onChange={this.handleOnChange.bind(this)}
                                onBlur={this.handleOnBlur.bind(this)}
                              />
                            </CCol>
                            <CCol sm="12" md="6" lg="6" xl="6" className='rightCol'>
                              <UiInput type="text" name={`languages[${index}].duration`} label="Table.duration"
                                disabled={!this.state.editable}
                                required={this.state.mandatoryFields.indexOf('duration') >= 0}
                                value={translation.duration}
                                onFocus={this.handleOnFocus.bind(this)}
                                onChange={this.handleOnChange.bind(this)}
                                onBlur={this.handleOnBlur.bind(this)}
                              />
                            </CCol>
                          </CRow>
                          <CRow>
                            <UiMdEditor name={`languages[${index}].description`}
                              value={translation.description}
                              disabled={!this.state.editable}
                              onFocus={this.handleOnFocus.bind(this)}
                              onChange={this.handleOnChange.bind(this)}
                              onBlur={this.handleOnBlur.bind(this)}
                            />
                          </CRow>
                          <UiRelationBox
                            title='Table.topics' icon='cis-education' model={this.state.model.topics} disabled={!this.state.editable} cardIsOpen
                            currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer} route='topics' apiReducerName={'academy_topics'}
                            renderRow={this.renderTopicsRow.bind(this, t)}
                            isCreationValid={this.isCreationValid.bind(this, 'topics')}
                            toggleModal={this.toggleModal.bind(this, `modalAddTopics${translation.language}`)}
                            isModalOpen={(translation.language === 'it') ? this.state.modalAddTopicsit : this.state.modalAddTopicsen}
                            toggleDeleteModal={this.toggleModal.bind(this, `modalDeleteTopics${translation.language}`)}
                            isDeleteModalOpen={(translation.language === 'it') ? this.state.modalDeleteTopicsit : this.state.modalDeleteTopicsen}
                            insertable deletable editable onSearchChange={this.handleOnSearchChange.bind(this)}
                            onEdit={this.handleOnEditTopics.bind(this)}
                            onAdd={this.handleOnAddTopics.bind(this)}
                            onDelete={this.handleOnDeleteTopics.bind(this)}
                            customFields={this.state.topicUpdateFields}
                            creationCustomFields={this.state.topicCreationFields}
                            handleCurrentItem={this.handleCurrentItem.bind(this)}
                          />
                        </div>
                      ))}
                    </div>
                  </UiTranslationsTabs>
                </UiCard>
                
              </>
            )
          }
        </I18n>
      </>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Academy));
