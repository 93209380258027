// GLOBAL
import React from 'react';
import ReactLoading from 'react-loading';

// STYLES
import styles from '../../../styles/importable-style.module.scss';

function UiLoading(props) {
  return (
    <div className="UiLoadingComponent">
      <ReactLoading type={(props.type) ? props.type : 'spinningBubbles'} color={styles.primary} height={(props.height) ? props.height : 40} width={(props.width) ? props.width : 40} />

    </div>
  );
}

export default UiLoading
