import BaseService from './baseService';

export default class CitiesService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!CitiesService.instance) {
      CitiesService.instance = new CitiesService(store);
    }
    return CitiesService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.LOGIN_PATH;
    super(store, baseUrl, 'cities', 'city');
  }

  updateItemCustomId(contentId, obj, lang, token, okCallBack, koCallBack) {
    function updateKoCallBack(error) {
      this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
    }
    return super.put(this.itemName, 'admin/' + this.urlPath + '/' + contentId, lang, token, obj, okCallBack, updateKoCallBack.bind(this));
  }

  async getAddresses(filterObj, lang, token, okCallBack, koCallBack){
    return await super.get(this.listName, encodeURI('/admin/cities/addresses'+ `?and^latitude=lte_${filterObj.n.lat}&and^latitude=gte_${filterObj.s.lat}&and^longitude=lte_${filterObj.e.lng}&and^longitude=gte_${filterObj.w.lng}`), lang, token, null, okCallBack, koCallBack);
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, 'admin/' + this.urlPath, lang, token, obj, okCallBack, koCallBack);
  }


  deleteItem(contentId, lang, token, okCallBack, koCallBack) {
    function deleteKoCallBack(error) {
      this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
    }
    return super.delete(this.itemName, 'admin/' + this.urlPath + '/' + contentId, lang, token, okCallBack, deleteKoCallBack.bind(this));
  }
}
