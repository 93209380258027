import BaseService from './baseService';

export default class CompaniesService extends BaseService {

  static instance = null;

  static getInstance(store) {
    if (!CompaniesService.instance) {
      CompaniesService.instance = new CompaniesService(store);
    }
    return CompaniesService.instance;
  }

  constructor(store) {
    let baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'companies', 'company');
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    const newObj = {
      company_name: obj.company_name,
      address: obj.address,
      country: obj.country,
      timesheet_attachment: obj.timesheet_attachment ? obj.timesheet_attachment : false,
      unique_code: obj.unique_code,
      note: "",
      payment_days: 0,
      vat_number: obj.vat_number,
      client_company_manager: [],
    }
    return super.post(this.itemName, this.urlPath, lang, token, newObj, okCallBack, koCallBack);
  }
}
