import BaseService from './baseService';
import cloneDeep from 'lodash/cloneDeep';

export default class EmployeesService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!EmployeesService.instance) {
      EmployeesService.instance = new EmployeesService(store);
    }
    return EmployeesService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'employees', 'employee');
    this.urlPath = 'about-us';
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    const newObj = cloneDeep(obj);
    newObj.name = obj.name;
    newObj.surname = obj.surname;
    newObj.translations = [
      {
        language: 'it',
        role: obj.role,
        description: ''
      },
      {
        language: 'en',
        role: obj.role,
        description: ''
      }
    ]
    return super.post(this.itemName, this.urlPath, lang, token, newObj, okCallBack, koCallBack);
  }

  orderItems(obj, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, this.urlPath + '/ordered', lang, token, obj, okCallBack, koCallBack);
  }
}
