//GLOBAL
import React from 'react'
import { connect } from 'react-redux';
import i18n from 'i18next';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';

//UI
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro';

//UTILS
import withRouter from '../../utils/withNavigation';
import store from "../../redux/store";
import ObjectsUtils from '../../utils/objectsUtils';

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiSelect from '../ui/UiSelect';
import UiTextArea from '../ui/UiTextArea';
import UiRelationBox from '../ui/UiRelationBox';

//SERVICE
import ConstantsService from '../../services/constantsService';
import CompanyAddressesService from '../../services/companyAddressesService';
import MapService from '../../services/mapService';

class Company extends AbstractDetail {

  constructor(props) {
    super(props);

    this.state.companyAddresses = [];
    this.companyAddressesService = CompanyAddressesService.getInstance(store);
    this.mapService = MapService.getInstance(store);
  }

  updateModel() {
    if (!this.computedItemId && !this.itemId) {
      return;
    }
    super.updateModel();
    this.getData();
  }

  setData(evt) {
    this.setState({
      companyAddresses: evt.data,
    })
  }

  getData() {
    this.companyAddressesService.getList(this.itemId,{},false, this.globalService.currentLanguage, this.globalService.currentUser, this.setData.bind(this));
  }

  isCreationValid(evt) {
    if (evt?.address && evt?.address !== '' && ObjectsUtils.isValidZipCode(evt?.zip_code) && evt?.start_date && evt?.city !== null) {
      return true;
    } else {
      return false;
    }
  }

  handleOnEditAddress(evt) {
    const obj = cloneDeep(evt);
    obj.city_id = Number(evt.city_id);
    this.companyAddressesService.updateItem(obj, this.globalService?.currentLanguage, this.globalService?.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal('modalAddAddress');
  }

  handleOnDeleteAddress(evt) {
    this.companyAddressesService.deleteItem(this.itemId, evt.id, this.globalService?.currentLanguage, this.globalService?.currentUser, this.okCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal('modalDeleteAddress');
  }

  okCallback(){
    this.getData();
    return toast.success(i18n.t('Common.success'));
  }

  async refreshOkCallback(data) { //if address creation is ok update address coordinates with google maps api
    console.log("DATA", data)
    let address = '';
    if (data?.data) {
      address = data.data.address + ',' + data.data.city.city;
      let res = await this.mapService.findAddress(address);
      if(res !== null){
        const obj = {
          ...data.data,
          coordinates: res
        }
        delete obj.city;
        obj.city_id = data.data.city.id;
        obj.company_id = this.itemId;
        await this.companyAddressesService.updateItem(this.itemId,obj, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallback.bind(this), this.koCallback.bind(this));
      }
    }

  }

  handleOnAddAddress(evt) {
    const newObj = {
      company_id: this.itemId,
      coordinate: evt.coordinate ? evt.coordinate : undefined,
      city_id: evt.city_id ? Number(evt.city_id) : undefined,
      end_date: evt.end_date ? new Date(evt.end_date).toISOString().slice(0, 19) : undefined,
      start_date: evt.start_date ? new Date(evt.start_date).toISOString().slice(0, 19) : undefined,
      address: evt.address ? evt.address : undefined,
      type: evt.type ? evt.type : undefined,
      zip_code: evt.zip_code ? evt.zip_code : undefined,
      label: evt.label ? evt.label : undefined,
    }
    this.companyAddressesService.addItem(newObj, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal('modalAddAddress');
  }



  renderAddressRow(address) {
    return (
      <CRow>
        <CCol>{i18n.t('SelectValues.' + address.type)}</CCol>
        <CCol>{address.label ? address.label : '-'}</CCol>
        <CCol>{moment(address.start_date).format('DD/MM/YYYY')}</CCol>
        <CCol>{address.address}</CCol>
        <CCol>{address.city}</CCol>
      </CRow>
    )
  }

  renderAddressHeader() {
    return (
      <CRow className='fw-bold'>
        <CCol>{i18n.t('Table.type')}</CCol>
        <CCol>{i18n.t('Table.label')}</CCol>
        <CCol>{i18n.t('Table.start_date')}</CCol>
        <CCol>{i18n.t('Table.address')}</CCol>
        <CCol>{i18n.t('Table.city_id')}</CCol>
        <CCol sm="1" md="1" lg="1" xl="1"></CCol>
      </CRow>
    )
  }


  renderChildren(t) {
    return (
      <>
        <UiCard className="mt-4 colored-header" icon="cis-building" title={t('Table.main_data')} collapsible={false}>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiInput type="text" name="company_name" label="Table.name"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('company_name') >= 0}
                value={this.state.model.company_name}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
            {/* <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
              <UiInput type="text" name="address" label="Table.address"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('address') >= 0}
                value={this.state.model.address}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
          </CRow>
          <CRow> */}
            {/* <CCol sm="12" md="6" lg="6" xl="6">
              <UiSelectClassic name='country' label={'Table.country'} nullable
                value={this.state.model.country}
                fieldDisplayed={"name"} fieldReturned={"alpha_2_code"}
                values={ConstantsService.countries} nosort={true}
                onValueChanged={this.handleOnChange.bind(this)} onChange={this.handleOnChange.bind(this)}
              />
            </CCol> */}
            <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
              <UiInput type="number" name="payment_days" label="Table.payment_days"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('payment_days') >= 0}
                value={this.state.model.payment_days}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiInput type="text" name="unique_code" label="Table.unique_code"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('unique_code') >= 0}
                value={this.state.model.unique_code}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
            <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
              <UiInput type="text" name="vat_number" label="Table.vat_number"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('vat_number') >= 0}
                value={this.state.model.vat_number}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiTextArea type="text" name="note" label="Table.note"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('note') >= 0}
                value={this.state.model.note}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
          </CRow>
        </UiCard>
        <UiRelationBox
          title='Table.address' icon='cis-building' model={this.state.companyAddresses} disabled={!this.state.editable} cardIsOpen
          currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer} route='company_address' apiReducerName={'company_addresses'}
          renderRow={this.renderAddressRow.bind(this)} renderHeader={this.renderAddressHeader.bind(this)}
          isCreationValid={this.isCreationValid.bind(this)}
          toggleModal={this.toggleModal.bind(this, 'modalAddAddress')}
          isModalOpen={this.state.modalAddAddress}
          creationModel={{ start_date: moment().format('YYYY-MM-DD') }}
          toggleDeleteModal={this.toggleModal.bind(this, 'modalDeleteAddress')}
          isDeleteModalOpen={this.state.modalDeleteAddress}
          insertable deletable editable onSearchChange={this.handleOnSearchChange.bind(this)}
          onEdit={this.handleOnEditAddress.bind(this)}
          onAdd={this.handleOnAddAddress.bind(this)}
          onDelete={this.handleOnDeleteAddress.bind(this)}
        />
      </>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Company));