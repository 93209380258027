import React, { useState, useEffect } from 'react';
import { CRow, CCol, CProgress } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

// STYLES
import styles from '../../../styles/style.scss';
import '../../../styles/components/UiProgressBar.scss';

function UiProgressBar(props) {
  const [color, setColor] = useState('green');
  const [progress, setProgress] = useState(props.progress);

  useEffect(() => {
    let localColor = cloneDeep(color);
    switch (props.type) {
      case 'author':
        localColor = styles.yellow;
        break;
      case 'artworks':
        localColor = styles.green;
        break;
      case 'exhibition':
        localColor = styles.pink;
        break;
      case 'artworks_by_exhibition':
        localColor = styles.red;
        break;
      case 'event':
        localColor = styles.cyan;
        break;
      case 'artworks_by_event':
        localColor = styles.indigo;
        break;
      default:
        localColor = styles.teal;
        break;
    }
    setColor(localColor);
  }, [props])

  const goToDetail = (url) => () => {
    if (url) {
      window.open(url, '_blank');
    }
  }

  return (
    <I18n ns="translations">
      {(t, { i18n }) => (
        <div style={{ cursor: props.url && 'pointer' }} onClick={(props.url) ? goToDetail(props.url) : undefined}>
          <div className="progress-bar-wrapper">
            <div className="progress-bar-container" style={{ borderLeftColor: color }}>
              <CCol style={{ marginLeft: 20 }}>
                <CRow sm="12" md="12" lg="12" xl="12">
                  {props.title && (
                    <span className="progress-bar-title ">{props.title}</span>
                  )}
                </CRow>
                {props.subtitle && (
                  <CRow sm="12" md="12" lg="12" xl="12" className="progress-bar-subtitle">
                    <CIcon icon="cis-calendar" />
                    <span className="ms-2">{props.subtitle}</span>
                  </CRow>
                )}
                <CRow sm="12" md="12" lg="12" xl="12">
                  <div className="progress-label">
                    {(props.error_message) ? `${t('Common.error')}: ${props.error_message}` : ''}
                    {(!props.error_message) ? (props.progress && props.progress < 100) ? t('Common.Processing') : t('Common.Ready') : ''}
                  </div>
                </CRow>
                {!props.error_message && props.progress && props.progress < 100 && (
                  <>
                    <CRow sm="12" md="12" lg="12" xl="12">
                      <div className="progress-label">{props.progress} %</div>
                    </CRow>
                    <CRow sm="12" md="12" lg="12" xl="12" className="progress-group-bars">
                      <CProgress size="xs" color="success" value={progress} className="mb-1 bg-white" />
                    </CRow>
                  </>
                )}
              </CCol>
            </div>
          </div>
          <div className="progress-bar-bottom-line" />
        </div>
      )}
    </I18n>
  );

}

export default UiProgressBar;
