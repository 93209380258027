import BaseService from './baseService';
import cloneDeep from 'lodash/cloneDeep';

export default class AcademyService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!AcademyService.instance) {
      AcademyService.instance = new AcademyService(store);
    }
    return AcademyService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'academies', 'academy');
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    const newObj = {
      work_mode: obj?.work_mode,
      published: false,
      languages: [
        {
          title: obj?.title,
          subtitle: obj?.subtitle,
          duration: obj?.duration,
          language: 'it',
          academy_name: obj?.academy_name
        },
        {
          title: obj?.title,
          subtitle: obj?.subtitle,
          duration: obj?.duration,
          language: 'en',
          academy_name: obj?.academy_name
        }
      ]
    };

    return super.post(this.itemName, this.urlPath, lang, token, newObj, okCallBack, koCallBack);
  }

  addTopic(academyId, obj, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, this.urlPath + '/' + academyId + '/topics', lang, token, obj, okCallBack, koCallBack);
  }

  deleteTopic(academyId, topicId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName, this.urlPath + '/' + academyId + '/topics/' + topicId, lang, token, okCallBack, koCallBack);
  }

}
