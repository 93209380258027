import Oauth2Service from './oauthService';

// REDUCERS
import { tokenNeeded } from '../redux/tokenReducer';

export default class JWTService extends Oauth2Service {

  static getInstance(store, baseUrl, client_id, client_secret, storage, basename) {
    if (!JWTService.instance) {
      JWTService.instance = new JWTService(store, baseUrl, client_id, client_secret, storage, basename);
    }
    return JWTService.instance;
  }

  getToken(username, password, okCallback, koCallback) {
    this.clearLocalStorage();

    let path = '/signin';
    let body = {
      username: username,
      password: password,
    };

    let koCallBack = (error) => {
      this.handleTokenError(error);
      if (koCallback) {
        koCallback(error);
      }
    }

    this.store.dispatch(tokenNeeded(username, password));

    return this.axiosInstance.post(path, body)
      .then(this.handleTokenSuccess.bind(this))
      .catch(koCallBack.bind(this));
  }

  refreshToken(okCallback, koCallback) {
    const auth = this.localStorage.getItem('auth');
    if (auth) {
      const cookie = JSON.parse(this.localStorage.getItem('auth'));
      this.localStorage.removeItem('auth');

      let path = '/updateAuthToken';
      let body = {
        refresh_token: cookie.refreshToken,
      };

      let koCallBack = (error) => {
        this.handleTokenError(error);
        if (koCallback) {
          koCallback(error);
        }
      }

      this.store.dispatch(tokenNeeded());

      return this.axiosInstance.post(path, body)
        .then(this.handleTokenSuccess.bind(this))
        .catch(koCallBack.bind(this));
    }
    return null;
  }

  resetPassword(username, okCallback, koCallback) {
    let path = '/resetpsw';
    let body = {
      email: username,
    };

    return this.axiosInstance.put(path, body)
      .then(okCallback.bind(this))
      .catch(koCallback.bind(this));
  }

  recoverPassword(token, password, okCallback, koCallback) {
    let path = '/recoverpsw';
    let body = {
      newPassword: password,
      token: token,
    };

    return this.axiosInstance.put(path, body)
      .then(okCallback.bind(this))
      .catch(koCallback.bind(this));
  }
}
