
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

//UI
import { CNav, CFormSwitch, CProgress, CTabPane, CNavItem, CNavLink, CSidebar, CListGroup, CTabContent, CSidebarToggler } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import UiProgressBar from '../UiProgressBar';

const propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
  toggleAside: PropTypes.func,
};

const defaultProps = {
  children: null,
  show: false,
  toggleAside: () => null,
};

function DefaultAside(props) {

  const [activeTab, setActiveTab] = useState(1)
  const [queue, setQueue] = useState()
  const [documentQueue, setDocumentQueue] = useState()

  //this useEffect simulate getDerivedStateFromProps
  useEffect(() => {
    setQueue(props.queue)
  }, [props])

  function changeActiveTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <CSidebar placement='end' size="lg" overlaid visible={props.show} colorScheme="light">
      <CSidebarToggler onClick={props.toggleAside} />
      <CNav variant="underline" role="tablist" className="nav-underline nav-underline-primary">
        <CNavItem>
          <CNavLink href="#" active={activeTab === 1} onClick={(e) => { e.preventDefault(); changeActiveTab(1); }}>
            <CIcon icon="cis-cloud-download" alt="Downloads" />
            <span>&nbsp;&nbsp;{i18n.t('Common.export')}</span>
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#" active={activeTab === 2} onClick={(e) => { e.preventDefault(); setActiveTab(2); }}>
            <CIcon icon="cis-cloud-upload" alt="Uploads" />
            <span>&nbsp;&nbsp;{i18n.t('Common.import')}</span>
          </CNavLink>
        </CNavItem>
        <CNavItem className="me-2">
          <CNavLink href="#" active={activeTab === 3} onClick={(e) => { e.preventDefault(); setActiveTab(3); }}>
            <CIcon icon="cis-settings" alt="CoreUI Icons Settings" />
          </CNavLink>
        </CNavItem>
      </CNav>
      <CTabContent>
        <CTabPane visible={activeTab === 1}>
          <CListGroup accent>
            {documentQueue && documentQueue.map((item, index) => {
              if (item.job_type === 'export') {
                return (
                  <UiProgressBar key={`export-${index}`} title={item.title} subtitle={item.subtitle} progress={item.progress} error_message={item.error_message} type={item.type} url={item.url} />
                );
              }
              return null;
            })}
          </CListGroup>
        </CTabPane>
        <CTabPane visible={activeTab === 2} className="p-3">
          <CListGroup accent>
            {documentQueue && documentQueue.map((item, index) => {
              if (item.job_type === 'import') {
                return (
                  <UiProgressBar key={`import-${index}`} title={item.title} subtitle={item.subtitle} progress={item.progress} error_message={item.error_message} type={item.type} url={item.url} />
                );
              }
              return null;
            })}
          </CListGroup>
        </CTabPane>
        <CTabPane visible={activeTab === 3} className="p-3">
          <h6>Settings</h6>
          <div>
            <div className="clearfix mt-4">
              <small><b>Option 1</b></small>
              <CFormSwitch className="float-right" color="success" defaultChecked />
            </div>
            <div>
              <small className="text-muted">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </small>
            </div>
          </div>
          <div>
            <div className="clearfix mt-3">
              <small><b>Option 2</b></small>
              <CFormSwitch className="float-right" color="success" />
            </div>
            <div>
              <small className="text-muted">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </small>
            </div>
          </div>
          <div>
            <div className="clearfix mt-3">
              <small><b>Option 3</b></small>
              <CFormSwitch className="float-right" color="success" />
            </div>
          </div>
          <div>
            <div className="clearfix mt-3">
              <small>
                <b>Option 4</b>
              </small>
              <CFormSwitch className="float-right" color="success" defaultChecked />
            </div>
          </div>
          <hr />
          <h6>System Utilization</h6>
          <div className="text-uppercase mb-1 mt-4">
            <small><b>CPU Usage</b></small>
          </div>
          <CProgress size="xs" color="info" value={25} />
          <small className="text-muted">348 Processes. 1/4 Cores.</small>
          <div className="text-uppercase mb-1 mt-2">
            <small><b>Memory Usage</b></small>
          </div>
          <CProgress size="xs" color="warning" value={70} />
          <small className="text-muted">11444GB/16384MB</small>
          <div className="text-uppercase mb-1 mt-2">
            <small><b>SSD 1 Usage</b></small>
          </div>
          <CProgress size="xs" color="danger" value={95} />
          <small className="text-muted">243GB/256GB</small>
          <div className="text-uppercase mb-1 mt-2">
            <small><b>SSD 2 Usage</b></small>
          </div>
          <CProgress size="xs" color="success" value={10} />
          <small className="text-muted">25GB/256GB</small>
        </CTabPane>
      </CTabContent>
    </CSidebar>
  );
}

DefaultAside.propTypes = propTypes;
DefaultAside.defaultProps = defaultProps;
export default DefaultAside;
