import React from 'react';
import { CCol, CFormLabel, CTooltip } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { useEffect } from 'react';
import { useState } from 'react';

const UiDisplayImageField = props => {
  const { obj, hideLabel, tooltipMsg } = props;
  const [zoom, setZoom] = React.useState(false);
  const [figureStyle, setFigureStyle] = React.useState({});
  const [lensClass, setLensClass] = useState();

  React.useEffect(() => {
    setFigureStyle({ ...figureStyle, height: obj.height || 'auto', width: obj.width || '100%' });
  }, []);

  useEffect(() => {
    setLensClass(`clickable ${zoom ? 'text-primary' : ''}`);
  }, [zoom])


  const handleMouseMove = e => {
    if (!zoom) return;
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setFigureStyle({
      ...figureStyle,
      backgroundImage: `url(${obj.value})`,
      backgroundPosition: `${x}% ${y}%`,
    });
  };

  const handleMouseOut = () => {
    if (!zoom) return;
    setFigureStyle({ ...figureStyle, backgroundImage: undefined, backgroundPosition: undefined });
  };

  return (
    <CCol md={obj.size || '6'} className="mb-3">
      <div className="UiInput px-2">
        {!hideLabel && (
          <div>
            <CFormLabel htmlFor={obj.name} className="UiMainLabel">
              {obj.label}
              {tooltipMsg && (
                <span>
                  <CTooltip placement="top" content={tooltipMsg}>
                    <CIcon icon="cis-info-circle" size="sm" />
                  </CTooltip>
                </span>
              )}
            </CFormLabel>
            <span className={`ms-3 ${lensClass}`}>
              <CIcon icon={`cis-zoom${zoom ? '-in' : ''}`} size="lg" onClick={() => setZoom(!zoom)} />
            </span>
          </div>
        )}
        <figure className="zoom" style={figureStyle} onMouseOut={handleMouseOut.bind(this)} onMouseMove={handleMouseMove.bind(this)} >
          <img className={zoom ? 'zoom' : ''} src={obj.value} alt={obj.label || obj.name} onClick={() => setZoom(!zoom)}
          /* width={obj.width || '100%' || undefined} 
          height={hasCustomHeight ? obj.height : 'auto' || undefined}  */
          />
        </figure>
      </div>
    </CCol>
  );
};

export default UiDisplayImageField;
