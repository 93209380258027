import BaseService from './baseService';
import cloneDeep from 'lodash/cloneDeep';

export default class BlogsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!BlogsService.instance) {
      BlogsService.instance = new BlogsService(store);
    }
    return BlogsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'blogs', 'blog');
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    const newObj = {};
    newObj.author = obj.author;
    newObj.translations = [{ language: obj.language, title: obj.title, subtitle: obj.subtitle, permalink: obj.permalink, description: '' }];
    return super.addItem(newObj, lang, token, okCallBack, koCallBack);
  }

  updateItemCustomId(contentId, obj, lang, token, okCallBack, koCallBack) {
    let newObj = cloneDeep(obj);
    // if (newObj.translations[1].id_blog === undefined || newObj.translations[1].id_blog === null) {
    //   newObj.translations[1] = {
    //     id_blog: obj.translations[0].id_blog,
    //     language: newObj.translations[1].language,
    //     title: newObj.translations[1].title,
    //     subtitle: newObj.translations[1].subtitle,
    //     permalink: newObj.translations[1].permalink,
    //     description: newObj.translations[1].description
    //   }
    // }

    function updateKoCallBack(error) {
      this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
    }
    return super.put(this.itemName, this.urlPath + '/' + contentId, lang, token, newObj, okCallBack, updateKoCallBack.bind(this));
  }

  getCover(contentId, obj, lang, token, okCallBack, koCallBack) {
    return super.get(this.itemName + '_cover', this.urlPath + '/' + contentId + '/cover', lang, token, obj, okCallBack, koCallBack);
  }

  setAsCover(contentId, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName + "_cover", this.urlPath + '/' + contentId + '/set_cover', lang, token, obj, okCallBack, koCallBack);
  }
  removeCover(contentId, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName + "_cover", this.urlPath + '/' + contentId + '/remove_cover', lang, token, {}, okCallBack, koCallBack);
  }

  disableItem(contentId, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName + "_deactivate", this.urlPath + '/deactivate/' + contentId, lang, token, {}, okCallBack, koCallBack);
  }

  reactivateItem(contentId, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName + "_activate", this.urlPath + '/activate/' + contentId, lang, token, {}, okCallBack, koCallBack);
  }
}
