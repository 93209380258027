import BaseService from './baseService';
import cloneDeep from 'lodash/cloneDeep';

import GlobalService from './globalService';

export default class CvsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!CvsService.instance) {
      CvsService.instance = new CvsService(store);
    }
    return CvsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.LOGIN_PATH;
    super(store, baseUrl, 'cvs', 'cv');

    this.globalService = GlobalService.getInstance(store);
  }

  getList(obj, lang, token, okCallBack, koCallBack) {
    return super.get(this.listName, 'admin/cv', lang, token, obj, okCallBack, koCallBack);
  }

  updateItemCustomId(contentId, obj, lang, token, okCallBack, koCallBack) {
    function updateKoCallBack(error) {
      this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
    }
    return super.put(this.itemName, 'admin/cv/' + contentId, lang, token, obj, okCallBack, updateKoCallBack.bind(this));
  }

  getItem(contentId, lang, token, okCallBack, koCallBack, firstError) {
    return super.get(this.itemName, 'admin/cv/' + contentId, lang, token, null, okCallBack, koCallBack, firstError);
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    const newObj = cloneDeep(obj);
    newObj.jobs = [];
    newObj.languages = [];
    newObj.skills = [];
    newObj.educations = [];
    newObj.birth_year = Number(newObj.birth_date.split('-')[0]);
    newObj.user_id = Number(newObj.user_id);
    delete newObj.birth_date;
    return super.post(this.itemName, 'admin/cv', lang, token, newObj, okCallBack, koCallBack);
  }

  deleteItem(contentId, lang, token, okCallBack, koCallBack) {
    function deleteKoCallBack(error) {
      this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
    }
    return super.delete(this.itemName, 'admin/cv/' + contentId, lang, token, okCallBack, deleteKoCallBack.bind(this));
  }

  //requests

  getRequests(contentId, lang, token, okCallBack, koCallBack) {
    return super.get(this.itemName + '_requests', 'admin/cv/' + contentId + '/requests', lang, token, null, okCallBack, koCallBack);
  }

  getAllRequests(obj, lang, token, okCallBack, koCallBack) {
    return super.get(this.itemName + '_all_requests', 'admin/cv/requests', lang, token, obj, okCallBack, koCallBack);
  }

  acceptRequest(contentId, type, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, 'admin/cv/' + type + '/requests/' + contentId + '/accept', lang, token, null, okCallBack, koCallBack);
  }

  rejectRequest(contentId, type, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, 'admin/cv/' + type + '/requests/' + contentId + '/reject', lang, token, obj, okCallBack, koCallBack);
  }

  deleteRequest(contentId, type, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName, 'admin/cv/' + type + '/requests/' + contentId, lang, token, okCallBack, koCallBack);
  }

  addRequest(obj, type, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, 'admin/cv/' + type + '/requests', lang, token, obj, okCallBack, koCallBack);
  }

  updateRequest(contentId, type, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, 'admin/cv/' + type + '/requests/' + contentId, lang, token, obj, okCallBack, koCallBack);
  }

  //cv_languages
  addLanguage(cvId, obj, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, 'admin/cv/' + cvId + '/languages', lang, token, obj, okCallBack, koCallBack);
  }

  updateLanguage(cvId, languageId, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, 'admin/cv/' + cvId + '/languages/' + languageId, lang, token, obj, okCallBack, koCallBack);
  }

  deleteLanguage(cvId, languageId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName, 'admin/cv/' + cvId + '/languages/' + languageId, lang, token, okCallBack, koCallBack);
  }

  //cv_educations
  addEducation(cvId, obj, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, 'admin/cv/' + cvId + '/educations', lang, token, obj, okCallBack, koCallBack);
  }

  updateEducation(cvId, educationId, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, 'admin/cv/' + cvId + '/educations/' + educationId, lang, token, obj, okCallBack, koCallBack);
  }

  deleteEducation(cvId, educationId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName, 'admin/cv/' + cvId + '/educations/' + educationId, lang, token, okCallBack, koCallBack);
  }

  //cv jobs
  addJob(cvId, obj, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, 'admin/cv/' + cvId + '/jobs', lang, token, obj, okCallBack, koCallBack);
  }

  updateJob(cvId, jobId, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, 'admin/cv/' + cvId + '/jobs/' + jobId, lang, token, obj, okCallBack, koCallBack);
  }

  deleteJob(cvId, jobId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName, 'admin/cv/' + cvId + '/jobs/' + jobId, lang, token, okCallBack, koCallBack);
  }

  //cv skills
  addSkill(cvId, obj, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, 'admin/cv/' + cvId + '/skills', lang, token, obj, okCallBack, koCallBack);
  }

  updateSkill(cvId, skillId, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, 'admin/cv/' + cvId + '/skills/' + skillId, lang, token, obj, okCallBack, koCallBack);
  }

  deleteSkill(cvId, skillId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName, 'admin/cv/' + cvId + '/skills/' + skillId, lang, token, okCallBack, koCallBack);
  }

  //export as pdf
  exportCv(cvId, cvLang, lang, token, okCallBack, koCallBack, firstError) {
    return super.get(this.itemName, 'admin/cv/' + cvId + '/generate_pdf/' + cvLang, lang, token, null, okCallBack, koCallBack, firstError);
  }
}
