import React from 'react';
import { CSidebar, CSidebarNav, CSidebarBrand, CSidebarToggler, CNavGroup, CNavItem, CBadge, CImage } from '@coreui/react-pro';
import { NavLink, useLocation } from 'react-router-dom'
import i18n from 'i18next';

import CIcon from '@coreui/icons-react';

import logo from '../../../images/logo.svg';
import tinyLogo from '../../../images/logoWhite.svg'
//import logoMin from '../../../../images/logo-min.png';

const UiSidebarMenu = props => {

  const { navConfig, show, unfoldable, unfoldableToggler, onAction, toggleUnfoldable, toggleSidebar, closeMenuOptionText, routes } = props;
  const location = useLocation();

  const isExternal = url => {
    const link = url ? url.substring(0, 4) : '';
    return link === 'http';
  };

  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && <CIcon icon={icon} customClassName="nav-icon" />}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item, index) => {
    const { component, name, badge, icon, color, className, ...rest } = item;
    const Component = component;
    const resolvedClassName = `${className || ''}${!!color ? ` site-link__color-${color}` : ''}`
    return (
      <Component
        {...(rest.to &&
          !rest.children && {
          component: NavLink,
          //activeClassName: 'active',
        })}
        className={resolvedClassName}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }

  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item;
    const Component = component;
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.children?.map((item, index) =>
          item.children ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )
  }

  const menuAdapter = (e) => {
    const cItem = {
      name: e.name,
      badge: e.badge ? { ...e.badge, color: e.badge.variant } : undefined,
      icon: (e.icon.indexOf('icon-cloud') < 0) ? e.icon : e.icon.replace('icon-cloud', 'cis-cloud'),
      className: e.class,
      color: e.variant,
      onClick: (e.action) ? ((event) => { onAction(e.action) }) : undefined,
      to: e.url || '/#',
      component: e.children ? CNavGroup : CNavItem,
      target: isExternal(e.url) ? '_blank' : '_self',
    };
    return cItem;
  };

  const handleToggle = () => {
    toggleSidebar(!show);
  };

  const getPaths = pathname => {
    const paths = [{ id: '/', url: '/' }];

    if (pathname === '/') return paths;

    pathname.split('/').reduce((prev, curr) => {
      const currPath = {};
      currPath.id = curr;
      currPath.url = `${prev}/${curr}`;
      paths.push(currPath);
      return currPath.url;
    });
    return paths;
  };

  const menuConstructor = () => {
    const elements = navConfig.items;
    const items = elements.map(e => {
      const item = {
        ...menuAdapter(e),
        children: e.children ? e.children.map(c => menuAdapter(c)) : undefined,
      };
      return item;
    });

    items.unshift({
      name: closeMenuOptionText,
      badge: null,
      icon: 'cis-x',
      className: 'side-menu-close-mobile',
      onClick: handleToggle,
      component: CNavItem,
      target: '_self',
    });

    const paths = getPaths(location.pathname);
    let currentRoute;
    paths.forEach(path => {
      if (!currentRoute) currentRoute = routes.find(route => path.id === route.child)
    })
    if (!!currentRoute) {
      items.forEach((item) => {
        if (!!item.children) {
          item.children.forEach(el => {
            if (i18n.t(`Section.${currentRoute.name}`) === el.name) el.className = 'active'
          });
        } else {
          if (i18n.t(`Section.${currentRoute.name}`) === item.name) item.className = 'active'
        }
      })
    }
    return (
      <>
        {items.map((currentItem, index) => (currentItem.children ? navGroup(currentItem, index) : navItem(currentItem, index)))}
      </>
    )
  };

  /*
   * export button -> class: mt-auto bg-success
   * import button -> class: bg-danger (e mt-auto se non c'è export)
   * 
   * 
   * */

  return (
    <CSidebar position='fixed' unfoldable={unfoldable} visible={show} onVisibleChange={(value) => toggleSidebar(value)}>
      <CSidebarBrand to="/" style={{ maxHeight: '4em', padding: '5px' }}>
        <CImage style={{ maxHeight: '70%' }} fluid src={unfoldable ? tinyLogo : logo} />
      </CSidebarBrand>
      <CSidebarNav>
        {menuConstructor()}
      </CSidebarNav>
      {unfoldableToggler && <CSidebarToggler className="d-nome d-lg-flex" onClick={toggleUnfoldable} />}
    </CSidebar>
  );
};

export default UiSidebarMenu;
