import React, { useState, useEffect } from 'react';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
//MUI
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//UI
import { CFormLabel, CRow, CCol } from '@coreui/react-pro';

//TYPES
import PropTypes from 'prop-types';

//STYLE
import "react-datepicker/dist/react-datepicker.css";

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  isOutsideRange: PropTypes.bool,
  value: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  disabled: PropTypes.bool,
  default: PropTypes.string,
  views: PropTypes.array
};

function UiDatePicker(props) {
  const [invalid, setInvalid] = useState(false);
  const [today, setToday] = useState();
  const [date, setDate] = useState();
  const [disabled, setDisabled] = useState();
  const [focused, setFocused] = useState(false);
  const [maxDate, setMaxDate] = useState();
  const [minDate, setMinDate] = useState();

  useEffect(() => {
    const date = props.value !== undefined && props.value !== null ? moment.utc(props.value) : null;
    const maxDate = props.maxDate && moment(props.maxDate);
    const minDate = props.minDate && moment(props.minDate);
    setDate(date);
    setMaxDate(maxDate);
    setMinDate(minDate);
    setToday(moment().format('YYYY/MM/DD'));
    setDisabled(!!props.disabled && (`${props.disabled}` === 'true'));
  }, [props])

  function handleOnChange(date) {
    if (!disabled) {
      const invalid = props.required ? (focused ? false : (!date)) : undefined;
      const event = {
        target: {
          name: props.name,
          value: date.toISOString(),
        },
      };
      setInvalid(invalid);

      if (props.onChange) props.onChange(event);

    }
  }

  useEffect(() => { moment.locale(i18n.language) }, [props])

  return (
    <I18n ns="translations">
      {t => (
        <>
          {
            props?.showSlider ? (
              <div className={`UiDatePicker${invalid ? ' is-invalid' : ''}`} style={props.style}>
                {
                  props.label ? (
                    <CFormLabel htmlFor={props.name} className="UiMainLabel">
                      {t(props.label)} {props.required ? '*' : ''}
                    </CFormLabel>
                  ) : (
                    ''
                  )
                }
                <CRow style={{ gap: '5px' }}>
                  <CCol sm="3" md="3" lg="3" xl="3" style={{ display: 'flex', alignItems: 'center', gap: '2px', justifyContent: 'start' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'lightGrey', borderRadius: '5px', padding: '0 10px', height: '100%' }} className='slider-date'><CIcon icon="cis-chevron-double-left" onClick={props?.handleDoubleLeftClick} /></div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'lightGrey', borderRadius: '5px', padding: '0 10px', height: '100%' }} className='slider-date' onClick={props?.handleSingleLeftClick}><CIcon icon="cis-chevron-left" /></div>
                  </CCol>
                  <CCol sm="6" md="6" lg="6" xl="6">
                    <div id={props.name} className={`date-picker-container form-control UiInputGroup input-group mutipleInputContainer ${focused ? 'UiInput_is_focused' : ''}`}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{ height: '40px', width: '100%' }}
                          minDate={minDate}
                          maxDate={maxDate}
                          onChange={handleOnChange.bind(this)}
                          value={date ?? null}
                          disabled={disabled}
                        />
                      </LocalizationProvider>
                    </div>
                  </CCol>
                  <CCol sm="3" md="3" lg="3" xl="3" style={{ display: 'flex', alignItems: 'center', gap: '2px', justifyContent: 'end' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'lightGrey', borderRadius: '5px', padding: '0 10px', height: '100%' }} className='slider-date' onClick={props?.handleSingleRightClick}><CIcon icon="cis-chevron-right" /></div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'lightGrey', borderRadius: '5px', padding: '0 10px', height: '100%' }} className='slider-date' onClick={props?.handleDoubleRightClick}><CIcon icon="cis-chevron-double-right" /></div>
                  </CCol>
                </CRow>
                {invalid && (
                  <div className="invalid-feedback">{t('Common.field_invalid')}</div>
                )}
              </div>
            ) : <div className={`UiDatePicker${invalid ? ' is-invalid' : ''}`} style={props.style}>
              {
                props.label ? (
                  <CFormLabel htmlFor={props.name} className="UiMainLabel">
                    {t(props.label)} {props.required ? '*' : ''}
                  </CFormLabel>
                ) : (
                  ''
                )
              }
              <div id={props.name} className={`date-picker-container form-control UiInputGroup input-group mutipleInputContainer ${focused ? 'UiInput_is_focused' : ''}`}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{ height: '40px', width: '100%' }}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={handleOnChange.bind(this)}
                    value={date ? date : props?.default ? props.default : null}
                    disabled={disabled}
                    views={props?.views}
                    timezone="UTC"
                  />
                </LocalizationProvider>
              </div>
              {invalid && (
                <div className="invalid-feedback">{t('Common.field_invalid')}</div>
              )}
            </div>
          }

        </>
      )}
    </I18n>
  );
}

UiDatePicker.propTypes = propTypes;

export default UiDatePicker;