import BaseService from './baseService';

export default class AcademySubtopicsService extends BaseService {

  static instance = null;

  static getInstance(store) {
      if (!AcademySubtopicsService.instance) {
          AcademySubtopicsService.instance = new AcademySubtopicsService(store);
      }
      return AcademySubtopicsService.instance;
  }

  constructor(store) {
      let baseUrl = process.env.API_PATH;
      super(store, baseUrl, 'academy_subtopics', 'academy_subtopic');
      this.urlPath = 'subtopics';
  }
}