import React, { Component } from 'react';
import { CCard, CCardHeader } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import PropTypes from 'prop-types';

import withRouter from '../../utils/withNavigation';

import UiButton from './UiButton';
import { useNavigate } from 'react-router';

const propTypes = {
  onSave: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  warning: PropTypes.bool,
  changing: PropTypes.bool,
  history: PropTypes.object,
  subtitle: PropTypes.string,
  showBackButton: PropTypes.bool,
  description: PropTypes.string,
  classTitleName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  sectionParent: PropTypes.string
};

function UiSectionHeader(props) {

  const navigate = useNavigate()

  function goBack() {
    navigate(-1);
  }

  function getComponent(key) {
    if (props.children && props.children.props.children) {
      return props.children.props.children.filter(comp => comp.key === key);
    }
    return <div />;
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiSectionHeader">
          <CCard className="main-header-card">
            <CCardHeader className={`UiSectionHeader-content ${props.color ? `bg-${props.color}` : ''}`}>
              <div className='UiSectionHeader_container'>
                <div className="UiSectionHeader-content__icon mr-2">
                  <div style={{ minWidth: '54px' }} className="bg-primary p-2 font-2xl mr-3 float-left text-center">
                    <CIcon icon={props.icon} size="lg" fill='white' />
                  </div>
                </div>
                <div className={`UiSectionHeader-content__title ${props.classTitleName ? props.classTitleName : ''}`}>
                  <p>
                    <b>{t(props.title)}</b>
                    {props.statusMessage && (props.statusMessage !== 'available') && (
                      <span className="warningMessage">
                        {` - ${t(`SelectValues.${props.statusMessage}`)}`}
                      </span>
                    )}
                    {props.warningMessage && (
                      <span className="warningMessage">
                        {` - ${t('Common.reserved').toUpperCase()}`}
                      </span>
                    )}
                    <br />
                    {props.description ? t(props.description) : props.subtitle}
                  </p>
                </div>
              </div>
              <div className="UiSectionHeader-content__buttons">
                {props.children}
                {props.changing && (
                  <UiButton classNameContainer="saveButton" icon="cis-save" label={t('Common.save')} onClick={props.onSave} styleCont={{marginRight: '5px'}} />
                )}
                {props.showBackButton && (
                  <UiButton icon="cis-chevron-left" label={t('Common.go_back')} disabled={props.changing} onClick={goBack} />
                )}
              </div>
            </CCardHeader>
          </CCard>
        </div>
      )}
    </I18n>
  );
}


UiSectionHeader.defaultProps = {
  showBackButton: true,
};

UiSectionHeader.propTypes = propTypes;

export default withRouter(UiSectionHeader);
