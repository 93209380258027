import BaseService from './baseService';

export default class DocsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!DocsService.instance) {
      DocsService.instance = new DocsService(store);
    }
    return DocsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.LOGIN_PATH;
    super(store, baseUrl, 'docs', 'doc');
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    const newObj = {
      academy: obj.academy,
      description: obj.description ? obj.description : '',
      link: obj.link,
      title: obj.title,
      type: 'document',
    }
    return super.post(this.itemName, 'admin/docs', lang, token, newObj, okCallBack, koCallBack);
  }
}
