// GLOBAL
import React from 'react';
import { connect } from 'react-redux';
import { CRow, CCol, CAccordion, CAccordionItem, CAccordionHeader, CBadge, CAccordionBody, CPopover, CButton, CTooltip } from '@coreui/react-pro';
import i18n from 'i18next';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

// UTILS
import withRouter from '../../utils/withNavigation';

// SERVICES
import ConstantsService from '../../services/constantsService';
import CvsService from '../../services/cvsService';
import store from '../../redux/store';
import TableMapService from '../../services/tableMapService';

// COMPONENTS
import AbstractDetail from './AbstractDetail';
import { mapStateToProps } from './AbstractComponent';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiTranslationsTabs from '../ui/UiTranslationsTabs';
import UiRelationBox from '../ui/UiRelationBox';
import UiDatePicker from '../ui/UiDatePicker';
import UiButton from '../ui/UiButton';
import UiModal from '../ui/UiModal';
import UiSelectClassic from '../ui/UiSelectClassic';
import CIcon from '@coreui/icons-react';
import UiTextArea from '../ui/UiTextArea';
import UiCreationsModal from '../ui/UiCreationsModal';
import UiAutocomplete from '../ui/UiAutocomplete';

class Cv extends AbstractDetail {

  constructor(props) {
    super(props)

    this.state.language = 'IT';
    this.state.translations = [
      { locale: 'IT' },
      { locale: 'EN' },
    ];
    this.state.skills = [];

    this.state.cv_languages = [
      { grid: 0, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'select', name: 'name', label: 'Table.language', values: ConstantsService.iso6933LangExtList, fieldDisplayed: this.state.language === 'IT' ? 'name_it' : 'name_en', fieldReturned: this.state.language === 'IT' ? 'name_it' : 'name_en', alreadyTranslated: true, nullable: true },
      { grid: 2, sortable: true, position: 0, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'select', name: 'level', label: 'Table.level', values: ConstantsService.languageLevel, alreadyTranslated: true, nullable: true },
    ];
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = super.getDerivedStateFromProps(nextProps, prevState);
    newState.cv_languages = [
      { grid: 0, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'select', name: 'name', label: 'Table.language', values: ConstantsService.iso6933LangExtList, fieldDisplayed: newState.language === 'IT' ? 'name_it' : 'name_en', fieldReturned: newState.language === 'IT' ? 'name_it' : 'name_en', alreadyTranslated: true, nullable: true },
      { grid: 2, sortable: true, position: 0, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'select', name: 'level', label: 'Table.level', values: ConstantsService.languageLevel, alreadyTranslated: true, nullable: true },
    ];

    if (newState?.model) {
      //data mapping
      newState.translatedData = [
        {
          locale: 'IT',
          data: {
            languages: [],
            educations: [],
            jobs: [],
          }
        },
        {
          locale: 'EN',
          data: {
            languages: [],
            educations: [],
            jobs: [],
          }
        },
      ];

      newState.translatedData.forEach(locale => {
        //languages mapping
        newState.model?.languages && newState.model?.languages.length > 0 && newState.model?.languages.forEach(language => {
          let translation = language.translations.find(translation => translation.language_option === locale.locale);
          locale.data.languages.push({
            ...translation,
            ...language,
            translations_id: translation?.id
          })
        });

        //educations mapping
        newState.model?.educations && newState.model?.educations.length > 0 && newState.model?.educations.forEach(education => {
          let translation = education.translations.find(translation => translation.language_option === locale.locale);
          locale.data.educations.push({
            ...translation,
            ...education,
            translations_id: translation?.id
          })
        })

        //jobs mapping
        newState.model?.jobs && newState.model?.jobs.length > 0 && newState.model?.jobs.forEach(job => {
          let translation = job.translations.find(translation => translation.language_option === locale.locale);
          locale.data.jobs.push({
            ...translation,
            ...job,
            translations_id: translation?.id
          })
        })
      });
    }

    return newState;
  }

  updateModel() {
    if (!this.computedItemId && !this.itemId) {
      return;
    }
    super.updateModel();
    this.sectionService.getRequests(this.itemId, this.globalService.currentLanguage, this.globalService.currentUser, this.setData.bind(this));
  }

  setData(evt) {
    this.setState({
      requests: evt.data,
    })
  }

  //renders
  renderHeader(type) {
    if (type === 'languages') {
      return (
        <CRow className='fw-bold'>
          <CCol>{i18n.t('Table.language')}</CCol>
          <CCol>{i18n.t('Table.level')}</CCol>
          <CCol sm="1" md="1" lg="1" xl="1"></CCol>
        </CRow>
      )
    } else if (type === 'education') {
      return (
        <CRow className='fw-bold'>
          <CCol>{i18n.t('Table.school')}</CCol>
          <CCol>{i18n.t('Table.start_date')}</CCol>
          <CCol>{i18n.t('Table.end_date')}</CCol>
          <CCol sm="1" md="1" lg="1" xl="1"></CCol>
        </CRow>
      )
    } else if (type === 'jobs') {
      return (
        <CRow className='fw-bold'>
          <CCol>{i18n.t('Table.job_type')}</CCol>
          <CCol>{i18n.t('Table.employer')}</CCol>
          <CCol>{i18n.t('Table.start_date')}</CCol>
          <CCol>{i18n.t('Table.end_date')}</CCol>
          <CCol sm="1" md="1" lg="1" xl="1"></CCol>
        </CRow>
      )
    } else if (type === 'skills') {
      return (
        <CRow className='fw-bold'>
          <CCol>{i18n.t('Table.skill')}</CCol>
          <CCol>{i18n.t('Table.type')}</CCol>
          <CCol sm="1" md="1" lg="1" xl="1"></CCol>
        </CRow>
      )
    }
    return
  }

  renderRow(type, model) {
    moment.locale(this.state.language);
    if (type === 'languages') {
      return (
        <CRow>
          <CCol className='text-capitalize'>{model.name ? model.name : '-'}</CCol>
          <CCol>{model.level ? model.level : '-'}</CCol>
        </CRow>
      )
    } else if (type === 'education') {
      const start_date = moment(model.start_year + '-' + model.start_month).format('MMMM YYYY');
      const end_date = moment(model.end_year + '-' + model.end_month).format('MMMM YYYY');
      return (
        <CRow>
          <CCol className='text-capitalize'>{model.school ? model.school : '-'}</CCol>
          <CCol className='text-capitalize'>{start_date !== 'Invalid date' ? start_date : '-'}</CCol>
          <CCol className='text-capitalize'>{model.end_year ? model.end_month ? end_date : model.end_year : '-'}</CCol>
        </CRow>
      )
    } else if (type === 'jobs') {
      const start_date = moment(model.start_year + '-' + model.start_month).format('MMMM YYYY');
      const end_date = moment(model.end_year + '-' + model.end_month).format('MMMM YYYY');
      return (
        <CRow>
          <CCol>{model.job_type ? model.job_type : '-'}</CCol>
          <CCol className='text-capitalize'>{model.employer ? model.employer : '-'}</CCol>
          <CCol className='text-capitalize'>{start_date !== 'Invalid date' ? start_date : '-'}</CCol>
          <CCol className='text-capitalize'>{model.end_year ? model.end_month ? end_date : model.end_year : '-'}</CCol>
        </CRow>
      )
    } else if (type === 'skills') {
      return (
        <CRow>
          <CCol className='text-capitalize'>{model.name ? model.name : '-'}</CCol>
          <CCol>{model.type ? i18n.t('SelectValues.' + model.type) : '-'}</CCol>
        </CRow>
      )
    }
    return
  }

  //handlers
  isCreationValid(type, evt) {
    if (type === 'languages') {
      if (evt.level && evt.name && evt.name !== '') {
        return true;
      }
    } else if (type === 'education') {
      if (evt.start_year && evt.start_month && evt.school && evt.school !== '') {
        return true;
      }
    } else if (type === 'skill') {
      if (evt.ls_id) {
        return true;
      }
    } else if (type === 'jobs') {
      if (evt.employer && evt.employer !== '' && evt.job_type && evt.job_type !== '' && evt.start_month && evt.start_year) {
        return true;
      }
    }
    return false;
  }

  handleOnEdit(type, evt) {
    const translation = cloneDeep(evt.translations);
    if (type === 'languages') {
      if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
        translation.forEach(translation => {
          if (translation.id === evt.translations_id) {
            translation.name = evt.name;
          }
        })
        const obj = {
          level: evt.level,
          translations: translation
        }
        this.sectionService.updateLanguage(this.state.model.id, evt.id, obj, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      } else {
        const obj = {
          level: evt.level,
          name: evt.name,
          language_option: this.state.language,
          status: 'update',
          cv_id: this.state.model.id,
          language_translation_id: evt.translations_id,
          language_id: evt.id
        }
        this.sectionService.addRequest(obj, 'languages', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      }
      this.toggleModal('modalAddLanguage' + this.state.language);
    } else if (type === 'education') {
      if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
        translation.forEach(translation => {
          if (translation.id === evt.translations_id) {
            translation.school = evt.school;
            translation.description = evt.description;
          }
        })

        const obj = {
          end_year: typeof evt.end_year === "string" ? Number(evt.end_year) : evt.end_year,
          start_year: typeof evt.start_year === "string" ? Number(evt.start_year) : evt.start_year,
          end_month: typeof evt.end_month === "string" ? Number(evt.end_month) : evt.end_month,
          start_month: typeof evt.start_month === "string" ? Number(evt.start_month) : evt.start_month,
          translations: translation
        }
        this.sectionService.updateEducation(this.state.model.id, evt.id, obj, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      } else {
        const obj = {
          end_year: typeof evt.end_year === "string" ? Number(evt.end_year) : evt.end_year,
          start_year: typeof evt.start_year === "string" ? Number(evt.start_year) : evt.start_year,
          end_month: typeof evt.end_month === "string" ? Number(evt.end_month) : evt.end_month,
          start_month: typeof evt.start_month === "string" ? Number(evt.start_month) : evt.start_month,
          description: evt.description,
          school: evt.school,
          language_option: this.state.language,
          status: 'update',
          cv_id: this.state.model.id,
          education_translation_id: evt.translations_id,
          education_id: evt.id
        }
        this.sectionService.addRequest(obj, 'educations', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      }
      this.toggleModal('modalAddEducation' + this.state.language);
    } else if (type === 'jobs') {
      if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
        translation.forEach(translation => {
          if (translation.id === evt.translations_id) {
            translation.description = evt.description;
            translation.job_type = evt.job_type;
          }
        })
        const obj = {
          end_year: typeof evt.end_year === "string" ? Number(evt.end_year) : evt.end_year,
          start_year: typeof evt.start_year === "string" ? Number(evt.start_year) : evt.start_year,
          end_month: typeof evt.end_month === "string" ? Number(evt.end_month) : evt.end_month,
          start_month: typeof evt.start_month === "string" ? Number(evt.start_month) : evt.start_month,
          translations: translation
        }
        this.sectionService.updateJob(this.state.model.id, evt.id, obj, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      } else {
        const obj = {
          end_year: typeof evt.end_year === "string" ? Number(evt.end_year) : evt.end_year,
          start_year: typeof evt.start_year === "string" ? Number(evt.start_year) : evt.start_year,
          end_month: typeof evt.end_month === "string" ? Number(evt.end_month) : evt.end_month,
          start_month: typeof evt.start_month === "string" ? Number(evt.start_month) : evt.start_month,
          description: evt.description,
          language_option: this.state.language,
          status: 'update',
          cv_id: this.state.model.id,
          job_translation_id: evt.translations_id,
          job_id: evt.id,
          job_type: evt.job_type,
          employer: evt.employer,
        }
        this.sectionService.addRequest(obj, 'jobs', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      }
      this.toggleModal('modalAddJobs' + this.state.language);
    }
  }

  handleOnDelete(type, evt) {
    if (type === 'languages') {
      if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
        this.sectionService.deleteLanguage(this.state.model.id, evt.id, this.globalService.currentLanguage, this.globalService.currentUser, this.deleteOkCallback.bind(this, evt.id, 'languages'), this.koCallback.bind(this));
      } else {
        const obj = {
          status: 'delete',
          cv_id: this.state.model.id,
          language_id: evt.id,
          language_translation_id: evt.translations_id
        }
        this.sectionService.addRequest(obj, 'languages', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      }
      this.toggleModal('modalDeleteLanguage' + this.state.language);
    } else if (type === 'education') {
      if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
        this.sectionService.deleteEducation(this.state.model.id, evt.id, this.globalService.currentLanguage, this.globalService.currentUser, this.deleteOkCallback.bind(this, evt.id, 'educations'), this.koCallback.bind(this));
      } else {
        const obj = {
          status: 'delete',
          cv_id: this.state.model.id,
          education_id: evt.id,
          education_translation_id: evt.translations_id
        }
        this.sectionService.addRequest(obj, 'educations', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      }
      this.toggleModal('modalDeleteEducation' + this.state.language);
    } else if (type === 'jobs') {
      if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
        this.sectionService.deleteJob(this.state.model.id, evt.id, this.globalService.currentLanguage, this.globalService.currentUser, this.deleteOkCallback.bind(this, evt.id, 'jobs'), this.koCallback.bind(this));
      } else {
        const obj = {
          status: 'delete',
          cv_id: this.state.model.id,
          job_id: evt.id,
          job_translation_id: evt.translations_id
        }
        this.sectionService.addRequest(obj, 'jobs', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      }
      this.toggleModal('modalDeleteJobs' + this.state.language);
    } else if (type === 'skills') {
      if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
        this.sectionService.deleteSkill(this.state.model.id, evt.id, this.globalService.currentLanguage, this.globalService.currentUser, this.deleteOkCallback.bind(this, evt.id, 'skills'), this.koCallback.bind(this));
      } else {
        const obj = {
          skill_id: evt.id,
          ls_id: evt.ls_id,
          status: 'delete',
          cv_id: this.state.model.id
        }
        this.sectionService.addRequest(obj, 'skills', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      }
      this.toggleModal('modalDeleteSkills');
    }
  }

  handleOnAdd(type, evt) {
    if (type === 'languages') {
      if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
        const obj = {
          level: evt.level,
          translations: [
            {
              name: evt.name,
              language_option: 'IT',
            },
            {
              name: evt.name,
              language_option: 'EN',
            },
          ]
        }
        this.sectionService.addLanguage(this.state.model.id, obj, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      } else {
        const obj = {
          level: evt.level,
          name: evt.name,
          language_option: this.state.language,
          status: 'new',
          cv_id: this.state.model.id
        }
        this.sectionService.addRequest(obj, 'languages', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      }
      this.toggleModal('modalAddLanguage' + this.state.language);
    } else if (type === 'education') {
      if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
        const obj = {
          end_year: evt.end_year ? Number(evt.end_year) : null,
          start_year: Number(evt.start_year),
          end_month: evt.end_month ? Number(evt.end_month) : null,
          start_month: Number(evt.start_month),
          translations: [
            {
              description: evt.description,
              school: evt.school,
              language_option: 'IT',
            },
            {
              description: evt.description,
              school: evt.school,
              language_option: 'EN',
            },
          ]
        }
        this.sectionService.addEducation(this.state.model.id, obj, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      } else {
        const obj = {
          end_year: evt.end_year ? Number(evt.end_year) : null,
          start_year: Number(evt.start_year),
          end_month: evt.end_month ? Number(evt.end_month) : null,
          start_month: Number(evt.start_month),
          description: evt.description,
          school: evt.school,
          language_option: this.state.language,
          status: 'new',
          cv_id: this.state.model.id
        }
        this.sectionService.addRequest(obj, 'educations', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      }
      this.toggleModal('modalAddEducation' + this.state.language);
    } else if (type === 'jobs') {
      if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
        const obj = {
          end_year: evt.end_year ? Number(evt.end_year) : null,
          start_year: Number(evt.start_year),
          end_month: evt.end_month ? Number(evt.end_month) : null,
          start_month: Number(evt.start_month),
          employer: evt.employer,
          translations: [
            {
              description: evt.description,
              job_type: evt.job_type,
              language_option: "IT",
            },
            {
              description: evt.description,
              job_type: evt.job_type,
              language_option: "EN",
            }
          ]
        }
        this.sectionService.addJob(this.state.model.id, obj, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      } else {
        const obj = {
          end_year: evt.end_year ? Number(evt.end_year) : null,
          start_year: Number(evt.start_year),
          end_month: evt.end_month ? Number(evt.end_month) : null,
          start_month: Number(evt.start_month),
          description: evt.description,
          job_type: evt.job_type,
          employer: evt.employer,
          language_option: this.state.language,
          status: 'new',
          cv_id: this.state.model.id
        }
        this.sectionService.addRequest(obj, 'jobs', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      }
      this.toggleModal('modalAddJobs' + this.state.language);
    } else if (type === 'skills') {
      if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
        this.sectionService.addSkill(this.state.model.id, { id: evt.ls_id }, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      } else {
        const obj = {
          ls_id: evt.ls_id,
          status: 'new',
          cv_id: this.state.model.id
        }
        this.sectionService.addRequest(obj, 'skills', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
      }
      this.toggleModal('modalAddSkills');
    }
  }

  refreshOkCallback() {
    function saveData(type, evt) {
      if (type === 'cv')
        this.setState({ model: evt.data });
      else {
        if (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_HR')) {
          const filterData = evt.data.filter(request => !request.status.includes('rejected'));
          this.setState({ requests: filterData });
        } else {
          this.setState({ requests: evt.data });
        }

      }

    }
    this.okCallback();
    this.sectionService.getItem((this.computedItemId) ? this.computedItemId : this.itemId, this.globalService.currentLanguage, this.globalService.currentUser, saveData.bind(this, 'cv'), this.manageMissingItem.bind(this));
    this.sectionService.getRequests(this.itemId, this.globalService.currentLanguage, this.globalService.currentUser, saveData.bind(this, 'requests'), this.manageMissingItem.bind(this));
  }

  deleteOkCallback(id, type, evt) {
    this.okCallback();
    let clone = cloneDeep(this.state.model);
    eval(`clone.${type}=clone.${type}.filter(item => item.id !== id);`)
    this.setState({ model: clone })
  }

  handleExport() {
    this.sectionService.exportCv(this.state.model.id, (this.state.exportLanguage).toUpperCase(), this.globalService.currentLanguage, this.globalService.currentUser, this.exportOkCallback.bind(this), this.koCallback.bind(this));
  }

  async exportOkCallback(evt) {
    // Decodifica la stringa Base64 in un array di byte
    const byteCharacters = atob(evt.data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = this.state.model.first_name + '_' + this.state.model.last_name + '_CV-' + this.state.exportLanguage;
    document.body.appendChild(link);
    link.click();
    this.okCallback();
    this.toggleModal('modalExportCv');
  }

  handleAcceptRequest(request, key, evt) {
    this.sectionService.acceptRequest(request.id, request.type + 's', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal('acceptRequestModal' + key);
  }

  handleRejectRequest(request, key, evt) {
    this.sectionService.rejectRequest(request.id, request.type + 's', { notes: this.state?.reject_notes }, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal('rejectRequestModal' + key);
  }

  handleDeleteRequest(request, key, evt) {
    this.sectionService.deleteRequest(request.id, request.type + 's', this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal('deleteRequestModal' + key);
  }

  handleUpdateRequest(request, key, evt) {
    this.sectionService.updateRequest(request.id, request.type + 's', request, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal('updateRequestModal' + key);
  }

  handleOnChangeUpdateRequest(key, evt) {
    const newState = cloneDeep(this.state.requests);
    newState[key][evt.target.name] = evt.target.value;
    this.setState({ requests: newState });
  }

  renderChildren(t) {
    return (
      <>
        <UiCard className="mt-4 colored-header" icon="cis-group" title={t('Table.personal_data')} collapsible={false}>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiInput type="text" name="first_name" label="Table.name"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('first_name') >= 0}
                value={this.state.model?.first_name}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
            <CCol sm="12" md="6" lg="6" xl="6" className='rightCol'>
              <UiInput type="text" name="last_name" label="Table.last_name"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('last_name') >= 0}
                value={this.state.model?.last_name}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiInput type="text" name="profile" label="Table.profile"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('profile') >= 0}
                value={this.state.model?.profile}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
            <CCol sm="12" md="6" lg="6" xl="6" className='rightCol' style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
              <UiInput type="number" name="birth_year" label="Table.birth_year"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('birth_year') >= 0}
                value={this.state.model?.birth_year}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
                maxLength={4}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiAutocomplete name="user_id" label="Table.user" route="user" currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer}
                values={this.props.apiReducer?.users?.data} reducer={"users"} disabled
                value={this.state.model?.user_id} fieldDisplayed="username" fieldReturned="id"
                onSearchChange={this.handleOnSearchChange.bind(this)} onChange={this.handleOnChange.bind(this)}
              />
            </CCol>
            <CCol sm="12" md="6" lg="6" xl="6" className='rightCol' style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
              <div className='mt-4 d-flex align-items-center justify-content-end'>
                <UiButton
                  style={{ width: '200px' }}
                  label={t('Common.export')}
                  onClick={() => this.toggleModal('modalExportCv')}
                />
              </div>
            </CCol>
          </CRow>
        </UiCard>
        <UiCard className="mt-4 colored-header" icon="cis-group" title={t('Table.requests')} collapsible={false}>
          {
            this.state.requests && this.state.requests.length > 0 ?
              this.state.requests.map((request, key) => {
                const admin = (this.globalService.currentUser?.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser?.userGroup.includes('ROLE_HR'))
                let icon, color_status;
                switch (request.type) {
                  case 'language':
                    icon = 'cis-language';
                    break;
                  case 'education':
                    icon = 'cis-school';
                    break;
                  case 'job':
                    icon = 'cis-briefcase';
                    break;
                  case 'skill':
                    icon = 'cis-badge';
                    break;
                }
                const status = request.status.includes('rejected') ? request.status.split('_')[1] : request.status;

                switch (status) {
                  case 'new':
                    color_status = 'success';
                    break;
                  case 'update':
                    color_status = 'warning';
                    break;
                  case 'delete':
                    color_status = 'danger';
                    break;
                };

                if (admin && request.status.includes('rejected')) {
                  return <></>
                }

                const start_date = moment(request.start_year + '-' + request.start_month).format('MMMM YYYY');
                const end_date = moment(request.end_year + '-' + request.end_month).format('MMMM YYYY');

                return (
                  <>
                    <CAccordion key={key} className='mb-2'>
                      <CAccordionItem>
                        <CAccordionHeader>
                          <div className='w-100 d-flex flex-row align-items-center justify-content-between'>
                            <div>
                              <CIcon icon={icon} /><span style={{ marginLeft: '10px', marginRight: '10px' }}>{t('Table.' + request.type)}</span>
                              {!admin && <>
                                {!request.status.includes('rejected') ? <span className={'fw-bold text-' + color_status}>{t('Table.' + request.status)}</span>
                                  :
                                  <span className={'fw-bold text-' + color_status}>{t('Table.' + request.status.split('_')[1])}</span>}
                              </>
                              }
                            </div>
                            <div style={{ width: "auto", marginRight: '20px' }}>
                              {admin ?
                                <CBadge color={color_status} className='text-center text-uppercase'><span className='w-100'>{t('Table.' + request.status)}</span></CBadge>
                                :
                                <>
                                  {!request.status.includes('rejected') ?
                                    <CBadge color={'secondary'} className='text-center text-uppercase'><span className='w-100'>{t('Table.pending')}</span></CBadge>
                                    :
                                    <CBadge color={'danger'} className='text-center text-uppercase'><span className='w-100'>{t('Table.' + request.status.split('_')[0])}</span></CBadge>
                                  }
                                </>
                              }
                            </div>
                          </div>
                        </CAccordionHeader>
                        <CAccordionBody>
                          <CRow>
                            <CCol sm="10" md="10" lg="10" xl="10">
                              {request.type === 'language' &&
                                <>
                                  {request.status === 'update' &&
                                    <CRow>
                                      <span className='text-capitalize'><span className='fw-bold'>{t('Table.translation') + ': '}</span>{request?.language_option ? t('Common.' + request.language_option) : '-'}</span>
                                    </CRow>}
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.language') + ': '}</span>{request?.name ? request.name : '-'}</span>
                                  </CRow>
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.level') + ': '}</span>{request?.level ? request.level : '-'}</span>
                                  </CRow>
                                </>}
                              {request.type === 'job' &&
                                <>
                                  {request.status === 'update' &&
                                    <CRow>
                                      <span className='text-capitalize'><span className='fw-bold'>{t('Table.translation') + ': '}</span>{request?.language_option ? t('Common.' + request.language_option) : '-'}</span>
                                    </CRow>}
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.job_type') + ': '}</span>{request?.job_type ? request.job_type : '-'}</span>
                                  </CRow>
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.employer') + ': '}</span>{request?.employer ? request.employer : '-'}</span>
                                  </CRow>
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.start_date') + ': '}</span>{start_date}</span>
                                  </CRow>
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.end_date') + ': '}</span>{request.end_year ? request.end_month ? end_date : request.end_year : '-'}</span>
                                  </CRow>
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.description') + ': '}</span>{request?.description ? request.description : '-'}</span>
                                  </CRow>
                                </>}
                              {request.type === 'education' &&
                                <>
                                  {request.status === 'update' &&
                                    <CRow>
                                      <span className='text-capitalize'><span className='fw-bold'>{t('Table.translation') + ': '}</span>{request?.language_option ? t('Common.' + request.language_option) : '-'}</span>
                                    </CRow>}
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.school') + ': '}</span>{request?.school ? request.school : '-'}</span>
                                  </CRow>
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.start_date') + ': '}</span>{start_date}</span>
                                  </CRow>
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.end_date') + ': '}</span>{request.end_year ? request.end_month ? end_date : request.end_year : '-'}</span>
                                  </CRow>
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.description') + ': '}</span>{request?.description ? request.description : '-'}</span>
                                  </CRow>
                                </>}
                              {request.type === 'skill' &&
                                <>
                                  <CRow>
                                    <span className='text-capitalize'><span className='fw-bold'>{t('Table.skill') + ': '}</span>{request?.skill_name ? request.skill_name : '-'}</span>
                                  </CRow>
                                </>}
                            </CCol>
                            <CCol sm="2" md="2" lg="2" xl="2">
                              {request.status.includes('rejected') &&
                                <>
                                  <CRow>
                                    <CTooltip content={request?.notes ? request.notes.length > 150 ? request.notes.substring(0, 150) + '...' : request.notes : t('Table.no_notes')} placement="left">
                                      <CButton color='danger' className='text-light text-uppercase fw-bold' style={{ fontSize: '12px' }} onClick={this.toggleModal.bind(this, 'refuseNotesModal' + key)}>
                                        {t('Table.refuse_notes')}
                                      </CButton>
                                    </CTooltip>
                                  </CRow>
                                  <UiModal isOpen={this.state['refuseNotesModal' + key]} onSubmit={this.toggleModal.bind(this, 'refuseNotesModal' + key)} title={t('Table.refuse_notes')}>
                                    {request?.notes ? request.notes : t('Table.no_notes')}
                                  </UiModal>
                                </>}
                            </CCol>
                          </CRow>
                          <hr />
                          {admin ?
                            <>
                              {!request.status.includes('rejected') &&
                                <div className='d-flex justify-content-center align-items-center' style={{ gap: '20px' }}>
                                  <UiButton label={t('Common.accept')} color='success' onClick={this.toggleModal.bind(this, 'acceptRequestModal' + key)} />
                                  <UiButton label={t('Common.refuse')} color='danger' onClick={this.toggleModal.bind(this, 'rejectRequestModal' + key)} />
                                </div>
                              }
                            </>
                            :
                            <div className='d-flex justify-content-center align-items-center' style={{ gap: '20px' }}>
                              <UiButton label={t('Table.delete')} color='danger' onClick={this.toggleModal.bind(this, 'deleteRequestModal' + key)} />
                              {request.type !== 'skill' && <UiButton label={t('Table.update')} color='warning' onClick={this.toggleModal.bind(this, 'updateRequestModal' + key)} />}
                            </div>
                          }
                          <UiModal isOpen={this.state['deleteRequestModal' + key]} onCancel={this.toggleModal.bind(this, 'deleteRequestModal' + key)} title={t('Common.delete_confirm')} onSubmit={this.handleDeleteRequest.bind(this, request, key)}>
                            {t('Common.are_you_sure')}
                          </UiModal>
                          <UiModal isOpen={this.state['acceptRequestModal' + key]} onCancel={this.toggleModal.bind(this, 'acceptRequestModal' + key)} title={t('Table.accept_request')} onSubmit={this.handleAcceptRequest.bind(this, request, key)}>
                            {t('Table.confirm_irreversible_action')}
                          </UiModal>
                          <UiModal isOpen={this.state['rejectRequestModal' + key]} onCancel={this.toggleModal.bind(this, 'rejectRequestModal' + key)} title={t('Table.reject_request')} onSubmit={this.handleRejectRequest.bind(this, request, key)}>
                            <UiTextArea label={t('Table.description')} onChange={(evt) => this.setState({ reject_notes: evt.target.value })} value={this.state?.reject_notes} />
                          </UiModal>

                          <UiCreationsModal isOpen={this.state['updateRequestModal' + key]} title={t('Table.update_request')} model={request} onChange={this.handleOnChangeUpdateRequest.bind(this, key)}
                            fields={request.type === 'language' ? this.state.cv_languages : TableMapService.getTableMapByGroup(this.globalService.currentUser.userGroup)['cv_' + request.type + 's']} updating
                            apiReducer={this.props.apiReducer} currentUser={this.globalService.currentUser} onSearchChange={this.handleOnSearchChange.bind(this)}
                            onSubmit={this.handleUpdateRequest.bind(this, request, key)} onCancel={this.toggleModal.bind(this, 'updateRequestModal' + key)}
                          />
                        </CAccordionBody>
                      </CAccordionItem>
                    </CAccordion>
                  </>
                )
              })
              :
              <div className='text-center'>{t('Table.no_requests')}</div>
          }
        </UiCard >
        <UiTranslationsTabs tabs={this.state.translations} activeTab={this.state.language} disabled={true}
          onTab={(locale) => this.setState({ language: locale })} >
          <div>
            {this.state.translatedData && this.state.translatedData.map(translation => (
              <div id={translation.locale} key={translation.locale}>
                <UiRelationBox
                  title='Table.languages' icon='cis-language' model={translation.data.languages} cardIsOpen
                  currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer} route='cv_languages' apiReducerName={'cv_languages'}
                  renderRow={this.renderRow.bind(this, 'languages')} renderHeader={this.renderHeader.bind(this, 'languages')}
                  isCreationValid={this.isCreationValid.bind(this, 'languages')}
                  toggleModal={this.toggleModal.bind(this, 'modalAddLanguage' + translation.locale)}
                  isModalOpen={translation.locale === 'IT' ? this.state.modalAddLanguageIT : this.state.modalAddLanguageEN}
                  toggleDeleteModal={this.toggleModal.bind(this, 'modalDeleteLanguage' + translation.locale)}
                  isDeleteModalOpen={translation.locale === 'IT' ? this.state.modalDeleteLanguageIT : this.state.modalDeleteLanguageEN}
                  insertable deletable editable
                  onSearchChange={this.handleOnSearchChange.bind(this)}
                  onEdit={this.handleOnEdit.bind(this, 'languages')}
                  onAdd={this.handleOnAdd.bind(this, 'languages')}
                  onDelete={this.handleOnDelete.bind(this, 'languages')}
                  creationCustomFields={this.state.cv_languages}
                />
                <div className='mt-4' />
                <UiRelationBox
                  title='Table.education' icon='cis-school' model={translation.data.educations} cardIsOpen
                  currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer} route='cv_educations' apiReducerName={'cv_educations'}
                  renderRow={this.renderRow.bind(this, 'education')} renderHeader={this.renderHeader.bind(this, 'education')}
                  isCreationValid={this.isCreationValid.bind(this, 'education')}
                  toggleModal={this.toggleModal.bind(this, 'modalAddEducation' + translation.locale)}
                  isModalOpen={translation.locale === 'IT' ? this.state.modalAddEducationIT : this.state.modalAddEducationEN}
                  toggleDeleteModal={this.toggleModal.bind(this, 'modalDeleteEducation' + translation.locale)}
                  isDeleteModalOpen={translation.locale === 'IT' ? this.state.modalDeleteEducationIT : this.state.modalDeleteEducationEN}
                  insertable deletable editable
                  onSearchChange={this.handleOnSearchChange.bind(this)}
                  onEdit={this.handleOnEdit.bind(this, 'education')}
                  onAdd={this.handleOnAdd.bind(this, 'education')}
                  onDelete={this.handleOnDelete.bind(this, 'education')}
                // customFields={this.customEducationFields}
                />
                <div className='mt-4' />
                <UiRelationBox
                  title='Table.jobs' icon='cis-briefcase' model={translation.data.jobs} cardIsOpen
                  currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer} route='cv_jobs' apiReducerName={'cv_jobs'}
                  renderRow={this.renderRow.bind(this, 'jobs')} renderHeader={this.renderHeader.bind(this, 'jobs')}
                  isCreationValid={this.isCreationValid.bind(this, 'jobs')}
                  toggleModal={this.toggleModal.bind(this, 'modalAddJobs' + translation.locale)}
                  isModalOpen={translation.locale === 'IT' ? this.state.modalAddJobsIT : this.state.modalAddJobsEN}
                  toggleDeleteModal={this.toggleModal.bind(this, 'modalDeleteJobs' + translation.locale)}
                  isDeleteModalOpen={translation.locale === 'IT' ? this.state.modalDeleteJobsIT : this.state.modalDeleteJobsEN}
                  insertable deletable editable
                  onSearchChange={this.handleOnSearchChange.bind(this)}
                  onEdit={this.handleOnEdit.bind(this, 'jobs')}
                  onAdd={this.handleOnAdd.bind(this, 'jobs')}
                  onDelete={this.handleOnDelete.bind(this, 'jobs')}
                // customFields={this.customJobFields}
                />
              </div>
            ))}
          </div>
        </UiTranslationsTabs>
        <div className='mt-4' />
        <UiRelationBox
          title='Section.skills' icon='cis-badge' model={this.state.model.skills} cardIsOpen
          currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer} route='cv_skills' apiReducerName={'cv_skills'}
          renderRow={this.renderRow.bind(this, 'skills')} renderHeader={this.renderHeader.bind(this, 'skills')}
          isCreationValid={this.isCreationValid.bind(this, 'skill')}
          toggleModal={this.toggleModal.bind(this, 'modalAddSkills')}
          isModalOpen={this.state.modalAddSkills}
          toggleDeleteModal={this.toggleModal.bind(this, 'modalDeleteSkills')}
          isDeleteModalOpen={this.state.modalDeleteSkills}
          insertable deletable
          onSearchChange={this.handleOnSearchChange.bind(this)}
          onAdd={this.handleOnAdd.bind(this, 'skills')}
          onDelete={this.handleOnDelete.bind(this, 'skills')}
        />
        <UiModal title={t('Common.choose_language')} loadingModal={false} isOpen={this.state.modalExportCv} valid={!!this.state.exportLanguage}
          onSubmit={this.handleExport.bind(this)} onCancel={() => this.toggleModal('modalExportCv')}>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiSelectClassic name="exportLanguage" label="Common.export_language"
                required values={ConstantsService.iso6933LangList}
                value={this.state.exportLanguage}
                fieldDisplayed='name' fieldReturned='iso2' forceTranslation={true} nullable
                onChange={(evt) => this.setState({ exportLanguage: evt.target.value })} />
            </CCol>
          </CRow>
        </UiModal>
      </>
    );
  }

  render() {
    return super.render();
  }
}

export default connect(mapStateToProps)(withRouter(Cv));
