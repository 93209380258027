// GLOBAL
import React from 'react';
import CIcon from '@coreui/icons-react';
import i18n from 'i18next';
import moment from 'moment';
import { CBadge } from '@coreui/react-pro';
// import TagsInput from 'react-tagsinput';

//ROUTER
import { Link } from 'react-router-dom';


let isVisible = false;

export default class TablesUtils {

  static renderRowByType(columns, rowData, computedCallback, clickFunction) {
    if (columns && columns.length > 0 && rowData) {
      const values = columns.map((key, k) => {
        const keyName = key.name.replace('.', '_');
        const rowDataKeyName = '';
        try {
          eval(`rowDataKeyName = rowData.${key.name}`);
        } catch (e) {
          console.log('errore', e);
        }
        if (key.type === 'image') {
          if (rowDataKeyName && rowDataKeyName.indexOf('.tiff') !== (rowDataKeyName.length - 5)) {
            return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}><img className="TableImage" src={`${rowDataKeyName}?height=90`} alt={rowDataKeyName} /></td>);
          } else if (rowDataKeyName) {
            return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}><CIcon icon="cis-file-image" />tiff</td>);
          }
          return <td key={keyName + k} />;
        } else if (key.type === 'autocomplete' || key.type === 'select') {
          let data = rowDataKeyName ?? '-';
          if (key.values && key.values.length > 0 && (!key.like_name || !rowData[key.like_name]) && key.fieldReturned && key.fieldDisplayed) {
            const dataArray = key.values.filter(obj => obj[key.fieldReturned] === rowDataKeyName);
            if (dataArray.length > 0) {
              data = dataArray[0][key.fieldDisplayed];
            }
          } else if (key.like_name && rowData[key.like_name]) {
            data = rowData[key.like_name];
          }
          if (key.type === 'select' && !key.alreadyTranslated && (!key.fieldDisplayed || key.forceTranslation)) {
            data = (data !== '-' && (!key.fieldDisplayed || key.forceTranslation)) ? i18n.t(`SelectValues.${data}`) : data;
          }
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}>{(data !== '-' && key.prefix) ? key.prefix : ''}{data}{(data !== '-' && key.suffix) ? key.suffix : ''}</td>);
        } else if (key.type === 'chip') {
          let data = rowDataKeyName
          return <td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}> {
            data.map((tag, key) => {
              return (
                <CBadge color={'primary'} className="ms-auto" key={key}>
                  {tag.toUpperCase()}
                </CBadge>
              )
            })
          }
          </td >;
        } else if (key.type === 'date') {
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}>{(rowDataKeyName && key.prefix) ? key.prefix : ''}{(rowDataKeyName) ? moment.utc(rowDataKeyName).format('DD/MM/YYYY') : '-'}{(rowDataKeyName && key.suffix) ? key.suffix : ''}</td>);
        } else if (key.type === 'datetime') {
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}>{(rowDataKeyName && key.prefix) ? key.prefix : ''}{(rowDataKeyName) ? moment.utc(rowDataKeyName).format('DD/MM/YYYY HH:mm:ss') : '-'}{(rowDataKeyName && key.suffix) ? key.suffix : ''}</td>);
        } else if (key.type === 'date-not-null') {
          if (!!rowDataKeyName && moment(rowDataKeyName).isValid()) {
            return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)} className="text-success"><CIcon icon="cis-check-circle" /></td>);
          }
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)} className="text-danger"><CIcon icon="cis-x-circle" /></td>);
        } else if (key.type === 'date-null') {
          if (!rowDataKeyName || !moment(rowDataKeyName).isValid()) {
            return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)} className="text-success"><CIcon icon="cis-check-circle" /></td>);
          }
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)} className="text-danger"><CIcon icon="cis-x-circle" /></td>);
        } else if (key.type === 'currency') {
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}>{(rowDataKeyName && key.prefix) ? key.prefix : ''}{(rowDataKeyName) ? parseFloat(rowDataKeyName).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : '-'}{(rowDataKeyName && key.suffix && key.suffix !== '€') ? key.suffix : ''}</td>);
        } else if (key.type === 'password') {
          if (rowDataKeyName) {
            return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)} className="text-success"><CIcon icon="cis-check-circle" /></td>);
          }
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)} className="text-danger"><CIcon icon="cis-x-circle" /></td>);
        } else if (key.type === 'checkbox') {
          if (`${rowDataKeyName}` === 'true') {
            return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)} className="text-success"><CIcon icon="cis-check-circle" /></td>);
          }
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)} className="text-danger"><CIcon icon="cis-x-circle" /></td>);
        } else if (key.type === 'list') {
          let list = '';
          if (rowDataKeyName) {
            let model = rowDataKeyName;
            if (key.fieldDisplayed) {
              list = model.map(a => a[key.fieldDisplayed]).join(', ');
            }
          }
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}>{(rowDataKeyName && key.prefix) ? key.prefix : ''}{(list.length > 0) ? list : '-'}{(rowDataKeyName && key.suffix) ? key.suffix : ''}</td>);
        } else if (key.type === 'computed') {
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}>{(rowDataKeyName && key.prefix) ? key.prefix : ''}{computedCallback(rowData)}{(rowDataKeyName && key.suffix) ? key.suffix : ''}</td>);
        } else if (key.type === 'payment_data') {
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}>{(rowDataKeyName && rowDataKeyName.address) ? rowDataKeyName.address.recipient_name : '-'}</td>);
        } else if (key.type === 'reserved' || key.type === 'multicolor') {
          return null;
        } else if (key.type === "download_file") {
          return (
            <td key={keyName + k}>
              <div className='file-icons-container'>
                <div className='download-file-box'>
                  <CIcon icon={"cis-cloud-download"} />
                </div>
                <div className='trash-file-box'>
                  <CIcon icon={"cis-trash-alt"} />
                </div>
              </div>
            </td>
          )
        } else if (key.type === "textarea") {
          let stringToShow = '';
          let stringToHide = '';
          if (rowDataKeyName?.length > 30) {
            stringToShow = rowDataKeyName.substring(0, 30);
            // stringToHide = rowDataKeyName.substring(250,rowDataKeyName.length);
          }
          return (
            <td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}>
              <div>
                {stringToShow + '...'}
                {/* <CCollapse visible={isVisible}>
                    {stringToHide}
                </CCollapse> */}
              </div>
            </td>
          )
        } else if (key.type === "link") {
          return (<td key={keyName + k}><Link to={'/' + key.route + '/' + rowData[key.path]}>{rowDataKeyName}</Link></td>);
        } else if (key.type === "external-link") {
          if (rowDataKeyName) {
            if (rowDataKeyName.includes('https://') || rowDataKeyName.includes('http://')) {
              return (<td key={keyName + k}><a href={rowDataKeyName} target='_blank'>{rowDataKeyName}</a></td>);
            } else {
              let exactLink = 'https://' + rowDataKeyName;
              return (<td key={keyName + k}><a href={exactLink} target='_blank'>{exactLink}</a></td>);
            }
          } else {
            return (
              <td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}>-</td>
            )
          }
        } else if (key.type === 'value-not-null') {
          if (rowDataKeyName && rowDataKeyName.length > 0) {
            return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)} className="text-success"><CIcon icon="cis-check-circle" /></td>);
          }
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)} className="text-danger"><CIcon icon="cis-x-circle" /></td>);
        } else if (key.type === 'percentage') {
          return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}>{(rowDataKeyName) ? ((Number(rowDataKeyName) <= 1) ? (Number(rowDataKeyName) * 100).toFixed(2) : (Number(rowDataKeyName)).toFixed(2)) : '-'}{' %'}</td>);
        } else if (key.type === 'radio') {
          return (<td key={keyName + k}>{typeof rowDataKeyName === "number" ? i18n.t(`SelectValues.${key.values[rowDataKeyName][key.fieldDisplayed]}`) : '-'}</td>);
        } else if (key.type === 'active') {
          return (<td key={keyName + k} className='text-center'>{rowDataKeyName ? <CIcon icon="cis-lock-locked" className='text-danger' /> : <CIcon icon="cis-lock-unlocked" className='text-primary' />}</td>);
        }
        return (<td key={keyName + k} onClick={!!clickFunction && clickFunction.bind(this)}>{(rowDataKeyName && key.prefix) ? key.prefix : ''}{(rowDataKeyName) ? rowDataKeyName : '-'}{(rowDataKeyName && key.suffix) ? key.suffix : ''}</td>);
      });
      return values;
    }
    return null;
  }

  static renderDivsHeaderByType(columns, maxperc) {
    const perc = maxperc || 100;
    const className = `widthperc-${Math.floor(perc / columns.length)}`;
    if (columns && columns.length > 0) {
      const values = columns.map((key, k) => (<div className={className} key={key.name.replace('.', '_') + k}>{i18n.t(`Table.${key.name.replace('.', '_')}`)}</div>));
      return values;
    }
    return null;
  }

  static renderDivsByType(columns, rowData, apiReducer, computedCallback, maxperc) {
    const perc = maxperc || 100;
    const className = `widthperc-${Math.floor(perc / columns.length)}`;
    if (columns && columns.length > 0 && rowData) {
      const val = columns.map((key, k) => {
        const keyName = key.name.replace('.', '_');
        const rowDataKeyName = '';
        try {
          eval(`rowDataKeyName = rowData.${key.name}`);
        } catch (e) { }
        if (key.type === 'image') {
          return (<div className={className} key={keyName + k}><img className="TableImage" src={(rowDataKeyName) ? `${rowDataKeyName}?height=90` : ''} alt={keyName} /></div>);
        } else if (key.type === 'autocomplete' || key.type === 'select') {
          let data = '-';
          let { values } = key;
          if (key.reducer && apiReducer[key.reducer].data) {
            values = apiReducer[key.reducer].data;
          }
          if (values && values.length > 0 && rowDataKeyName) {
            data = rowDataKeyName;
            const dataArray = values.filter(obj => obj[key.fieldReturned] === rowDataKeyName);
            if (dataArray.length > 0) {
              data = dataArray[0][key.fieldDisplayed];
            }
          } else if (key.like_name && rowData[key.like_name]) {
            data = rowData[key.like_name];
          }
          if (key.type === 'select' && !key.fieldDisplayed) {
            data = (data !== '-' && !key.alreadyTranslated) ? i18n.t(`SelectValues.${data}`) : data;
          }
          return (<div className={className} key={keyName + k}>{(data !== '-' && key.prefix) ? key.prefix : ''}{data}{(data !== '-' && key.suffix) ? key.suffix : ''}</div>);
        } else if (key.type === 'date') {
          return (<div className={className} key={keyName + k}>{(rowDataKeyName && key.prefix) ? key.prefix : ''}{(rowDataKeyName) ? moment(rowDataKeyName).format('DD/MM/YYYY') : '-'}{(rowDataKeyName && key.suffix) ? key.suffix : ''}</div>);
        } else if (key.type === 'datetime') {
          return (<div className={className} key={keyName + k}>{(rowDataKeyName && key.prefix) ? key.prefix : ''}{(rowDataKeyName) ? moment(rowDataKeyName).format('DD/MM/YYYY HH:mm:ss') : '-'}{(rowDataKeyName && key.suffix) ? key.suffix : ''}</div>);
        } else if (key.type === 'date-not-null') {
          if (moment(rowDataKeyName).isValid()) {
            return (<div className={`${className} text-success`} key={keyName + k}><CIcon icon="cis-check-circle" /></div>);
          }
          return (<div className={`${className} text-danger`} key={keyName + k}><CIcon icon="cis-x-circle" /></div>);
        } else if (key.type === 'date-null') {
          if (!moment(rowDataKeyName).isValid()) {
            return (<div className={`${className} text-success`} key={keyName + k}><CIcon icon="cis-check-circle" /></div>);
          }
          return (<div className={`${className} text-danger`} key={keyName + k}><CIcon icon="cis-x-circle" /></div>);
        } else if (key.type === 'currency') {
          return (<div className={className} key={keyName + k}>{(rowDataKeyName && key.prefix) ? key.prefix : ''}{(rowDataKeyName) ? parseFloat(rowDataKeyName).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : '-'}{(rowDataKeyName && key.suffix && key.suffix !== '€') ? key.suffix : ''}</div>);
        } else if (key.type === 'computed') {
          return (<div className={className} key={keyName + k}>{(rowDataKeyName && key.prefix) ? key.prefix : ''}{computedCallback(rowData)}{(rowDataKeyName && key.suffix) ? key.suffix : ''}</div>);
        } else if (key.type === 'payment_data') {
          return (<div className={className} key={keyName + k}>{(rowDataKeyName && rowDataKeyName.address) ? rowDataKeyName.address.recipient_name : '-'}</div>);
        }
        return (<div className={className} key={keyName + k}>{(rowDataKeyName && key.prefix) ? key.prefix : ''}{(rowDataKeyName) ? rowDataKeyName : '-'}{(rowDataKeyName && key.suffix) ? key.suffix : ''}</div>);
      });
      return val;
    }
    return null;
  }
}
