import React from 'react';
import PropTypes from 'prop-types';
import { CFormCheck, CFormLabel, CTooltip } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';

const propTypes = {
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltipMsg: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  link: PropTypes.string
};

function UiCheckbox(props) {

  function handleOnChange(evt) {
    if (!props.disabled) {
      const event = {
        target: {
          name: evt.target.name,
          value: evt.target.checked,
        },
      };
      if (props.onChange) props.onChange(event);

      if (props.onBlur) {
        setTimeout(() => {
          props.onBlur(event);
        }, 500);
      }
    }
  }

  function handleOnFocus(evt) {
    if (!props.disabled && props.onFocus) {
      props.onFocus(evt);
    }
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className={`UiCheckbox${props.group ? ' UiCheckbox_group' : ''}${props.className ? ` ${props.className}` : ''} ${props.link && 'justify-content-center'}`}>
          <div className="UiCheckbox_body">
            {props.label ? (props.link ?
              <a href={props.link} target="_blank">
                <CFormLabel htmlFor={props.name} className="UiMainLabel">
                  {t(props.label)} {props.required ? '*' : ''}
                </CFormLabel>
              </a> :
              <CFormLabel htmlFor={props.name} className="UiMainLabel">
                {t(props.label)} {props.required ? '*' : ''}
              </CFormLabel>
            ) : (
              ''
            )}
            <CFormCheck id={`${props.name}`} name={props.name} disabled={props.disabled}
              required={props.required} checked={props.value === true}
              onFocus={handleOnFocus.bind(this)} onChange={handleOnChange.bind(this)} />
            <span className="checkmark" />
          </div>
          {props.tooltipMsg && (
            <span className="UiCheckbox_Tooltip">
              <CTooltip placement="top" content={t(props.tooltipMsg)}>
                <CIcon icon="cis-info-circle" size="sm" />
              </CTooltip>
            </span>
          )}
        </div>
      )}
    </I18n>
  );
}

UiCheckbox.propTypes = propTypes;

export default UiCheckbox;
