import React from 'react';
import { CRow, CFormLabel, CFormInput } from '@coreui/react-pro';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import UiButton from './UiButton';
import { useState } from 'react';
import { useRef } from 'react';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

function UiFilesUpload(props) {

  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [hasImage, setHasImage] = useState(false);

  let UiInputFile = useRef(null);

  function handleImageChange(ev) {
    ev.preventDefault();
    const tmpfiles = cloneDeep(files);

    if (ev.target.files && ev.target.files.length > 0) {
      for (let i = 0; i < ev.target.files.length; i++) {
        tmpfiles[tmpfiles.length] = ev.target.files[i];
      }
    }
    setFiles(tmpfiles);
    dispatchChange(tmpfiles);
  }

  function handleDragOver(ev) {
    ev.preventDefault();

    if (!isDragging) {
      setIsDragging(true);
    }
  }

  function handleDrop(ev) {
    ev.preventDefault();
    const tmpfiles = cloneDeep(files);
    if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        tmpfiles[tmpfiles.length] = ev.dataTransfer.files[i];
      }
    }
    setFiles(tmpfiles);
    dispatchChange(tmpfiles);
  }

  function handleDragLeave() {
    if (isDragging) {
      setIsDragging(false);
    }
  }

  function dispatchChange(files) {
    const event = {
      target: {
        name: props.name,
        value: (props.multiple) ? files : files[0],
      },
    };
    if (props.onChange) props.onChange(event);
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiPictureUpload">
          {props.label ? (
            <CFormLabel className="UiMainLabel">
              {t(props.label)} {props.required ? '*' : ''}
            </CFormLabel>
          ) : (
            ''
          )}
          <div className="Detail_imageCover previewComponent">
            <div className={`UiPictureUploadPreview ${isDragging ? ' dragInFile' : ''} ${hasImage ? ' imagePreview' : ''}`}
              draggable onDragEnter={handleDragOver.bind(this)} onDragOver={handleDragOver.bind(this)}
              onDragLeave={handleDragLeave.bind(this)} onDrop={handleDrop.bind(this)}>
              <div className={`previewPlaceholder${isDragging ? ' dragInFile' : ''} ${!hasImage ? ' noImage' : ''}`}>
                <div className={`placeholderContent${!hasImage ? ' noImage' : ''}`}>
                  <p className="placeholderMessage">{t('Common.dnd_file_here')}</p>

                  {files && files.map((file, index) => (
                    <p key={`file-upload-${props.name}-${index}`} className="placeholderMessage">{file.name}</p>
                  ))}

                  <UiButton className="UiAddImageButton" label={(hasImage) ? t('Common.change_file') : t('Common.select_file')} onClick={() => (UiInputFile.click())} />
                </div>
              </div>
            </div>
            <CRow>
              <div>
                <CFormInput type="file" ref={node => {
                  if (node) {
                    UiInputFile = node;
                  }
                }}
                  id="UiInputFile" className="UiInputFile" name="UiInputFile"
                  multiple onChange={e => handleImageChange(e)} />
              </div>
            </CRow>
          </div>
        </div>
      )}
    </I18n>
  );
}

UiFilesUpload.propTypes = propTypes;

export default UiFilesUpload;
