//GLOBAL
import React from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

//UI
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro';

//UTILS
import ConstantsService from '../../services/constantsService';
import withRouter from '../../utils/withNavigation';
import ObjectsUtils from '../../utils/objectsUtils';

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiMdEditor from '../ui/UiMdEditor';
import UiPictureUpload from '../ui/UiPictureUpload';
import UiButton from '../ui/UiButton';
import UiTranslationsTabs from '../ui/UiTranslationsTabs';
import UiTextArea from '../ui/UiTextArea';

class Blog extends AbstractDetail {

  constructor(props) {
    super(props);

    this.state.language = 'it';
    this.state.translations = [
      { locale: 'it' },
      { locale: 'en' },
    ];
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = super.getDerivedStateFromProps(nextProps, prevState);

    if (!newState.newImage) {
      newState.newImage = {};
    }

    if (newState.model) {
      let languages = newState.model.translations ?? [];

      let localeIt = languages.find(obj => obj.language === 'it');
      if (!localeIt) {
        languages.push({ language: 'it' });
      }

      let localeEn = languages.find(obj => obj.language === 'en');
      if (!localeEn) {
        languages.push({ language: 'en' });
      }

      newState.model.translations = languages;

      if (newState.model?.cover_img_id) {
        let cover = newState.model.images.find(img => img.id === newState.model.cover_img_id);
        newState.cover = cover;
      } else {
        newState.cover = null;
      }
    }

    return newState;
  }

  getChangedModel(event) {
    var model = super.getChangedModel(event);
    if (model) {
      if (event.target.name && event.target.name.indexOf('.title') > 0 && event.target.value) {
        let permalinkField = event.target.name.replace('.title', '.permalink');
        if (!ObjectsUtils.isStringValid(eval(`model.${permalinkField}`))) {
          let link = ObjectsUtils.buildPermalink(event.target.value, this.state.language);
          eval(`model.${permalinkField} = link`);
        }
      }
    }
    return model;
  }

  buildHeaderSubtitle() {
    let subtitle = null;
    if (this.state.model && ObjectsUtils.isArrayNotEmpty(this.state.model.translations)) {
      subtitle = this.state.model.translations.find(obj => obj.language === this.globalService.currentLanguage)?.title;
      if (!subtitle) {
        subtitle = this.state.model.translations.find(obj => obj.language === 'en')?.title;
      }
      if (!subtitle) {
        subtitle = this.state.model.translations[0].title;
      }
    }
    return subtitle;
  }

  addOkCallBack() {
    this.updateModel();
    return toast.success(i18n.t('Common.success'));
  }

  progressCallBack(toastId, event) {
    toast.update(toastId, {
      render: `${i18n.t('Common.uploading')}: ${event.loaded}/${event.total}`,
      type: 'info',
      className: 'rotateY animated',
      autoClose: (event.loaded < event.total) ? false : 1000,
    });
  }

  handleSetImageAsCover(event) {
    let obj = {
      cover_img_id: event.target.image_id
    }
    this.sectionService.setAsCover(this.itemId, obj, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  handleOnImageDelete(index, model) {
    this.sectionService.deleteImage(this.itemId, model[index].id, this.globalService.currentLanguage, this.globalService.currentUser, this.deleteOkCallback.bind(this, model[index].id), this.koCallback.bind(this));
  }

  handleOnNewImageChange(target, event) {
    let toastId = toast(`${i18n.t('Common.uploading')}...`, { autoClose: true })
    this.sectionService.addImage(this.itemId, target, event.target, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this), this.progressCallBack.bind(this, toastId));
  }

  handleDeleteCover() {
    this.sectionService.removeCover(this.itemId, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
  }

  deleteOkCallback(id, evt) {
    this.okCallback();
    let clone = cloneDeep(this.state.model);
    clone.images = clone.images.filter(item => item.id !== id);
    this.setState({
      model: clone
    })
  }

  handleDisable() {
    if (!this.state.model.disable_date || this.state.model.disable_date === null) {
      let item = cloneDeep(this.state.model);
      item.disable_date = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      this.sectionService.updateItem(item, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
    } else {
      let item = cloneDeep(this.state.model);
      item.disable_date = null;
      this.sectionService.updateItem(item, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
    }
  }

  renderChildren(t) {
    let imageCount = 0;

    return (
      <>
        <UiCard className="mt-4 colored-header" icon="cis-browser" title={t('Table.main_data')} collapsible={false}>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiInput type="text" name="author" label="Table.author"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('author') >= 0}
                value={this.state.model.author}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
            <CCol sm="12" md="6" lg="6" xl="6" className="rightCol" style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <UiButton
                label={!this.state.model.disable_date || this.state.model.disable_date === null ? t('Common.disable') : t('Common.reactivate')}
                onClick={this.handleDisable.bind(this)}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="12" lg="12" xl="12">
              <UiPictureUpload name='cover_img' label={'Table.cover_image'} disabled={!this.state.editable}
                value={this.state.cover?.original}
                onDelete={this.handleDeleteCover.bind(this)}
                notInsertable downloadable deletable fullScreenEnabled
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="12" lg="12" xl="12">
              <UiTranslationsTabs tabs={this.state.translations} activeTab={this.state.language} disabled={true}
                onTab={(locale) => this.setState({ language: locale })} >
                <div>
                  {this.state.model.translations && this.state.model.translations.map((translation, index) => (
                    <div id={translation.language} key={translation.language}>
                      <CRow>
                        <CCol sm="12" md="6" lg="6" xl="6">
                          <UiInput type="text" name={`translations[${index}].title`} label="Table.title"
                            disabled={!this.state.editable}
                            required={this.state.mandatoryFields.indexOf('translations[0].title') >= 0}
                            value={translation.title}
                            onFocus={this.handleOnFocus.bind(this)}
                            onChange={this.handleOnChange.bind(this)}
                            onBlur={this.handleOnBlur.bind(this)}
                          />
                        </CCol>
                        <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                          <UiInput type="text" name={`translations[${index}].permalink`} label="Table.permalink"
                            disabled={!this.state.editable}
                            required={this.state.mandatoryFields.indexOf('permalink') >= 0}
                            value={translation.permalink}
                            onFocus={this.handleOnFocus.bind(this)}
                            onChange={this.handleOnChange.bind(this)}
                            onBlur={this.handleOnBlur.bind(this)}
                          />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol sm="12" md="12" lg="12" xl="12">
                          <UiTextArea type="text" name={`translations[${index}].subtitle`} label="Table.subtitle"
                            disabled={!this.state.editable}
                            required={this.state.mandatoryFields.indexOf('subtitle') >= 0}
                            value={translation.subtitle}
                            onFocus={this.handleOnFocus.bind(this)}
                            onChange={this.handleOnChange.bind(this)}
                            onBlur={this.handleOnBlur.bind(this)}
                          />
                        </CCol>
                      </CRow>
                      <CRow>
                        <UiMdEditor name={`translations[${index}].description`}
                          value={translation.description}
                          disabled={!this.state.editable}
                          onFocus={this.handleOnFocus.bind(this)}
                          onChange={this.handleOnChange.bind(this)}
                          onBlur={this.handleOnBlur.bind(this)}
                        />
                      </CRow>
                    </div>
                  ))
                  }
                </div>
              </UiTranslationsTabs>
            </CCol>
          </CRow>
        </UiCard>
        <UiCard className="mt-4 colored-header" icon="cis-browser" title={t('Table.images')} collapsible={false}>
          <CRow row>
            {this.state.newImage
              && (
                <CCol sm="12" md="6" lg="6" xl="6">
                  <UiPictureUpload name={`newImage${imageCount}.image`} label="Table.new_image" disabled={!this.state.editable}
                    currentUser={this.globalService.currentUser} filename={this.state.newImage.image_name} value={this.state.newImage.image_path}
                    //onChange={this.handleOnImageChange.bind(this, this.state.model, `newImage${imageCount}.image`)}
                    onChange={this.handleOnNewImageChange.bind(this, this.state.model)}
                  />
                </CCol>
              )}
            {this.state.model?.images && this.state.model?.images.map((image, index) => (
              <CCol sm="12" md="6" lg="6" xl="6" key={`site-images-${index}`}>
                <UiPictureUpload name={`images[${index}].image`} label={`${t('Table.image')} ${++imageCount}/${this.state.model?.images.length}`}
                  disabled={!this.state.editable}
                  currentUser={this.globalService.currentUser} filename={image.image_name}
                  value={(image.original) ? image.original : undefined} imageId={image.id}
                  description={image.description}
                  weight={image.weight} width={image.width} height={image.height}
                  onSetAsCover={this.handleSetImageAsCover.bind(this)}
                  onDelete={this.handleOnImageDelete.bind(this, index, this.state.model?.images)}
                  deletable fullScreenEnabled downloadable setCoverEnabled
                />
              </CCol>
            )
            )}
          </CRow>
        </UiCard>
      </>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Blog));