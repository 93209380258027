import React, { useEffect, useState, useRef } from 'react';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { Chips, Chip } from 'react-chips-input'
import { userType } from '../../types';

//UI
import { CFormInput, CFormLabel } from '@coreui/react-pro';
import UiAutocomplete from './UiAutocomplete';
import UiButton from './UiButton';

const propTypes = {
  onBlur: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
  values: PropTypes.array,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  currentUser: userType,
  apiReducer: PropTypes.object,
  onRemoveTag: PropTypes.func
};

const UiInputChip = React.memo((props) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [currentChipSelected, setCurrentChipSelected] = useState({});
  const [subtopics, setSubtopics] = useState([]);

  const UiInputChipComp = useRef()
  const updateCounter = useRef(0);

  useEffect(() => {
    const localSuggestions = [];
    const localSelectedValues = [];

    if (props.chipSection === 'academy' && (props.lng !== null && props.lng !== undefined)) {
      if (props.value && props.value.length > 0 && props.isUpdate) {
        props.value.map((obj, i) => {
          obj?.translations?.forEach((translation) => {
            if (translation.id !== obj.id) translation.id = obj.id;
            if (props.lng === translation.language) {
              localSelectedValues.push(translation);
            }
          })
        })
        setSelectedValues(localSelectedValues);
      }

      if (props.values && props.values.length > 0) {
        props.values.forEach((suggestion) => {
          suggestion.translations.forEach((val) => {
            if (val.id !== suggestion.id) val.id = suggestion.id;
            if (props.lng === val.language) {
              localSuggestions.push(val);
            }
          })
        });
      }
    } else {
      if (props.value && props.value.length > 0) {
        props.value.forEach((val) => localSelectedValues.push(val));
        setSelectedValues(localSelectedValues);
      }

      if (props.values && props.values.length > 0) {
        props.values.forEach((suggestion) => {
          let sugg = (props.fieldDisplayed) ? suggestion[props.fieldDisplayed] : suggestion;
          sugg = (!props.alreadyTranslated) ? i18n.t(`SelectValues.${sugg}`) : sugg;
          if (sugg && !localSelectedValues.current.find(val => sugg[props.fieldReturned] === val[props.fieldReturned])) {
            localSuggestions.push(sugg);
          }
        });
      }
    }
    setSuggestions(localSuggestions);
  }, [props])

  useEffect(() => {
    if (selectedValues?.length > 0) {
      const values = selectedValues.map((el) => {
        return {
          id: el.id
        }
      })
      const event = {
        target: {
          name: props.name,
          value: values
        }
      }
      const customEvent = new CustomEvent('chipsChanged', { detail: event });
      document.dispatchEvent(customEvent);
    }

  }, [selectedValues])

  function handleOnChange(evt) {
    if (!props.disabled) {
      setCurrentChipSelected(evt.target.value ? evt.target.value : {});
    }
  }

  function removeTag(tag) {
    const index = selectedValues.indexOf(tag); //get index position of tag that i want to remove
    const cloneSelectedValues = cloneDeep(selectedValues);
    cloneSelectedValues.splice(index, 1);
    updateCounter.current = 0;
    setSelectedValues(cloneSelectedValues);
    if (props.onRemoveTag) props.onRemoveTag(index);
  }

  function handleOnFocus(evt) {
    if (!props.disabled && props.onFocus) {
      props.onFocus(evt);
    }
  }

  function handleOnBlur() {
    if (!props.disabled && props.onBlur) {
      props.onBlur();
    }
  }

  function handleAddSubtopic() {
    const clone = cloneDeep(selectedValues);
    const obj = {
      value: currentChipSelected.value,
      language: props.lng,
      id: currentChipSelected.id
    }
    clone.push(obj);
    setSelectedValues(clone);
    setCurrentChipSelected({});
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className='chip-macro-container'>
          <div className="UiInputChip" ref={UiInputChipComp}>
            {props.label ? (
              <CFormLabel htmlFor={props.name} className="UiMainLabel">
                {t(props.label)} {props.required ? '*' : ''}
              </CFormLabel>
            ) : (
              ''
            )}
            {props.disabled && (
              <CFormInput type="text" className="UiInputChipDisabledPlaceholder" disabled
                value={props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
                onFocus={handleOnFocus.bind(this)} />
            )}
            {props.disabled && (
              <div className="UiContainerRelative">
                <Chips
                  value={selectedValues}
                  onChange={handleOnChange.bind(this)}
                  suggestions={suggestions}
                  renderChip={value =>
                    <div style={{ display: 'none' }} >{value}</div>
                  }
                  renderSuggestion={value =>
                    <div className="suggestionbox">{value}</div>
                  }
                  uniqueChips={true}
                />
                <div className="chipcontainer">
                  {!!selectedValues && selectedValues.length > 0 && selectedValues.map((tag, key) => {
                    return (
                      <div className="chipbox">
                        <Chip onRemove={removeTag.bind(this, tag)} key={key} onFocus={handleOnFocus.bind(this)}>
                          {tag}
                        </Chip>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}

            {!props.disabled && (
              <div className="inputchipbox">
                <div className="chipcontainer">
                  {!!selectedValues && selectedValues.length > 0 && selectedValues.map((tag, key) => {
                    return (
                      <div className="chipbox" key={key}>
                        <Chip onRemove={removeTag.bind(this, tag)} onFocus={handleOnFocus.bind(this)} >
                          {tag.value}
                        </Chip>
                      </div>
                    );
                  })}
                </div>
                <UiAutocomplete name="subtopics" label="" route="subtopic" reducer='academy_subtopics'
                  currentUser={props.currentUser}
                  apiReducer={props.apiReducer}
                  values={suggestions}
                  value={currentChipSelected} fieldDisplayed={props.fieldDisplayed} fieldReturned={props.fieldReturned}
                  onChange={handleOnChange.bind(this)}
                  section="academy"
                />
              </div>
            )}
          </div>
          <div>
            <UiButton label={t('Common.add')} onClick={handleAddSubtopic.bind(this)} />
          </div>
        </div>
      )}
    </I18n>
  );
})

UiInputChip.propTypes = propTypes;

export default UiInputChip;
