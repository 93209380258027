// GLOBAL
import Echo from 'laravel-echo';
import socketIOClient from "socket.io-client";

// REDUCERS
import { websocketReceived } from '../redux/globalServiceDuck';

// SERVICES
import { GlobalService } from './globalService';

export default class WebsocketService {

	static instance = null;

	static getInstance(store, ws_path, ws_channel_type, ws_channel, ws_class) {
		if (!WebsocketService.instance) {
			WebsocketService.instance = new WebsocketService(store, ws_path, ws_channel_type, ws_channel, ws_class);
		}
		return WebsocketService.instance;
	}

	constructor(store, ws_path, ws_channel_type, ws_channel, ws_class) {
		this.store = store;
		this.ws_path = ws_path;
		this.ws_channel_type = ws_channel_type;
		this.ws_channel = ws_channel;
		this.ws_class = ws_class;
		this.connectTimeout = null;

		this.connect();
	}

	connect() {
		let auth = JSON.parse(localStorage.getItem('auth'));
		if (!auth) {
			return;
		}

		let token = auth.accessToken;
		let tokenType = auth.tokenType;

		const socket = new Echo({
			broadcaster: 'socket.io',
			host: this.ws_path,
			client: socketIOClient,
			auth: {
				headers: { Authorization: tokenType + " " + token }
			}
		});

		socket.connector.socket.on("reconnect_attempt", attempt => {

			if (attempt >= 5) {
				socket.connector.disconnect();
			}
		});

		if (this.ws_channel_type === 'private') {
			socket.private(this.ws_channel).listen(this.ws_class, (msg) => {
				if (msg) {
					this.store.dispatch(websocketReceived(msg));
				}
			});
		} else {
			socket.channel(this.ws_channel).listen(this.ws_class, (msg) => {
				if (msg) {
					this.store.dispatch(websocketReceived(msg));
				}
			});

		}
	}

}
