// GLOBAL
import React from 'react';
import { connect } from 'react-redux';
import { CContainer, CRow } from '@coreui/react-pro';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';

// STORE
import ApplicationStore from '../../redux/store';

// UTILS
import withRouter from '../../utils/withNavigation';

// COMPONENTS
import AbstractComponent, { mapStateToProps } from './AbstractComponent';
import UiCard from '../ui/UiCard';
import UiMap from '../ui/UiMap';
import UiDashboardTable from '../ui/UiDashboardTable';
import UiLoading from '../ui/UiLoading';

//TYPES
import { userType } from '../../types';

//SERVICES
import CvsService from '../../services/cvsService';
import CitiesService from '../../services/citiesService';

class Dashboard extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      exportData: {},
      importData: {},
      cvRequests: [],
      isLoading: false,
      currentPage: 1,
      currentPaginate: 10
    };

    this.cvService = CvsService.getInstance(ApplicationStore);
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    })
    const obj = {
      page: this.state.currentPage,
      paginate: this.state.currentPaginate
    }
    this.cvService.getAllRequests(obj, this?.globalService?.currentLanguage, this?.globalService?.currentUser, this.setData.bind(this, 'cvRequests'), this.requestsKoCallback.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPaginate !== this.state.currentPaginate || prevState.currentPage !== this.state.currentPage) {
      const obj = {
        page: this.state.currentPage,
        paginate: this.state.currentPaginate
      }
      this.cvService.getAllRequests(obj, this?.globalService?.currentLanguage, this?.globalService?.currentUser, this.setData.bind(this, 'cvRequests'), this.requestsKoCallback.bind(this));
    }
  }

  setData(type, data) {
    if (type === 'cvRequests') {
      if (data?.data?.length > 0) {
        this.setState({ [type]: data.data, isLoading: false });
      } else {
        this.setState({ [type]: [], isLoading: false });
      }
    }
  }

  requestsKoCallback() {
    this.setState({
      isLoading: false
    })
    return toast.error(`${i18n.t('Common.error')}`);
  }

  populateServices() {
    if (!this.globalService.currentUser || !(this.globalService.currentUser.userId !== 0)) {
      return;
    }

    super.populateServices();

    this.citiesService = CitiesService.getInstance(ApplicationStore);
    this.citiesService.initList();
  }

  handlePaginate(pages) {
    this.setState({
      currentPaginate: pages
    })
  }

  handlePage(page) {
    this.setState({
      currentPage: page
    })
  }


  render() {
    const isAdmin = this.globalService?.currentUser?.userGroup ? this.globalService.currentUser.userGroup.includes('ADMIN') : false
    return (
      <I18n ns="translations">
        {t => (
          <div className="animated fadeIn">
            {this.renderToast()}
            <CContainer fluid>
              <CRow>
                <UiCard className="UiDashboardCard" icon="cis-bell" title="MainTitles.notifications" collapsible={true} isOpen={true}>
                  <>
                    {
                      isAdmin && (
                        <>
                          {
                            !this?.state?.isLoading ? (
                              <UiDashboardTable tab="alert"
                                label={"cv_requests"}
                                model={this.state.cvRequests}
                                modelType="cvRequests"
                                headers={["type", "user", "status"]}
                                showFooter={true}
                                isOpen={true}
                                onPaginateChange={this.handlePaginate.bind(this)}
                                onPageChange={this.handlePage.bind(this)}
                                page={this.state.currentPage}
                                paginate={this.state.currentPaginate}
                                total={this.state.cvRequests.length}
                              />
                            ) : <UiLoading />
                          }
                        </>
                      )
                    }
                  </>
                </UiCard>
              </CRow>
            </CContainer>
            {this.renderExportModal(t)}
            {this.renderImportModal(t)}
          </div>
        )}
      </I18n>
    );
  }
}

Dashboard.propTypes = { currentUser: userType };

export default connect(mapStateToProps)(withRouter(Dashboard));
