import { sygnet } from './sygnet';
import { logo } from './logo';
import { logoNegative } from './logo-negative';

// import * as coreUiIcons from '@coreui/icons';
import * as coreUiIcons from '@coreui/icons-pro';

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  coreUiIcons
);
