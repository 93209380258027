import React, { useEffect, useState, useRef } from 'react'
import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CButton, CSmartPagination, CBadge } from '@coreui/react-pro'

import { useNavigate } from 'react-router';

//UTILS
import { toast } from 'react-toastify';

//I18N
import i18n from 'i18next';
import { I18n } from 'react-i18next';

//COMPONENTS
import CIcon from '@coreui/icons-react';
import UiCard from './UiCard';
import UiSelectClassic from './UiSelectClassic';


function UiDashboardTable(props) {

	const [filters, setFilters] = useState();
	const [windowSize, setWindowSize] = useState();

	//navigate
	const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener('resize', handleResize.bind(this));
		return () => {
			window.removeEventListener('resize', handleResize.bind(this));
		}
	}, [])

	function handleResize() {
		setWindowSize(window.innerWidth)
	}

	function goToDetail(el) {
		if (Number.isInteger(el.cv_id)) {
			const url = `/cv/${el.cv_id} `;
			navigate(url);
		} else {
			return toast.error(`${i18n.t('Common.error')}`);
		}

	}

	function handlePageChange(link) {
		if (props.onPageChange && link > 0) props.onPageChange(link);
	}


	function setPaginateValue(quantity) {
		const paginate = parseInt(quantity.target.value);
		if (props.onPaginateChange) props.onPaginateChange(paginate);
	}

	function handlePaginate(pages) {
		const basicFilterClone = cloneDeep(filters);
		if (basicFilterClone.hasOwnProperty('paginate')) {
			basicFilterClone.paginate = pages;
			setFilters(basicFilterClone);
		}
	}

	function handleSort(sortProperty) {
		if (props.onSortChange) props.onSortChange(sortProperty, props.label);
	}



	function renderTableHeaders(t) {
		if (props?.headers?.length > 0 && props?.sort) {
			let sortDirection = props.order === 'ASC' ? 'cis-caret-top' : 'cis-caret-bottom';
			let sortOrder = <CIcon icon={sortDirection} className="UiFlatTable_sortArrow" />;
			return props.headers.map((label, i) => {
				return <CTableHeaderCell key={i + Date.now()} onClick={handleSort.bind(this, label)}>{t(`Table.${label}`)}{props.currentSortedKey === label && sortOrder}</CTableHeaderCell>
			})
		} else {
			return props.headers.map((label, i) => {
				return <CTableHeaderCell key={i + Date.now()}>{t(`Table.${label}`)}</CTableHeaderCell>
			})
		}
	}

	function renderTableBody(t) {
		if (props.modelType === 'cvRequests') {
			return props?.model.map((el, key) => {
				let icon;
				let status;
				switch (el.type) {
					case 'language':
						icon = 'cis-language';
						break;
					case 'education':
						icon = 'cis-school';
						break;
					case 'job':
						icon = 'cis-briefcase';
						break;
					case 'skill':
						icon = 'cis-badge'
						break;
				}
				switch (el.status) {
					case 'new':
						status = 'success';
						break;
					case 'update':
						status = 'warning';
						break;
					case 'delete':
						status = 'danger';
						break;
				};

				return (
					<CTableRow key={'propertiesTableRow_' + key} className='user-row-table' onClick={goToDetail.bind(this, el, '')}>
						<CTableDataCell><CIcon icon={icon} className='requests-all-icon' />{t('Table.' + el.type)}</CTableDataCell>
						<CTableDataCell>{el.full_name}</CTableDataCell>
						<CTableDataCell>
							<CBadge color={status} className='text-center text-uppercase'><span className=''>{t('Table.' + el.status)}</span></CBadge>
						</CTableDataCell>
					</CTableRow>
				)
			})
		}
	}

	function renderPagination() {
		if (!props?.total) return null;
		return (
			<CSmartPagination className="UiFlatTable_pagination"
				pages={Math.ceil(props.total / props.paginate)} activePage={props.page}
				onActivePageChange={(i) => handlePageChange(i)}
				firstButton={i18n.t('Common.first')} lastButton={i18n.t('Common.last')}
				previousButton={<>&laquo;</>} nextButton={<>&raquo;</>} size={windowSize < 450 ? 'sm' : 'sm'}
			/>
		);
	}

	function renderTotalItems(t) {
		if (!props?.total) {
			return null;
		}

		const startIndex = (props.model?.length > 0) ? (props.page - 1) * props.paginate + 1 : 0;
		const endIndex = (props.model?.length > 0 && startIndex > 0) ? startIndex - 1 + props.model?.length : 0;
		return (
			<div className="UiFlatTable-footer__total__number">
				{`${t('Common.results')} ${startIndex}`}
				{endIndex > startIndex ? ` - ${endIndex} ` : ' '}
				{`${t('Common.of')} ${props?.total}`}
			</div>
		);
	}

	return (
		<I18n ns="translations">
			{(t) => (
				<UiCard className={`mt-4 ${props.tab === 'priority' ? 'pending-header' : props.tab === 'alert' ? 'danger-header' : props.tab === 'reminder' ? 'reminder-header' : props.tab === 'activity' ? 'activity-header' : ''} card-shadow`} icon="cis-alarm" title={props?.label === "pending-users" ? t('Common.pending_users') : props?.label === "pending-pms" ? t('Common.pending_pms') : props?.label === "pending-customers" ? t('Common.pending_customers') : t(`Table.${props.label}`)} collapsible={true} isOpen={props.isOpen ? props.isOpen : false}>
					{props?.model && props?.model.length > 0 ?
						<>
							<CTable>
								<CTableHead>
									<CTableRow>
										{renderTableHeaders(t)}
									</CTableRow>
								</CTableHead>
								<CTableBody>
									{renderTableBody(t)}
								</CTableBody>
							</CTable>
							{
								props.showFooter && (
									<div className="flex-table-footer">
										{renderPagination()}
										<UiSelectClassic inline autowidth nosort alreadyTranslated className="UiFlatTable-footer__paginate__items-selector" name="paginate"
											label="Common.items_per_page" value={props.paginate} values={['10', '25', '50', '100']} onChange={setPaginateValue.bind(this)}
										/>
										{renderTotalItems(t)}
									</div>
								)
							}
						</> :
						<div className='text-center'> {t('Common.no_data')}</div>}
				</UiCard>
			)}
		</I18n>
	)
}

export default React.memo(UiDashboardTable)