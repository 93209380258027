// SERVICES
import moment from 'moment';
import ConstantsService from './constantsService';

/**
 * Service with the purpose of mapping all the tables entities of the site into a single entry point
 * @category services
 */

export default class TableMapService {
  /**
   * Method that returns the tables map in objects consisting of:
   *
   * <pre>
   * grid: {Number} column index of the field in the grid view;
   * sortable: {Boolean} true if in grid view is possible to order content on that value;
   * position: {Number} 1 if it has to be a filter on that value in filters section on grid view, 0 if not;
   * creation: {Number} position index of the field in the creation modal;
   * singlerow: {Boolean} true if that field has to take the entire row in the creation modal;
   * updatable: {Boolean} true if that field could be updated after creation in modals;
   * mandatory: {Boolean} true if that field has to be displayed as mandatory in creation modal and in detail view;
   * readonly: {Boolean} true if that field is read only;
   * type: {String} the type of component that has to be used for insert component value;
   *       managed values:
   *       'autocomplete'
   *       'checkbox'
   *       'checkbox-group'
   *       'chip'
   *       'currency'
   *       'date'
   *       'datetime'
   *       'date-not-null'
   *       'file'
   *       'file-multiple'
   *       'image'
   *       'input'
   *       'input-number'
   *       'select'
   *       'textarea'
   * name: {String} name of the property into the json object;
   * like_name: {String} name of the property to use as query filter in backend search when the filter compatibility is set to "Like";
   * label: {String} name of the label to use with that field in translation's files (e.g.: 'Table.name');
   * values: {String[]} array in witch search for static availables values (necessary only for autocomplete, chip and select);
   * reducer: {String} reducer name in witch search for api availables values (necessary only for autocomplete, chip and select);
   * route: {String} route in witch find selected value (necessary only for autocomplete, chip and select);
   * fieldDisplayed: {String} name of the property of the selected value that has to be displayed into the dropdown;
   * fieldReturned: {String} name of the property of the selected value that has to be returned to backend as object value;
   * extraordinaryCase {Array}
   * prefix {String} string displayed before the value in grid view
   * suffix {String} string displayed after the value in grid view
   * </pre>
   * @static
   * @public
   * @return {Map} the tables map
   */
  static getTableMap() {
    const currentLanguage = localStorage.getItem('i18nextLng');
    const tableMap = [];

    tableMap.doc = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "title", label: "Table.title" },
      { grid: 2, sortable: true, position: 0, creation: 4, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "textarea", name: "description", label: "Table.description" },
      { grid: 3, sortable: true, position: 2, creation: 2, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "link", label: "Table.link" },
      { grid: 4, sortable: true, position: 3, creation: 3, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "select", name: "academy", label: "Section.academy", values: ConstantsService.academy_types, fieldDisplayed: 'label', fieldReturned: 'value', forceTranslation: true },
    ];

    tableMap.employee = [
      { grid: 1, sortable: true, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "image", name: "picture_url", label: "Table.pic_url" },
      { grid: 2, sortable: true, position: 1, creation: 1, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "name", label: "Table.name" },
      { grid: 3, sortable: true, position: 2, creation: 2, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "surname", label: "Table.last_name" },
      { grid: 4, sortable: false, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "input", name: "translations[0].role", label: "Table.role" },
      { grid: 0, sortable: true, position: 3, creation: 3, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "input", name: "role", label: "Table.role" },
      { grid: 5, sortable: true, position: 4, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "date", name: "create_datetime", label: "Table.create_date" },
    ];

    tableMap.job_application = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "title_it", label: "Table.title" },
      { grid: 2, sortable: true, position: 2, creation: 2, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "select", name: "type", label: "Table.type", values: ConstantsService.job_types, fieldDisplayed: "label", fieldReturned: "value", forceTranslation: true, nullable: true },
      { grid: 5, sortable: true, position: 4, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "date", name: "date_creation", label: "Table.create_date" },
      { grid: 3, sortable: true, position: 3, creation: 3, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "select", name: "mode", label: "Table.site", values: ConstantsService.mode_types, fieldDisplayed: "label", fieldReturned: "value", forceTranslation: true, nullable: true },
      { grid: 0, sortable: true, position: 0, creation: 4, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "checkbox", name: "academy", label: "Section.academy" },
      { grid: 4, sortable: true, position: 5, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "date-null", name: "disable_date", label: "Table.active" },
    ];

    tableMap.academy = [
      { grid: 1, sortable: false, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "languages[0].academy_name", label: "Table.name" },
      { grid: 0, sortable: true, position: 1, creation: 1, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "academy_name", label: "Table.name" },
      { grid: 2, sortable: false, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "languages[0].title", label: "Table.title" },
      { grid: 0, sortable: true, position: 2, creation: 3, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "title", label: "Table.title" },
      { grid: 0, sortable: true, position: 0, creation: 5, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "subtitle", label: "Table.subtitle" },
      { grid: 3, sortable: true, position: 2, creation: 9, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "select", name: "work_mode", label: "Table.work_mode", values: ConstantsService.mode_types, fieldDisplayed: "label", fieldReturned: "value", forceTranslation: true, nullable: true },
      { grid: 0, sortable: true, position: 0, creation: 7, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "duration", label: "Table.duration" },
      { grid: 4, sortable: true, position: 3, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "checkbox", name: "published", label: "Table.published" },
    ];

    tableMap.blog = [
      { grid: 1, sortable: true, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "translations[0].title", label: "Table.title" },
      { grid: 0, sortable: true, position: 1, creation: 1, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "title", label: "Table.title" },
      { grid: 0, sortable: true, position: 0, creation: 2, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "subtitle", label: "Table.subtitle" },
      { grid: 2, sortable: true, position: 2, creation: 3, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "input", name: "author", label: "Table.author" },
      { grid: 0, sortable: true, position: 0, creation: 4, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "select", name: "language", label: "Table.language", values: ConstantsService.iso6933LangList, fieldDisplayed: 'name', fieldReturned: 'iso2', forceTranslation: true, nullable: true },
      { grid: 3, sortable: true, position: 4, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "date", name: "create_date_time", label: "Table.create_date" },
      { grid: 0, sortable: true, position: 0, creation: 5, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "permalink", name: "permalink", label: "Table.permalink", bindValue: "title" },
      { grid: 5, sortable: true, position: 5, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "date-null", name: "disable_date", label: "Table.active" },
      { grid: 0, sortable: true, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "select", name: "active", label: "Table.active", values: ConstantsService.yes_no, fieldDisplayed: "label", fieldReturned: "value", forceTranslation: true },
    ];

    tableMap.case_study = [
      { grid: 1, sortable: false, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "translations[0].title", label: "Table.title" },
      { grid: 0, sortable: true, position: 1, creation: 1, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "title", label: "Table.title" },
      { grid: 2, sortable: false, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "translations[0].subtitle", label: "Table.subtitle" },
      { grid: 0, sortable: true, position: 2, creation: 2, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "subtitle", label: "Table.subtitle" },
      { grid: 3, sortable: true, position: 3, creation: 3, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "select", name: "type", label: "Table.type", values: ConstantsService.case_study_types, alreadyTranslated: false, fieldDisplayed: "label", fieldReturned: "value", forceTranslation: true, nullable: true },
      { grid: 0, sortable: true, position: 0, creation: 4, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "select", name: "language", label: "Table.language", values: ConstantsService.iso6933LangList, alreadyTranslated: false, fieldDisplayed: "name", fieldReturned: "iso2", forceTranslation: true },
      { grid: 5, sortable: true, position: 5, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "date-null", name: "disable_date", label: "Table.active" },
      { grid: 0, sortable: true, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "select", name: "active", label: "Table.active", values: ConstantsService.yes_no, fieldDisplayed: "label", fieldReturned: "value", forceTranslation: true },
      { grid: 6, sortable: true, position: 6, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "date", name: "create_datetime", label: "Table.create_date" },
      { grid: 0, sortable: true, position: 0, creation: 5, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "permalink", name: "permalink", label: "Table.permalink", bindValue: "title" },
    ];

    tableMap.event = [
      { grid: 1, sortable: false, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "translations[0].title", label: "Table.title" },
      { grid: 0, sortable: true, position: 1, creation: 1, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "title", label: "Table.title" },
      { grid: 0, sortable: true, position: 2, creation: 2, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "subtitle", label: "Table.subtitle" },
      { grid: 3, sortable: true, position: 6, creation: 3, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "date", name: "event_date", label: "Table.event_date" },
      { grid: 0, sortable: true, position: 4, creation: 5, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "select", name: "language", label: "Table.language", values: ConstantsService.iso6933LangList, fieldDisplayed: 'name', fieldReturned: 'iso2', forceTranslation: true, nullable: true },
      { grid: 0, sortable: true, position: 0, creation: 6, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "permalink", name: "permalink", label: "Table.permalink", bindValue: "title" },
      { grid: 5, sortable: true, position: 5, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "date-null", name: "disable_date", label: "Table.active" },
      { grid: 0, sortable: true, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "select", name: "active", label: "Table.active", values: ConstantsService.yes_no, fieldDisplayed: "label", fieldReturned: "value", forceTranslation: true },
    ];

    tableMap.user = [
      { grid: 1, sortable: true, position: 0, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "image", name: "pic_url", label: "Table.pic_url" },
      { grid: 2, sortable: true, position: 1, creation: 1, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "first_name", label: "Table.name" },
      { grid: 3, sortable: true, position: 2, creation: 2, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "last_name", label: "Table.last_name" },
      { grid: 4, sortable: true, position: 3, creation: 3, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "email", label: "Table.email" },
      { grid: 7, sortable: true, position: 9, creation: 4, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "date", name: "hire_date", label: "Table.hire_date" },
      { grid: 5, sortable: true, position: 4, creation: 5, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "date", name: "birth_date", label: "Table.birth_date" },
      { grid: 0, sortable: true, position: 5, creation: 6, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "autocomplete", name: "birth_place_id", label: "Table.birth_place", reducer: 'cities', route: 'city', fieldDisplayed: 'city', fieldReturned: 'id' },
      { grid: 0, sortable: true, position: 6, creation: 7, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "select", name: "nationality", label: "Table.nationality", values: ConstantsService.nationalities, fieldDisplayed: 'name', fieldReturned: 'value', forceTranslation: true },
      { grid: 0, sortable: true, position: 0, creation: 8, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "input", name: "fiscal_code", label: "Table.fiscal_code" },
      { grid: 0, sortable: true, position: 7, creation: 9, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "radio", name: "gender", label: "Table.gender", values: ConstantsService.gender_values, fieldDisplayed: 'label', fieldReturned: 'value', inline: true },
      { grid: 6, sortable: true, position: 8, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "date-null", name: "disable_date", label: "Table.active" },
      // { grid: 6, sortable: true, position: 8, creation: 0, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "select", name: "active", label: "Table.active", values: ConstantsService.yes_no, fieldDisplayed: "label", fieldReturned: "value", forceTranslation: true },

    ];

    tableMap.company = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "company_name", label: "Table.name" },
      // { grid: 2, sortable: true, position: 2, creation: 2, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "input", name: "address", label: "Table.address" },
      // { grid: 3, sortable: true, position: 3, creation: 3, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "select", name: "country", label: "Table.country", values: ConstantsService.countries, fieldDisplayed: 'name', fieldReturned: 'alpha_2_code' },
      { grid: 0, sortable: true, position: 0, creation: 4, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "unique_code", label: "Table.unique_code" },
      { grid: 4, sortable: true, position: 4, creation: 5, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "vat_number", label: "Table.vat_number" },
      //  { grid: 4, sortable: true, position: 1, creation: 6, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "checkbox", name: "timesheet_attachment", label: "Table.timesheet_attachment" },
    ];

    tableMap.role = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: false, mandatory: true, readonly: false, type: "input", name: "name", label: "Table.name" }
    ];

    tableMap.user_address = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: false, readonly: false, type: "input", name: "label", label: "Table.label" },
      { grid: 1, sortable: true, position: 1, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: "select", name: "type", label: "Table.type", values: ConstantsService.user_address_type, fieldDisplayed: "name", fieldReturned: "value", forceTranslation: true, nullable: true },
      { grid: 1, sortable: true, position: 1, creation: 3, singlerow: false, updatable: true, mandatory: true, readonly: false, type: "input", name: "address", label: "Table.address" },
      { grid: 4, sortable: true, position: 5, creation: 4, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'autocomplete', name: 'city_id', label: 'Table.city_id', reducer: 'cities', route: 'city', fieldDisplayed: 'city', fieldReturned: 'id' },
      { grid: 1, sortable: true, position: 1, creation: 5, singlerow: false, updatable: true, mandatory: true, readonly: false, type: "input", name: "zip_code", label: "Table.zip_code" },
      { grid: 2, sortable: true, position: 3, creation: 4, singlerow: false, updatable: true, mandatory: false, readonly: false, type: "date", name: "start_date", label: "Table.start_date", default: moment() },
      { grid: 3, sortable: true, position: 4, creation: 7, singlerow: false, updatable: true, mandatory: false, readonly: false, type: "date", name: "end_date", label: "Table.end_date", bindValue: 'start_date', bindType: 'min' },
      //{ grid: 5, sortable: true, position: 5, creation: 5, singlerow: false, updatable: true, mandatory: false, readonly: false, type: "select", name: "country", label: "Table.country", values: ConstantsService.iso6933LangList, fieldDisplayed: 'name', fieldReturned: 'iso2', forceTranslation:true },
    ];

    tableMap.company_address = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: false, readonly: false, type: "input", name: "label", label: "Table.label" },
      { grid: 1, sortable: true, position: 1, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: "select", name: "type", label: "Table.type", values: ConstantsService.companies_address_type, fieldDisplayed: 'name', fieldReturned: 'value', forceTranslation: true, nullable: true },
      { grid: 1, sortable: true, position: 1, creation: 3, singlerow: false, updatable: true, mandatory: true, readonly: false, type: "input", name: "address", label: "Table.address" },
      { grid: 4, sortable: true, position: 4, creation: 4, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'autocomplete', name: 'city_id', label: 'Table.city_id', reducer: 'cities', route: 'city', fieldDisplayed: 'city', fieldReturned: 'id' },
      { grid: 1, sortable: true, position: 1, creation: 5, singlerow: false, updatable: true, mandatory: true, readonly: false, type: "input", name: "zip_code", label: "Table.zip_code" },
      { grid: 2, sortable: true, position: 2, creation: 6, singlerow: false, updatable: true, mandatory: false, readonly: false, type: "date", name: "start_date", label: "Table.start_date", default: moment() },
      { grid: 3, sortable: true, position: 3, creation: 7, singlerow: false, updatable: true, mandatory: false, readonly: false, type: "date", name: "end_date", label: "Table.end_date", bindValue: 'start_date', bindType: 'min' },
      //{ grid: 5, sortable: true, position: 5, creation: 5, singlerow: false, updatable: true, mandatory: false, readonly: false, type: "select", name: "country", label: "Table.country", values: ConstantsService.iso6933LangList, fieldDisplayed: 'name', fieldReturned: 'iso2', forceTranslation:true },
    ];

    tableMap.city = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'city', label: 'Table.name' },
      { grid: 2, sortable: true, position: 2, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'zip_code', label: 'Table.zip_code' },
      { grid: 3, sortable: true, position: 3, creation: 3, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'province', label: 'Table.state' },
      { grid: 4, sortable: true, position: 4, creation: 4, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'district', label: 'Table.region' },
      { grid: 5, sortable: true, position: 5, creation: 5, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'select', name: 'country', label: 'Table.country', values: ConstantsService.countries, fieldDisplayed: 'name', fieldReturned: 'alpha_2_code' },
    ];

    tableMap.cv = [
      { grid: 0, sortable: true, position: 1, creation: 1, singlerow: false, updatable: false, mandatory: false, readonly: false, type: "autocomplete", name: "user_id", label: "Table.user", reducer: 'users', route: 'user', fieldDisplayed: 'username', fieldReturned: 'id' },
      { grid: 1, sortable: true, position: 2, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'first_name', label: 'Table.name' },
      { grid: 2, sortable: true, position: 3, creation: 3, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'last_name', label: 'Table.last_name' },
      { grid: 0, sortable: true, position: 0, creation: 4, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'profile', label: 'Table.profile' },
      { grid: 0, sortable: true, position: 0, creation: 5, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'date', name: 'birth_date', label: 'Table.birth_year', views: ['year'] },
    ];

    tableMap.cv_languages = [
      { grid: 0, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'select', name: 'name', label: 'Table.language', values: ConstantsService.iso6933LangExtList, fieldDisplayed: 'name', fieldReturned: 'name', forceTranslation: true, alreadyTranslated: false, nullable: true },
      { grid: 2, sortable: true, position: 0, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'select', name: 'level', label: 'Table.level', values: ConstantsService.languageLevel, alreadyTranslated: true, nullable: true },
    ];

    tableMap.cv_educations = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: true, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'school', label: 'Table.school' },
      { grid: 0, sortable: true, position: 1, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'select', name: 'start_month', label: 'Table.start_month', values: ConstantsService.monthsTab, fieldDisplayed: 'name', fieldReturned: 'value', forceTranslation: true, alreadyTranslated: false, nosort: true, nullable: true },
      { grid: 2, sortable: true, position: 2, creation: 3, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input-number', name: 'start_year', label: 'Table.start_year', maxLength: '4' },
      { grid: 0, sortable: true, position: 1, creation: 4, singlerow: false, updatable: true, mandatory: false, readonly: false, type: 'select', name: 'end_month', label: 'Table.end_month', values: ConstantsService.monthsTab, fieldDisplayed: 'name', fieldReturned: 'value', forceTranslation: true, alreadyTranslated: false, nosort: true },
      { grid: 2, sortable: true, position: 2, creation: 5, singlerow: false, updatable: true, mandatory: false, readonly: false, type: 'input-number', name: 'end_year', label: 'Table.end_year', maxLength: '4' },
      { grid: 2, sortable: true, position: 2, creation: 6, singlerow: true, updatable: true, mandatory: false, readonly: false, type: 'textarea', name: 'description', label: 'Table.description' },

    ];

    tableMap.cv_jobs = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'job_type', label: 'Table.job_type' },
      { grid: 2, sortable: true, position: 2, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'employer', label: 'Table.employer' },
      { grid: 0, sortable: true, position: 1, creation: 3, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'select', name: 'start_month', label: 'Table.start_month', values: ConstantsService.monthsTab, fieldDisplayed: 'name', fieldReturned: 'value', forceTranslation: true, alreadyTranslated: false, nosort: true, nullable: true },
      // { grid: 1, sortable: true, position: 1, creation: 4, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'date', name: 'start_year', label: 'Table.start_year', views: ['year'], maxDate: new Date(), bindValue: "end_year", bindType: "max", maxDate: moment() },
      { grid: 2, sortable: true, position: 2, creation: 4, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input-number', name: 'start_year', label: 'Table.start_year', maxLength: '4' },
      { grid: 0, sortable: true, position: 1, creation: 5, singlerow: false, updatable: true, mandatory: false, readonly: false, type: 'select', name: 'end_month', label: 'Table.end_month', values: ConstantsService.monthsTab, fieldDisplayed: 'name', fieldReturned: 'value', forceTranslation: true, alreadyTranslated: false, nosort: true },
      { grid: 2, sortable: true, position: 2, creation: 6, singlerow: false, updatable: true, mandatory: false, readonly: false, type: 'input-number', name: 'end_year', label: 'Table.end_year', maxLength: '4' },
      { grid: 1, sortable: true, position: 1, creation: 7, singlerow: true, updatable: true, mandatory: false, readonly: false, type: 'textarea', name: 'description', label: 'Table.description' },

    ];

    tableMap.cv_skills = [
      { grid: 0, sortable: true, position: 0, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'autocomplete', name: 'ls_id', label: 'Section.skill', reducer: 'skills', route: 'skill', fieldDisplayed: 'name', fieldReturned: 'id' },
    ]

    tableMap.skill = [
      { grid: 1, sortable: true, position: 1, creation: 1, singlerow: false, updatable: true, mandatory: true, readonly: false, type: 'input', name: 'name', label: 'Table.skill' },
      { grid: 2, sortable: true, position: 1, creation: 2, singlerow: false, updatable: true, mandatory: true, readonly: false, type: "select", name: "type", label: "Table.type", values: ConstantsService.skills_type, fieldDisplayed: 'name', fieldReturned: 'value', forceTranslation: true, nullable: true },
    ]

    return tableMap;
  }

  /**
   * Method that returns the tables map specific for user's groups
   * @static
   * @public
   * @param {Number} groupIndex - The user group index
   * @return {Map} the tables map
   */
  static getTableMapByGroup(groupIndex) {
    const tableMap = TableMapService.getTableMap();

    switch (groupIndex) {
      default:
        break;
    }
    return tableMap;
  }
}
