import React, { useState, useEffect } from 'react';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

//UI
import { CCol, CRow } from '@coreui/react-pro';

//COMPONENTS
import UiCheckbox from './UiCheckbox';

const UiCheckboxGroup = (props) => {

  const [currentSelected, setCurrentSelected] = useState();
  const [allGroupSelected, setAllGroupSelected] = useState();
  const [values, setValues] = useState(props?.values);

  useEffect(() => {
    let values = (props.values && props.values.length > 0) ? cloneDeep(props.values) : (props.reducer && props.apiReducer && props.apiReducer[props.reducer].status === 'VALID') ? cloneDeep(props.apiReducer[props.reducer].data) : [];
    if (props.nullable && values.indexOf('null') < 0) {
      values.push('null');
    }
    setCurrentSelected(props.value ? props.value : []);
    setAllGroupSelected(!!(props.value && props.value.length === values.length));
    setValues(values);
  }, [props])


  function selectAllCheckboxGroup() {
    if (!props.disabled && props.onChange) {
      let value = [];
      let event = null;

      if (!allGroupSelected) {
        value = (props.fieldReturned) ? values.map((obj) => `${obj[props.fieldReturned]}`) : values;
        event = {
          target: {
            allSelected: true,
            name: props.name,
            value,
          },
        };
      } else {

        event = {
          target: {
            allSelected: false,
            name: props.name,
            value,
          },
        };
      }



      props.onChange(event);
    }
  }

  function handleOnChange(evt) {
    if (!props.disabled) {
      const localCurrentSelected = cloneDeep(currentSelected);

      let index = -1;
      const element = evt.target.name;
      const { value } = evt.target;

      if (!value) {
        index = localCurrentSelected.indexOf(element);
        if (index > -1) localCurrentSelected.splice(index, 1);
      } else {
        localCurrentSelected.push(element);
      }

      const event = {
        target: {
          name: props.name,
          value: localCurrentSelected,
        },
      };
      if (props.onChange) props.onChange(event);

      if (props.onBlur) {
        setTimeout(() => {
          props.onBlur(event);
        }, 500);
      }
    }
  }

  function calcValue(current, currentSelected, fieldReturned) {
    if (currentSelected !== undefined) {
      return (currentSelected.indexOf((fieldReturned) ? `${current[fieldReturned]}` : `${current}`) > -1);
    }
  }

  function handleOnFocus(evt) {
    if (!props.disabled && props.onFocus) {
      props.onFocus(evt);
    }
  }

  function buildCheckboxesArray(t) {
    return values.map((current, key) => {
      const labelValue = (props.fieldDisplayed) ? current[props.fieldDisplayed] : current;
      let labelTranslated = labelValue;
      if (!props.fieldDisplayed) {
        if (!props.customLabels) {
          labelTranslated = (labelValue.indexOf('Table.') !== 0 && labelValue.indexOf('Common.') !== 0 && !props.alreadyTranslated) ? `SelectValues.${labelValue}` : labelValue;
        } else {
          labelTranslated = (props.customLabels[labelValue]) ? props.customLabels[labelValue] : `SelectValues.${labelValue}`;
        }
      }
      return (
        <UiCheckbox key={`checkbox-group-${props.name}-${key}`} name={(props.fieldReturned) ? current[props.fieldReturned] : current}
          label={!props.alreadyTranslated ? t(`SelectValues.${labelTranslated}`) : labelTranslated} disabled={props.disabled} group
          value={calcValue(current, currentSelected, props.fieldReturned)}
          onFocus={handleOnFocus.bind(this)} onChange={handleOnChange.bind(this)} />
      );
    });
  }

  function renderGroupBody(t) {
    const groupBody = [];
    let columns = 1;
    let itemsForColumn = 0;
    let itemsInitialIndex = 0;

    const checkboxesArray = buildCheckboxesArray(t);

    if (props.columns) {
      columns = props.columns;
    }

    itemsForColumn = Math.ceil(checkboxesArray.length / columns);

    for (let columnCounter = 0; columnCounter < columns; columnCounter++) {
      groupBody.push(
        <CCol key={`checkbox-group-${props.name}-${columnCounter}`}>
          {checkboxesArray.slice(itemsInitialIndex, itemsInitialIndex + itemsForColumn)}
        </CCol>
      );
      itemsInitialIndex += itemsForColumn;
    }

    return <CRow style={{ marginTop: '10px', maxWidth: '50%' }}>{groupBody}</CRow>;
  }

  return (
    <I18n ns="translations">
      {t => (
        <>
          {props.inline ? (
            <div className="UiCheckboxGroup mt-2" style={props.style}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <UiCheckbox className="inline" label={props.label ? t(props.label) : ''} disabled={props.disabled}
                  required={props.required} value={allGroupSelected}
                  onChange={selectAllCheckboxGroup.bind(this)} />
                {buildCheckboxesArray(t)}
              </div>
            </div>
          ) : (
            <div className="UiCheckboxGroup" style={props.style}>
              <UiCheckbox label={props.label ? t(props.label) : ''} disabled={props.disabled}
                required={props.required} value={allGroupSelected}
                onChange={selectAllCheckboxGroup.bind(this)} />
              {renderGroupBody(t)}
            </div>
          )}
        </>
      )}
    </I18n>
  );

}

export default UiCheckboxGroup;
