import React, { useEffect, useState, useRef } from 'react';
import { CRow, CCol, CFormInput, CFormLabel, CTooltip, CInputGroup, CInputGroupText } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  title: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  display: PropTypes.string,
  plaintext: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
  tooltipMsg: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number,
  max: PropTypes.number,
  type: PropTypes.oneOf(['tel', 'text', 'email', 'password', 'number', 'currency', 'percentage']),
};

const defaultProps = { type: 'text' };

function UiInput(props) {

  const [touched, setTouched] = useState(false);
  const [invalid, setInvalid] = useState(props.required ? false : null);
  const [isChanging, setIsChanging] = useState(false);
  const [value, setValue] = useState('');

  let focusTarget = useRef();

  useEffect(() => {
    if (props.required && !props.value) {
      setInvalid(true);
    } else if (props.hasToBeEquals && props.value !== props.hasToBeEquals) {
      setInvalid(true);
    } else if (props.max && Number.parseFloat(props.value) > Number.parseFloat(props.max)) {
      setInvalid(true);
    } else if (props.min && Number.parseFloat(props.value) < Number.parseFloat(props.min)) {
      setInvalid(true);
    } else if (props.invalid) {
      setInvalid(props.invalid);
    } else {
      setInvalid(false);
    }

    let tmpValue = props.value;
    if (!!tmpValue && props.type === 'percentage') {
      tmpValue = tmpValue * 100;
    }
    if (!!tmpValue && props.type === 'currency') {
      tmpValue = parseFloat(Math.round(tmpValue * 100) / 100).toFixed(2);
    }
    tmpValue = (!!tmpValue || '' + tmpValue === '0') ? '' + tmpValue : '';
    if (props.type === 'number' || props.type === 'currency' || props.type === 'percentage') {
      tmpValue = tmpValue.replace(/[^\d.-]+/g, '').replace('.', ',');
    }
    setValue(tmpValue);

  }, [props])

  function handleOnFocus(evt) {
    if (!props.disabled) {
      if (props.onFocus) {
        props.onFocus(evt);
      }
      setInvalid(invalid);
      setIsChanging(true);
    }
  }

  function handleOnBlur() {
    if (!props.disabled) {
      let tmpValue = value;

      if (props.type === 'number' || props.type === 'currency' || props.type === 'percentage') {
        tmpValue = tmpValue.replace(/[^\d,-]+/g, '').replace(',', '.').replace(/,/g, '');
        if (props.type === 'currency') {
          tmpValue = (tmpValue && tmpValue.length > 0) ? parseFloat(Math.round(tmpValue * 100) / 100).toFixed(2) : null;
        } else if (props.type === 'number') {
          tmpValue = (tmpValue && tmpValue.length > 0) ? parseFloat(tmpValue) : null;
        } else if (props.type === 'percentage') {
          tmpValue = (tmpValue && tmpValue.length > 0) ? parseFloat(tmpValue) / 100 : null;
        }
      }

      const event = {
        target: {
          name: props.name,
          value: tmpValue,
        },
      };

      if (props.type === 'number' || props.type === 'currency' || props.type === 'percentage') {
        if (props.onChange) props.onChange(event);
      }
      if (props.onBlur) props.onBlur(event);
      if (!touched) {
        setTouched(true);
        setIsChanging(false);
      }
    }
  }

  function handleOnChange(evt) {
    if (!props.disabled) {
      const tmpValue = '' + evt.target.value;
      let tmpInvalid = false;

      if (props.required && !tmpValue) tmpInvalid = true;

      if (props.maxLength && tmpValue.length > props.maxLength) tmpInvalid = true;

      let event = evt/*{
        target: {
          name: evt.target.name,
          value,
        }
      }*/;

      setTouched(true);
      setInvalid(tmpInvalid);
      if (!tmpInvalid) {
        if (props.emitEvent) {
          const customEvent = new CustomEvent('inputChanged', { detail: event });
          document.dispatchEvent(customEvent);
        } else {
          if (props.onChange) props.onChange(event);
        }
      }
      setValue(tmpValue);
    }
  }

  function handleOnChangeNumber(evt) {
    if (!props.disabled) {
      let tmpValue = evt.target.value;
      let tmpInvalid = false;

      if (props.required && !tmpValue || Number.isNaN(tmpValue))
        tmpInvalid = true;
      if (props.max && tmpValue > Number.parseFloat(props.max)) {
        tmpInvalid = true;
      }
      if (props.min && tmpValue < Number.parseFloat(props.min)) {
        tmpInvalid = true;
      }

      setInvalid(tmpInvalid);
      setValue(tmpValue);
    }
  }

  function handleOnPureNumberChange(evt) {
    if (!evt?.target?.value?.includes('.') && !evt?.target?.value?.includes('-')) {
      const obj = {
        name: evt?.target?.name,
        value: evt?.target?.value
      }
      props.onChange(obj);
    } else {
      const obj = {
        name: evt?.target?.name,
        value: evt?.target?.value?.includes('.') ? evt?.target?.value?.split('.')[0] : evt?.target?.value?.split('-')[1]
      }
      props.onChange(obj);
    }

  }

  function renderCurrency(t) {
    return (
      <>
        <CFormInput ref={(Input) => focusTarget = Input}
          type="text" id={props.name} className="multipleInputContent currencyValue" name={props.name} disabled={props.disabled}
          title={props.title} placeholder={props.disabled ? '' : props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
          required={props.required} invalid={invalid} value={value} readOnly={props.readOnly} autoComplete={props.autoComplete}
          onFocus={handleOnFocus.bind(this)} onChange={handleOnChangeNumber.bind(this)} onBlur={handleOnBlur.bind(this)} />
        <CInputGroupText>{(props.suffix) ? props.suffix : '�'}</CInputGroupText>
      </>
    );
  }

  function renderNumber(t) {
    return (
      <>
        <CFormInput ref={(Input) => focusTarget = Input} maxLength={props.maxLength}
          type="text" id={props.name} className="multipleInputContent" name={props.name} disabled={props.disabled}
          title={props.title} placeholder={props.disabled ? '' : props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
          required={props.required} invalid={invalid} value={value} readOnly={props.readOnly} autoComplete={props.autoComplete}
          onFocus={handleOnFocus.bind(this)} onChange={handleOnChangeNumber.bind(this)} onBlur={handleOnBlur.bind(this)} />
        {props.suffix && (
          <CInputGroupText>{props.suffix}</CInputGroupText>
        )}
      </>
    );
  }

  function renderPercentage(t) {
    return (
      <>
        <CFormInput ref={(Input) => focusTarget = Input} maxLength={props.maxLength}
          type="text" id={props.name} className="multipleInputContent" name={props.name} disabled={props.disabled}
          title={props.title} placeholder={props.disabled ? '' : props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
          required={props.required} invalid={invalid} value={value} readOnly={props.readOnly} autoComplete={props.autoComplete}
          onFocus={handleOnFocus.bind(this)} onChange={handleOnChangeNumber.bind(this)} onBlur={handleOnBlur.bind(this)} />
        <CInputGroupText>{(props.suffix) ? props.suffix : '%'}</CInputGroupText>
      </>
    );
  }

  function renderText(t) {
    return (
      <>
        <CFormInput ref={(Input) => focusTarget = Input}
          type="text" id={props.name} className={`multipleInputContent ${props.className ? props.className : ''}`} name={props.name} disabled={props.disabled}
          title={props.title} placeholder={props.disabled ? '' : props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
          required={props.required} feedbackInvalid={props.feedbackInvalid} autoComplete={props.autoComplete}
          value={value} readOnly={props.readOnly} maxLength={props.maxLength || null}
          onFocus={handleOnFocus.bind(this)} onChange={handleOnChange.bind(this)} onBlur={handleOnBlur.bind(this)} invalid={props.invalid} />
        {props.suffix && (
          <CInputGroupText>{props.suffix}</CInputGroupText>
        )}
      </>
    );
  }

  function renderPassword(t) {
    return (
      <>
        <CFormInput type="password" id={props.name} className="multipleInputContent" name={props.name} disabled={props.disabled}
          maxLength={props.maxLength || null} title={props.title} placeholder={props.disabled ? '' : props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
          required={props.required} value={value} readOnly={props.readOnly} autoComplete={props.autoComplete}
          onFocus={handleOnFocus.bind(this)} onChange={handleOnChange.bind(this)} onBlur={handleOnBlur.bind(this)} invalid={props.invalid} feedbackInvalid={props.feedbackInvalid} />
        {props.suffix && (
          <CInputGroupText>{props.suffix}</CInputGroupText>
        )}
      </>
    );
  }

  function renderPureNumber(t) {
    return (
      <>
        <CFormInput
          type='number'
          title={props.title}
          name={props.name}
          disabled={props.disabled}
          placeholder={props.disabled ? '' : props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
          onChange={handleOnPureNumberChange.bind(this)}
          min={props.min}
          max={props.max}
          value={props.value}
          onKeyDown={props.handleKeyDown}
        />
        {props.suffix && (
          <CInputGroupText>{props.suffix}</CInputGroupText>
        )}
      </>
    )
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className={`UiInput ${props.className ? props.className : ''} ${(!!invalid && !!touched) ? 'is-invalid' : ''}`}>
          {props.inline ? (
            <CRow>
              <CCol>
                {props.label && (
                  <CFormLabel htmlFor={props.name} className="UiMainLabel">
                    {t(props.label)} {props.required ? '*' : ''}
                    {(props.type === 'currency' || props.tooltipMsg) && (
                      <span>
                        <CTooltip placement="top" content={props.tooltipMsg ? t(props.tooltipMsg) : t('Tooltips.currency_format_info')}>
                          <CIcon icon="cil-info-circle" size="sm" />
                        </CTooltip>
                      </span>
                    )}
                  </CFormLabel>
                )}
              </CCol>
              <CCol>
                <CInputGroup className={`multipleInputContainer ${(!!invalid && !!touched) ? ' is-invalid was-validated' : ''}`}>
                  {props.prefix && props.display !== 'text' && props.display !== 'password' && (
                    <CInputGroupText>{props.prefix}</CInputGroupText>
                  )}
                  {props.type === 'currency' && renderCurrency(t)}
                  {props.type === 'number' && renderNumber(t)}
                  {props.type === 'pure-number' && renderPureNumber(t)}
                  {props.type === 'percentage' && renderPercentage(t)}
                  {props.type === 'password' && renderPassword(t)}
                  {props.type !== 'currency' && props.type !== 'number' && props.type !== 'percentage' && props.type !== 'password' && props.type !== 'pure-number' && renderText(t)}
                </CInputGroup>

                {(!!invalid && !!touched) && (
                  <div className="invalid-feedback">{t('Common.field_invalid')}</div>
                )}
              </CCol>
            </CRow>
          ) : (
            <>
              {props.label && (
                <CFormLabel htmlFor={props.name} className="UiMainLabel">
                  {t(props.label)} {props.required ? '*' : ''}
                  {(props.type === 'currency' || props.tooltipMsg) && (
                    <span>
                      <CTooltip placement="top" content={props.tooltipMsg ? t(props.tooltipMsg) : t('Tooltips.currency_format_info')}>
                        <CIcon icon="cil-info-circle" size="sm" />
                      </CTooltip>
                    </span>
                  )}
                </CFormLabel>
              )}

              <CInputGroup className={`multipleInputContainer ${(!!invalid && !!touched) ? ' is-invalid was-validated' : ''}`}>
                {props.prefix && props.display !== 'text' && props.display !== 'password' && (
                  <CInputGroupText>{props.prefix}</CInputGroupText>
                )}
                {props.type === 'currency' && renderCurrency(t)}
                {props.type === 'number' && renderNumber(t)}
                {props.type === 'pure-number' && renderPureNumber(t)}
                {props.type === 'percentage' && renderPercentage(t)}
                {props.type === 'password' && renderPassword(t)}
                {props.type !== 'currency' && props.type !== 'number' && props.type !== 'percentage' && props.type !== 'password' && props.type !== 'pure-number' && renderText(t)}
              </CInputGroup>

              {(!!invalid && !!touched && props.type !== 'email') && (
                <div className="invalid-feedback">{t('Common.field_invalid')}</div>
              )}
            </>
          )}
        </div>
      )}
    </I18n>
  );

}

UiInput.propTypes = propTypes;
UiInput.defaultProps = defaultProps;

export default UiInput;
