import React from 'react';
import { CFormInput, CFormLabel, CTooltip } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import Multiselect from 'multiselect-react-dropdown';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import { useState, useEffect, useRef } from 'react';

function UiMultiSelect(props) {
  const [state, setState] = useState({})
  const UiInputChipComp = useRef()

  useEffect(() => {
    getTopInputChips()
    window.addEventListener('scroll', getTopInputChips.bind(this));
    return () => {
      window.removeEventListener('scroll', getTopInputChips.bind(this));
    }
  }, [])

  function getTopInputChips() {
    setState({
      ...state,
      offsetTop: UiInputChipComp.getBoundingClientRect().top + 300,
    });
  }

  useEffect(() => {
    setState(prevState => {
      const fieldDisplayed = (props.fieldDisplayed) ? props.fieldDisplayed : "label";
      const fieldReturned = (props.fieldReturned) ? props.fieldReturned : "value";
      const suggestions = [];
      const currentSelected = [];
      const currentSelectedValues = [];

      let values = cloneDeep(props.values);
      if (props.nullable === true && props.values && props.values.length > 0) {
        values.push("null");
      }

      if (values && values.length > 0) {
        values.forEach((suggestion) => {
          let sugg = {}
          if (props.fieldDisplayed) {
            sugg[fieldDisplayed] = (!props.alreadyTranslated) ? i18n.t(`SelectValues.${suggestion[fieldDisplayed]}`) : suggestion[fieldDisplayed];
            sugg[fieldReturned] = suggestion[fieldReturned];
          } else {
            sugg.label = (!props.alreadyTranslated) ? i18n.t(`SelectValues.${suggestion}`) : suggestion;
            sugg.value = suggestion;
          }
          suggestions.push(sugg);

          if (props.value && props.value.indexOf(sugg[fieldReturned]) > -1) {
            currentSelected.push(sugg);
            currentSelectedValues.push(sugg[fieldReturned]);
          }
        });
      }
      const newState = {
        fieldDisplayed,
        fieldReturned,
        currentSelected,
        currentSelectedValues,
        suggestions,
        values
      };

      return newState;
    })
  }, [props])

  function handleOnChange(selectedList, selectedItem) {
    if (!props.disabled) {
      const currentSelectedValues = [];
      selectedList.map((value) => {
        let objValue = null;
        let index = -1;
        index = state.suggestions.findIndex((obj) => obj[state.fieldReturned] === value[state.fieldReturned]);
        objValue = state.values[index];
        if (objValue) {
          if (typeof objValue === 'object') {
            currentSelected.push(objValue);
            currentSelectedValues.push(objValue[state.fieldReturned]);
          } else {
            currentSelectedValues.push(objValue);
          }
        }
      });

      const event = {
        target: {
          name: props.name,
          value: currentSelectedValues,
        },
      };
      if (props.onChange) props.onChange(event);

      if (props.onBlur && currentSelectedValues.length !== state.currentSelected.length) {
        setTimeout(
          () => {
            props.onBlur(event);
          },
          500,
        );
      }
    }
  }

  return (
    <I18n ns="translations">
      {(t) => (
        <div className="UiInputChip" ref={UiInputChipComp} onClick={getTopInputChips.bind(this)}>
          {props.label && (
            <CFormLabel htmlFor={props.name} className="UiMainLabel">
              {t(props.label)} {props.required ? '*' : ''}
              {(props.type === 'currency' || props.tooltipMsg) && (
                <span>
                  <CTooltip placement="top" content={props.tooltipMsg ? t(props.tooltipMsg) : t('Tooltips.currency_format_info')}>
                    <CIcon icon="cis-info-circle" size="sm" />
                  </CTooltip>
                </span>
              )}
            </CFormLabel>
          )}
          {props.disabled
            && <CFormInput type="text" className="UiInputChipDisabledPlaceholder" disabled value={(props.placeholder) ? t(props.placeholder) : `${t('Common.write')}...`} />}
          {!props.disabled
            && (
              <div className="UiContainerRelative">
                <Multiselect id={props.name} name={props.name} showCheckbox
                  placeholder={!!props.placeholder ? t(props.placeholder) : `${t('Common.write')}...`}
                  options={state.suggestions} // Options to display in the dropdown
                  selectedValues={state.currentSelected} // Preselected value to persist in dropdown
                  onSelect={handleOnChange.bind(this)} closeMenuOnSelect={false} // Function will trigger on select event
                  onRemove={handleOnChange.bind(this)} // Function will trigger on remove event
                  displayValue={state.fieldDisplayed} // Property name to display in the dropdown options
                />
              </div>
            )}
        </div>
      )}
    </I18n>
  );
}

export default UiMultiSelect
