// GLOBAL
import React, { Component } from 'react';
import { CRow, CButton, CCol } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

// SERVICES
import ConstantsService from '../../services/constantsService';
import TableMapService from '../../services/tableMapService';

// UTILS
import ComponentsUtils from '../../utils/componentsUtils';
import ObjectsUtils from '../../utils/objectsUtils';

// COMPONENTS
import UiCard from './UiCard';
import UiModal from './UiModal';
import UiCreationsModal from './UiCreationsModal';
import UiLoading from './UiLoading';

//TYPES
import { userType } from '../../types';
import { useState } from 'react';
import { useEffect } from 'react';

const propTypes = {
  currentUser: userType,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  icon: PropTypes.string,
  name: PropTypes.string,
  route: PropTypes.string,
  model: PropTypes.array,
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  deletable: PropTypes.bool,
  onExport: PropTypes.func,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  relatable: PropTypes.bool,
  renderRow: PropTypes.func,
  insertable: PropTypes.bool,
  onDownload: PropTypes.func,
  cardIsOpen: PropTypes.bool,
  exportable: PropTypes.bool,
  collapsible: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  loadingModal: PropTypes.bool,
  exportData: PropTypes.object,
  errorsModal: PropTypes.array,
  apiReducer: PropTypes.object,
  envContextLink: PropTypes.string,
  envContext: PropTypes.object,
  envCreationRoute: PropTypes.string,
  onSearchChange: PropTypes.func,
  isCreationValid: PropTypes.func,
  relationFields: PropTypes.array,
  toggleExportModal: PropTypes.func,
  isDeleteModalOpen: PropTypes.bool,
  isExportModalOpen: PropTypes.bool,
  localFilterName: PropTypes.string,
  childIdentifier: PropTypes.string,
  toggleDeleteModal: PropTypes.func,
  downloadPathField: PropTypes.string,
  creationCustomFields: PropTypes.array,
  customDeleteModalAlert: PropTypes.string,
  listRowClassName: PropTypes.string,
  rowContentClass: PropTypes.string,
  rowActionClass: PropTypes.string,
  scrollable: PropTypes.bool,
  apiReducerName: PropTypes.string
};

const TableStructure = ({ scrollable, children }) => {
  if (scrollable) {
    return (
      <div style={{ overflow: 'auto', width: '100%', height: 'auto' }}>
        <div className="relative-scroll-card" style={{ minWidth: 900 }}>
          {children}
        </div>
      </div>
    );
  }
  return children;
};

function UiRelationBox(props) {

  const [cardIsOpen, setCardIsOpen] = useState(props.cardIsOpen ? props.cardIsOpen : false);
  const [propsCardIsOpen, setPropsCardIsOpen] = useState(props.cardIsOpen);
  const [model, setModel] = useState([]);
  const [fieldsMap, setFieldsMap] = useState();
  const [filter, setFilter] = useState();
  const [localFilter, setLocalFilter] = useState({});
  const [exportFilter, setExportFilter] = useState({});
  const [currentItem, setCurrentItem] = useState(props.creationModel);
  const [modalFields, setModalFields] = useState([]);

  useEffect(() => {
    const oldVal = (propsCardIsOpen !== null && propsCardIsOpen !== undefined) ? propsCardIsOpen : null;
    const newVal = (props.cardIsOpen !== null && props.cardIsOpen !== undefined) ? props.cardIsOpen : null;
    if (oldVal !== newVal) {
      setCardIsOpen((props.cardIsOpen) ? props.cardIsOpen : false);
    }
    setPropsCardIsOpen(props.cardIsOpen);
    let fieldsM = null;
    if (props.insertable || props.editable) {
      fieldsM = (props.creationCustomFields) ? props.creationCustomFields : (props.currentUser && props.currentUser.userId !== null) ? TableMapService.getTableMapByGroup(props.currentUser.userGroup)[props.route] : null;
    } else if (props.relatable) {
      fieldsM = (props.relationFields) ? props.relationFields : (props.currentUser && props.currentUser.userId !== null) ? TableMapService.getTableMapByGroup(props.currentUser.userGroup)[props.route] : null;
    }
    setFieldsMap(fieldsM);

    let newLocalFilter = null;
    let newExportFilter = null;
    if (props.localFilterName && (fieldsMap || props.localFilterField)) {
      newLocalFilter = (props.localFilterField) ? props.localFilterField : fieldsMap.filter((obj) => obj.name === props.localFilterName)[0];

      if (props.exportable) {
        newExportFilter = cloneDeep(newLocalFilter);
        newExportFilter.type = 'checkbox-group';
        newExportFilter.columns = 3;
      }
    }
    setLocalFilter(newLocalFilter);
    setExportFilter(newExportFilter);

    let newModel = cloneDeep(props.model);
    if (newModel && filter && filter.value) {
      if (newLocalFilter.type === 'input') {
        newModel = newModel.filter((obj) => obj[props.localFilterName].toLowerCase().includes(filter.value.toLowerCase()) === true);
      } else if (newLocalFilter.type === 'checkbox-group' || newLocalFilter.type === 'chip' || newLocalFilter.type === 'multiselect') {
        if (filter.value.indexOf('null') >= 0) {
          newModel = newModel.filter((obj) => !obj[props.localFilterName] || filter.value.indexOf(obj[props.localFilterName]) >= 0);
        } else {
          newModel = newModel.filter((obj) => filter.value.indexOf(obj[props.localFilterName]) >= 0);
        }
      } else if (Number.isNaN(parseInt(filter.value))) {
        if (filter.value === 'null') {
          newModel = newModel.filter((obj) => !obj[props.localFilterName]);
        } else {
          newModel = newModel.filter((obj) => obj[props.localFilterName] === filter.value);
        }
      } else {
        newModel = newModel.filter((obj) => parseInt(obj[props.localFilterName]) === parseInt(filter.value));
      }
    }
    setModel(newModel);
  }, [props])

  function calculateModalFields() {
    let newModalFields = (fieldsMap) ? cloneDeep((currentItem && ObjectsUtils.isIdValid(currentItem[(props.childIdentifier) ? props.childIdentifier : ConstantsService.defaultDBIdentifier])) ? fieldsMap.filter((obj) => obj.updatable) : fieldsMap.filter((obj) => obj.creation > 0)) : [];
    if (props.envContextLink && (props.envContext || props.envCreationRoute)) {
      newModalFields.map((obj) => {
        const field = obj;
        if (field.name === props.envContextLink) {
          field.envContext = props.envContext;
          field.envCreationRoute = props.envCreationRoute;
        }
        return field;
      });
    }

    if (!!fieldsMap && currentItem && ObjectsUtils.isIdValid(currentItem[(props.childIdentifier) ? props.childIdentifier : ConstantsService.defaultDBIdentifier])) {
      newModalFields.map((v, index) => v.creation = (v.creation) ? v.creation : fieldsMap.length + index);
    }

    setModalFields(newModalFields);
  }

  function toggleModal() {
    calculateModalFields();
    props.toggleModal();
  }

  function handleOnChange(event) {
    if (!props.disabled) {
      if (event.target && event.target.name) {
        setCurrentItem(prevState => {
          let newModel = cloneDeep(prevState);
          eval('newModel.' + event.target.name + ' = event.target.value');
          return newModel;
        });
        if (props.onChange) props.onChange(event);
      } else {
      }
    }
  }

  function handleAdd() {
    if (!props.disabled) {
      setCardIsOpen(true);
      setCurrentItem(props.creationModel ?? {});
      setFilter();
      setExportFilter({});
      toggleModal();
    }
  }

  function handleEdit(issue) {
    if (!props.disabled) {
      if(props.handleCurrentItem) props.handleCurrentItem(issue);
      props?.creationModel ? setCurrentItem(Object.assign(props.creationModel, issue)) : setCurrentItem(issue);
      toggleModal();
    }
  }

  function handleView(doc) {
    if (doc[props.downloadPathField]) {
      window.open(doc[props.downloadPathField], '_blank');
    }
  }

  function handleDownload(doc) {
    if (doc[props.downloadPathField]) {
      ObjectsUtils.toDataURL(doc[props.downloadPathField]).then((response) => {
        if (response) {
          const a = document.createElement('a');
          a.href = response;
          a.download = doc[props.downloadNameField];
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      });
    }
  }

  function handleDelete(issue) {
    if (!props.disabled) {
      setCurrentItem(issue);
      props.toggleDeleteModal();
    }
  }

  function handleLocalFilter(event) {
    setFilter(event.target);
    setCardIsOpen(true);
  }

  function handleExportFilter(event) {
    setExportFilter({ [event.target.name]: event.target.value });
  }

  function renderCounter(current, total, t) {
    return (
      <CRow>
        <CCol style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px' }}>{t('Common.show_counter', { current, total })}</CCol>
      </CRow>
    );
  }

  return (
    <I18n ns="translations">
      {t => (
        <UiCard className={`card-shadow ${props?.coloredHeader ? 'colored-header' : 'no-colored-header'}`} icon={(props.icon) ? props.icon : null} title={props.title} disabled={props.disabled}
          collapsible={props.collapsible} isOpen={cardIsOpen} cardId={props.cardId} onToggleCard={props.onToggleCard}
          insertable={props.insertable || props.relatable} exportable={props.exportable} onClick={handleAdd.bind(this)}
          localFilter={localFilter} apiReducer={props.apiReducer} styleCard={props?.styleCard}
          onLocalFilter={handleLocalFilter.bind(this)} onExport={(props.toggleExportModal) ? props.toggleExportModal.bind(this) : null}>
          {!!model && model.length > 0 ? (
            <>
              {props.renderHeaderLegend && typeof props.renderHeaderLegend === 'function' && (
                <>{props.renderHeaderLegend()}</>
              )}
              <TableStructure scrollable={props.scrollable}>
                {props.renderHeader && (
                  <>{props.renderHeader()}</>
                )}
                {model.map((issue, index) => {
                  const row = props.renderRow(issue, index, handleEdit.bind(this, issue), handleDelete.bind(this, issue));
                  if (!row) return null;
                  return (
                    <CRow key={`relation-box-${props.name}-${index}`} className={`relation-box-table ${props.listRowClassName}`}>
                      <CCol className={props.rowContentClass}>
                        {props.renderRow(issue, handleEdit.bind(this, issue), handleDelete.bind(this, issue))}
                        {props.editable && (
                          <CButton variant="ghost" color="primary" disabled={props.disabled}
                            title={t('Common.edit')} onClick={handleEdit.bind(this, issue)}>
                            <CIcon icon="cis-pencil" />
                          </CButton>
                        )}
                        {props.downloadPathField && (
                          <CButton variant="ghost" color="primary"
                            title={t('Common.download')} onClick={handleDownload.bind(this, issue)}>
                            <CIcon icon="cis-cloud-download" />
                          </CButton>
                        )}
                        {props.deletable && (
                          <CButton variant="ghost" color="danger" disabled={props.disabled}
                            title={t('Common.delete')} onClick={handleDelete.bind(this, issue)}>
                            <CIcon icon="cis-trash" />
                          </CButton>
                        )}
                      </CCol>
                    </CRow>
                  );
                })}
              </TableStructure>
              {props.model && props.counter && renderCounter(model.length, props.model.length, t)}
            </>
          ) :
            <>
              {
                eval(`props.apiReducer?.${props.apiReducerName}?.status === 'SERVER_INVALID'`) ?
                  <p>{t('Common.network_error_label')}</p>
                  : (!!model || model?.length <= 0) ?
                    <p>{t('Common.no_data')}</p>
                    :
                    <UiLoading />
              }
            </>
          }

          {(props.insertable || props.editable) && (
            <UiCreationsModal title={(currentItem && ObjectsUtils.isIdValid(currentItem[(props.childIdentifier) ? props.childIdentifier : ConstantsService.defaultDBIdentifier])) ? t(`Common.edit_${props.route}`) : t(`Common.add_${props.route}`)}
              model={currentItem} currentUser={props.currentUser} envContextLink={props.envContextLink} envContext={props.envContext}
              updating={(currentItem && ObjectsUtils.isIdValid(currentItem[(props.childIdentifier) ? props.childIdentifier : ConstantsService.defaultDBIdentifier]))} isOpen={props.isModalOpen} isValid={props.isCreationValid} loadingModal={props.loadingModal} errorsModal={props.errorsModal}
              fields={(currentItem && ObjectsUtils.isIdValid(currentItem[(props.childIdentifier) ? props.childIdentifier : ConstantsService.defaultDBIdentifier])) && !!props.customFields ? props.customFields : modalFields} apiReducer={props.apiReducer} onSearchChange={props.onSearchChange.bind(this)} onChange={handleOnChange.bind(this)}
              onSubmit={(currentItem && ObjectsUtils.isIdValid(currentItem[(props.childIdentifier) ? props.childIdentifier : ConstantsService.defaultDBIdentifier]) && props.onEdit) ? props.onEdit.bind(this) : props.onAdd.bind(this)} onCancel={props.toggleModal.bind(this)} />
          )}

          {props.relatable && (
            <UiCreationsModal title={(currentItem && ObjectsUtils.isIdValid(currentItem[(props.childIdentifier) ? props.childIdentifier : ConstantsService.defaultDBIdentifier])) ? t(`Common.edit_${props.route}`) : t(`Common.add_${props.route}`)}
              model={currentItem} currentUser={props.currentUser} envContextLink={props.envContextLink} envContext={props.envContext}
              updating={(currentItem && ObjectsUtils.isIdValid(currentItem[(props.childIdentifier) ? props.childIdentifier : ConstantsService.defaultDBIdentifier]))} isOpen={props.isModalOpen} loadingModal={props.loadingModal} errorsModal={props.errorsModal}
              fields={modalFields} apiReducer={props.apiReducer} onSearchChange={props.onSearchChange.bind(this)}
              onSubmit={(currentItem && ObjectsUtils.isIdValid(currentItem[(props.childIdentifier) ? props.childIdentifier : ConstantsService.defaultDBIdentifier]) && props.onEdit) ? props.onEdit.bind(this) : props.onAdd.bind(this)} onCancel={props.toggleModal.bind(this)} />
          )}

          {props.deletable && (
            <UiModal title={t(`Common.delete_${props.route}`)} okLabel={t('Common.yes')} koLabel={t('Common.no')}
              isOpen={props.isDeleteModalOpen} loadingModal={props.loadingModal} errorsModal={props.errorsModal}
              onSubmit={props.onDelete.bind(this, currentItem)} onCancel={props.toggleDeleteModal.bind(this)}>
              <p>{t((props.customDeleteModalAlert) ? props.customDeleteModalAlert : 'Common.are_you_sure')}</p>
            </UiModal>
          )}

          {props.exportable && exportFilter && (
            <UiModal title={t('Common.export')} okLabel={(props.exportData && props.exportData.url) ? t('Common.download') : t('Common.ok')}
              isOpen={props.isExportModalOpen} loadingModal={props.loadingModal} errorsModal={props.errorsModal}
              valid={exportFilter && exportFilter.linked_document_type_id && exportFilter.linked_document_type_id.length > 0}
              onSubmit={(props.exportData && props.exportData.url) ? props.onDownload.bind(this) : props.onExport.bind(this, exportFilter)} onCancel={props.toggleExportModal.bind(this)}>
              {(!props.exportData || !props.exportData.url)
                && (
                  <div>
                    {ComponentsUtils.renderByType(false, false, false, exportFilter, exportFilter, handleExportFilter.bind(this), props.onSearchChange.bind(this), props.apiReducer, props.currentUser)}
                  </div>
                )}
              {props.exportData && props.exportData.url
                && <p>{t('Common.export_download_success')}</p>}
            </UiModal>
          )}
        </UiCard>
      )}
    </I18n>
  );
}

UiRelationBox.propTypes = propTypes;

UiRelationBox.defaultProps = {
  listRowClassName: '',
  rowContentClass: 'flexComponent',
  rowActionClass: 'flexComponentNoGrow',
  scrollable: false,
};

export default UiRelationBox;
