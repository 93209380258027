import BaseService from './baseService';

export default class SkillsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!SkillsService.instance) {
      SkillsService.instance = new SkillsService(store);
    }
    return SkillsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.LOGIN_PATH;
    super(store, baseUrl, 'skills', 'skill');
  }

  getList(obj, lang, token, okCallBack, koCallBack) {
    return super.get(this.listName, 'listed_skills', lang, token, obj, okCallBack, koCallBack);
  }

  getItem(contentId, lang, token, okCallBack, koCallBack, firstError) {
    return super.get(this.itemName, 'listed_skills/' + contentId, lang, token, null, okCallBack, koCallBack, firstError);
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    return super.post(this.itemName, 'listed_skills', lang, token, obj, okCallBack, koCallBack);
  }

  deleteItem(contentId, lang, token, okCallBack, koCallBack) {
    return super.delete(this.itemName, 'listed_skills/' + contentId, lang, token, okCallBack, koCallBack);
  }

  updateItemCustomId(contentId, obj, lang, token, okCallBack, koCallBack) {
    return super.put(this.itemName, 'listed_skills/' + contentId, lang, token, obj, okCallBack, koCallBack);
  }

}
