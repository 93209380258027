import React, { useState, useEffect } from "react";

//i18n
import { I18n } from 'react-i18next';

//mui
import { Slider, Typography } from "@mui/material";

function UiRangeSlider(props) {

    function handleOnChange(evt) {
        if (props.onChange) props.onChange(evt)
    }

    function setMinValue() {
        if (props.hanleMinChange) props.handleMinChange();
    }

    function setMaxValue() {
        if (props.hanleMinChange) props.handleMaxChange();
    }


    return (
        <I18n ns="translations">
            {
                t => (
                    <>
                        <Typography id="track" gutterBottom className="fw-bold">
                            {t(`Common.${props.label}`)}
                        </Typography>
                        <Slider
                            name='slider'
                            min={props.min}
                            max={props.max}
                            step={props.step}
                            disabled={props.disabled}
                            valueLabelDisplay="auto"
                            aria-labelledby="track"
                            onChange={handleOnChange}
                            value={props.value ?? null}
                            sx={{ color: props.color }}
                            style={props.style}
                        />
                        {
                            props.showMarksPlacement && (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p
                                        onClick={setMinValue}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {props.min}
                                    </p>
                                    <p
                                        onClick={setMaxValue}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {props.max} Km
                                    </p>
                                </div>
                            )
                        }
                    </>
                )
            }
        </I18n>
    )
}

export default UiRangeSlider;