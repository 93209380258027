// GLOBAL
import React from 'react';

//REDUX
import { connect } from 'react-redux';

//UTILS
import { mapStateToProps } from './AbstractComponent';
import withRouter from '../../utils/withNavigation';
import ConstantsService from '../../services/constantsService';
//VIEWS
import AbstractDetail from './AbstractDetail';

//CORE UI 
import { CCard, CCardBody, CRow, CCol, CFormLabel } from '@coreui/react-pro';

//COMPONENTS
import UiInput from '../ui/UiInput';
import UiSelectClassic from '../ui/UiSelectClassic';

class Skill extends AbstractDetail {
  constructor(props) {
    super(props);
  }


  renderChildren(t) {
    return (
      <>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12" md="6" lg="6" xl="6">
                <UiInput type="text" name="name" label="Table.name" disabled={!this.state.editable}
                  value={this.state.model.name} required={this.state.mandatoryFields.indexOf('name') >= 0}
                  onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)} />
              </CCol>
              <CCol sm="12" md="6" lg="6" xl="6" className='rightCol'>
                <UiSelectClassic name='type' label={'Table.type'}
                  value={this.state?.model?.type}
                  values={ConstantsService.skills_type}
                  onValueChanged={this.handleOnChange.bind(this)}
                  onChange={this.handleOnChange.bind(this)}
                  fieldDisplayed="name" fieldReturned="value"
                  forceTranslation={true}
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </>
    )
  }

  render() {
    return super.render();
  }

}

export default connect(mapStateToProps)(withRouter(Skill));