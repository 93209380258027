// GLOBAL
import React from 'react';

//REDUX
import { connect } from 'react-redux';
import store from '../../redux/store';

//UTILS
import { mapStateToProps } from './AbstractComponent';
import withRouter from '../../utils/withNavigation';
import ConstantsService from '../../services/constantsService';
import UiSelectClassic from '../ui/UiSelectClassic';
import _ from 'lodash';

//VIEWS
import AbstractDetail from './AbstractDetail';

//CORE UI 
import { CCard, CCardBody, CRow, CCol, } from '@coreui/react-pro';

//COMPONENTS
import UiInput from '../ui/UiInput';
import UiCheckboxGroup from '../ui/UiCheckboxGroup';

//SERVICES
import RolesService from '../../services/rolesService';

class Role extends AbstractDetail {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      currentRole: {
        name: "",
        id: "",
        privileges: []
      }
    };

    this.allSelected = false;
    this.RolesService = RolesService.getInstance(store);
  }

  componentDidMount() {
    console.log("MOUNTED");
    this.RolesService.getList({}, this.globalService.currentLanguage, this.globalService.currentUser, this.setData.bind(this))
  }

  setData(data) {
    console.log("DATA", data)
    if (data.data) {
      this.setState({
        roles: data.data
      })
    }
  }


  handleOnChangeCheckbox(evt) {

    this.allSelected = evt?.target?.allSelected;
    this.handleOnChange(evt);
  }

  handleOnChangeRole(evt) {
    console.log("EVT", evt);
    this.setState({
      currentRole: evt.target.value
    })
    // this.handleOnChange(evt);
  }


  renderChildren(t) {
    return (
      <>
          <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="12" md="12" lg="12" xl="12" className='rightCol'>
                <h3 style={{textAlign: 'center'}}>{t('Common.scopes')}</h3>
                <UiCheckboxGroup
                  name="privileges"
                  values={ConstantsService.scopes}
                  label={!this.allSelected ? "Common.select_all" : "Common.uncheck_all"}
                  inline={false}
                  value={this.state?.model?.privileges}
                  onChange={this.handleOnChangeCheckbox.bind(this)}
                  style={{ marginBottom: '20px' }}
                  disabled={false}
                  alreadyTranslated
                  columns={3}
                />
              </CCol>

            </CRow>
          </CCardBody>
        </CCard>
      </>
    )
  }

  render() {
    return super.render();
  }

}

export default connect(mapStateToProps)(withRouter(Role));