//GLOBAL
import React from 'react'
import { connect } from 'react-redux';

//UI
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro';

//UTILS
import withRouter from '../../utils/withNavigation';
import ConstantsService from '../../services/constantsService';
import EmployeesService from '../../services/employeesService';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiSelectClassic from '../ui/UiSelectClassic';
import UiPictureUpload from '../ui/UiPictureUpload';
import UiSortableGrid from '../ui/UiSortableGrid';
import UiTranslationsTabs from '../ui/UiTranslationsTabs';

class Employee extends AbstractDetail {

  constructor(props) {
    super(props);

    this.state.orderedEmployees = [];
    this.state.language = 'it';
    this.state.translations = [
      { locale: 'it' },
      { locale: 'en' },
    ];
  }

  populateServices() {
    if (!this.globalService.currentUser || !(this.globalService.currentUser.userId !== 0)) {
      return;
    }

    if (!this.sectionService) {
      super.populateServices();
      this.populateReducers({ filter: [] }, EmployeesService, 'employees', this.initEmployees.bind(this));
    } else {
      // this.sectionService.getList({ filter: [] }, this.globalService.currentLanguage, this.globalService.currentLanguage, this.initEmployees.bind(this))
    }
  }


  initEmployees(link, response) {
    let employees = (response?.data) ? cloneDeep(response.data) : [];
    employees = employees.sort((a, b) => a.v_order - b.v_order);
    this.setState({ employees: employees, orderedEmployees: (employees) ? employees.map((obj) => '' + obj.id) : [] });
  }

  handleOnOrderChange(evt) {
    if (evt?.target?.value && !isEqual(this.state.orderedEmployees, evt.target.value)) {
      const new_employees = [];
      for (let index of evt.target.value) {
        new_employees.push(this.state.employees.find(obj => '' + obj.id === '' + index));
      }
      this.setState({ employees: new_employees, orderedEmployees: evt.target.value });
      let body = evt.target.value.map((obj) => { return { id: obj }; })
      this.sectionService.orderItems(body, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallback.bind(this), this.koCallback.bind(this));
    }
  }

  renderChildren(t) {
    return (
      <>
        <UiCard className="mt-4 colored-header" icon="cis-person" title={t('Table.main_data')} collapsible={false}>
          <CRow >
            <CCol sm="12" md="6" lg="6" xl="6" className={'mb-4'}>
              <UiInput type="text" name="name" label="Table.name"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('name') >= 0}
                value={this.state.model.name}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
            <CCol sm="12" md="6" lg="6" xl="6" className="rightCol mb-4">
              <UiInput type="text" name="surname" label="Table.last_name"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('surname') >= 0}
                value={this.state.model.surname}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
          </CRow>
          <UiTranslationsTabs
            tabs={this.state.translations}
            activeTab={this.state.language}
            disabled={true}
            onTab={(locale) => this.setState({ language: locale })}
          >
            <div>
              {this.state.model.translations && this.state.model.translations.map((translation, index) => (
                <div id={translation.language} key={translation.language} >
                  <CRow >
                    <CCol sm="12" md="6" lg="6" xl="6">
                      <UiInput type="text" name="role" label="Table.role"
                        disabled={!this.state.editable}
                        required={this.state.mandatoryFields.indexOf('role') >= 0}
                        value={translation.role}
                        onFocus={this.handleOnFocus.bind(this)}
                        onChange={this.handleOnChange.bind(this)}
                        onBlur={this.handleOnBlur.bind(this)}
                      />
                    </CCol>
                    <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
                      <UiInput type="text" name="description" label="Table.sentence"
                        disabled={!this.state.editable}
                        required={this.state.mandatoryFields.indexOf('description') >= 0}
                        value={translation.description}
                        onFocus={this.handleOnFocus.bind(this)}
                        onChange={this.handleOnChange.bind(this)}
                        onBlur={this.handleOnBlur.bind(this)}
                      />
                    </CCol>
                  </CRow>
                  {/* <CRow>
                    <UiSelectClassic name='language' label={'Table.language'}
                      value={translation.language}
                      fieldDisplayed={"name"} fieldReturned={"iso2"}
                      values={ConstantsService.iso6933LangList}
                      nosort={true}
                      onValueChanged={(evt) => {
                        this.handleOnChange(evt)
                      }}
                      onChange={(evt) => {
                        this.handleOnChange(evt)
                      }}
                      forceTranslation alreadyTranslated={false}
                      disabled
                    />
                  </CRow> */}
                </div>
              ))}
            </div>
          </UiTranslationsTabs>
        </UiCard>
        <UiCard className="mt-4 colored-header" icon="cis-person" title={t('Table.pic_url')} collapsible={false}>
          <CRow>
            <CCol sm="12" md="12" lg="12" xl="12">
              <UiPictureUpload name='picture_classname'
                disabled={!this.state.editable}
                value={this.state.model.picture_url}
                onChange={this.onNewImageChange.bind(this, this.state.model, 'picture_classname')}
                onDelete={this.onImageDelete.bind(this, this.itemId)}
                deletable fullScreenEnabled editable
              />
            </CCol>
          </CRow>
        </UiCard>
        <UiCard className="mt-4 colored-header" icon="cis-person" title={t('Table.order')} collapsible={false}>
          <UiSortableGrid
            type="text" name="prova" label="Table.order" columns={4} sortValue={'v_order'}
            value={this.state.orderedEmployees} required alreadyTranslated fieldDisplayed={'name'} fieldReturned={'id'}
            values={this.state.employees}
            onChange={this.handleOnOrderChange.bind(this)}
          />
        </UiCard>
      </>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Employee));
