// GLOBAL
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CRow, CCol, CCard, CFormInput, CButton, CCardBody, CCardGroup, CContainer, CInputGroup, CInputGroupText, CFooter, } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { cilLockLocked, cilUser } from '@coreui/icons'
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';

// STORE
import store from '../../redux/store';

// UTILS
import withRouter from '../../utils/withNavigation';
import ObjectsUtils from '../../utils/objectsUtils';

// SERVICES
import JWTService from '../../services/jwtService';
import GlobalService from '../../services/globalService';
import SiteMapService from '../../services/siteMapService';
import ConstantsService from '../../services/constantsService';

// COMPONENTS
import { mapStateToProps } from './AbstractComponent';

import UiLoading from '../ui/UiLoading';

import logo from '../../../images/logo.svg';
import logoWhite from '../../../images/logoWhite.svg';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.oauthService = JWTService.getInstance(store, process.env.LOGIN_PATH, process.env.CLIENT_ID, process.env.CLIENT_SECRET, localStorage, process.env.BASENAME);

    this.globalService = GlobalService.getInstance(store, localStorage, ConstantsService.defaultLanguage, SiteMapService.getSiteMap());



    this.state = {
      token: null,
      error: null,
      type: null,
      confirm_password: '',
      password: '',
      passwordError: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.state.token && nextProps.location?.pathname) {
      let params = ObjectsUtils.getUrlParamsFromFormat("/change_password/accept/:type/:token", nextProps.location.pathname);
      let { type, token } = params;
      if (!this.state.token && token) {
        token = decodeURIComponent(token.replaceAll('%20', '.'));
        this.setState({ type, token });
      }
      return false;
    }
    return true;
  }

  changeHandler(target, event) {
    const newState = cloneDeep(this.state);
    if (target === "confirm_password") {
      if (event.target.value !== this.state.password) {
        newState['passwordError'] = true;
      } else {
        newState['passwordError'] = false;
      }
    }
    newState[target] = event.target.value;
    this.setState(newState);
  }

  handleOnClick() {
    this.setState({ loading: true, error: null, success: null });
    function okCallback(success) {
      this.setState({ loading: false, error: null });
      this.props.navigate('/login');
    }

    function koCallback(error) {
      let msg = ObjectsUtils.buildError(error);

      this.setState({ loading: false, error: msg, success: null });
    }

    this.oauthService.recoverPassword(this.state.token, this.state.password, okCallback.bind(this), koCallback.bind(this));
  }

  changeLanguage(lang, evt) {
    this.globalService.changeLanguage(lang);
    i18n.changeLanguage(lang);
  }

  changeLanguage(lang, evt) {
    this.globalService.changeLanguage(lang);
    i18n.changeLanguage(lang);
  }

  render() {
    return (
      <I18n ns="translations">
        {(t, { i18n }) => (
          <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
              <CRow className="justify-content-center">
                <CCol sm="10" md="10" lg="10" xl="10">
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody className="p-4">
                        <CRow>
                          <CCol>
                            <img src={logo} alt="Beije - People First" className="login-brand-logo" />
                          </CCol>
                        </CRow>
                        <CRow className='pb-4'>
                          {this.state.error && <p className="error">{t('Login.error_psw')}</p>}
                          <CCol sm="9" md="9" lg="9" xl="9">
                            <h3>{t('Login.confirm_password')}</h3>
                          </CCol>
                          <CCol sm="3" md="3" lg="3" xl="3">
                            <CCol className='d-flex justify-content-end align-items-start'>
                              <div className='d-flex align-items-center'>
                                <CIcon size='xl' icon={'cif-it'} className={this.globalService.currentLanguage === 'it' ? 'm-2' : 'opacity-50 m-2'} onClick={this.changeLanguage.bind(this, 'it')} />
                                |
                                <CIcon size='xl' icon={'cif-gb'} className={this.globalService.currentLanguage === 'en' ? 'm-2' : 'opacity-50 m-2'} onClick={this.changeLanguage.bind(this, 'en')} />
                              </div>
                            </CCol>
                          </CCol>
                        </CRow>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <CFormInput type="password" placeholder={t('Login.password')} value={this.state.password}
                            onChange={this.changeHandler.bind(this, 'password')} />
                        </CInputGroup>
                        <CInputGroup>
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <CFormInput type="password" placeholder={t('Login.confirm_password')} value={this.state.confirm_password} invalid={this.state.passwordError} feedbackInvalid={t('Login.restore_password_mismatch')}
                            onChange={this.changeHandler.bind(this, 'confirm_password')} />
                        </CInputGroup>
                        <CRow className='justify-content-center mt-4'>
                          <CCol sm={6} md={6} lg={6} xl={6} className="d-flex align-items-center justify-content-center justify-content-sm-start login-btn-container">
                            <CButton className="px-4 login-btn" color="primary" disabled={!this.state.password || !this.state.confirm_password || this.state.passwordError}
                              onClick={this.handleOnClick.bind(this)}>
                              {t('Login.restore_password_btn')}
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        )}
      </I18n>
    );
  }
}

export default connect(mapStateToProps)(withRouter(ChangePassword));
