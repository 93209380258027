import BaseService from './baseService';

export default class JobApplicationsService extends BaseService {
  static instance = null;

  static getInstance(store) {
    if (!JobApplicationsService.instance) {
      JobApplicationsService.instance = new JobApplicationsService(store);
    }
    return JobApplicationsService.instance;
  }

  constructor(store) {
    const baseUrl = process.env.API_PATH;
    super(store, baseUrl, 'job_applications', 'job_application');
  }

  addItem(obj, lang, token, okCallBack, koCallBack) {
    let newObj = { ... obj};
    newObj.academy = obj.academy ?? false;
    newObj.title_en = obj.title_it;
    newObj.description_it = '';
    newObj.description_en = '';
    return super.post(this.itemName, this.urlPath, lang, token, newObj, okCallBack, koCallBack);
  }

  duplicateItem(obj, lang, token, okCallBack, koCallBack) {
    const newObj = {
      academy: obj.academy,
      description_it: obj.description_it,
      description_en: obj.description_en,
      mode: obj.mode,
      type: obj.type,
      title_it: obj.title_it + ' (copia)',
      title_en: obj.title_en + ' (copy)',
    }
    return super.post(this.itemName, this.urlPath, lang, token, newObj, okCallBack, koCallBack);
  }

  reactivateItem(contentId, obj, lang, token, okCallBack, koCallBack) {
    let newObj = { ... obj};
    newObj.disable_date = null;
    function updateKoCallBack(error) {
      this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
    }
    return super.put(this.itemName, this.urlPath + '/' + contentId, lang, token, newObj, okCallBack, updateKoCallBack.bind(this));
  }
}
