import { createSlice } from '@reduxjs/toolkit';

const TOKEN_STATUS = {
    NEED_TO_REFRESH: 'NEED_TO_REFRESH',
    AUTHENTICATING: 'AUTHENTICATING',
    AUTHENTICATED: 'AUTHENTICATED',
    NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
}
export const TOKEN_ACTIONS = {
    GET_NEW_TOKEN: 'GET_NEW_TOKEN',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    TOKEN_REFRESHED: 'TOKEN_REFRESHED',
    TOKEN_ERROR: 'TOKEN_ERROR'
}

// Action Creator
export const tokenNeeded = (username, password) => dispatch => {
    try {
        return dispatch(tokenNeededAction(username, password));
    } catch (e) {
        return console.error(e.message);
    }
}


// Action Creator
export const refreshToken = () => dispatch => {
    try {
        return dispatch(refreshTokenAction())
    } catch (e) {
        return console.error(e.message);
    }
}

//action creator
export const receiveToken = (json) => dispatch => {
    try {
        return dispatch(receiveTokenAction(json))
    } catch (e) {
        return console.error(e.message);
    }
}

export const invalidateToken = (json) => dispatch => {
    try {
        return dispatch(invalidateTokenAction(json))
    } catch (e) {
        return console.error(e.message);
    }
}

// Slice
const tokenReducer = createSlice(
    {
        name: 'tokenReducer',
        initialState: {
            status: TOKEN_STATUS.NOT_AUTHENTICATED,
            token: undefined,
            error: undefined
        },
        reducers: {
            tokenNeededAction: (state, action) => {
                state.status = TOKEN_STATUS.AUTHENTICATING
            },
            refreshTokenAction: (state) => {
                state.status = TOKEN_STATUS.NEED_TO_REFRESH
            },
            receiveTokenAction: (state, action) => {
                state.status = TOKEN_STATUS.AUTHENTICATED
                state.token = action.payload
            },
            invalidateTokenAction: (state, action) => {
                state.status = TOKEN_STATUS.NOT_AUTHENTICATED
                state.error = action.payload
            }
        },
    }
);

export default tokenReducer.reducer

// Actions
const {
    tokenNeededAction,
    refreshTokenAction,
    receiveTokenAction,
    invalidateTokenAction
} = tokenReducer.actions