// GLOBAL
import React, { Component, useEffect, useState } from 'react';
import { CCol, CRow, CForm } from '@coreui/react-pro';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

// UTILS
import ComponentsUtils from '../../utils/componentsUtils';
import ObjectsUtils from '../../utils/objectsUtils';

// COMPONENTS
import UiModal from './UiModal';

// TYPES
import { userType } from '../../types';

const propTypes = {
  apiReducer: PropTypes.object,
  currentUser: userType,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  model: PropTypes.object,
  fields: PropTypes.array,
  updating: PropTypes.bool,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  errorsModal: PropTypes.array,
  onSearchChange: PropTypes.func,
  isValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

function UiCreationsModal(props) {
  const [requiredFields, setRequiredFields] = useState([]);
  const [creationFields, setCreationFields] = useState([]);
  const [inputError, setInputError] = useState(true);
  const [creationModel, setCreationModel] = useState({});
  const [formIsValid, setFormIsValid] = useState();

  useEffect(() => {
    const requiredFieldsLocal = [];
    const creationFieldsLocal = [];
    if (props.fields && props.fields.length > 0) {
      props.fields.forEach(field => {
        if (field.mandatory || field.creation > 0 || field.extraordinaryCase) {
          if (!props.updating || field.updatable) {
            if (field.reducer && props.apiReducer && props.apiReducer[field.reducer] && props.apiReducer[field.reducer].status === 'VALID') {
              field.values = props.apiReducer[field.reducer].data;
            }
            if (field.mandatory || field.extraordinaryCase) requiredFieldsLocal.push(field);
            creationFieldsLocal.push(field);
          }
        }
      });
    }

    setRequiredFields(requiredFieldsLocal);
    setCreationFields(creationFieldsLocal.sort((a, b) => ((a.creation > b.creation) ? 1 : ((a.creation < b.creation) ? -1
      : ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)
    ))));

    if (props.isOpen) {
      if (!creationModel || Object.keys(creationModel).length === 0 || props.reloadOnChange) {
        if (props.model) {
          setCreationModel(props.model);
        } else if (!creationModel) {
          setCreationModel({});
        }
      }
    } else {
      if (!creationModel || Object.keys(creationModel).length > 0) {
        setCreationModel({});
      }
      setInputError(true);
    }

  }, [props])

  useEffect(() => {
    setFormIsValid(isValid());
  }, [creationModel])

  function handleOnChange(event) {
    let emailError;
    if (event.target.name === "email" || event.target.name === "pec") { //l'input di riferimento è una mail
      emailError = ObjectsUtils.isEmailValid(event.target.value) ? false : true
    }
    setInputError(emailError);

    if (event) {
      setCreationModel(prevState => {
        const newState = cloneDeep(prevState);
        eval(`newState.${event.target.name} = event.target.value`);

        if (event.target.name === 'city_id') {
          const city = props?.apiReducer?.cities?.data.find(el => el.id === Number(event.target.value));
          newState.zip_code = (city) ? city.zip_code : null;
        }

        if (event.target.name === 'user_id') {
          const user = props?.apiReducer?.users?.data.find(el => el.id === Number(event.target.value));
          newState.first_name = (user) ? user.first_name : null;
          newState.last_name = (user) ? user.last_name : null;
          newState.birth_date = (user) ? user.birth_date : null;
        }
        return newState;
      })
    }
    if (props?.onChange) props.onChange(event);
  }

  function handleOnRangeChange(event) {

    setCreationModel(prevState => {
      const cloneCreationModel = cloneDeep(prevState);
      cloneCreationModel[event.target.name] = event.target.value;
      if (cloneCreationModel.start_date > cloneCreationModel.end_date) {
        cloneCreationModel[event.target.name] = null;
      }
      return cloneCreationModel;
    });

    if (props.onChange) props.onChange(event);
  }

  function handleOnSearchChange(event) {
    let eventWithModel = cloneDeep(event);
    eventWithModel.target.model = creationModel;
    if (props.onSearchChange) props.onSearchChange(eventWithModel);
  }

  function checkExtraordinary(field) {
    // eslint-disable-next-line
    let extraordinaryCheck = true;
    if (field.extraordinaryCase && field.extraordinaryCase.length > 0) {
      extraordinaryCheck = false;
      for (let cond = 0; cond < field.extraordinaryCase.length; cond++) {
        if (eval(`creationModel.${field.extraordinaryCase[cond]}`)) {
          extraordinaryCheck = true;
          break;
        }
      }
    }
    return extraordinaryCheck;
  }

  function isValid() {
    if (typeof props.isValid === 'function') {
      return props.isValid(creationModel);
    }
    if (creationModel && requiredFields) {
      for (const field of requiredFields) {
        if (checkExtraordinary(field) && (eval(`creationModel?.${field.name}`) === undefined || eval(`creationModel?.${field.name}`) === null)) {
          if (field.type === 'checkbox') {
            eval(`creationModel.${field.name}= false`);
          } else {
            return false;
          }
        }
      }
    }
    return true;
  }

  function evalDisabledCondition(disabledCondition, creationModel) {
    return (creationModel[disabledCondition] !== undefined && creationModel[disabledCondition] !== null);
  }

  function evalTooltipCondition(tooltipCondition, creationModel) {
    let conditionSatisfied = false;
    if (creationModel[tooltipCondition.property] !== undefined && creationModel[tooltipCondition.property] !== null) {
      switch (tooltipCondition.operator) {
        case 'NOT_EQUALS':
          conditionSatisfied = (creationModel[tooltipCondition.property] !== tooltipCondition.value);
          break;
        case 'EQUALS':
        default:
          conditionSatisfied = (creationModel[tooltipCondition.property] === tooltipCondition.value);
          break;
      }
    }
    if (conditionSatisfied) return tooltipCondition.text;
    return null;
  }

  function renderRows() {
    let count = 0;

    return creationFields.map((field, index) => {
      if (field.creation > 0 && checkExtraordinary(field)) {
        if ((field.type === 'checkbox-group' || field.type === 'chip' || field.type === 'select') && (!field.values || field.values.length === 0)) {
          return null;
        }
        let disabled = (!!field.disabledCondition) ? evalDisabledCondition(field.disabledCondition, creationModel) : field.readonly;
        field.readonly = disabled;

        const tooltip = (!!field.tooltipEval) ? evalTooltipCondition(field.tooltipEval, creationModel) : null;
        field.tooltipMsg = tooltip !== null ? tooltip : field.tooltipMsg;

        let envContext = props.envContext;
        if (field.type === 'date' && field.bindValue) {
          if (field.bindType === 'min' && creationModel[field.bindValue]) {
            field.minDate = creationModel[field.bindValue];
          } else if (field.bindType === 'max' && creationModel[field.bindValue]) {
            field.maxDate = creationModel[field.bindValue];
          }
        } else if (field.type === 'permalink' && field.bindValue) {
          creationModel[field.name] = (creationModel[field.bindValue]) ? ObjectsUtils.buildPermalink(creationModel[field.bindValue], creationModel.language) : undefined;
          field.readonly = true;
        } else if (field.bindValue) {
          if (!envContext) {
            envContext = {};
          }
          envContext[field.bindValue] = creationModel[field.bindValue];
        }
        field.envContext = envContext;


        const value = (
          <CCol key={`creation-modal-${props.name}-${index}`} sm="12" md={(field.numcol) ? field.numcol : (field.singlerow) ? '12' : '6'} lg={(field.numcol) ? field.numcol : (field.singlerow) ? '12' : '6'} xl={(field.numcol) ? field.numcol : (field.singlerow) ? '12' : '6'} className={((count % 12 !== 0) && (field.noRight !== true)) ? 'rightCol' : null}>
            {ComponentsUtils.renderByType(false, (!!field.bindValue && !creationModel[field.bindValue] && field.isDeactivable), false, field, creationModel, (field.name === 'date_start' || field.name === 'date_end') ? handleOnRangeChange.bind(this) : handleOnChange.bind(this), handleOnSearchChange.bind(this), inputError, props.apiReducer, props.currentUser)}
          </CCol>
        );
        count += (field.numcol) ? field.numcol : (field.singlerow) ? 12 : 6;
        return value;
      }
    });
  }

  function handleSubmit() {
    if (props.onSubmit) props.onSubmit(creationModel);
  }

  return (
    <I18n ns="translations">
      {t => (
        <UiModal title={props.title ? t(props.title) : t('Common.add_item')} isOpen={props.isOpen}
          creation={!props.updating} valid={formIsValid} errorsModal={!!props.errorsModal ? props.errorsModal : []}
          onSubmit={handleSubmit.bind(this)} onCancel={props.onCancel.bind(this)} >
          <CForm>
            <CRow>
              {creationModel && creationFields && renderRows()}
            </CRow>
          </CForm>
        </UiModal>
      )}
    </I18n>
  );
}


UiCreationsModal.propTypes = propTypes;

export default UiCreationsModal;
