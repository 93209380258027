import { createSlice } from '@reduxjs/toolkit';

import ObjectsUtils from '../utils/objectsUtils';

export const API_TYPES = {
    INIT: 'INIT',
    INIT_LIST: 'INIT_LIST',
    REQUESTED: 'REQUESTED',
    NOT_AUTHORIZED: 'NOT_AUTHORIZED',
    AUTHORIZED: 'AUTHORIZED',
    RECEIVED: 'RECEIVED',
    INVALIDATED: 'INVALIDATED',
    SERVER_INVALIDATED: 'SERVER_INVALIDATED'
}

const API_STATUS = {
    INITIALIZED: 'INITIALIZED',
    LOADING: 'LOADING',
    WAITING_FOR_TOKEN: 'WAITING_FOR_TOKEN',
    VALID: 'VALID',
    VALID_EMPTY: 'VALID_EMPTY',
    INVALID: 'INVALID',
    SERVER_INVALID: 'SERVER_INVALID',
}

// Action Creator
export const init = (moduleName) => dispatch => {
    try {
        return dispatch(initAction(moduleName));
    } catch (e) {
        return console.error(e.message);
    }
}


// Action Creator
export const initList = (moduleName) => dispatch => {
    try {
        return dispatch(initListAction(moduleName))
    } catch (e) {
        return console.error(e.message);
    }
}

//action creator
export const request = (moduleName) => dispatch => {
    try {
        return dispatch(requestAction(moduleName))
    } catch (e) {
        return console.error(e.message);
    }
}

//action creator
export const received = (obj) => dispatch => {
    try {
        return dispatch(receivedAction(obj))
    } catch (e) {
        return console.error(e.message);
    }
}

//action creator
export const receivedNotAuthorized = (moduleName) => dispatch => {
    try {
        return dispatch(receivedNotAuthorizedAction(moduleName))
    } catch (e) {
        return console.error(e.message);
    }
}

//action creator
export const authorize = (obj) => dispatch => {
    try {
        return dispatch(requestAction(obj))
    } catch (e) {
        return console.error(e.message);
    }
}

//action creator
export const invalidate = (obj) => dispatch => { //mandare anche type
    try {
        return dispatch(invalidateAction(obj))
    } catch (e) {
        return console.error(e.message);
    }
}

// Slice
const apiReducer = createSlice(
    {
        name: 'apiReducer',
        initialState: {},
        reducers: {
            initAction: (state, action) => {
                state[action.payload.moduleName] = {
                    status: API_STATUS.INITIALIZED,
                    error: undefined,
                    data: {},
                    links: undefined,
                    filter: undefined
                }
            },
            initListAction: (state, action) => {
                state[action.payload.moduleName] = {
                    status: API_STATUS.INITIALIZED,
                    error: undefined,
                    data: [],
                    links: undefined,
                    filter: undefined
                }
            },
            requestAction: (state, action) => { //utilizzo anche in caso di authorize
                state[action.payload.moduleName] = {
                    status: API_STATUS.LOADING,
                    error: undefined,
                    data: [],
                    links: undefined,
                    filter: undefined
                }
            },
            receivedAction: (state, action) => {
                state[action.payload.moduleName] = {
                    status: (ObjectsUtils.isObjectNotEmpty(action)) ? API_STATUS.VALID : API_STATUS.VALID_EMPTY,
                    error: undefined,
                    data: action.payload.json,
                    links: action.payload.links,
                    total: action.payload.total,
                    filter: action.payload.filter
                }
            },
            receivedNotAuthorizedAction: (state, action) => {
                state[action.payload.moduleName] = {
                    status: API_STATUS.WAITING_FOR_TOKEN,
                    error: undefined,
                    data: undefined,
                    links: undefined,
                    filter: undefined
                }
            },
            // authorizeAction: (state, action) => {
            //     state[action.payload.moduleName] = {
            //         status: API_STATUS.LOADING,
            //         error: undefined,
            //         data: undefined,
            //         links: undefined,
            //         filter: undefined
            //     }
            // },
            invalidateAction: (state, action) => {
                state[action.payload.moduleName] = {
                    status: (action.payload.type === "TEST") ? "TEST" : (action.payload.type === API_TYPES.INVALIDATED) ? API_STATUS.INVALID : API_STATUS.SERVER_INVALID,
                    error: action.payload.error,
                    data: undefined,
                    links: undefined,
                    filter: undefined
                };
            }


        },
    }
);

export default apiReducer.reducer

// Actions
const {
    initAction,
    initListAction,
    requestAction,
    invalidateAction,
    receivedAction,
    receivedNotAuthorizedAction,
} = apiReducer.actions