import BaseService from './baseService';

export default class CompanyAddressesService extends BaseService {

    static instance = null;

    static getInstance(store) {
        if (!CompanyAddressesService.instance) {
            CompanyAddressesService.instance = new CompanyAddressesService(store);
        }
        return CompanyAddressesService.instance;
    }

    constructor(store) {
        let baseUrl = process.env.API_PATH;
        super(store, baseUrl, 'company_addresses', 'company_address');
        this.pathName = 'addresses';
    }


    async getList(companyId, filterObj, isMapFilter, lang, token, okCallBack, koCallBack) {
        if (isMapFilter) {
            if (filterObj.type) {
                return await super.get(this.listName, encodeURI('companies/' + this.pathName + `?and^latitude=lte_${filterObj.n.lat}&and^latitude=gte_${filterObj.s.lat}&and^longitude=lte_${filterObj.e.lng}&and^longitude=gte_${filterObj.w.lng}&and^type=${filterObj.type}&and^endDate=null`), lang, token, null, okCallBack, koCallBack);
            }else{
                return await super.get(this.listName,encodeURI( 'companies/' + this.pathName + `?and^latitude=lte_${filterObj.n.lat}&and^latitude=gte_${filterObj.s.lat}&and^longitude=lte_${filterObj.e.lng}&and^longitude=gte_${filterObj.w.lng}&and^endDate=null`) , lang, token, null, okCallBack, koCallBack);
            }
        } else {
            if(filterObj?.type){
                return await super.get(this.listName, encodeURI('companies/' + this.pathName + `?and^id=${companyId}&and^type=${filterObj?.type}&and^endDate=null`), lang, token, null, okCallBack, koCallBack);
            }else{
                return await super.get(this.listName, encodeURI('companies/' + this.pathName + `?and^id=${companyId}&and^endDate=null`), lang, token, null, okCallBack, koCallBack);

            }
        }

    }

    addItem(obj, lang, token, okCallBack, koCallBack) {
        return super.post(this.itemName, 'companies/' + obj.company_id + '/' + this.pathName, lang, token, obj, okCallBack, koCallBack);
    }

    updateItem(itemId, obj, lang, token, okCallBack, koCallBack) {
        return super.put(this.itemName, 'companies/' + itemId + '/' + this.pathName + '/' + obj.id, lang, token, obj, okCallBack, koCallBack);
    }

    updateItemCustomId(contentId, obj, lang, token, okCallBack, koCallBack) {
        return super.put(this.itemName, 'companies/' + obj.company_id + '/' + this.pathName + '/' + contentId, lang, token, obj, okCallBack, koCallBack);
    }

    deleteItem(userId, contentId, lang, token, okCallBack, koCallBack) {
        function deleteKoCallBack(error) {
            this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
        }
        return super.delete(this.itemName, 'companies/' + userId + '/' + this.pathName + '/' + contentId, lang, token, okCallBack, deleteKoCallBack.bind(this));
    }

}