import React from 'react';
import UiInput from './UiInput';
import { CCol } from '@coreui/react-pro';
import UiSelectClassic from './UiSelectClassic';

const UiRepeatInput = props => {
  const { obj, required, disabled, hideLabel, tooltipMsg, maxLength, model } = props;

  const [values, setValues] = React.useState([]);

  React.useEffect(() => {
    if (model[obj.name] !== undefined) {
      setValues(model[obj.name]);
    }
  }, [model[obj.name]]);

  const handleInternalChange = (e, i) => {
    const { value } = e.target;
    const updatedValues = values;
    updatedValues.splice(i, 1, value);
    props.handleChange(obj.name, updatedValues);
    // setValues(updatedValues);
  };

  const renderFields = () => {
    let fields = [];
    for (let i = 0; i < obj.repeat; i++) {
      switch (obj.originalType) {
        case 'select':
        case 'dropdown':
          fields.push(
            <CCol key={i} md={obj.size || '6'} className="mb-3">
              <div className="px-2">
                <UiSelectClassic
                  value={values[i]}
                  values={obj.values}
                  disabled={disabled}
                  required={required}
                  nullable={!required}
                  tooltipMsg={tooltipMsg}
                  alreadyTranslated={true}
                  name={`${obj.name}_${i}`}
                  fieldReturned={obj.fieldReturned}
                  fieldDisplayed={obj.fieldDisplayed}
                  onChange={e => handleInternalChange(e, i)}
                  label={!hideLabel ? `${obj.label} ${i + 1}` : null}
                  onBlur={props.handleBlur ? props.handleBlur : null}
                  placeholder={!hideLabel ? obj.placeholder : obj.label}
                />
              </div>
            </CCol>
          );
          break;
        default:
          fields.push(
            <CCol key={i} md={obj.size || '6'} className="mb-3">
              <div className="px-2">
                <UiInput
                  key={i}
                  value={values[i]}
                  suffix={obj.suffix}
                  required={required}
                  disabled={disabled}
                  maxLength={maxLength}
                  tooltipMsg={tooltipMsg}
                  name={`${obj.name}_${i}`}
                  onChange={e => handleInternalChange(e, i)}
                  label={!hideLabel ? `${obj.label} ${i + 1}` : null}
                  onBlur={props.handleBlur ? props.handleBlur : null}
                  type={obj.originalType === 'number' ? 'number' : 'text'}
                  placeholder={!hideLabel ? obj.placeholder : obj.label}
                />
              </div>
            </CCol>
          );
      }
    }

    return fields.map(f => f);
  };

  return <React.Fragment>{renderFields()}</React.Fragment>;
};

export default UiRepeatInput;
