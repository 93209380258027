// SERVICES
import ConstantsService from './constantsService';
import CitiesService from './citiesService';
import UsersService from './usersService';
import DocsService from './docsService';
import EmployeesService from './employeesService';
import JobApplicationsService from './jobApplicationsService';
import BlogsService from './blogsService';
import CaseStudiesService from './caseStudiesService';
import EventsService from './eventsService';
import CommunityService from './communityService';
import AcademyService from './academyService';
import CompaniesService from './companiesService';
import SkillsService from './skillsService';
import RolesService from './rolesService';

// VIEWS
import Dashboard from '../components/views/Dashboard';
import City from '../components/views/City';
import Cities from '../components/views/Cities';
import User from '../components/views/User';
import Users from '../components/views/Users';
import Employees from '../components/views/Employees';
import Employee from '../components/views/Employee';
import JobApplications from '../components/views/JobApplications';
import JobApplication from '../components/views/JobApplication';
import Blogs from '../components/views/Blogs';
import Blog from '../components/views/Blog';
import CaseStudies from '../components/views/CaseStudies';
import CaseStudy from '../components/views/CaseStudy';
import Events from '../components/views/Events';
import Event from '../components/views/Event';
import Academies from '../components/views/Academies';
import Academy from '../components/views/Academy';
import Companies from '../components/views/Companies';
import Company from '../components/views/Company';
import Cvs from '../components/views/Cvs';
import CvsService from './cvsService';
import Cv from '../components/views/Cv';
import Skill from '../components/views/Skill';
import Skills from '../components/views/Skills';
import Map from '../components/views/Map';
import Roles from '../components/views/Roles';
import Role from '../components/views/Role';



/**
 * Service with the purpose of mapping all the sections and the related routes of the site into a single entry point
 * @category services
 */
export default class SiteMapService {
  /**
   * Method that returns the sitemap in objects consisting of:
   *
   * <pre>
   * #sectionIndex:
   * --indice della sezione all'interno del menù;
   * #sectionName:
   * --nome della label che andrà inserita all'interno dell'header della sezione;
   * #sectionIcon:
   * --nome dell'icona che andrà inserita nel menu ed all'interno dell'header della sezione;
   * #sectionId:
   * --id della sezione utilizzato per riconoscere la sezione corrente salvata nel GlobalService, per identificare i campi della sezione nel {@link TableMapService} e per identificare la sezione padre o la sezione figlia nella mappa;
   * #sectionMenuId:
   * --proprietà speciale che sostituisce sectionId per identificare delle voci di menù che raggruppano più sezioni;
   * #menuChildren:
   * --array di sectionId che popoleranno il raggruppamento di menu (necessario solo se presente sectionMenuId);
   * #sectionRoute:
   * --rotta della sezione che verrà settata nel routing;
   * #exactRoute:
   * --flag exactRoute settato nel routing;
   * #sectionComponent:
   * --classe del componente della sezione;
   * #sectionService:
   * --classe del servizio della sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * #sectionReducerId:
   * --nome del reducer della sezione (necessario solo per la sezione dashboard);
   * #sectionTabs:
   * --array di tab che devono essere renderizzati nella sezione identificati da
   *      {id} del tab,
   *      {scope} abilitato a visualizzare il tab,
   *      flag {default} che identifica il tab attivato di default all'apertura della pagina e
   *      la {route} dello specifico tab (!!! utilizzare currentSectionId al posto di :id nell'url ed assicurarsi che sectionRoute termini con /:activeTab?);
   * #sectionNewItemsTitle:
   * --nome della label del titolo della modale di creazione (necessario solo per sezioni padre);
   * #sectionDefaultFilters:
   * --oggetto che definisce l'ordinamento di default e il numero di elementi visualizzati nella griglia (necessario solo per sezioni padre) tramite:
   *         {numPagination} numero elementi,
   *         {sort} nome della colonna su cui ordinare,
   *         {order} 'ASC' o 'DESC';
   * #sectionChild:
   * --id della sezione figlia a cui redirigere la selezione di una riga nella griglia (necessario solo per sezioni padre);
   * #sectionParent:
   * --id della sezione padre (necessario solo per sezioni figlie);
   * #sectionMainField:
   * --nome del parametro principale da visualizzare come sottotitolo nell'header in caso di sezione figlia o da visualizzare nell'errore per identificare gli    elementi in caso di fallimento delle api su una selezione multipla nelle griglie;
   * #sectionLanguagesData:
   * --array di campi necessari in caso di modali di creazione di nuove lingue all'interno della sezione (necessario solo per sezioni figlie);
   * #sectionGroups:
   * --array di id dei gruppi di utenze abilitate a vedere la sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * #sectionViewScope:
   * --scope necessario a visualizzare la sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * #sectionCreateScope:
   * --scope necessario a creare un nuovo item nella sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * #sectionEditScope:
   * --scope necessario a modificare un item nella sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * #sectionDeleteScope:
   * --scope necessario a cancellare un item nella sezione (necessario solo per sezioni padre, le sezioni figlie lo ereditano);
   * </pre>
   * @static
   * @public
   * @return {map} the site map
   */
  static getSiteMap() {
    const siteMap = {};

    siteMap.home = {
      sectionIndex: 0,
      sectionName: 'Section.dashboard',
      sectionIcon: 'cis-speedometer',
      sectionBadge: {
        variant: 'info',
        text: 'NEW',
      },
      sectionId: 'dashboard',
      sectionReducerId: 'users',
      sectionRoute: '/',
      exactRoute: true,
      sectionComponent: Dashboard,
      sectionService: UsersService,
      sectionTabs: [],
      sectionGroups: undefined,
      sectionViewScope: '*',
    };

    // siteMap.docs = {
    //   sectionIndex: 1,
    //   sectionName: 'Section.docs',
    //   sectionIcon: 'cis-book',
    //   sectionId: 'docs',
    //   sectionRoute: '/docs',
    //   exactRoute: true,
    //   sectionComponent: Docs,
    //   sectionService: DocsService,
    //   sectionTabs: [],
    //   sectionNewItemsTitle: 'Common.add_doc',
    //   sectionChild: 'doc',
    //   sectionMainField: 'title',
    //   sectionGroups: undefined,
    //   sectionViewScope: 'DOCS',
    //   sectionCreateScope: 'DOCS',
    //   sectionEditScope: 'DOCS',
    //   sectionDeleteScope: 'DOCS',
    // };

    // siteMap.doc = {
    //   sectionName: 'Section.docs',
    //   sectionIcon: 'cis-book',
    //   sectionId: 'doc',
    //   sectionRoute: '/doc/:id',
    //   exactRoute: false,
    //   sectionComponent: Doc,
    //   sectionTabs: [],
    //   sectionParent: 'docs',
    //   sectionMainField: 'title',
    //   sectionLanguagesData: [],
    // };

    siteMap.employees = {
      sectionIndex: 7,
      sectionName: 'Section.employees',
      sectionIcon: 'cis-person',
      sectionId: 'employees',
      sectionRoute: '/employees',
      exactRoute: true,
      sectionComponent: Employees,
      sectionService: EmployeesService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_employee',
      sectionChild: 'employee',
      sectionMainField: 'name',
      sectionGroups: undefined,
      sectionViewScope: 'about_us_view',
      sectionCreateScope: 'about_us_create',
      sectionEditScope: 'about_us_edit',
      sectionDeleteScope: 'about_us_delete',
    };

    siteMap.employee = {
      sectionName: 'Section.employee',
      sectionIcon: 'cis-person',
      sectionId: 'employee',
      sectionRoute: '/employee/:id',
      exactRoute: false,
      sectionComponent: Employee,
      sectionTabs: [],
      sectionParent: 'employees',
      sectionMainField: 'name',
      sectionLanguagesData: [],
    };

    siteMap.job_applications = {
      sectionIndex: 1,
      sectionName: 'Section.job_applications',
      sectionIcon: 'cis-briefcase',
      sectionId: 'job_applications',
      sectionRoute: '/job_applications',
      exactRoute: true,
      sectionComponent: JobApplications,
      sectionService: JobApplicationsService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_job',
      sectionChild: 'job_application',
      sectionMainField: 'title_it',
      sectionGroups: undefined,
      sectionViewScope: 'job_application_view',
      sectionCreateScope: 'job_application_create',
      sectionEditScope: 'job_application_edit',
      sectionDeleteScope: 'job_application_delete',
      sectionDefaultFilters: { numPagination: 10, sort: 'id', order: 'ASC' },
    };

    siteMap.job_application = {
      sectionName: 'Section.job_application',
      sectionIcon: 'cis-briefcase',
      sectionId: 'job_application',
      sectionRoute: '/job_application/:id',
      exactRoute: false,
      sectionComponent: JobApplication,
      sectionTabs: [],
      sectionParent: 'job_applications',
      sectionMainField: 'title_it',
      sectionLanguagesData: [],
    };

    siteMap.blogs = {
      sectionIndex: 4,
      sectionName: 'Section.blogs',
      sectionIcon: 'cis-browser',
      sectionId: 'blogs',
      sectionRoute: '/blogs',
      exactRoute: true,
      sectionComponent: Blogs,
      sectionService: BlogsService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_post',
      sectionChild: 'blog',
      sectionMainField: 'title',
      sectionGroups: undefined,
      sectionViewScope: 'blog_view',
      sectionCreateScope: 'blog_create',
      sectionEditScope: 'blog_edit',
      sectionDeleteScope: 'blog_delete',
      sectionArchiveScope: '*',
      sectionDefaultFilters: { numPagination: 10, sort: 'id', order: 'ASC' },
    };

    siteMap.blog = {
      sectionName: 'Section.blog',
      sectionIcon: 'cis-browser',
      sectionId: 'blog',
      sectionRoute: '/blog/:id',
      exactRoute: false,
      sectionComponent: Blog,
      sectionTabs: [],
      sectionParent: 'blogs',
      sectionMainField: 'title',
      sectionLanguagesData: [],
    };

    siteMap.case_studies = {
      sectionIndex: 5,
      sectionName: 'Section.case_studies',
      sectionIcon: 'cis-penAlt',
      sectionId: 'case_studies',
      sectionRoute: '/case_studies',
      exactRoute: true,
      sectionComponent: CaseStudies,
      sectionService: CaseStudiesService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_case_study',
      sectionChild: 'case_study',
      sectionMainField: 'title',
      sectionGroups: undefined,
      sectionViewScope: 'case_study_view',
      sectionCreateScope: 'case_study_create',
      sectionEditScope: 'case_study_edit',
      sectionDeleteScope: 'case_study_delete',
      sectionDefaultFilters: { numPagination: 10, sort: 'id', order: 'ASC', lang: 'it', type: 1, active: 'yes' },
    };

    siteMap.case_study = {
      sectionName: 'Section.case_study',
      sectionIcon: 'cis-penAlt',
      sectionId: 'case_study',
      sectionRoute: '/case_study/:id',
      exactRoute: false,
      sectionComponent: CaseStudy,
      sectionTabs: [],
      sectionParent: 'case_studies',
      sectionMainField: 'title',
      sectionLanguagesData: [],
    };

    siteMap.events = {
      sectionIndex: 6,
      sectionName: 'Section.events',
      sectionIcon: 'cis-drink',
      sectionId: 'events',
      sectionRoute: '/events',
      exactRoute: true,
      sectionComponent: Events,
      sectionService: EventsService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_event',
      sectionChild: 'event',
      sectionMainField: 'title',
      sectionGroups: undefined,
      sectionViewScope: 'event_view',
      sectionCreateScope: 'event_create',
      sectionEditScope: 'event_edit',
      sectionDeleteScope: 'event_delete',
      sectionDefaultFilters: { numPagination: 10, sort: 'id', order: 'ASC' },
    };

    siteMap.event = {
      sectionName: 'Section.event',
      sectionIcon: 'cis-drink',
      sectionId: 'event',
      sectionRoute: '/event/:id',
      exactRoute: false,
      sectionComponent: Event,
      sectionTabs: [],
      sectionParent: 'events',
      sectionMainField: 'title',
      sectionLanguagesData: [],
    };

    siteMap.users = {
      sectionIndex: 2,
      sectionName: 'Section.users',
      sectionIcon: 'cis-group',
      sectionId: 'users',
      sectionRoute: '/users',
      exactRoute: true,
      sectionComponent: Users,
      sectionService: CommunityService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_user',
      sectionChild: 'user',
      sectionMainField: 'first_name',
      sectionGroups: undefined,
      sectionViewScope: 'user_view',
      sectionCreateScope: 'user_create',
      sectionEditScope: 'user_edit',
      sectionDeleteScope: 'user_delete',
    };

    siteMap.user = {
      sectionName: 'Section.user',
      sectionIcon: 'cis-group',
      sectionId: 'user',
      sectionRoute: '/user/:id',
      exactRoute: false,
      sectionComponent: User,
      sectionTabs: [],
      sectionParent: 'users',
      sectionMainField: 'first_name',
      sectionMainFieldTwo: 'last_name',
      sectionLanguagesData: [],
    };

    siteMap.roles = {
      sectionIndex: 3,
      sectionName: 'Section.roles',
      sectionIcon: 'cis-tag',
      sectionId: 'roles',
      sectionRoute: '/roles',
      exactRoute: true,
      sectionComponent: Roles,
      sectionService: RolesService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_role',
      sectionChild: 'role',
      sectionMainField: 'first_name',
      sectionGroups: undefined,
      sectionViewScope: 'role_view',
      sectionCreateScope: 'role_view',
      sectionEditScope: 'role_view',
      sectionDeleteScope: 'role_view',
    };

    siteMap.role = {
      sectionName: 'Section.role',
      sectionIcon: 'cis-tag',
      sectionId: 'role',
      sectionRoute: '/role/:id',
      exactRoute: false,
      sectionComponent: Role,
      sectionTabs: [],
      sectionParent: 'roles',
      sectionMainField: 'name',
      // sectionMainFieldTwo: 'last_name',
      sectionLanguagesData: [],
    };


    siteMap.map = {
      sectionIndex: 4,
      sectionName: 'Section.map',
      sectionIcon: 'cis-map',
      sectionId: 'map',
      sectionReducerId: 'users',
      sectionRoute: '/map',
      exactRoute: true,
      sectionComponent: Map,
      sectionService: CommunityService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_user',
      sectionChild: 'user',
      sectionMainField: 'first_name',
      sectionGroups: undefined,
      sectionViewScope: 'map_view',
      // sectionCreateScope: 'user_create',
      // sectionEditScope: 'user_edit',
      // sectionDeleteScope: 'user_delete',
    };

    siteMap.academies = {
      sectionIndex: 5,
      sectionName: 'Section.academies',
      sectionIcon: 'cis-education',
      sectionId: 'academies',
      sectionRoute: '/academies',
      exactRoute: true,
      sectionComponent: Academies,
      sectionService: AcademyService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_academy',
      sectionChild: 'academy',
      sectionMainField: 'academies.title',
      sectionGroups: undefined,
      sectionViewScope: 'academy_view',
      sectionCreateScope: 'academy_create',
      sectionEditScope: 'academy_edit',
      sectionDeleteScope: 'academy_delete',
    };

    siteMap.academy = {
      sectionName: 'Section.academy',
      sectionIcon: 'cis-education',
      sectionId: 'academy',
      sectionRoute: '/academy/:id',
      exactRoute: false,
      sectionComponent: Academy,
      sectionTabs: [],
      sectionParent: 'academies',
      sectionMainField: 'academies.title',
      sectionLanguagesData: [],
    };

    siteMap.companies = {
      sectionIndex: 8,
      sectionName: 'Section.companies',
      sectionIcon: 'cis-building',
      sectionId: 'companies',
      sectionRoute: '/companies',
      exactRoute: true,
      sectionComponent: Companies,
      sectionService: CompaniesService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_company',
      sectionChild: 'company',
      sectionMainField: 'company_name',
      sectionGroups: undefined,
      sectionViewScope: 'company_view',
      sectionCreateScope: 'company_create',
      sectionEditScope: 'company_edit',
      sectionDeleteScope: 'company_delete',
    };

    siteMap.company = {
      sectionName: 'Section.company',
      sectionIcon: 'cis-building',
      sectionId: 'company',
      sectionRoute: '/company/:id',
      exactRoute: false,
      sectionComponent: Company,
      sectionTabs: [],
      sectionParent: 'companies',
      sectionMainField: 'company_name',
      sectionLanguagesData: [],
    };

    siteMap.settingsSection = {
      sectionMenuId: 'settingsSection',
      sectionIndex: 10,
      sectionName: 'Section.settings_section',
      sectionIcon: 'cis-cog',
      menuChildren: ['cities', 'skills'],
      menuRouteScopes: ['city'],
      sectionGroups: undefined,
      sectionViewScope: '*',
    };

    siteMap.cities = {
      sectionIndex: 1001,
      sectionName: 'Section.cities',
      sectionIcon: 'cis-city',
      sectionId: 'cities',
      sectionRoute: '/cities',
      exactRoute: true,
      sectionComponent: Cities,
      sectionService: CitiesService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_city',
      sectionChild: 'city',
      sectionMainField: 'city',
      sectionGroups: undefined,
      sectionViewScope: 'city_view',
      sectionCreateScope: 'city_create',
      sectionEditScope: 'city_edit',
      sectionDeleteScope: 'city_delete',
    }

    siteMap.city = {
      sectionName: 'Section.city',
      sectionIcon: 'cis-city',
      sectionId: 'city',
      sectionRoute: '/city/:id',
      exactRoute: false,
      sectionComponent: City,
      sectionTabs: [],
      sectionParent: 'cities',
      sectionMainField: 'city',
      sectionLanguagesData: [],
    };

    siteMap.skills = {
      sectionIndex: 1002,
      sectionName: 'Section.skills',
      sectionIcon: 'cis-badge',
      sectionId: 'skills',
      sectionRoute: '/skills',
      exactRoute: true,
      sectionComponent: Skills,
      sectionService: SkillsService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_skill',
      sectionChild: 'skill',
      sectionMainField: 'skill',
      sectionGroups: undefined,
      sectionViewScope: 'listed_skill_view',
      sectionCreateScope: 'listed_skill_create',
      sectionEditScope: 'listed_skill_edit',
      sectionDeleteScope: 'listed_skill_delete',

    }

    siteMap.skill = {
      sectionName: 'Section.skill',
      sectionIcon: 'cis-badge',
      sectionId: 'skill',
      sectionRoute: '/skill/:id',
      exactRoute: false,
      sectionComponent: Skill,
      sectionTabs: [],
      sectionParent: 'skills',
      sectionMainField: 'name',
      sectionLanguagesData: [],
    };

    siteMap.cvs = {
      sectionIndex: 9,
      sectionName: 'Section.cvs',
      sectionIcon: 'cis-file-pdf',
      sectionId: 'cvs',
      sectionRoute: '/cvs',
      exactRoute: true,
      sectionComponent: Cvs,
      sectionService: CvsService,
      sectionTabs: [],
      sectionNewItemsTitle: 'Common.add_cv',
      sectionChild: 'cv',
      sectionMainField: 'cv',
      sectionGroups: undefined,
      sectionViewScope: 'cv_view',
      sectionCreateScope: 'cv_edit',
      sectionEditScope: 'cv_edit',
      sectionDeleteScope: 'cv_delete',
    }

    siteMap.cv = {
      sectionName: 'Section.cv',
      sectionIcon: 'cis-file-pdf',
      sectionId: 'cv',
      sectionRoute: '/cv/:id',
      exactRoute: false,
      sectionComponent: Cv,
      sectionTabs: [],
      sectionParent: 'cvs',
      sectionMainField: 'first_name',
      sectionMainFieldTwo: 'last_name',
      sectionLanguagesData: [],
    };

    return siteMap;
  }

  /**
   * Method that returns the map of reducer's names and service class by route
   * @static
   * @public
   * @return {map} the reducers map
   */
  static getReducersMapByRoute() {
    const reducerMap = {
      city: { reducer_name: 'cities', service: CitiesService },
      city_related: { reducer_name: 'cities_related', service: CitiesService },
      doc: { reducer_name: 'docs', service: DocsService },
      employe: { reducer_name: 'employees', service: EmployeesService },
      job_application: { reducer_name: 'job_applications', service: JobApplicationsService },
      blog: { reducer_name: 'blogs', service: BlogsService },
      case_study: { reducer_name: 'case_studies', service: CaseStudiesService },
      event: { reducer_name: 'events', service: EventsService },
      academy: { reducer_name: 'academies', service: AcademyService },
      company: { reducer_name: 'companies', service: CompaniesService },
      user: { reducer_name: 'users', service: CommunityService },
      user_related: { reducer_name: 'users_related', service: CommunityService },
      skill: { reducer_name: 'skills', service: SkillsService },
    };

    return reducerMap;
  }
}
