// GLOBAL
import React from 'react';
import i18n from 'i18next';
import moment from 'moment';

// COMPONENTS
import UiAutocomplete from '../components/ui/UiAutocomplete';
import UiCheckbox from '../components/ui/UiCheckbox';
import UiCheckboxGroup from '../components/ui/UiCheckboxGroup';
import UiDatePicker from '../components/ui/UiDatePicker';
import UiFilesUpload from '../components/ui/UiFilesUpload';
import UiInput from '../components/ui/UiInput';
import UiInputChip from '../components/ui/UiInputChip';
import UiMultiSelect from '../components/ui/UiMultiSelect';
import UiRadioButton from '../components/ui/UiRadioButton';
import UiSearch from '../components/ui/UiSearch';
import UiTextArea from '../components/ui/UiTextArea';
import UiRepeatInput from '../components/ui/UiRepeatInput';
import UiDisplayImageField from '../components/ui/UiDisplayImageField';
import UiChangePasswordField from '../components/ui/UiChangePasswordField';
import UiSelectClassic from '../components/ui/UiSelectClassic';

export default class ComponentsUtils {
  static renderByType(isFilter, disabled, hideLabel, obj, model, handleChange, handleSearchChange, mailError, apiReducer = null, currentUser = null, handleBlur = null) {
    const nullable = (obj.mandatory !== true || obj.nullable);
    switch (obj.type) {
      case 'input':
      case 'permalink':
      case 'external-link':
        if (isFilter) {
          return (
            <UiSearch name={obj.name} type="text"
              disabled={disabled || obj.readonly}
              label={(!hideLabel) ? obj.label : null}
              placeholder={(!hideLabel) ? obj.placeholder : obj.label}
              value={model[obj.name]}
              onChange={handleChange ? handleChange.bind(this) : null}
              onBlur={handleBlur ? handleBlur.bind(this) : null}
              tooltipMsg={obj.tooltipMsg}
              invalid={obj.mandatory} />
          );
        }
        return (
          <UiInput name={obj.name} type="text"
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            value={((model?.languages && model?.languages.length > 0) || (model.translations && model?.translations?.length > 0)) ? eval(`model.${obj?.name}`) : model[obj?.name]}
            maxLength={obj.maxLength}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} emitEvent={obj.emitEvent} />
        );
      case 'input-number':
        if (isFilter) {
          return (
            <UiSearch name={obj.name} type="number"
              disabled={disabled || obj.readonly}
              label={(!hideLabel) ? obj.label : null}
              placeholder={(!hideLabel) ? obj.placeholder : obj.label}
              value={model[obj.name]}
              onChange={handleChange ? handleChange.bind(this) : null}
              onBlur={handleBlur ? handleBlur.bind(this) : null}
              tooltipMsg={obj.tooltipMsg}
              invalid={obj.mandatory} />
          );
        }
        return (
          <UiInput name={obj.name} type="number"
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null} suffix={obj.suffix}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            value={model[obj.name]}
            min={obj.min}
            max={obj.max}
            maxLength={obj.maxLength}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'email':
        return (
          <UiInput name={obj.name} type="email"
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null} suffix={obj.suffix}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            value={model[obj.name]}
            min={obj.min}
            max={obj.max}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} invalid={mailError} />
        )
      case 'currency':
        return (
          <UiInput name={obj.name} type="currency"
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null} suffix={obj.suffix}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            value={model[obj.name]}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg}
            maxLength={obj.maxLength} />
        );
      case 'password':
        return (
          <UiInput name={obj.name} type="password"
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null} suffix={obj.suffix}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            value={model[obj.name]}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg}
            maxLength={obj.maxLength}
            invalid={obj.mandatory} />
        );
      case 'change-password':
        return (
          <UiChangePasswordField name={obj.name}
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null} suffix={obj.suffix}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            value={model[obj.name]}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg}
            maxLength={obj.maxLength} />
        );
      case 'select':
        return (
          <UiSelectClassic name={obj.name}
            required={obj.mandatory && !isFilter}
            nullable={nullable || isFilter}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            fieldDisplayed={obj.fieldDisplayed}
            fieldReturned={obj.fieldReturned}
            values={obj.values}
            value={model[obj.name]}
            nosort={obj.nosort}
            forceTranslation={obj.forceTranslation}
            alreadyTranslated={obj.alreadyTranslated}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'radio':
        return (
          <UiRadioButton name={obj.name}
            required={obj.mandatory}
            nullable={nullable}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            fieldDisplayed={obj.fieldDisplayed}
            fieldReturned={obj.fieldReturned}
            values={obj.values}
            inline={obj.inline}
            value={model[obj.name]}
            alreadyTranslated={obj.alreadyTranslated}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'autocomplete':
        return (
          <UiAutocomplete name={obj.name}
            required={obj.mandatory && !isFilter}
            nullable={nullable || isFilter}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            fieldDisplayed={obj.fieldDisplayed}
            fieldReturned={obj.fieldReturned}
            values={obj.values} envContext={obj.envContext} envCreationRoute={obj.envCreationRoute}
            reducer={obj.reducer} route={(isFilter) ? 'filter' : obj.route} bindValue={obj.bindValue}
            apiReducer={apiReducer} currentUser={currentUser}
            value={model[obj.name]}
            onChange={handleChange ? handleChange.bind(this) : null}
            onValueChanged={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            onSearchChange={handleSearchChange ? handleSearchChange.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'chip':
        return (
          <UiInputChip name={obj.name}
            required={obj.mandatory}
            nullable={nullable || obj.nullable}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            apiReducer={apiReducer} currentUser={currentUser}
            fieldDisplayed={obj.fieldDisplayed}
            fieldReturned={obj.fieldReturned}
            values={obj.values}
            alreadyTranslated={!!obj.reducer || obj.alreadyTranslated}
            value={(model?.translations && model?.translations.length > 0) ? eval(`model.${obj.name}`) : model[obj.name]}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            chipSection={obj.chipSection}
            lng={obj.lng}
            isUpdate={obj.isUpdate}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'multiselect':
        return (
          <UiMultiSelect name={obj.name}
            required={obj.mandatory}
            nullable={nullable}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            fieldDisplayed={obj.fieldDisplayed}
            fieldReturned={obj.fieldReturned}
            values={obj.values}
            alreadyTranslated={!!obj.reducer}
            value={model[obj.name]}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'date':
      case 'datetime':
        return (
          <UiDatePicker name={obj.name}
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            value={model[obj.name] && moment.utc((model[obj.name]))} views={obj.views}
            default={obj.default}
            minDate={obj.minDate}
            maxDate={obj.maxDate}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'date-not-null':
        if (isFilter) {
          return (
            <UiSelectClassic name={obj.name}
              nullable
              disabled={disabled || obj.readonly}
              label={obj.label}
              fieldDisplayed="label"
              fieldReturned="value"
              alreadyTranslated
              values={[{ label: i18n.t('Common.yes'), value: '!!null' }, { label: i18n.t('Common.no'), value: 'null' }]}
              value={model[obj.name]}
              onChange={handleChange ? handleChange.bind(this) : null}
              onBlur={handleBlur ? handleBlur.bind(this) : null}
              tooltipMsg={obj.tooltipMsg} />
          );
        }
        return null;
      case 'date-null':
        if (isFilter) {
          return (
            <UiSelectClassic name={obj.name}
              nullable
              disabled={disabled || obj.readonly}
              label={obj.label}
              fieldDisplayed="label"
              fieldReturned="value"
              alreadyTranslated
              values={[{ label: i18n.t('Common.yes'), value: 'null' }, { label: i18n.t('Common.no'), value: '!!null' }]}
              value={model[obj.name]}
              onChange={handleChange ? handleChange.bind(this) : null}
              onBlur={handleBlur ? handleBlur.bind(this) : null}
              tooltipMsg={obj.tooltipMsg} />
          );
        }
        return null;
      case 'checkbox':
      case 'reserved':
      case 'archived':
        if (isFilter) {
          return (
            <UiSelectClassic name={obj.name}
              nullable
              disabled={disabled || obj.readonly}
              label={(!hideLabel) ? obj.label : null}
              placeholder={(!hideLabel) ? ' ' : obj.label}
              fieldDisplayed="label"
              fieldReturned="value"
              alreadyTranslated
              values={[{ label: i18n.t('Common.yes'), value: 'true' }, { label: i18n.t('Common.no'), value: 'false' }]}
              value={model[obj.name]}
              onChange={handleChange ? handleChange.bind(this) : null}
              onBlur={handleBlur ? handleBlur.bind(this) : null}
              tooltipMsg={obj.tooltipMsg} />
          );
        }
        return (
          <UiCheckbox name={obj.name}
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            label={obj.label}
            value={model[obj.name] || false}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'checkbox-group':
        return (
          <UiCheckboxGroup name={obj.name}
            columns={obj.columns}
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            inline={obj.inline}
            label={obj.label}
            customLabels={obj.customLabels}
            fieldDisplayed={obj.fieldDisplayed}
            fieldReturned={obj.fieldReturned}
            values={obj.values} nullable={/*nullable || */isFilter}
            value={model[obj.name]}
            reducer={obj.reducer}
            apiReducer={apiReducer} currentUser={currentUser}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'textarea':
        return (
          <UiTextArea name={obj.name}
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            label={(!hideLabel) ? obj.label : null}
            placeholder={(!hideLabel) ? obj.placeholder : obj.label}
            value={model[obj.name]}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'file':
        return (
          <UiFilesUpload name={obj.name}
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            label={obj.label}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'file-multiple':
        return (
          <UiFilesUpload name={obj.name} multiple
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            label={obj.label}
            onChange={handleChange ? handleChange.bind(this) : null}
            onBlur={handleBlur ? handleBlur.bind(this) : null}
            tooltipMsg={obj.tooltipMsg} />
        );
      case 'hidden':
        if (isFilter) {
          return (
            <UiSearch name={obj.name} type="hidden"
              disabled={disabled || obj.readonly}
              label={(!hideLabel) ? obj.label : null}
              placeholder={(!hideLabel) ? obj.placeholder : obj.label}
              value={model[obj.name]}
              onChange={handleChange ? handleChange.bind(this) : null}
              onBlur={handleBlur ? handleBlur.bind(this) : null}
              tooltipMsg={obj.tooltipMsg} />
          );
        } else {
          return <input type="hidden" value={model[obj.name]} name={obj.name} />
        }
      case 'repeat':
        return (
          <UiRepeatInput
            obj={obj}
            model={model}
            required={obj.mandatory}
            disabled={disabled || obj.readonly}
            hideLabel={hideLabel}
            apiReducer={apiReducer}
            currentUser={currentUser}
            tooltipMsg={obj.tooltipMsg}
            maxLength={obj.maxLength}
            inputType={obj.inputType}
            handleChange={handleChange ? handleChange.bind(this) : null}
            handleBlur={handleBlur ? handleBlur.bind(this) : null} />
        );
      case 'image':
        return <UiDisplayImageField obj={obj} tooltipMsg={obj.tooltipMsg} hideLabel={hideLabel} />
      default:
        return null;
    }
  }
}
