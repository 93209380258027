//GLOBAL
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

//UI
import { CCol, CRow, CCollapse } from '@coreui/react-pro';

//UTILS
import ComponentsUtils from '../../utils/componentsUtils';

//COMPONENTS
import UiButton from './UiButton';
import UiSelect from './UiSelect';

const propTypes = {
  fields: PropTypes.array,
  apiReducer: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSearchChange: PropTypes.func,
  currentSectionId: PropTypes.string,
  section: PropTypes.string
};

function UiBasicGridFilters(props) {

  const [currentSectionId, setCurrentSectionId] = useState();
  const [fields, setFields] = useState();
  const [apiReducer, setApiReducer] = useState();
  const [filters, setFilters] = useState([]);
  const [allFilters, setAllFilters] = useState([]);
  const [settedFilters, setSettedFilters] = useState([]);
  const [newFilter, setNewFilter] = useState();
  const [openCollapse, setOpenCollapse] = useState(false);

  useEffect(() => {
    let sFilters = !!props.section ? JSON.parse(localStorage.getItem(`${props.currentSectionId}${props.section}Filters`)) : JSON.parse(localStorage.getItem(`${props.currentSectionId}Filters`));
    setSettedFilters(sFilters?.filter ?? []);
  }, [])

  useEffect(() => {
    const oldFilters = cloneDeep(filters);
    if (currentSectionId === props.currentSectionId && isEqual(fields, props.fields) && isEqual(apiReducer, props.apiReducer)) {
      return;
    }
    setCurrentSectionId(props.currentSectionId);
    setFields(props.fields);
    setApiReducer(props.apiReducer);

    let newFilters = [];
    let newAllFilters = [];
    let newOpenCollapse = false;

    if (props.fields && props.fields.length > 0) {
      let globalFilters = props.fields.filter((obj) => obj.position > 0);
      globalFilters = globalFilters.sort((a, b) => ((a.position > b.position) ? 1 : ((a.position < b.position) ? -1 : ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0))));
      if (globalFilters && globalFilters.length > 0) {
        globalFilters.map((field, index) => {
          if (field.reducer && props.apiReducer && props.apiReducer[field.reducer]) {
            field.values = props.apiReducer[field.reducer].data;
          }
          if (!field.mandatory) {
            const nn = (field.alreadyTranslated) ? i18n.t('SelectValues.null') : 'null';
            const nullValue = (field.fieldReturned) ? {} : nn;
            if (field.fieldReturned) {
              nullValue[field.fieldReturned] = 'null';
              nullValue[field.fieldDisplayed] = i18n.t('SelectValues.null');
            }
            if (field.values) {
              if (field.values.length > 0) {
                let values = [...field.values];
                if ((field.fieldReturned && field.values.filter((obj) => obj[field.fieldReturned] === 'null').length === 0)
                  || (!field.fieldReturned && field.values.indexOf(nn) < 0)) values.unshift(nullValue);
                field.values = values;
              } else {
                field.values = [nullValue];
              }
            }
          }
          const prevFilterVal = (settedFilters.findIndex(obj => obj.key === field.name) > 0) ? settedFilters.find(obj => obj.key === field.name).value : null;
          field.visible = ((oldFilters.length > 0 && oldFilters[index].name === field.name && oldFilters[index].visible) ||
            (prevFilterVal !== null && prevFilterVal !== undefined && prevFilterVal !== '') || globalFilters.length < 5);
          newFilters.push(field);
          if (!field.visible) {
            newAllFilters.push(field.label);
          } else {
            if (index > 1) {
              newOpenCollapse = true;
            }
          }
        });
        if (newFilters[0]) {
          newFilters[0].visible = true;
          newAllFilters = newAllFilters.filter(obj => obj !== newFilters[0].label);
        }
        if (newFilters[1]) {
          newFilters[1].visible = true;
          newAllFilters = newAllFilters.filter(obj => obj !== newFilters[1].label);
        }
        let sFilters = !!props.section ? JSON.parse(localStorage.getItem(`${props.currentSectionId}${props.section}Filters`)) : JSON.parse(localStorage.getItem(`${props.currentSectionId}Filters`));
        if (sFilters?.filter.length > 0) {
          newFilters.forEach((filter, key) => {
            sFilters.filter.forEach(settedFilter => {
              if (filter.name === settedFilter.key) {
                filter.visible = true;
                newAllFilters = newAllFilters.filter(obj => obj !== filter.label);

                if (key > 1) newOpenCollapse = true;
              }
            });
          });
        }
      }

      setAllFilters(newAllFilters);
      setFilters(newFilters);
      setOpenCollapse(newOpenCollapse);
    }
  }, [props])

  function toggleCollapse() {
    setOpenCollapse(!openCollapse);
  }

  function handleOnChange(event) {
    let sFilters = cloneDeep(settedFilters);
    const index = sFilters.findIndex((obj) => obj.key === event.target.name);
    if (index >= 0) {
      sFilters[index].visible = true;
      sFilters[index].value = event.target.value;
    } else {
      const filter = filters.find((obj) => obj.name === event.target.name);
      let newFilter = {};
      newFilter.key = event.target.name;
      newFilter.value = event.target.value;
      newFilter.compatibility = (filter && filter.type === 'input') ? 'like' : 'equals';
      newFilter.criteria = 'AND';
      sFilters.push(newFilter);
    }
    setSettedFilters(sFilters);
    if (props.onChange) props.onChange(sFilters);
  }

  function handleOnNewFilter(event) {
    let vFilters = cloneDeep(filters);
    let index = vFilters.findIndex(obj => obj.label === event.target.value);
    if (index >= 0) {
      vFilters[index].visible = true;
    }
    setAllFilters(allFilters.filter(obj => obj !== vFilters[0].label));
    setFilters(vFilters);
    setNewFilter(null);
  }

  function clearAllFilters() {
    setSettedFilters([]);
    setOpenCollapse(false);
    if (props.onChange) props.onChange('clear_all');
  }

  function renderFilter(filter, index) {
    if (!filter.visible) return null;
    let model = {};
    if (settedFilters) {
      const sFilter = settedFilters.find(obj => obj.key === filter.name);
      if (sFilter) {
        model[sFilter.key] = sFilter.value;
      }
    }
    return (
      <CCol sm="12" md="6" lg="6" xl="6" key={`grid-filter-${index}`}>
        <CRow>
          {ComponentsUtils.renderByType(true, props.disabled, false, filter, model, handleOnChange.bind(this), (props.onSearchChange) ? props.onSearchChange.bind(this) : null)}
        </CRow>
      </CCol>
    );
  }

  return (
    <I18n ns="translations">
      {t => (
        <div>
          <CRow>
            {filters.length > 0 && renderFilter(filters[0], 0)}
            {filters.length > 1 && renderFilter(filters[1], 1)}
          </CRow>
          <CRow>
            <CCol sm="12" md="12" lg="12" xl="12">
              {filters.length > 2 && (
                <CCollapse id="collapsedFilters" className="navbar-collapse" visible={openCollapse} >
                  <CRow>
                    {filters.map((filter, index) => {
                      if (index < 2 || !filter.visible) {
                        return null;
                      } else {
                        return renderFilter(filter, index);
                      }
                    })}
                  </CRow>
                  {allFilters.length > 0 && (
                    <CRow key={index}>
                      <UiSelect name="newFilter" label={t('Common.add_filter')} disabled={props.disabled}
                        value={newFilter} values={allFilters} nullable={true}
                        onChange={handleOnNewFilter.bind(this)} />
                    </CRow>
                  )}
                </CCollapse>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" md="12" lg="12" xl="12" className={'ButtonsContainer mt-2 mb-2 me-2'}>
              <div className='filterBtn'>
                {filters.length > 2 && (
                  <UiButton txtClassName="textBtn" styleCont={{marginRight: '5px'}} data-target="#collapsedFilters" aria-controls="collapsedFilters" aria-expanded={openCollapse} aria-label={t('Common.show_more')}
                    icon={openCollapse ? 'cis-minus' : 'cis-filter'} label={openCollapse ? t('Common.hide_more') : t('Common.show_more')}
                    disabled={props.disabled === true} onClick={toggleCollapse.bind(this)} />
                )}
              </div>
              <div style={{marginRight: '5px' }} className='filterBtn'>
                {filters.length > 0 && (
                  <UiButton txtClassName="textBtn" icon={'cis-minus'} label={t('Common.clear_all')} onClick={clearAllFilters.bind(this)} />
                )}
              </div>
            </CCol>
          </CRow>
        </div>
      )}
    </I18n>
  );
}

UiBasicGridFilters.propTypes = propTypes;
export default UiBasicGridFilters;
