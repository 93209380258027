// GLOBAL
import PropTypes from 'prop-types';
import { I18n } from 'react-i18next';
import CIcon from '@coreui/icons-react';
import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import { CRow, CFormLabel, CButton, CTooltip, CProgress, CFormInput } from '@coreui/react-pro';
// UTILS
import ObjectsUtils from '../../utils/objectsUtils';

// COMPONENTS
import UiInput from './UiInput';
import UiButton from './UiButton';
import { useState } from 'react';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  deletable: PropTypes.bool,
  videoId: PropTypes.string,
  weight: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};
function UiVideoUpload(props) {

  const [state, setState] = useState({
    oldProp: null,
  })

  useEffect(() => {
    setState(prevState => {
      let newState = cloneDeep(prevState);
      if (!newState.oldProp || !ObjectsUtils.areStringsEqual(newState.oldProp.name, props.name) || !ObjectsUtils.areStringsEqual(newState.oldProp.filename, props.filename) || !ObjectsUtils.areStringsEqual(newState.oldProp.value, props.value)) {
        newState = {
          oldProp: {
            name: props.name,
            filename: props.filename ? props.filename : null,
            value: props.value,
          },
          file: null,
          filename: props.filename ? props.filename : null,
          videoPreviewUrl: props.value ? props.value : '',
          uploadStatus: (props.value && props.value.length > 0) ? 'UPLOADED' : (props.file) ? 'UPLOADING' : '',
          /** State variables to control Drag and drop/File selection upload */
          isDragging: false,
          hasVideo: !!props.value,
        };
      }
      return newState;
    })
  }, [props])

  // static getDerivedStateFromProps(props, prevState) {
  //   let newState = cloneDeep(prevState);
  //   if (!newState.oldProp || !ObjectsUtils.areStringsEqual(newState.oldProp.name, props.name) || !ObjectsUtils.areStringsEqual(newState.oldProp.filename, props.filename) || !ObjectsUtils.areStringsEqual(newState.oldProp.value, props.value)) {
  //     newState = {
  //       oldProp: {
  //         name: props.name,
  //         filename: props.filename ? props.filename : null,
  //         value: props.value,
  //       },
  //       file: null,
  //       filename: props.filename ? props.filename : null,
  //       videoPreviewUrl: props.value ? props.value : '',
  //       uploadStatus: (props.value && props.value.length > 0) ? 'UPLOADED' : (props.file) ? 'UPLOADING' : '',
  //       /** State variables to control Drag and drop/File selection upload */
  //       isDragging: false,
  //       hasVideo: !!props.value,
  //     };
  //   }
  //   return newState;
  // }

  function dispatchChange(newState) {
    if (newState.file || !newState.oldProp || (newState.filename !== newState.oldProp.filename && newState.videoPreviewUrl)) {
      const event = {
        target: {
          name: props.name,
          video_name: (newState.filename) ? newState.filename : (newState.file) ? newState.file.name : null,
          video: newState.file,
        },
      };
      if (props.onChange) props.onChange(event);
    }
  }

  function handleOnNameChange(event) {
    const newState = cloneDeep(state);
    newState.filename = event ? event.target.value : null;
    setState(newState);
  }

  function handleOnNameBlur() {
    dispatchChange(state);
  }

  function handleVideoSubmit(file) {
    if (!props.disabled && file) {
      let newState = cloneDeep(state);
      newState.file = file;
      newState.videoPreviewUrl = null;
      newState.uploadStatus = 'UPLOADING';

      setState(newState);

      dispatchChange(newState);
    }
  }

  function handleVideoDelete() {
    if (!props.disabled && state.hasVideo) {
      setState(() => ({
        file: null,
        filename: null,
        videoPreviewUrl: '',
        hasVideo: false,
      }));

      const event = {
        target: {
          name: props.name,
        },
      };
      if (props.onDelete) props.onDelete(event);
    }
  }

  function handleVideoChange(ev) {
    ev.preventDefault();

    const file = ev.target.files[0];

    if (file) {
      handleVideoSubmit(file);
    }
  }

  function handleDragOver(ev) {
    ev.preventDefault();

    if (!props.disabled && !state.isDragging) {
      setState({ isDragging: true });
    }
  }

  function handleDrop(ev) {
    ev.preventDefault();

    const file = ev.dataTransfer.files[0];

    if (!props.disabled && file) {
      handleVideoSubmit(file);
    }
  }

  function handleDragLeave() {
    if (!props.disabled && state.isDragging) {
      setState({ isDragging: false });
    }
  }

  function renderFilenameToShow() {
    const filename = state.file ? state.file.name : null;
    const nameLenght = filename ? filename.length : 0;

    if (nameLenght <= 35) return filename;

    return `${filename.slice(0, 20)} ... ${filename.slice(nameLenght - 6, nameLenght)}`;
  }

  function renderUploadingBar() {
    return (
      <div className="previewPlaceholder noImage">
        <div className="placeholderContent noImage">
          {/* <p className={`placeholderMessage`}>{state.uploadStatus}:</p> */}
          <p className="placeholderMessage">{renderFilenameToShow()}</p>
          <CProgress color="success" value={100} />
        </div>
      </div>
    );
  }

  function renderPlaceholder(t) {
    return (
      <div className={`previewPlaceholder${state.isDragging ? ' dragInFile' : ''} ${!state.hasVideo ? ' noImage' : ''}`}>
        <div className={`placeholderContent${!state.hasVideo ? ' noImage' : ''}`}>
          <p className="placeholderMessage">{t('Common.dnd_video_here')}</p>
          {!state.hasVideo
            && (
              <UiButton className="UiAddImageButton" disabled={props.disabled}
                label={t('Common.select_video')}
                onClick={() => (UiInputFile.click())} />
            )}
        </div>
      </div>
    );
  }

  /* renderVideoUrl(target) {
    let resizer = '';
    let rect = (target !== undefined && target !== null) ? target.getBoundingClientRect() : null;
    if(rect && rect.height > rect.width){
      resizer = '?height='+rect.height;
    } else if (rect){
      resizer = '?width='+rect.width;
    }
    return state.videoPreviewUrl + resizer;
  } */

  function renderVideoUrl() {
    return `${state.videoPreviewUrl}?height=300&width=500`;
  }

  function renderActions(t) {
    return (
      <div className="UiImageActions">
        <div className="UiImageName">
          <p>
            MB: {props.weight ? props.weight : '-'} -{' '}
            {props.width ? props.width : '-'} x{' '}
            {props.height ? props.height : '-'} px
          </p>
        </div>
        <div className="UiImageChange">
          <CTooltip placement="top" content={t('Tooltips.change_image')}>
            <CButton id={`changeImageAction-${props.videoId}`} variant="ghost" color="primary" disabled={props.disabled}
              onClick={() => UiInputFile.click()}>
              <CIcon className="UiImageAction" icon="cis-pencil" />
            </CButton>
          </CTooltip>
        </div>
        {props.deletable && (
          <div className="UiImageRemove">
            <CTooltip placement="top" content={t('Tooltips.delete_image')}>
              <CButton id={`deleteImageAction-${props.videoId}`} variant="ghost" color="primary" disabled={props.disabled}
                onClick={handleVideoDelete.bind(this)}>
                <CIcon icon="cis-trash" className="UiImageAction" />
              </CButton>
            </CTooltip>
          </div>
        )}
      </div>
    );
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiPictureUpload">
          {props.label ? (
            <CFormLabel className="UiMainLabel">
              {t(props.label)} {props.required ? '*' : ''}
            </CFormLabel>
          ) : (
            ''
          )}
          <div className="Detail_imageCover UiImagePreview previewComponent">
            {state.videoPreviewUrl && state.videoPreviewUrl.length > 0 && (
              <UiInput type="text" className="UiInputPicture" name="videoName" disabled={props.disabled}
                placeholder={props.disabled ? '' : t('Common.placeholder_filename')}
                value={state.filename}
                onChange={handleOnNameChange.bind(this)} onBlur={handleOnNameBlur.bind(this)} />
            )}
            <div draggable className={`UiPictureUploadPreview ${state.isDragging ? ' dragInFile' : ''} ${state.hasVideo ? ' videoPreview' : ''}`}
              onDragEnter={handleDragOver.bind(this)} onDragOver={handleDragOver.bind(this)}
              onDragLeave={handleDragLeave.bind(this)} onDrop={handleDrop.bind(this)}>
              {(state.uploadStatus === 'UPLOADING') ? renderUploadingBar() : (!state.videoPreviewUrl || state.videoPreviewUrl.length <= 0) ? renderPlaceholder(t) : null}
              {!!state.videoPreviewUrl && state.videoPreviewUrl.length > 0
                && (
                  <div className="imagePlaceholder" ref={(videoPlaceholder) => videoPlaceholder = videoPlaceholder}>
                    <video width="320" height="240" controls>
                      <source src={state.videoPreviewUrl} type="video/mp4" />
                    </video>
                  </div>
                )}
            </div>
            <CRow>
              <CFormInput type="file" ref={UiInputFile => (UiInputFile = UiInputFile)}
                id="UiInputFile" className="UiInputFile" name="UiInputFile" disabled={props.disabled}
                onChange={handleVideoChange.bind(this)} />

              {state.uploadStatus ? (
                renderActions(t)
              ) : (
                <div className="UiImageActions" />
              )}
            </CRow>
          </div>
        </div>
      )}
    </I18n>
  );
}

UiVideoUpload.propTypes = propTypes;

export default UiVideoUpload;
