import React from 'react';
import { Link, matchPath } from 'react-router-dom';
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react-pro';
import i18n from 'i18next';

const UiBreadcrumb = props => {
  const { routes, router, location } = props;

  const getPaths = pathname => {
    const paths = [{ id: '/', url: '/' }];

    if (pathname === '/') return paths;

    pathname.split('/').reduce((prev, curr) => {
      const currPath = {};
      currPath.id = curr;
      currPath.url = `${prev}/${curr}`;
      paths.push(currPath);
      return currPath.url;
    });
    return paths;
  };

  const findRoute = path => {
    let aroute = routes.find(route => {
      let fixed_route = route.path.split('/');
      fixed_route.forEach((el, key) => {
        if (el.includes('?')) fixed_route.splice(key, 1)
      });
      fixed_route = fixed_route.join('/');
      return matchPath(fixed_route, path.url);
    });
    if (!aroute) {
      aroute = routes.find(route => route.child === path.id);
      if (aroute) {
        aroute.replaced = true;
      } else {
        aroute = routes.find(route => route.name === path.url.slice(1).replace('/','s&'));
        if (aroute) {
          aroute.replaced = true;
          aroute.overridePrev = true;
        }
      }
    }
    return aroute;
  };

  const renderBreadcrumbItems = () => {
    const paths = getPaths(location.pathname);
    const items = [];
    if (paths.length) {
      paths.map(path => {
        const route = findRoute(path);
        if (route) {
          const index = items.findIndex(obj => obj.breadcrumbName === route.breadcrumbName);
          if (index >= 0) {
            items.splice(index, 1);
          }
          const item = {};
          item.breadcrumbName = route.breadcrumbName;
          item.breadcrumbUrl = route.replaced ? route.path : path.url;
          if (route.overridePrev) {
            items.pop();
          }
          items.push(item);
          return item;
        }
        return null;
      });
    }

    if (items.length <= 0) return null;

    return (
      <>
        {items.map((item, i) => {
          const active = i === items.length - 1;
          if (!active) {
            return (
              <CBreadcrumbItem key={`breadcrumb-${i}`} active={active}>
                <Link to={item.breadcrumbUrl}>{i18n.t(item.breadcrumbName)}</Link>
              </CBreadcrumbItem>
            );
          }
          return (
            <CBreadcrumbItem key={`breadcrumb-${i}`} active={active}>
              {i18n.t(item.breadcrumbName)}
            </CBreadcrumbItem>
          );
        })}
      </>
    );
  };

  const renderBreadcrumbRouter = () => (
    <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3 text-capitalize" routes={routes} />
  );

  return (
    <CBreadcrumb className="border-0 c-subheader-nav m-0 px-0 px-md-3">
      {router ? renderBreadcrumbRouter() : renderBreadcrumbItems()}
    </CBreadcrumb>
  );
};

export default UiBreadcrumb;
