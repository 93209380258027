import React, { useEffect, useState, useRef } from 'react';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import Tiff from 'tiff.js';
import PropTypes from 'prop-types';

//UI
import { CRow, CFormLabel, CButton, CTooltip, CProgress, CFormInput } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';

// UTILS
import ObjectsUtils from '../../utils/objectsUtils';

// COMPONENTS
import UiButton from './UiButton';
import UiInput from './UiInput';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  imageId: PropTypes.string,
  filename: PropTypes.string,
  disabled: PropTypes.bool,
};

function UiCoverUpload(props) {
  const [oldProp, setOldProp] = useState();
  const [tiffRendered, setTiffRendered] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [file, setFile] = useState();
  const [filename, setFilename] = useState();
  const [fileurl, setFileurl] = useState();
  const [uploadStatus, setUploadStatus] = useState();
  const [isFullScreen, setIsFullScreen] = useState();
  const [isDragging, setIsDragging] = useState();
  const [hasImage, sethasImage] = useState();

  const fullcanvas = useRef()
  const imagePlaceholder = useRef()
  const canvas = useRef()
  let UiInputFile = useRef()

  useEffect(() => {
    if (!oldProp || !ObjectsUtils.areStringsEqual(oldProp.name, props.name) || !ObjectsUtils.areStringsEqual(oldProp.filename, props.filename) || !ObjectsUtils.areStringsEqual(oldProp.value, props.value)) {
      setOldProp({
        name: props.name,
        filename: (props.filename) ? props.filename : null,
        value: props.value,
      })
      setFile(null);
      setFilename((props.filename) ? props.filename : null);
      setFileurl(null);
      setImagePreviewUrl((props.value) ? props.value : '');
      setTiffRendered(false);
      setUploadStatus((props.value && props.value.length > 0) ? 'UPLOADED' : (props.file) ? 'UPLOADING' : '');
      setIsFullScreen(false);
      setIsDragging(false);
      sethasImage(!!props.value);
    }
  }, [props])


  useEffect(() => {
    if (imagePreviewUrl && (imagePreviewUrl.indexOf('.tiff') === (imagePreviewUrl.length - 5))) {
      if (!tiffRendered) {
        renderTiff(imagePreviewUrl);
        setTiffRendered(true);
      }
    }
  }, [imagePreviewUrl, tiffRendered])

  function dispatchChange(newState) {
    if (file || fileurl || !oldProp || (filename !== oldProp.filename && imagePreviewUrl)) {
      setOldProp(null);
      const event = {
        target: {
          name: props.name,
          image_name: (filename) ? filename : (file) ? file.name : null,
          file: file,
          fileurl: fileurl,
        },
      };
      if (props.onChange) props.onChange(event);
    }
  }

  // function handleOnNameChange(event) {
  //   const newState = cloneDeep(state);
  //   newState.filename = (event) ? event.target.value : null;
  //   setState(newState);
  // }

  // function handleOnNameBlur() {
  //   dispatchChange(state);
  // }

  function handleImageSubmit(file) {
    if (!props.disabled && file) {
      setFile(file);
      setImagePreviewUrl('');
      setUploadStatus('UPLOADING')
      dispatchChange();
    }
  }

  function handleImageDelete() {
    if (!props.disabled && hasImage) {
      setFile(null);
      setFilename(null);
      setFileurl(null);
      setImagePreviewUrl('');
      sethasImage(false);

      dispatchChange();
    }
  }

  function handleImageChange(ev) {
    ev.preventDefault();

    const file = ev.target.files[0];

    if (file) {
      handleImageSubmit(file);
    }
  }

  function handleDragOver(ev) {
    ev.preventDefault();

    if (!props.disabled && !isDragging) {
      setIsDragging(true);
    }
  }

  function handleDrop(ev) {
    ev.preventDefault();

    const file = ev.dataTransfer.files[0];

    if (!props.disabled && file) {
      handleImageSubmit(file);
    }
  }

  function handleDragLeave() {
    if (!props.disabled && isDragging) {
      setIsDragging(true);
    }
  }

  function handleOnChange(inputevent) {
    if (!props.disabled && fileurl !== inputevent.target.value) {
      let file, tmpfilename, fileurl, imagePreviewUrl, hasImage, uploadStatus;
      if (!inputevent.target.value && props.value && props.value.length > 0) {
        file = null;
        tmpfilename = filename;
        fileurl = inputevent.target.value;
        imagePreviewUrl = props.value;
        hasImage = true;
        uploadStatus = 'UPLOADED';
      } else {
        file = null;
        tmpfilename = filename;
        fileurl = inputevent.target.value;
        imagePreviewUrl = '';
        hasImage = false;
        uploadStatus = 'UPLOADING';
      }
      setFile(file);
      setFilename(tmpfilename);
      setFileurl(fileurl);
      setImagePreviewUrl(imagePreviewUrl);
      sethasImage(hasImage);
      setUploadStatus(uploadStatus);
    }
  }

  function handleOnBlur(inputevent) {
    if (props.disabled || !inputevent || !inputevent.target || (!inputevent.target.value && !fileurl)) {
      return;
    }

    setFile(null);
    setFileurl(fileurl);
    setFileurl(inputevent.target.value);
    setUploadStatus('UPLOADING');

    dispatchChange();
  }

  function handleToogleFullScreen() {
    setIsFullScreen(!isFullScreen);
  }

  function renderFilenameToShow() {
    const filename = (file) ? file.name : null;
    const nameLenght = (filename) ? filename.length : 0;

    if (nameLenght <= 35) return filename;

    return `${filename.slice(0, 20)} ... ${filename.slice(nameLenght - 6, nameLenght)}`;
  }

  function renderUploadingBar() {
    return (
      <div className="previewPlaceholder noImage">
        <div className="placeholderContent noImage">
          {/* <p className={`placeholderMessage`}>{uploadStatus}:</p> */}
          <p className="placeholderMessage">{renderFilenameToShow()}</p>
          <CProgress color="success" value={100} />
        </div>
      </div>
    );
  }

  function renderPlaceholder(t) {
    return (
      <div className={`previewPlaceholder${isDragging ? ' dragInFile' : ''} ${!hasImage ? ' noImage' : ''}`}>
        <div className={`placeholderContent${!hasImage ? ' noImage' : ''}`}>
          <p className="placeholderMessage">{t('Common.dnd_image_here')}</p>
          <CRow className="UiCoverUpload_cover">
            {!hasImage && (
              <UiButton className="UiAddImageButton" disabled={props.disabled}
                label={t('Common.select_image')}
                onClick={() => UiInputFile.click()}
              />
            )}
          </CRow>
        </div>
      </div>
    );
  }

  /* renderImageUrl(target) {
    let resizer = '';
    let rect = (target !== undefined && target !== null) ? target.getBoundingClientRect() : null;
    if(rect && rect.height > rect.width){
      resizer = '?height='+rect.height;
    } else if (rect){
      resizer = '?width='+rect.width;
    }
    return imagePreviewUrl + resizer;
  } */

  function renderImageUrl() {
    return `${imagePreviewUrl}?height=300&width=500`;
  }

  function renderTiff(filename) {
    const canvasNode = canvas;
    const fullCanvasNode = fullcanvas;

    if (canvasNode) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'arraybuffer';
      xhr.open('GET', filename);
      xhr.onload = () => {
        const tiff = new Tiff({ buffer: xhr.response });
        const width = tiff.width();
        const height = tiff.height();
        const ratio = width / height;
        const originalCanvas = tiff.toCanvas();

        let parentWidth, parentHeight;

        const contWidth = imagePlaceholder.getBoundingClientRect().width;
        const contHeight = imagePlaceholder.getBoundingClientRect().height;
        if (ratio < 1) {
          parentHeight = contHeight;
          parentWidth = Math.floor(ratio * contHeight);
        } else {
          parentWidth = contWidth;
          parentHeight = Math.floor(contWidth / ratio);
        }

        const resizedCanvas = document.createElement('canvas');
        const resizedContext = resizedCanvas.getContext('2d');
        resizedCanvas.width = parentWidth;
        resizedCanvas.height = parentHeight;

        resizedContext.drawImage(originalCanvas, 0, 0, parentWidth, parentHeight);

        while (canvasNode.firstChild) {
          canvasNode.removeChild(canvasNode.firstChild);
        }
        canvasNode.appendChild(resizedCanvas);

        if (fullCanvasNode) {
          let fullParentWidth, fullParentHeight;

          const fullContWidth = window.innerWidth;
          const fullContHeight = window.innerHeight;
          if (ratio < 1) {
            fullParentHeight = fullContHeight;
            fullParentWidth = Math.floor(ratio * fullContHeight);
          } else {
            fullParentWidth = fullContWidth;
            fullParentHeight = Math.floor(fullContWidth / ratio);
          }

          const fullResizedCanvas = document.createElement('canvas');
          const fullResizedContext = fullResizedCanvas.getContext('2d');
          fullResizedCanvas.width = fullParentWidth;
          fullResizedCanvas.height = fullParentHeight;

          fullResizedContext.drawImage(originalCanvas, 0, 0, fullParentWidth, fullParentHeight);

          while (fullCanvasNode.firstChild) {
            fullCanvasNode.removeChild(fullCanvasNode.firstChild);
          }
          fullCanvasNode.appendChild(fullResizedCanvas);
        }
        setTiffRendered(true);
      };

      xhr.send();
    }
  }

  function renderFullScreen() {
    return (
      <div className={`UiFullScreen ${isFullScreen ? '' : 'hidden'}`}>
        <div className="UiFullScreenToggle" onClick={handleToogleFullScreen.bind(this)}>
          <CIcon icon="cis-x" />
        </div>
        <div className="UiFullScreenImage">
          {imagePreviewUrl && imagePreviewUrl.length > 0 && isFullScreen && (
            <div>
              {imagePreviewUrl.indexOf('.tiff') !== imagePreviewUrl.length - 5 && (
                <img alt={props.name} src={`${imagePreviewUrl}?height=${window.innerHeight}`} />
              )}
              {imagePreviewUrl.indexOf('.tiff') === imagePreviewUrl.length - 5 && (
                <div ref={fullcanvas} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderActions(t) {
    return (
      <div className="UiCoverImageActions">
        <div className="UiImageName">
          <p title={props.value}>{hasImage ? renderFilenameToShow() : t('Common.no_image')}</p>
        </div>
        <div className="UiImageDetail">
          <CTooltip placement="top" content={t('Tooltips.image_fullscreen')}>
            <CButton id={`fullscreenImageAction-${props.imageId}`} className="btn-ghost-primary" color="primary" variant="ghost"
              onClick={handleToogleFullScreen.bind(this)}>
              <CIcon icon="cis-search" className="UiImageAction" />
            </CButton>
          </CTooltip>
        </div>
        <div className="UiImageChange">
          <CTooltip placement="top" content={t('Tooltips.change_image')}>
            <CButton id={`changeImageAction-${props.imageId}`} className="btn-ghost-primary" color="primary" variant="ghost" disabled={props.disabled}
              onClick={() => UiInputFile.click()}>
              <CIcon icon="cis-pencil" size="sm" className="UiImageAction" />
            </CButton>
          </CTooltip>
        </div>
        <div className="UiImageRemove">
          <CTooltip placement="top" content={t('Tooltips.delete_image')}>
            <CButton id={`deleteImageAction-${props.imageId}`} className="btn-ghost-primary" color="primary" variant="ghost" disabled={props.disabled}
              onClick={handleImageDelete.bind(this)}>
              <CIcon icon="cis-trash" className="UiImageAction" size="sm" />
            </CButton>
          </CTooltip>
        </div>
      </div>
    );
  }

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiPictureUpload">
          <div className="UiPicture_container">
            {renderFullScreen()}
            {props.label ? (
              <CFormLabel className="UiMainLabel">
                {t(props.label)} {props.required ? '*' : ''}
              </CFormLabel>
            ) : (
              ''
            )}
            <div className="Detail_imageCover previewComponent">
              {/* <UiInput type='text' name='imageName' placeholder={(props.disabled)? '' : t('Common.placeholder_filename')}
                  value={filename} disabled={props.disabled}
                  onChange={handleOnNameChange} onBlur={handleOnNameBlur}/> */}
              <div draggable className={`UiPictureUploadPreview ${isDragging ? ' dragInFile' : ''} ${hasImage ? ' imagePreview' : ''}`}
                onDragEnter={handleDragOver.bind(this)} onDragOver={handleDragOver.bind(this)}
                onDragLeave={handleDragLeave.bind(this)} onDrop={handleDrop.bind(this)}>
                {(uploadStatus === 'UPLOADING') ? renderUploadingBar() : (!imagePreviewUrl || imagePreviewUrl.length <= 0) ? renderPlaceholder(t) : null}
                {imagePreviewUrl && imagePreviewUrl.length > 0 && (
                  <div className="imagePlaceholder" ref={imagePlaceholder}>
                    {imagePreviewUrl.indexOf('.tiff') !== imagePreviewUrl.length - 5 && (
                      <img className="previewImage" alt={props.name} src={renderImageUrl()} />
                    )}
                    {imagePreviewUrl.indexOf('.tiff') === imagePreviewUrl.length - 5 && (
                      <div className="previewImage" ref={canvas} />
                    )}
                  </div>
                )}
              </div>
              <div className="UiCover_or">
                <p>{t('Common.or')}</p>
                <UiInput type="text" name="fileurl" disabled={props.disabled}
                  placeholder={props.disabled ? '' : t('Common.placeholder_fileurl')}
                  value={fileurl}
                  onChange={handleOnChange.bind(this)} onBlur={handleOnBlur.bind(this)} />
              </div>
              <div>
                <CFormInput type="file" ref={node => {
                    if (node) {
                      UiInputFile = node;
                    }
                  }}
                  accept={props.accept}
                  id="UiInputFile" className="UiInputFile" name="UiInputFile" disabled={props.disabled}
                  onChange={handleImageChange.bind(this)} />

                {uploadStatus ? renderActions(t) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </I18n>
  );
}

UiCoverUpload.propTypes = propTypes;

export default UiCoverUpload;
