import BaseService from './baseService';

export default class UserAddressesService extends BaseService {

    static instance = null;

    static getInstance(store) {
        if (!UserAddressesService.instance) {
            UserAddressesService.instance = new UserAddressesService(store);
        }
        return UserAddressesService.instance;
    }

    constructor(store) {
        let baseUrl = process.env.API_PATH;
        super(store, baseUrl, 'user_addresses', 'user_address');
        this.pathName = 'addresses';
    }

    async getList(userId, filterObj, isMapFilter, lang, token, okCallBack, koCallBack) {
        if (isMapFilter) {
            if (filterObj.type) {
                return await super.get(this.listName, encodeURI('users/' + this.pathName + `?and^latitude=lte_${filterObj?.n?.lat}&and^latitude=gte_${filterObj?.s?.lat}&and^longitude=lte_${filterObj?.e?.lng}&and^longitude=gte_${filterObj?.w?.lng}&and^type=${filterObj?.type}`), lang, token, null, okCallBack, koCallBack);
            }else{
                return await super.get(this.listName,encodeURI( 'users/' + this.pathName + `?and^latitude=lte_${filterObj?.n?.lat}&and^latitude=gte_${filterObj?.s?.lat}&and^longitude=lte_${filterObj?.e?.lng}&and^longitude=gte_${filterObj?.w?.lng}`) , lang, token, null, okCallBack, koCallBack);
            }
        } else {
            if(filterObj?.type){
                return await super.get(this.listName, encodeURI('users/' + this.pathName + `?and^id=${userId}&and^type=${filterObj?.type}`), lang, token, null, okCallBack, koCallBack);
            }else{
                return await super.get(this.listName, encodeURI('users/' + this.pathName + `?and^id=${userId}`), lang, token, null, okCallBack, koCallBack);

            }
        }

    }

    addItem(obj, lang, token, okCallBack, koCallBack) {
        return super.post(this.itemName, 'users/' + obj.user_id + '/' + this.pathName, lang, token, obj, okCallBack, koCallBack);
    }

    updateItem(itemId, obj, lang, token, okCallBack, koCallBack) {
        return super.put(this.itemName, 'users/' + itemId + '/' + this.pathName + '/' + obj.id, lang, token, obj, okCallBack, koCallBack);
    }

    updateItemCustomId(contentId, obj, lang, token, okCallBack, koCallBack) {
        return super.put(this.itemName, 'users/' + this.pathName + '/' + contentId, lang, token, obj, okCallBack, koCallBack);
    }

    deleteItem(userId, contentId, lang, token, okCallBack, koCallBack) {
        function deleteKoCallBack(error) {
            this.getItem(contentId, lang, token, koCallBack, koCallBack, error);
        }
        return super.delete(this.itemName, 'users/' + userId + '/' + this.pathName + '/' + contentId, lang, token, okCallBack, deleteKoCallBack.bind(this));
    }
}