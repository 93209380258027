import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CRow, CCol, CCard, CFormInput, CButton, CCardBody, CContainer, CInputGroup, CCardFooter, CInputGroupText } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { cilLockLocked, cilUser } from '@coreui/icons';

// UTILS
import withRouter from '../../utils/withNavigation';

// COMPONENTS
import { mapStateToProps } from './AbstractComponent';

class Register extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol sm="12" md="6" lg="6" xl="6">
              <CCard className="mx-4">
                <CCardBody className="p-4">
                  <h1>Register</h1>
                  <p className="text-muted">Create your account</p>
                  <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                    <CFormInput type="text" placeholder="Username" />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                      <CInputGroupText>@</CInputGroupText>
                    <CFormInput type="text" placeholder="Email" />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                    <CFormInput type="password" placeholder="Password" />
                  </CInputGroup>
                  <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                    <CFormInput type="password" placeholder="Repeat password" />
                  </CInputGroup>
                  <CButton color="success" block>
                    Create Account
                  </CButton>
                </CCardBody>
                <CCardFooter className="p-4">
                  <CRow>
                    <CCol sm="12" md="6" lg="6" xl="6">
                      <CButton className="btn-facebook" block>
                        <span>facebook</span>
                      </CButton>
                    </CCol>
                    <CCol sm="12" md="6" lg="6" xl="6">
                      <CButton className="btn-twitter" block>
                        <span>twitter</span>
                      </CButton>
                    </CCol>
                  </CRow>
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Register));
