import React from 'react';
import { CButton, CTooltip } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';

const UiButton = props => {
  const { tag, type, icon, className, classNameContainer, tooltipMsg, txtClassName } = props;
  return (
    <I18n ns="translations">
      {t => (
        <div className={`UiButton_container ${classNameContainer || 'primaryColor'}${tooltipMsg ? ' UiButton_container_tooltip' : ''}`} style={props.styleCont}>
          <CButton tag={tag || (type || 'button')} className={`UiButton ${className || ''} `}
            size={props.size} style={props.style} block={props.block} shape={props.shape} color={props.color || 'primary'}
            active={props.active} pressed={props.pressed} variant={props.variant} disabled={props.disabled}
            onClick={props.onClick}>
            {icon ? (
              <span className={`${props.label ? 'me-2' : ''}`} >
                <CIcon icon={icon} />
              </span>
            ) : (
              ''
            )}
            <span className={`${icon ? '' : 'w100'} ${txtClassName}`} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {t(props.label)}
            </span>
          </CButton>
          {tooltipMsg && (
            <span className="UiButton_container_tooltip">
              <CTooltip placement="top" content={t(tooltipMsg)}>
                <CIcon icon="cis-info-circle" size="sm" />
              </CTooltip>
            </span>
          )}
        </div>
      )}
    </I18n>
  );
};

export default UiButton;
