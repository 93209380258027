import React, { useState, useEffect } from 'react';
import { I18n } from 'react-i18next';
import i18n from 'i18next';

//core ui
import { CFormLabel, CRow, CCol, CCardBody, CCard, CTableHead, CTableBody, CTableDataCell, CTable, CTableHeaderCell, CTableRow, CBadge } from '@coreui/react-pro';


//components
import UiMap from '../ui/UiMap';
import UiInput from '../ui/UiInput';
import UiButton from '../ui/UiButton';
import UiAutocomplete from '../ui/UiAutocomplete';
import AbstractComponent, { mapStateToProps } from './AbstractComponent';
import UiRangeSlider from '../ui/UiRangeSlider';
import UiCheckboxGroup from '../ui/UiCheckboxGroup';

//services
import ConstantsService from '../../services/constantsService';
import UserAddressesService from '../../services/userAddressesService';
import CompanyAddressesService from '../../services/companyAddressesService';
import MapService from '../../services/mapService';
import CitiesService from '../../services/citiesService';

//utils
import store from '../../redux/store';
import { connect } from 'react-redux';
import withRouter from '../../utils/withNavigation';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';


class Map extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      sliderVal: 0,
      city: null,
      user: null,
      company: null,
      address: null,
      userError: false,
      companyError: false,
      markers: [],
      userAddressType: ['home', 'work', 'domicile'],
      companyAddressType: ['headquarter', 'registered_office', 'operational_office'],
      currentMarkerSelected: null,
    }

    this.userAddressesService = UserAddressesService.getInstance(store);
    this.companyAddressesService = CompanyAddressesService.getInstance(store);
    this.mapService = MapService.getInstance(store);
    this.citiesService = CitiesService.getInstance(store);
    this.allSelectedCompany = true;
    this.allSelectedUser = true;

    this.tmpResponse = [];
    this.counter = 0;
  }

  componentDidUpdate() {
    console.log("STATE", this.state)
  }

  handleFiltersChange(evt) {
    this.setState({
      sliderVal: evt.target.value
    })
  }

  handleOnChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    })
  }

  handleMinChange() {
    this.setState({
      sliderVal: 0
    })
  }

  handleMaxChange() {
    this.setState({
      sliderVal: 50
    })
  }

  handleOnChangeCheckbox(evt) {
    if (evt.target.name === 'userAddressType') {
      this.allSelectedUser = evt?.target?.allSelected;
    } else {
      this.allSelectedCompany = evt?.target?.allSelected;
    }

    this.setState({
      [evt.target.name]: evt.target.value
    })
  }

  koCallback(response, firstError) {
    if (firstError) {
      return toast.error(`${i18n.t('Common.error')}: ${ObjectsUtils.buildError(firstError)}`, { autoClose: 5000 });
    }
    else if (response?.status > 399 || response?.data?.status > 399) {
      return toast.error(`${i18n.t('Common.error')}: ${ObjectsUtils.buildError(response)}`, { autoClose: 5000 });
    } else {
      return toast.error(`${i18n.t('Common.address_not_found')}`, { autoClose: 5000 });
    }
  }

  setMarkersData(res, type, both) {
    let markers = both ? [{...this.state.currentMarkerSelected, showWindow: false}] : [];
    if (res.length > 0) {
      const results = res.map((address) => {
        const cloneAddress = cloneDeep(address);
        if (address.coordinates.lat !== null && address.coordinates.lng !== null) {
          cloneAddress.coordinates.lat = parseFloat(address.coordinates.lat);
          cloneAddress.coordinates.lng = parseFloat(address.coordinates.lng);
          let obj = {};
          if (cloneAddress.hasOwnProperty('user_id')) {
            obj = {
              coordinates: cloneAddress.coordinates,
              address: `${cloneAddress.address},${cloneAddress.city}`,
              user: cloneAddress.full_name,
              type: cloneAddress.type

            }
          } else if (cloneAddress.hasOwnProperty('company_id')) {
            obj = {
              coordinates: cloneAddress.coordinates,
              address: `${cloneAddress.address},${cloneAddress.city}`,
              company: cloneAddress.label,
              type: cloneAddress.type

            }
          }

          return obj;
        }

      });
      if (results.every((value) => value == null)) {
        const property = type === 'user' ? 'userError' : 'companyError'
        this.setState({
          [property]: true
        })
      } else {
        results.forEach((el) => {
          if (el !== null) {
            let obj = {};
            if (el.hasOwnProperty('user')) {
              obj = {
                coordinates: el.coordinates,
                showWindow: false,
                category: 'user',
                address: el.address,
                user: el.user,
                type: el.type,
                active: false
              }
            } else if (el.hasOwnProperty('company')) {
              obj = {
                coordinates: el.coordinates,
                showWindow: false,
                category: 'company',
                address: el.address,
                company: el.company,
                type: el.type,
                active: false
              }
            }

            markers.push(obj);
          }
        })
        const property = type === 'user' ? 'userError' : 'companyError'
        this.setState({
          [property]: false
        })
      }

    } else {
      const property = type === 'user' ? 'userError' : 'companyError'
      this.setState({
        [property]: true
      })
    }
    return markers;
  }

  asyncWrapper(filterObj, addressType, type) {
    if (type === 'user') {
      const newFilterObj = {
        ...filterObj,
        ...(addressType !== null && { type: addressType })
      };
      return new Promise((resolve, reject) => {
        this.userAddressesService.getList(
          this.state.user, newFilterObj, filterObj ? true : false, this.props.globalServiceReducer.currentLanguage, this.props.globalServiceReducer.currentUser, (evt) => {
            resolve(evt.data);
          }, (error) => {
            reject(error);
            // if (error.status === 401) {
            // 	setTimeout(this.handleMap, 3000)
            // 	return
            // }
          }
        );
      });
    } else if (type === 'company') {
      const newFilterObj = {
        ...filterObj,
        ...(addressType !== null && { type: addressType })
      };
      return new Promise((resolve, reject) => {
        this.companyAddressesService.getList(
          this.state.company, newFilterObj, filterObj ? true : false, this.props.globalServiceReducer.currentLanguage, this.props.globalServiceReducer.currentUser, (evt) => {
            resolve(evt.data);
          }, (error) => {
            reject(error);
            // if (error.status === 401) {
            // 	setTimeout(this.handleMap, 3000)
            // 	return
            // }
          }
        );
      });
    }
  }

  async manageAddressesApiCall(type, isRange, concat) {
    let res = [];
    let filterObj = {};
    let promises;
    const addressType = type === 'user' ? this.state.userAddressType : type === 'company' ? this.state.companyAddressType : [];
    if (isRange) {
      filterObj = this.movePoint(this.state.currentMarkerSelected.coordinates.lat, this.state.currentMarkerSelected.coordinates.lng, this.state.sliderVal);
    }
    if (addressType.length === 1 || addressType.length === 2) {
      try {
        if (addressType.length === 1) {
          promises = await this.asyncWrapper(isRange ? filterObj : null, addressType[0], type === 'user' ? 'user' : 'company');
          res = promises;
        } else {
          promises = addressType.map((addressType) => {
            return this.asyncWrapper(isRange ? filterObj : null, addressType, type === 'user' ? 'user' : 'company');
          })
          const results = await Promise.all(promises);
          res = addressType.length === 2 ? [].concat(...results) : results;
        }
      } catch (error) {
        //TODO mettere gestione errore chiamat per il range indirizzi
        this.koCallback(error);
        console.error('Errore durante le chiamate API:', error);
      }
    } else if (addressType.length === 3) {
      try {
        promises = await this.asyncWrapper(isRange ? filterObj : null, null, type === 'user' ? 'user' : 'company');
        res = promises;
      } catch (error) {
        //TODO mettere gestione errore chiamata per il range indirizzi
        this.koCallback(error);
        console.error('Errore durante le chiamate API:', error);
      }
    }
    if (concat) {
      this.tmpResponse = [...res, ...this.tmpResponse];
      this.counter++;
      if (this.counter === 2) {
        const markers = this.setMarkersData(this.tmpResponse, 'companyUser', isRange ? true : false);
        this.setState({
          markers: markers
        })
        if (markers.length === 1) {
          this.koCallback();
        }
      }
    } else {
      const markers = this.setMarkersData(res, type === 'user' ? 'user' : 'company', isRange ? true : false);
      this.setState({
        markers: markers
      })
      if (markers.length === 0) {
        this.koCallback();
      }
    }

  }

  //function that set the markers on the map
  handleMap = async () => {
    if (this.state.currentMarkerSelected !== null && this.state.sliderVal) {

      if (this.state.currentMarkerSelected?.hasOwnProperty('company')) { //make user range research starting from a selected company
        this.manageAddressesApiCall('user', true);
      } else if (this.state.currentMarkerSelected?.hasOwnProperty('user')) { //make company range research starting from a selected user
        this.manageAddressesApiCall('company', true);
      } else {
        this.manageAddressesApiCall('user', true, true);
        this.manageAddressesApiCall('company', true, true);
      }

    } else {
      if (this.state.user) {
        this.manageAddressesApiCall('user', false);
      }

      if (this.state.company) {
        this.manageAddressesApiCall('company', false);

      }

      if (Object.keys(this.state.city).length > 0) {
        let markerObj = {};
        if (this.state.address === null) {
          //only city selected
          if (this.state.city.coordinates.lat === null || this.state.city.coordinates.lng === null) {
            let res = await this.mapService.findAddress(this.state.city.city);
            if (res !== null) {
              markerObj = {
                coordinates: res,
                showWindow: false,
                active: false,
                category: 'address',
                address: this.state.city.city
              }
              const updateObj = {
                lat: res.lat,
                lng: res.lng
              }
              delete updateObj.coordinates;
              this.citiesService.updateItemCustomId(this.state.city.id, updateObj, this.props.globalServiceReducer.currentLanguage, this.props.globalServiceReducer.currentUser, () => { }, this.koCallback.bind(this));
            } else {
              //TODO: GESTIONE ERRRORE NEL CASO IN CUI CHIAMATA A GOOGLE NON REWSTITUISCE COORDINATE PER CITTà CERCATA
            }

          } else {
            markerObj = {
              coordinates: this.state.city.coordinates,
              showWindow: false,
              active: false,
              category: 'address',
              address: this.state.city.city
            }
          }
        } else {
          //city and address selected
          const address = address !== null ? `${this.state.address},${this.state.city.city}` : this.state.city.city;
          let res = await this.mapService.findAddress(address);
          if (res !== null) {
            markerObj = {
              coordinates: res,
              showWindow: false,
              active: false,
              category: 'address',
              address: address
            }
          } else {
            //TODO: GESTIONE ERRRORE NEL CASO IN CUI CHIAMATA A GOOGLE NON REWSTITUISCE COORDINATE PER INDIRIZZO CERCATO
          }

        }

        this.setState({
          markers: [markerObj]
        })

      }
    }

  };

  toRadians(degrees) {
    return degrees * Math.PI / 180;
  }

  toDegrees(radians) {
    return radians * 180 / Math.PI;
  }

  movePoint(lat, lng, distance) {
    const R = 6371; // Earth radius in km

    let newLngWest;
    let newLngEast;
    let newLatNorth;
    let newLatSouth;

    const latInRad = this.toRadians(lat);
    const deltaLon = distance / (R * Math.cos(latInRad));
    const deltaLonInDegrees = this.toDegrees(deltaLon);
    newLngWest = lng - deltaLonInDegrees;
    newLngEast = lng + deltaLonInDegrees;

    const deltaLat = distance / R;
    const deltaLatInDegrees = this.toDegrees(deltaLat);
    newLatNorth = lat + deltaLatInDegrees;
    newLatSouth = lat - deltaLatInDegrees;

    return {
      n: {
        lat: newLatNorth,
        lng: lng
      },
      s: {
        lat: newLatSouth,
        lng: lng
      },
      w: {
        lat: lat,
        lng: newLngWest
      },
      e: {
        lat: lat,
        lng: newLngEast
      }
    }
  }

  handleCurrentPosition = (data) => {
    if (data.active) {
      this.setState({
        currentMarkerSelected: data,
      })
    } else {
      this.setState({
        currentMarkerSelected: null
      })
    }
  };

  deleteAllFilters() {
    this.setState({
      user: null,
      company: null,
      city: {},
      sliderVal: 0,
      userAddressType: ['home', 'work', 'domicile'],
      companyAddressType: ['headquarter', 'registered_office', 'operational_office'],
      markers: [],
      currentMarkerSelected: null
    })
  }

  renderTableHead(t) {
    return (
      <CTableRow>
        <CTableHeaderCell>{t('Table.category')}</CTableHeaderCell>
        <CTableHeaderCell>{t('Table.address')}</CTableHeaderCell>
        <CTableHeaderCell>{t('Table.address_type')}</CTableHeaderCell>
      </CTableRow>
    )
  }

  render() {
    return (
      <I18n ns="translations">
        {
          (t) => (
            <>
              <CRow className='row-map-cont'>
                <CCol sm="12" md="3" lg="3" xl="3" style={{ height: '90%' }}>
                  <CCard className='card-map'>
                    <CCardBody>
                      <CRow>
                        <CCol sm="12" md="12" lg="12" xl="12">
                          <UiAutocomplete name="user" label="Section.user" route="user" reducer="users" currentUser={this.props.currentUser} apiReducer={this.props.apiReducer}
                            values={this.props.apiReducer?.users?.data?.length > 0 ? this.props.apiReducer?.users?.data : []} disabled={(this.state.city !== null ?Object.keys(this.state.city).length > 0 : this.state.city !== null) || this.state.company !== null || this.state?.currentMarkerSelected?.hasOwnProperty('user') || this.state?.currentMarkerSelected?.hasOwnProperty('company')}
                            value={this.state.user} fieldDisplayed="username" fieldReturned={ConstantsService.defaultDBIdentifier}
                            onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)} onChange={this.handleOnChange.bind(this)} invalid={this.state.userError} feedbackInvalid={t('Section.not_valid_address')}
                          />
                          <UiCheckboxGroup
                            name="userAddressType"
                            values={ConstantsService.user_address_type}
                            label={!this.allSelectedUser ? "Common.select_all" : "Common.uncheck_all"}
                            inline={false}
                            value={this.state?.userAddressType}
                            onChange={this.handleOnChangeCheckbox.bind(this)}
                            fieldDisplayed='name'
                            fieldReturned='value'
                            style={{ marginBottom: '20px' }}
                            disabled={this.state?.currentMarkerSelected?.hasOwnProperty('user') || (this.state.company !== null && !this.state.currentMarkerSelected?.hasOwnProperty('company'))}
                          />
                        </CCol>
                        <CCol sm="12" md="12" lg="12" xl="12">
                          <UiAutocomplete name="company" label="Section.company" route="company" reducer="companies" currentUser={this.props.currentUser} apiReducer={this.props.apiReducer}
                            values={this.props.apiReducer?.companies?.data} disabled={(this.state.city !== null ?Object.keys(this.state.city).length > 0 : this.state.city !== null)  || this.state.user !== null || this.state?.currentMarkerSelected?.hasOwnProperty('company') || this.state?.currentMarkerSelected?.hasOwnProperty('user')}
                            value={this.state.company} fieldDisplayed="company_name" fieldReturned={ConstantsService.defaultDBIdentifier}
                            onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)} onChange={this.handleOnChange.bind(this)} invalid={false} feedbackInvalid={t('Section.not_valid_address')}
                          />
                          <UiCheckboxGroup
                            name="companyAddressType"
                            values={ConstantsService.companies_address_type}
                            label={!this.allSelectedCompany ? "Common.select_all" : "Common.uncheck_all"}
                            inline={false}
                            value={this.state?.companyAddressType}
                            onChange={this.handleOnChangeCheckbox.bind(this)}
                            fieldDisplayed='name'
                            fieldReturned='value'
                            style={{ marginBottom: '20px' }}
                            disabled={this.state?.currentMarkerSelected?.hasOwnProperty('company') || (this.state.user !== null && !this.state.currentMarkerSelected?.hasOwnProperty('user'))}
                          />
                        </CCol>
                        <CCol sm="12" md="12" lg="12" xl="12">
                          <UiAutocomplete name="city" label="Table.city_id" route="city" reducer="cities" currentUser={this.props.currentUser} apiReducer={this.props.apiReducer}
                            values={this.props.apiReducer?.cities?.data} disabled={this.state.user !== null || this.state.company !== null || this.state?.currentMarkerSelected?.hasOwnProperty('user') || this.state?.currentMarkerSelected?.hasOwnProperty('company')}
                            value={this.state.city} fieldDisplayed="city" onValueChanged={this.handleOnForcedChange.bind(this)} onSearchChange={this.handleOnSearchChange.bind(this)}
                            onChange={this.handleOnChange.bind(this)}
                          />
                        </CCol>
                        {
                          this.state.city !== null && Object.keys(this.state.city)?.length > 0  && <CCol sm="12" md="12" lg="12" xl="12">
                            <UiInput type="text" name="address" label="Table.address" className="map-address"
                              value={this.state.address}
                              onChange={this.handleOnChange.bind(this)}
                              disabled={!Object.keys(this.state.city)?.length > 0}
                              invalid={this.state.showAddressError}
                              feedbackInvalid={t('Section.not_valid_value')}

                            />
                          </CCol>
                        }
                        <CCol style={{ marginTop: '30px' }}>
                          <UiRangeSlider
                            min={0}
                            max={50}
                            step={5}
                            disabled={this.state.currentMarkerSelected === null}
                            value={this.state.sliderVal}
                            onChange={this.handleFiltersChange.bind(this)}
                            handleMinChange={this.handleMinChange.bind(this)}
                            handleMaxChange={this.handleMaxChange.bind(this)}
                            showMarksPlacement={true}
                            color="#B12009"
                            label="addresses_range"
                          />
                          {
                            (!this.state.currentMarkerSelected) && <p className='fw-bold'>{t('Common.select_address')}</p>
                          }
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol sm="12" md="9" lg="9" xl="9" className='rightCol'>
                  <UiMap
                    markers={this.state.markers}
                    handleCurrentPosition={this.handleCurrentPosition.bind(this)}

                  />
                  <CRow className='mt-4 d-flex justify-content-center'>
                    <CCol sm="12" md="4" lg="4" xl="4"></CCol>
                    <CCol sm="6" md="4" lg="4" xl="4" style={{ height: '40px' }}>
                      <UiButton label={t('Common.view_on_map')} style={{ marginBottom: '50px' }} onClick={this.handleMap.bind(this)} disabled={(this.state.currentMarkerSelected && this.state.sliderVal < 1 || (this.state.user === null && this.state.company === null && (this.state.city !== null ? Object.keys(this.state.city).length === 0 : this.state.city !== null)))} />
                    </CCol>
                    <CCol sm="6" md="4" lg="4" xl="4" style={{display: 'flex', justifyContent: 'end'}}>
                    {
                        (
                          <UiButton icon="cis-trash-x" label="Cancella filtri" classNameContainer={"btn-map-delete-filter"} onClick={this.deleteAllFilters.bind(this)} disabled={!((this.state.user !== null || this.state.company !== null || (this.state.city !== null ?  Object.keys(this.state.city).length > 0 : this.state.city !== null)) && this.state.markers.length > 0)}/>
                        )
                      }
                    </CCol>
                  </CRow>
                  {this.state.currentMarkerSelected &&
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <CCol sm="6" md="4" lg="4" xl="4">
                        <p className='fw-bold text-center'>{t('Common.nearby_search')}</p>
                      </CCol>
                    </div>
                  }
                </CCol>
              </CRow>
              {
                this.state?.markers?.length > 0 && (
                  <>
                    <CTable bordered className='map-table'>
                      <CTableHead>
                        {
                          this.renderTableHead(t)
                        }
                      </CTableHead>
                      <CTableBody>
                        {
                          this.state.markers.map((marker, index) => {
                            return (
                              <CTableRow key={index + Date.now}>
                                <CTableDataCell>{marker.category} {((this.state.currentMarkerSelected?.address === marker?.address && this.state?.currentMarkerSelected?.type === marker?.type)) && <CBadge style={{ marginLeft: '10px', backgroundColor: '#B12009' }}>{i18n.t(`Table.active`)}</CBadge>}</CTableDataCell>
                                <CTableDataCell>{marker.address}</CTableDataCell>
                                <CTableDataCell>{t(`SelectValues.${marker.type}`)}</CTableDataCell>
                              </CTableRow>
                            )
                          })
                        }
                      </CTableBody>
                    </CTable></>
                )
              }
              {this.renderToast()}
            </>
          )
        }
      </I18n>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Map));