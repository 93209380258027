//GLOBAL
import React from 'react'
import { connect } from 'react-redux';

//UI
import { CCard, CCardBody, CCol, CRow } from '@coreui/react-pro';

//UTILS
import withRouter from '../../utils/withNavigation';
import store from "../../redux/store";
import ConstantsService from '../../services/constantsService';
import JobApplicationsService from '../../services/jobApplicationsService';

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiSelectClassic from '../ui/UiSelectClassic';
import UiCheckbox from '../ui/UiCheckbox';
import UiMdEditor from '../ui/UiMdEditor';
import UiButton from '../ui/UiButton';
import UiTranslationsTabs from '../ui/UiTranslationsTabs';

class JobApplication extends AbstractDetail {

  constructor(props) {
    super(props);

    this.state.language = 'it';
    this.state.translations = [
      { locale: 'it' },
      { locale: 'en' },
    ];
  }

  componentDidMount() {
    this.populateServices();
  }

  populateServices() {
    this.sectionService = JobApplicationsService.getInstance(store);
  }

  handleLanguageChange(evt) {
    this.setState({ language: evt.target.value })
  }

  handleDuplicate() {
    function navigateCallback(evt) {
      this.okCallback();
      this.props.navigate('/job_application/' + evt.data.id);
    }
    this.sectionService.duplicateItem(this.state.model, this.globalService.currentLanguage, this.globalService.currentUser, navigateCallback.bind(this), this.koCallback.bind(this));
  }

  handleDisable() {
    if (this.state.model.disable_date === null) {
      this.sectionService.deleteItem((this.computedItemId) ? this.computedItemId : this.itemId, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
    } else {
      this.sectionService.reactivateItem((this.computedItemId) ? this.computedItemId : this.itemId, this.state.model, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
    }
  }

  renderChildren(t) {
    return (
      <>

        <UiCard className="mt-4 colored-header" icon="cis-briefcase" title={t('Table.description')} collapsible={false}>
          <UiTranslationsTabs tabs={[{ locale: 'it' }, { locale: 'en' }]}
            activeTab={this.state.language}
            onTab={(locale) => this.setState({ language: locale })}
          >
            <div>
              {
                this.state.translations && this.state.translations.map((translation, index) => (
                  <div key={translation.locale}>
                    <CRow>
                      <CCol sm="12" md="6" lg="6" xl="6">
                        <UiInput type="text" name={this.state.language === 'it' ? "title_it" : "title_en"}
                          label="Table.title"
                          disabled={!this.state.editable}
                          required={this.state.mandatoryFields.indexOf(this.state.language === 'it' ? 'title_it' : 'title_en') >= 0}
                          value={this.state.language === 'it' ? this.state.model.title_it : this.state.model.title_en}
                          onFocus={this.handleOnFocus.bind(this)}
                          onChange={this.handleOnChange.bind(this)}
                          onBlur={this.handleOnBlur.bind(this)}
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <UiMdEditor name={this.state.language === 'it' ? "description_it" : "description_en"}
                        value={this.state.language === 'it' ? this.state.model.description_it : this.state.model.description_en}
                        disabled={!this.state.editable}
                        onFocus={this.handleOnFocus.bind(this)}
                        onChange={this.handleOnChange.bind(this)}
                        onBlur={this.handleOnBlur.bind(this)}
                      />
                    </CRow>
                  </div>
                ))
              }
            </div>
          </UiTranslationsTabs>
        </UiCard>
        <UiCard className="mt-4 colored-header" icon="cis-briefcase" title={t('Table.contractual_data')} collapsible={false}>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiSelectClassic name='type' label={'Table.type'}
                value={this.state.model.type}
                values={ConstantsService.job_types}
                nosort={true}
                onValueChanged={this.handleOnChange.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                fieldDisplayed={"label"} fieldReturned={"value"}
                alreadyTranslated={false} forceTranslation={true}
              />
            </CCol>
            <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
              <UiSelectClassic name='mode' label={'Table.site'}
                value={this.state.model.mode}
                values={ConstantsService.mode_types}
                nosort={true}
                onValueChanged={this.handleOnChange.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                fieldDisplayed={"label"} fieldReturned={"value"} forceTranslation={true}
                alreadyTranslated={false}
              />
            </CCol>
          </CRow>
          <CRow>
            <UiCheckbox name="academy" label="Section.academy"
              disabled={!this.state.editable}
              required={this.state.mandatoryFields.indexOf('academy') >= 0}
              value={this.state.model.academy}
              onFocus={this.handleOnFocus.bind(this)}
              onChange={this.handleOnChange.bind(this)}
              onBlur={this.handleOnBlur.bind(this)}
            />
          </CRow>
        </UiCard>
        <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', gap: '30px' }} className='mt-4'>
          <UiButton label={t('Common.duplicate')} onClick={this.handleDuplicate.bind(this)} />
          <UiButton label={this.state.model.disable_date === null ? t('Common.disable') : t('Common.reactivate')} onClick={this.handleDisable.bind(this)} />
        </div>
        <br />
        <br />
      </>
    )
  }


}

export default connect(mapStateToProps)(withRouter(JobApplication));