import {
  CRow,
  CCol,
  CFormInput,
  CButton,
  CContainer,
  CInputGroup,
  CInputGroupText,
} from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import React, { Component } from 'react';

class Page404 extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol sm="12" md="6" lg="6" xl="6">
              <div className="clearfix">
                <h1 className="float-left display-3 me-4">404</h1>
                <h4 className="pt-3">Oops! You're lost.</h4>
                <p className="text-muted float-left">The page you are looking for was not found.</p>
              </div>
              <CInputGroup className="input-prepend">
                <CInputGroupText>
                  <CIcon icon="cis-search" />
                </CInputGroupText>
                <CFormInput size="16" type="text" placeholder="What are you looking for?" />
                <CInputGroupText>
                  <CButton color="info">Search</CButton>
                </CInputGroupText>
              </CInputGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

export default Page404;
