import { combineReducers, configureStore } from '@reduxjs/toolkit'
import thunk from "redux-thunk";

//REDUCERS
import globalServiceReducer from './globalServiceDuck';
import apiReducer from './apiReducer';
import tokenReducer from './tokenReducer';

const reducer = combineReducers({
  globalServiceReducer,
  apiReducer,
  tokenReducer,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'globalServiceDuck/currentSessionChangedAction', 
          'globalServiceDuck/currentSessionTabChangedAction',
          'globalServiceDuck/setCurrentBreakpointAction',
          'apiReducer/requestAction', 
          'apiReducer/receivedAction', 
          'apiReducer/invalidateAction',
          'tokenReducer/receiveTokenAction',
          'tokenReducer/invalidateTokenAction'
        ],
        ignoredPaths: ['tokenReducer.error', 'payload']
      },
      thunk
    }),
  devTools: process.env.NODE_ENV !== 'production'
})

export default store;
