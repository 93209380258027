// GLOBAL
import React, { useState, useEffect, useCallback } from 'react';
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import PropTypes from 'prop-types';

// SERVICES
import ConstantsService from '../../services/constantsService';

//COMPONENTS
import UiModal from './UiModal';
import UiSelectClassic from './UiSelectClassic';

const propTypes = {
  onTab: PropTypes.func,
  disabled: PropTypes.bool,
  onAddLanguage: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.object), //? Array<{id: string, route: string}>
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

function UiTranslationsTabs(props) {

  const [currentTab, setCurrentTab] = useState();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [currentLng,setCurrentLng] = useState('');

  const memoizedHandleAddLng = useCallback(handleAddLng, []);
  const memoizedHandleLngChange = useCallback(handleLngChange, []);

  useEffect(() => {
    let { activeTab } = props;
    if (props.tabs.length > 0 && (!activeTab || !props.tabs.find(obj => obj.locale === activeTab))) {
      activeTab = props.tabs[0].locale;
    }
    setCurrentTab(activeTab);
  }, [props])

  function getComponent(key) {
    if (props.children && props.children.props.children) {
      if (props.children.props.children.length > 0) {
        return props.children.props.children.filter(comp => comp.key === key);
      } else if (props.children.props.children.key === key) {
        return props.children.props.children;
      }
    }

    return <div />;
  }

  function handleAddLng() {
    setIsAddModalOpen(prevState => !prevState);
  }

  function handleLngChange(evt){
    setCurrentLng(evt.target.value);
  }

  const sortedTabs = (props.tabs) ? props.tabs.sort((a, b) => ((a.locale > b.locale) ? -1 : ((a.locale < b.locale) ? 1 : 0))) : [];

  return (
    <I18n ns="translations">
      {t => (
        <div className="UiTranslationsTabs UiNavsTabs">

          <CNav variant="tabs" role="tablist">
            <CNavItem key="title">
              <div className="nav-link UiTranslation_title">
                <strong>{t('Common.translations')}</strong>
              </div>
            </CNavItem>
            {sortedTabs && sortedTabs.length > 0 && sortedTabs.map((i, key) => (
              <CNavItem onClick={() => props.onTab(i.locale)} key={key} className="UiTranslationsTabLocale">
                <CNavLink data-tab={i.locale}
                  className={props.activeTab === i.locale ? 'active' : ''}
                  onClick={props.onTab.bind(this, i.locale)}
                >
                  {i.locale}
                </CNavLink>
              </CNavItem>
            ))}
            {!props.disabled &&
              (!sortedTabs || sortedTabs.length < ConstantsService.languagesList.length) && (
                <div key="add" onClick={memoizedHandleAddLng} className="UiTranslationsTabLocale test">
                  <div className="nav-link clickable">
                    <CIcon icon="cis-plus" title={t('Common.add_translation')} />
                  </div>
                </div>
              )}
          </CNav>
          {sortedTabs && sortedTabs.length > 0 && (
            <CTabContent >
              {sortedTabs && sortedTabs.map((i, key) => (
                <CTabPane key={key} data-tab={i.locale} visible={i.locale === currentTab}>
                  {getComponent(i.locale)}
                </CTabPane>
              ))}
              <UiModal
                title={t('Common.add_translation')}
                isOpen={isAddModalOpen}
                creation
                onCancel={memoizedHandleAddLng}
              >
                <div>
                  <UiSelectClassic name={`language`} label={'Table.language'}
                    values={ConstantsService.languagesList}
                    value={currentLng}
                    nosort={true}
                    onChange={memoizedHandleLngChange}
                    alreadyTranslated={true}
                  />
                </div>
              </UiModal>
            </CTabContent>
          )}
        </div>
      )}
    </I18n>
  );

}

UiTranslationsTabs.propTypes = propTypes;
export default UiTranslationsTabs;
