import React, { StrictMode } from 'react';
import { render } from 'react-dom';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

import App from './app';
import i18n from './i18n'; //! do not remove

/* Sentry.init({
  dsn: 'https://f9f4d09cc27144de9a4e0792fc1268f5@o491884.ingest.sentry.io/5558190',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
}); */

// Styles
// CoreUI Icons Set
// import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set

import { icons } from '../assets/icons';
// import 'flag-icon-css/css/flag-icon.min.css';
import 'flag-icons/css/flag-icons.min.css'
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import '../styles/style.scss';

React.icons = icons;

const rootElement = document.getElementById('index');
render(
  <>
    <App />
  </>,
  rootElement
);
