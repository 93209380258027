// GLOBAL
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CRow, CCol, CCard, CFormInput, CButton, CContainer, CCardGroup, CCardBody, CInputGroup, CInputGroupText, CLink } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { I18n } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

// STORE
import store from '../../redux/store';

// UTILS
import withRouter from '../../utils/withNavigation';
import ObjectsUtils from '../../utils/objectsUtils';

// SERVICES
import JWTService from '../../services/jwtService';

// COMPONENTS
import { mapStateToProps } from './AbstractComponent';

import UiLoading from '../ui/UiLoading';

import logo from '../../../images/logo.svg';
import logoWhite from '../../../images/logoWhite.svg';
import { Link } from 'react-router-dom';
class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.oauthService = JWTService.getInstance(store, process.env.LOGIN_PATH, process.env.CLIENT_ID, process.env.CLIENT_SECRET, localStorage, process.env.BASENAME);

    let loading = true;
    let code = null;
    const queryParams = this.parseLocationSearch(this.props.location.search);
    if (queryParams.code) {
      code = queryParams.code;
    } else {
      loading = false;
    }

    this.state = {
      loading: false,
      error: null,
      success: null,
      email: '',
      code: code,
      emailError: false
    };
  }

  parseLocationSearch(locationSearch) {
    const location = locationSearch && locationSearch.length > 0 ? this.props.location.search.substr(1) : '';
    const queryStrings = location.split('&');
    const queryParams = {};
    for (const query of queryStrings) {
      const keyValue = query.split('=');
      if (keyValue.length > 0) {
        queryParams[keyValue[0]] = keyValue.length > 1 ? keyValue[1] : undefined;
      }
    }
    return queryParams;
  }

  changeHandler(target, event) {

    const newState = cloneDeep(this.state);
    if (target === "email") {
      if (!ObjectsUtils.isEmailValid(event.target.value)) {
        newState['emailError'] = true;
      } else {
        newState['emailError'] = false;
      }
    }
    newState[target] = event.target.value;
    newState.error = null;
    if (target.indexOf('password') >= 0 && newState.password !== newState.confirm_password) {
      newState.error = 'Login.restore_password_mismatch';
    }
    this.setState(newState);
  }

  handleKeyPress(event = null) {
    if (event && event.key) {
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          if (!this.state.code && this.state.email) {
            this.restorePassword();
          } else if (this.state.code && this.state.password && this.state.confirm_password) {
            this.renewPassword();
          }
          break;
        // no default
      }
    }
  }

  restorePassword() {
    this.setState({ loading: true, error: null, success: null });
    function okCallback(success) {
      this.setState({ loading: false, error: null, success: 'Login.restore_password_sent' });
    }

    function koCallback(error) {
      let msg = error.message;
      if (error && error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message;
      }
      this.setState({ loading: false, error: msg, success: null });
    }

    this.oauthService.resetPassword(this.state.email, okCallback.bind(this), koCallback.bind(this));
  }

  renewPassword() {
    this.setState({ loading: true, error: null, success: null });
    function okCallback(success) {
      this.setState({ loading: false, error: null, success: success.message });
    }

    function koCallback(error) {
      let msg = error.message;
      if (error && error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message;
      }
      this.setState({ loading: false, error: msg, success: null });
    }

    this.oauthService.renewPassword(this.state.code, this.state.password, okCallback.bind(this), koCallback.bind(this));
  }

  render() {
    return (
      <I18n ns="translations">
        {t => (
          <div className="app flex-row align-items-center animated">
            <CContainer>
              <CRow className="justify-content-center">
                <CCol md="8">
                  {this.state.loading && <UiLoading height={80} width={80} />}
                  {!this.state.loading && (
                    <CCardGroup>
                      <CCard className="p-4">
                        <CCardBody>
                          <CRow>
                            <CCol>
                              <img src={logoWhite} alt="Beije - People First" className="login-brand-logo" />
                            </CCol>
                          </CRow>
                          <h2 style={{ textAlign: 'center' }}>{t('Login.forgot_password')}</h2>
                          {!this.state.code ? (
                            <>
                              {!this.state.success && (
                                <p className="text-muted mt-4">{t('Login.forgot_password_msg')}</p>
                              )}
                              {this.state.error && <p className="error mt-4">{t(this.state.error)}</p>}
                              {this.state.success && (
                                <CRow className="mt-4 go-down">
                                  <CCol sm="6" md="6" lg="6" xl="6" className='center-btn'>
                                    <p className="success mt-4">{t(this.state.success)}</p>
                                  </CCol>
                                  <CCol sm="6" className="d-flex justify-content-end align-items-center">
                                    <Link to="/login">
                                      <CButton color="secondary">{t('Login.login_btn')}</CButton>
                                    </Link>
                                  </CCol>
                                </CRow>
                              )}
                              {!this.state.success && (
                                <>
                                  <CInputGroup className=" mb-3">
                                    <CInputGroupText>@</CInputGroupText>
                                    <CFormInput type="text" placeholder={'Email'} value={this.state.email}
                                      onChange={this.changeHandler.bind(this, 'email')} onKeyPress={event => this.handleKeyPress(event)} invalid={this.state.emailError} feedbackInvalid={t('Login.valid_email')} />
                                  </CInputGroup>
                                  <CRow className="mt-4 go-down">
                                    <CCol sm="6" md="6" lg="6" xl="6" className='center-btn'>
                                      <CButton className="px-4" color="primary" disabled={!this.state.email} onClick={this.restorePassword.bind(this)}>
                                        {t('Login.forgot_password_btn')}
                                      </CButton>
                                    </CCol>
                                    <CCol sm="6" className="d-flex justify-content-end">
                                      <Link to="/login">
                                        <CButton color="secondary">{t('Login.login_btn')}</CButton>
                                      </Link>
                                    </CCol>
                                  </CRow>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <p className="text-muted mt-4">{t('Login.restore_password_msg')}</p>
                              {this.state.error && <p className="error mt-4">{this.state.error}</p>}
                              <CInputGroup className="mt-4 mb-4">
                                <CInputGroupText>
                                  <CIcon icon="cis-lock-locked" />
                                </CInputGroupText>
                                <CFormInput type="password" placeholder={t('Login.password')} value={this.state.password}
                                  onChange={this.changeHandler.bind(this, 'password')} onKeyPress={event => this.handleKeyPress(event)} />
                              </CInputGroup>
                              <CInputGroup className="mt-4 mb-4">
                                <CInputGroupText>
                                  <CIcon icon="cis-lock-locked" />
                                </CInputGroupText>
                                <CFormInput type="password" placeholder={t('Login.confirm_password')} value={this.state.confirm_password}
                                  onChange={this.changeHandler.bind(this, 'confirm_password')} onKeyPress={event => this.handleKeyPress(event)} />
                              </CInputGroup>
                              <CRow className="mt-4">
                                <CCol sm="6" md="6" lg="6" xl="6">
                                  <CButton className="px-4" color="primary" disabled={!this.state.password || this.state.password !== this.state.confirm_password}
                                    onClick={this.renewPassword.bind(this)}>
                                    {t('Login.restore_password_btn')}
                                  </CButton>
                                </CCol>
                              </CRow>
                            </>
                          )}
                        </CCardBody>
                      </CCard>
                    </CCardGroup>
                  )}
                </CCol>
              </CRow>
            </CContainer>
          </div>
        )}
      </I18n>
    );
  }
}

export default connect(mapStateToProps)(withRouter(ForgotPassword));
