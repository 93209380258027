import i18n from 'i18next';
import camelCase from 'lodash/camelCase';

import BeijeService from './beijeService';

export default class AdvancedSearchBeijeService extends BeijeService {

  buildQueryParams(filter) {
    let response = '';
    const queryParams = [];
    const reservedKeys = ['query_origin', 'paginate', 'page', 'sort', 'order', 'elastic_query'];

    if (filter) {
      for (const key in filter) {
        if (key === 'filter') {
          //if (!filter.elastic_query) {
          for (const entry of filter.filter) {
            if (entry.value === null) {
              continue;
            }
            if (!entry.key) {
              continue;
            }
            let fkey = entry.key;
            let fvalue = '';
            if (entry.value) {
              if (entry.compatibility === 'like' && entry.like_name) {
                fkey = entry.like_name;
              }
              if (process.env.API_SEARCH_TYPE === 'advanced' && fkey !== 'active') {
                fkey = (entry.criteria === 'AND') ? `and%5E${fkey}` : `or%5E${fkey}`;
                fvalue += (entry.compatibility === 'not_equals') ? '!!' : (entry.compatibility === 'like') ? '??' : '';
                fvalue += (entry.compatibility === 'greater_than') ? 'gt_' : (entry.compatibility === 'less_than') ? 'lt_' : '';
                fvalue += (entry.compatibility === 'gt_or_eq_than') ? 'gte_' : (entry.compatibility === 'lt_or_eq_than') ? 'lte_' : '';
                fvalue += (entry.compatibility === 'not_like') ? '!?' : '';
              }
              fvalue += encodeURI((entry.value.toString() !== i18n.t('SelectValues.null')) ? entry.value.toString() : 'null');
              // if (entry.compatibility === 'like') {
              //   fvalue += '%';
              //   fvalue = encodeURIComponent(fvalue);
              // }
            }

            fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(fkey) : fkey;

            if (fvalue.length > 0) {
              queryParams.push(`${fkey}=${fvalue}`);
            }
          }
          //}
        } else if (filter[key]) {
          let fkey = null;
          let envKey = null;
          let fvalue = null;

          if (reservedKeys.indexOf(key) < 0) {
            fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(key) : key;
            if (process.env.API_SEARCH_TYPE === 'advanced') {
              queryParams.push(`and%5E${fkey}=${(key.indexOf('id') !== key.length - 2 && key !== 'due_in') ? '??' : ''}${filter[key]}`);
            } else {
              queryParams.push(`${fkey}=${filter[key]}`);
            }
          } else {
            switch (key) {
              case 'paginate':
                if (process.env.API_PAGINATION_TYPE === 'index') {
                  envKey = process.env.API_PAGINATION_END_INDEX_KEY ?? 'range_end';
                  fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
                  fvalue = (filter.page ?? 1) * filter.paginate;
                  queryParams.push(`${fkey}=${fvalue}`);
                  if (!filter.page) {
                    envKey = process.env.API_PAGINATION_INDEX_KEY ?? 'page';
                    fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
                    fvalue = (process.env.API_PAGINATION_TYPE === 'index') ? 0 : 1;
                    queryParams.push(`${fkey}=${fvalue}`);
                  }
                  break;
                }
                envKey = process.env.API_PAGINATION_SIZE_KEY ?? key;
                fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
                queryParams.push(`${fkey}=${filter[key]}`);
                break;
              case 'page':
                envKey = process.env.API_PAGINATION_INDEX_KEY ?? key;
                fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
                fvalue = (process.env.API_PAGINATION_TYPE === 'index') ? (filter.page - 1) * filter.paginate : filter[key];
                queryParams.push(`${fkey}=${fvalue}`);
                break;
              case 'sort':
                envKey = process.env.API_SORT_KEY ?? key;
                fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
                queryParams.push(`${fkey}=${filter[key]}`);
                break;
              case 'order':
                envKey = process.env.API_SORT_ORDER_KEY ?? key;
                fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
                queryParams.push(`${fkey}=${filter[key]}`);
                break;
              case 'elastic_query':
                envKey = process.env.API_FULL_TEXT_SEARCH_KEY ?? key;
                fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(envKey) : envKey;
                queryParams.push(`${fkey}=${filter[key]}`);
                break;
              default:
                fkey = (process.env.API_RESPONSE_FORMAT === 'camelCase') ? camelCase(key) : key;
                queryParams.push(`${fkey}=${filter[key]}`);
            }
          }
        }
      }
    }

    for (const index in queryParams) {
      if (+index === 0) {
        response = '?';
      }
      response += queryParams[index];
      if (index < queryParams.length - 1) {
        response += '&';
      }
    }

    return response;
  }
}
