import React from 'react';
import { CDropdown, CDropdownToggle, CDropdownItem, CDropdownMenu, CTooltip } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import { iso6933LangShape } from '../../types';


const UILanguageDropdown = props => {
  const { languages, currentLanguage, changeLanguageCallback } = props;

  const changeLanguage = lang => () => {
    let changeLangEvent = new CustomEvent('changeLang');
    document.dispatchEvent(changeLangEvent);
    changeLanguageCallback(lang);
  };

  const renderLanguages = () =>
    languages.map(l => {
      const languageName = i18n.t(`Languages.${l.name}`);
      return (
        <CDropdownItem
          key={`languages-${l.id}`}
          active={currentLanguage === l.iso2}
          onClick={changeLanguage(l.iso2)}
        >
          <CIcon title={languageName} icon={'cif-' + l.icon} />
          <span className="ms-2">{languageName}</span>
        </CDropdownItem>
      )
    });

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle caret={false} className="mx-2" tag="span">
        <CTooltip placement="bottom" content={i18n.t('Common.language')}>
          <CIcon icon="cis-language" className="me-2" title="Language" />
        </CTooltip>
      </CDropdownToggle>
      <CDropdownMenu>
        {renderLanguages()}
      </CDropdownMenu>
    </CDropdown>
  );
};

UILanguageDropdown.propTypes = {
  currentLanguage: PropTypes.string,
  changeLanguageCallback: PropTypes.func,
  languages: PropTypes.arrayOf(iso6933LangShape),
};

export default UILanguageDropdown;
