import BaseService from './baseService';
import axios from 'axios';

export default class MapService extends BaseService {
    static instance = null;

    static getInstance(store) {
        if (!MapService.instance) {
            MapService.instance = new MapService(store);
        }
        return MapService.instance;
    }

    constructor(store) {
        const baseUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
        super(store, baseUrl, 'map', 'map');
    }

    async findAddress(address) {
        const key = process.env.REACT_APP_GOOGLE_API_KEY;
        let res;
        await axios
            .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`)
            .then((response) => {
                if(!response.data.results[0].hasOwnProperty('partial_match') ){
                    res = response.data.results.length > 0 ? response.data.results[0].geometry.location : null;
                }else{
                    res = false;
                }
            })
            .catch((error) => {
                console.log('Errore nella richiesta di geocodifica:', error);
                res = error;
            });
        return res;
    };
}
