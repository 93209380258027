import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEn from '../i18n/en/common.json';
import homeEn from '../i18n/en/home.json';
import languagesEn from '../i18n/en/languages.json';
import loginEn from '../i18n/en/login.json';
import mainTitlesEn from '../i18n/en/mainTitles.json';
import sectionEn from '../i18n/en/section.json';
import selectValuesEn from '../i18n/en/selectValues.json';
import tableEn from '../i18n/en/table.json';
import tooltipsEn from '../i18n/en/tooltips.json';

import commonIt from '../i18n/it/common.json';
import homeIt from '../i18n/it/home.json';
import languagesIt from '../i18n/it/languages.json';
import loginIt from '../i18n/it/login.json';
import mainTitlesIt from '../i18n/it/mainTitles.json';
import sectionIt from '../i18n/it/section.json';
import selectValuesIt from '../i18n/it/selectValues.json';
import tableIt from '../i18n/it/table.json';
import tooltipsIt from '../i18n/it/tooltips.json';


i18n
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'it',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
    },

    resources: {
      // Using hardcoded resources
      en: {
        translations: {
          ...commonEn,
          ...homeEn,
          ...languagesEn,
          ...loginEn,
          ...mainTitlesEn,
          ...sectionEn,
          ...selectValuesEn,
          ...tableEn,
          ...tooltipsEn,
        },
      },
      it: {
        translations: {
          ...commonIt,
          ...homeIt,
          ...languagesIt,
          ...loginIt,
          ...mainTitlesIt,
          ...sectionIt,
          ...selectValuesIt,
          ...tableIt,
          ...tooltipsIt,
        },
      },
    },
  });

export default i18n;
